import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Login from './components/Login'
import Register from './components/Register'
import Profile from './components/Profile'
import Confirm from "./components/EmailConfirm/confirm";
import users from "./backOffice/Users/UsersTable"
import paymentsSuccess from "./components/Payments/SuccessPayment";
import Tirage from "./components/Tirage"
import NavBar from "./components/Navbar2";
import Nous_connaitre from "./Pages/Nous_connaitre";
import concept from "./Pages/Concept";
import nos_avantages from "./Pages/Avantages";
import regles from "./Pages/Regles";
import deroulement from "./Pages/Deroulement";
import Les_gains from "./Pages/les_gains";
import {AsssistanceRoute, Auth_AsssistanceRoute, LoginRoute, PrivateRoute} from "./Services/authService";
import 'antd/dist/antd.less';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { CookieBanner } from "@palmabit/react-cookie-law";



// *************  for reset password  ********************
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Home1 from "./Pages/Home1/Home1";
import Footer from "./components/Footer";
import HeaderNavbarDashboard from "./components/HeaderNavbarDashboard";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import VerticalNavbar2 from "./components/VerticalNavbar2";
import MesParametres from "./Pages/MesParametres";
import MesPaiements from "./Pages/MesPaiements";
import {foo, getIP, isCountryNoAuthorized, IsLoggedIn} from "./Helpers";
import MesCredits from "./Pages/MesCredits";
//*********** Assistance *********************
import NousContacter from "./Pages/Nous_Contacter";
import faq_s from "./Pages/faq_s";
import condition_jeu from "./Pages/condition_jeu"
import le_jeu from "./Pages/le_jeu"
import le_tirage from "./Pages/le_tirage"
import les_gains from "./Pages/le_gain"
import securite from "./Pages/securite"
import MesNotifications from "./Pages/MesNotifications";
import Drawer from "@material-ui/core/Drawer/Drawer";
import VerificationCode from "./Pages/VerificationCode";
import Parrainage from "./Pages/Parrainage";
import confirmIpChange from "./Pages/confirmIpChange";
import RGPD from "./Pages/RGPD";
import Licence_jeu from "./Pages/Licence_Jeu";
import Transparence_et_tarif from "./Pages/Transparence_et_tarif";
import Risques from "./Pages/Risques";
import ALGORITHME_VS_MACHINES from "./Pages/ALGORITHME_VS_MACHINES";
import conditions_generales from "./Pages/Conditions_generales";
import Conditions_generales_vente from "./Pages/Conditions générales de vente";
import Politique_confidentialite from "./Pages/Politique_confidentialite";
import Mentions_legales from "./Pages/Mentions_legales";
import axios from "axios";
import Country_noAuth from "./Pages/Country_NoAuth";
import Accessiblite from "./Pages/Accessibilite";
import MesParametres_jeu from "./Pages/MesParametres_jeu";
import SuccessPaymentAbo from "./components/Payments/SuccessPaymentAbonnement";
import A_propos from "./Pages/Apropos";
import Securite_public from "./Pages/Securite_public";
import SearchResult from "./Pages/SearchResult";
import Nous_Contacter_outisde from "./Pages/Nous_Contacter_outisde";

const isLoggedIn = IsLoggedIn()

class App extends Component {

  constructor(){
    super()
    this.state = {
      loading : true,
      NoAuthorized : true,
      deconnexion:false,
      deconnexion_time:""
    }

    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];

    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (let i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  clearTimeout() {
    // if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
/*
    this.warnTimeout = setTimeout(this.warn, 16 * 1000);
*/
    if (isLoggedIn && this.state.deconnexion && this.state.deconnexion_time.length>0 ){
      console.log("inside deconnexion", parseInt(this.state.deconnexion_time));
      this.logoutTimeout = setTimeout(this.logout, parseInt(this.state.deconnexion_time) * 60 * 1000);
    }
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  /*warn() {
    alert("You will be logged out automatically in 1 minute.");
  }*/

  logout() {
    if (isLoggedIn){
      // Send a logout request to the API
      // console.log("logout...");
      localStorage.removeItem('usertoken');
      window.location.reload();
      this.destroy(); // Cleanup
    }
    else this.destroy();
  }

  destroy() {
    this.clearTimeout();

    for (let i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }


  componentDidMount() {
    let CountryCode;
    this.setState({loading : false, NoAuthorized :false})
    // getIP().then(async r =>{let ip = r.ip;
    //   await axios.post('/api/get-country', {Userip : ip}).then(r => {console.log(r.data.country)
    //     CountryCode =r.data.country})
    //   if (CountryCode === 'CW' || CountryCode === 'NL' || CountryCode === 'US' 
    //   // || CountryCode === 'FR'
    //   ){
    //     console.log("true")
    //     this.setState({loading : false, NoAuthorized :true})
    //   }else {
    //     console.log("false")
    //     this.setState({loading : false, NoAuthorized :false})
    //   }
    // })
    axios.get('/api/notifParam/deconnexion')
        .then((res) => {
          console.log(res.data.statut)
          this.setState({deconnexion : res.data.statut, deconnexion_time: res.data.parametres})
        })
  }

  render() {
    return (
        <>{this.state.loading ?<div className="spinner-center-country">
              <Spinner animation="border" role="status" variant="primary"/> </div>
        :
        <Router>
          {this.state.NoAuthorized ? <Route path="/" component={Country_noAuth} />
          :
           <>
                <div className="App" style={{marginTop :"-15px"}}>
                  <NavBar />
                  {/*<div className="pass-wrapper">*/}
                  <Container fluid style={{minHeight: "33vh"}}>

                    <Row style={{marginTop:"30px"}}>
                      {/*<VerticalNavBar />*/}
                      <Switch>
                      <Route exact path="/" component={Home1} />

                      {/* ************* public routes **************** */}
                      {/*<Route exact path="/home" component={Home} />*/}
                      <LoginRoute exact path="/register" component={Register} />
                      <LoginRoute exact path="/login" component={Login} />
                      <Route exact path="/nous_connaitre" component={Nous_connaitre} />
                      <Route exact path="/politique-de-confidentialite" component={RGPD} />
                      <Route exact path="/licence-jeu" component={Licence_jeu} />
                      <Route exact path="/transparence-et-tarif" component={Transparence_et_tarif} />
                      <Route exact path="/les-gains" component={Les_gains} />
                      <Route exact path="/risques-pour-joueur" component={Risques} />
                      <Route exact path="/grievance_book" component={ALGORITHME_VS_MACHINES} />
                      <Route exact path="/concept" component={concept} />
                      <Route exact path="/nos_avantages" component={nos_avantages} />
                      <Route exact path="/a_propos" component={A_propos} />
                      <Route exact path="/securite" component={Securite_public} />
                      <Route exact path="/regles" component={regles} />
                      <Route exact path="/deroulement" component={deroulement} />
                      <Route exact path="/conditions_generales" component={conditions_generales} />
                      <Route exact path="/conditions_generales_vente" component={Conditions_generales_vente} />
                      <Route exact path="/politique_confidentialite" component={Politique_confidentialite} />
                      <Route exact path="/Mentions_legales" component={Mentions_legales} />
                      <LoginRoute exact path='/confirm/:id' component={Confirm} />
                      <LoginRoute exact path='/changed/:token' component={confirmIpChange} />


                      {/*!// *************  for reset password  *********************/}
                      <LoginRoute exact path='/forgotPassword' component={ForgotPassword} />
                      <LoginRoute exact path='/reset/:token' component={ResetPassword} />

                      {/* ***************** Verification Code page ******************/}
                      <LoginRoute exact path='/Code-de-verification/:token' component={VerificationCode} />


                      <PrivateRoute exact path="/moncompte" component={Profile} />

                      {/* *** payment ***/}
                      {/*<PrivateRoute exact path='/payment' component={payment2} />*/}
                      <PrivateRoute exact path='/paymentSuccess' component={paymentsSuccess} />
                      <PrivateRoute exact path='/paymentSuccessAbo' component={SuccessPaymentAbo} />



                      {/* Tirage */}
                      <PrivateRoute exact path='/Tirage' component={Tirage} />

                      {/* *** Mes parametres **** */}
                      <PrivateRoute exact path='/moncompte/parametres' component={MesParametres} />
                      <PrivateRoute exact path='/moncompte/parametres_jeu' component={MesParametres_jeu} />
                      <PrivateRoute exact path='/moncompte/mespaiements' component={MesPaiements} />
                      <PrivateRoute exact path='/moncompte/notifications' component={MesNotifications} />
                      <PrivateRoute exact path='/moncompte/mescredits' component={MesCredits} />
                      <PrivateRoute exact path='/moncompte/parrainage' component={Parrainage}/>
                      <Auth_AsssistanceRoute exact path='/assistance' component={NousContacter} />
                      <AsssistanceRoute exact path='/assistance_no_auth' component={Nous_Contacter_outisde} />


                      {/* ***********************
              *  ***** FAQ'S
              * ********************** */}
                      <Route exact path='/assistance/accessibilite' component={Accessiblite} />
                      <Route exact path='/assistance/faqS' component={faq_s} />
                      <Route exact path='/assistance/faqS/conditionjeu' component={condition_jeu} />
                      <Route exact path='/assistance/faqS/lejeu' component={le_jeu} />
                      <Route exact path='/assistance/faqS/letirage' component={le_tirage} />
                      <Route exact path='/assistance/faqS/lesgains' component={les_gains} />
                      <Route exact path='/assistance/faqS/securite' component={securite} />
                      <Route exact path='/assistance/faqS/search' component={SearchResult} />

                        <Route path="*" component={Home1} />
                      </Switch>
                    </Row>


                  </Container>
                </div>
                {/* <CookieBanner
      message="Nous utilisons des cookies et d'autres technologies similaires afin de personnaliser 
      notre contenu, mesurer l'efficacité de nos publicités et améliorer leur pertinence, 
      ainsi que proposer une meilleure expérience. En cliquant sur Accepter ou en activant une option
       dans Préférences de cookies, vous acceptez les conditions énoncées dans notre 
       Politique en matière de cookies. Pour modifier vos préférences ou retirer votre consentement, 
        vous devez mettre à jour vos Préférences de cookies."
      wholeDomain={true}
      showDeclineButton = {true}
      acceptButtonText	= "Accepter"
      necessaryOptionText = "Nécessaire"
      preferencesOptionText = "Préférences"
      policyLink = '/politique_confidentialite'
      statisticsOptionText = 'Statistiques'
      savePreferencesButtonText = "Enregistrer et Accepter"
      managePreferencesButtonText = "Préférences de cookies"
      declineButtonText = "Continuer sans accepter"
      privacyPolicyLinkText = "Politique de confidentialité"
    /> */}
                <Footer/>
              </>
          }
          {/*</div>*/}
        </Router>
        }
        </>
    )
  }
}

export default App
