import React, {Component, useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card, Rate} from "antd"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './home1.css'
import ProgressBar from "react-bootstrap/ProgressBar";

//**************** import images ********************
import col1 from "../../Images/homeInscrire.png"
import col2 from "../../Images/homeParticiper.png"
import col3 from "../../Images/homeTirage.png"
import col4 from "../../Images/homeGain.png"
import fiabilite_white from "../../Images/fiabilite-white.png"
import security_white from "../../Images/securite-white.png"
import access_white from "../../Images/access-white.png"
import solidarite_white from "../../Images/solidarite-white.png"
import fiabilite from "../../Images/fiabilite.png"
import security from "../../Images/securityLogo.png"
import access from "../../Images/access.png"
import solidarite from "../../Images/solidariteLogo.png"
import phonePhoto from "../../Images/phoneImage.png"
import pointImg from "../../Images/Point.png"
import timerIcon from "../../Images/timer.png"
import walletIcon from "../../Images/walletIcon.png"
import {Link, NavLink} from "react-router-dom";
import axios from "axios";
import {montant_participation} from "../../Constants/constants";
import {
    FormatDateNoTimeMonthName,
    getTimeRemaining,
    nextSaturday
} from "../../Helpers";
import { useTranslation } from 'react-i18next';
import Payment from "../../components/payment";



function toDigits(number) {

    let digits = number.toString().split('');
    return digits.map(Number)
}

const deadline2 = nextSaturday()
deadline2.setHours(21,0,0,)

const Home1 = () =>{
    const { t } = useTranslation();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },

        ]
    };
    const settingsRes = {
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 0,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 0,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true
                }
            },

        ]
    };
    let[daysSpan, SetdaysSpan] = useState("");
    let[hoursSpan, SethoursSpan] = useState("");
    let[minutesSpan, SetminutesSpan] = useState("");
    let[secondsSpan, SetsecondsSpan] = useState("");
    let[Winners1, SetWinners1] = useState("");
    let[Winners2, SetWinners2] = useState("");
    let[Winners3, SetWinners3] = useState("");
    let[NombreWinners1, SetNombreWinners1] = useState(0);
    let[NombreWinners2, SetNombreWinners2] = useState(0);
    let[NombreWinners3, SetNombreWinners3] = useState(0);
    let[loading, Setloading] = useState(true);

    const [PlayersNumber , SetPlayersNumber] =useState(0);

    useEffect(() =>{
        axios.get('/api/tirageNumber/AllPlayersNumber').then((response) => {
            SetPlayersNumber(response.data.nombres_joueurs) ;
        })
            .catch(err => {
                console.log("count player error : " +  [err] );
            });
        axios.get('/api/winners').then((res) => {
            console.log(res.data)
            if (Object.values(res.data[0]).length ===0){
                console.log("empty")
                SetWinners1(null)
                SetNombreWinners1(null)
            }
            else {
                SetWinners1(Object.values(res.data[0]))
                SetNombreWinners1(res.data[3])
            }

            if (Object.values(res.data[1]).length ===0){
                console.log("empty")
                SetWinners2(null)
                SetNombreWinners2(null)
            }else {
                SetWinners2(res.data[1])
                SetNombreWinners2(res.data[4])

            }

            if (Object.values(res.data[2]).length ===0){
                console.log("empty")
                SetWinners3(null)
                SetNombreWinners3(null)
            }else {
                SetWinners3(res.data[2])
                SetNombreWinners3(res.data[5])
            }

            Setloading(false)
            console.log("winners 1 : " ,Winners1)
        })
            .catch(err => {
                console.log("count player error : " +  [err] );
            });
    }, [])


    function initializeClock(endtime) {
        // const daysSpan = clock.querySelector('.days');
        // const hoursSpan = clock.querySelector('.hours');
        // const minutesSpan = clock.querySelector('.minutes');
        // const secondsSpan = clock.querySelector('.seconds');

        function updateClock() {
            const t = getTimeRemaining(endtime);

            SetdaysSpan(t.days);
            SethoursSpan(('0' + t.hours).slice(-2));
            SetminutesSpan(('0' + t.minutes).slice(-2));
            SetsecondsSpan(('0' + t.seconds).slice(-2))


            if (t.total <= 0) {
                clearInterval(timeinterval);
            }
        }

        //updateClock();
        const timeinterval = setInterval(updateClock, 1000);
    }



    initializeClock(deadline2);

    let width = window.innerWidth;

        return (
                <Container fluid className="main-home">
                    <Row className="vertical-align">
                        <Col className="poteur">
                            {width > 1025 ?
                            <h5 className={"home-title overpass-fam"}>
                                {t('1')}<br/> {t('2')}</h5> :
                            <h5 className={"home-title overpass-fam"}>
                                {t('3')}</h5>}
                            <div className="marg">
                                {toDigits(PlayersNumber).map((num) =>(
                                        <label className="nbr">{num}</label>
                                    ))}
                            </div>
                            <h5 className={"home-somme"}>{t('4')}</h5>
                            <div>
                                <img src={walletIcon} sizes={"100%"} className={"img-carte"}/>
                                <label className={"somme-chiffre"}>
                                    {PlayersNumber * montant_participation} €
                                </label>
                            </div>
                            <ProgressBar now={40} />
                            <div className="prochain_tirage">
                                <img src={timerIcon} className={"time-img"} />
                                {width > 1025 ?
                                <label className="prochain-label">{t('62')}</label> :
                                 <label className="prochain-label">{t('5')}</label>}
                                <div className="prochain-label numbers-compteur">
                                    {daysSpan}j : {hoursSpan}h : {minutesSpan}m : {secondsSpan}s  </div>
                            </div>
                            <button type="button" className="btn-participe">
                                <Link to={"/Tirage"} style={{color : "#fff", fontSize:"15px",
                                fontWeight : "600"}}>{t('12')}</Link></button>
                        </Col>


                    </Row>
                    <Row style={{justifyContent: "center",marginTop: "30px"}}>
                        <h2 className={"title-hom"}>{t('13')}</h2>
                    </Row>

                    <Row>
                        <hr className="solid"/>
                    </Row>

                    <div className="slider-rate">
                        <Slider {...settingsRes}>
                            <div>
                                <Card bordered={false} className="card-res" loading={loading}>
                                    {(Winners1 == null) ? <Row className="row-result">
                                            <Col className="left-res">{t('14')}</Col></Row> :
                                        <Row className="row-result">
                                        <Col className="left-res">
                                            <Row className="travelcompany" >
                                                <div className="center-home"><span>{Winners1 ?
                                                    FormatDateNoTimeMonthName(Winners1[0].createdAt): ""}</span></div>
                                                <div className="center-home"><hr className="solid"/></div>
                                                <div className="center-home gagn-nom">
                                                    <span>{Winners1.length} {t('15')}</span>
                                                </div>
                                                <div className="center-home"><span style={{fontSize:"10px"}}>
                                                {t('16')}</span></div>
                                                <div className="center-home"><span>{NombreWinners1 * montant_participation} €
                                            </span></div>
                                            </Row>
                                        </Col>
                                        <Col className="winners">
                                            <Row className="travelcompany" style={{color: "#fff"}}>
                                                {Object.keys(Winners1).map((keyName, i) => (
                                                    <div className="center-home">
                                                    <span >{Winners1[keyName].numberUsed}
                                                    </span>
                                                    </div>
                                                ))}
                                            </Row>
                                        </Col>
                                    </Row>}

                                </Card>
                            </div>
                            <div>
                                <Card bordered={false} className="card-res" loading={loading}>
                                    {(Winners2 == null) ? <Row className="row-result">
                                        <Col className="left-res">{t('14')}</Col></Row> :
                                    <Row className="row-result">
                                        <Col className="left-res">
                                            <Row className="travelcompany" >
                                                <div className="center-home"><span>{Winners2 ?
                                                    FormatDateNoTimeMonthName(Winners2[0].createdAt): ""}</span></div>
                                                <div className="center-home"><hr className="solid"/></div>
                                                <div className="center-home gagn-nom">
                                                    <span>{Winners2.length} {t('15')}</span>
                                                </div>
                                                <div className="center-home"><span style={{fontSize:"10px"}}>
                                                {t('16')}</span></div>
                                                <div className="center-home"><span>
                                                    {NombreWinners2 * montant_participation} €
                                            </span></div>
                                            </Row>
                                        </Col>
                                        <Col className="winners">
                                            <Row className="travelcompany" style={{color: "#fff"}}>
                                                {Object.keys(Winners2).map((keyName, i) => (
                                                    <div className="center-home">
                                                    <span >{Winners2[keyName].numberUsed}
                                                    </span>
                                                    </div>
                                                ))}
                                            </Row>
                                        </Col>
                                    </Row>}
                                </Card>
                            </div>
                            <div>
                                <Card bordered={false} className="card-res" loading={loading}>
                                    {(Winners3 == null) ? <Row className="row-result">
                                        <Col className="left-res">{t('14')}</Col></Row> :
                                <Row className="row-result">
                                    <Col className="left-res">
                                        <Row className="travelcompany" >
                                            <div className="center-home"><span>{Winners3 ?
                                                FormatDateNoTimeMonthName(Winners3[0].createdAt): ""}</span></div>
                                            <div className="center-home"><hr className="solid"/></div>
                                            <div className="center-home gagn-nom">
                                                <span>{Winners3.length} {t('15')}</span>
                                            </div>
                                            <div className="center-home"><span style={{fontSize:"10px"}}>
                                                {t('16')}</span></div>
                                            <div className="center-home"><span>{NombreWinners3 * montant_participation} €
                                            </span></div>
                                        </Row>
                                    </Col>
                                    <Col className="winners">
                                        <Row className="travelcompany" style={{color: "#fff"}}>
                                            {Object.keys(Winners3).map((keyName, i) => (
                                                <div className="center-home">
                                                    <span >{Winners3[keyName].numberUsed}
                                                    </span>
                                                </div>
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>}
                            </Card>
                            </div>
                        </Slider>
                    </div>

                    <Row style={{justifyContent: "center",marginTop: "50px"}}>
                        <h2 className={"title-hom"}>{t('17')}</h2>
                    </Row>
                    <Row>
                        <hr className="solid"/>
                    </Row>
                    <Row style={{justifyContent:"center"}}>
                        <div style={{marginTop:"50px"}}>
                            <button
                                type="submit"
                                className="btn-valider"
                                style={{boxShadow: "0px 15px 15px #00000029"}}>
                                {t('18')}
                            </button>
                        </div>
                        {/*<img src={video} width={"70%"} height={"70%"}/>*/}
                    </Row>
                    <Row style={{justifyContent:"center"}}>
                        <div style={{marginTop:"50px"}}>
                            <NavLink exact to="/deroulement" >
                                <p style={{color : "#153DF0", fontSize:"14px"}}>{t('19')}</p></NavLink>
                        </div>
                    </Row>
                        {width > 1025 ?
                            <Row style={{padding: "70px 120px"}}>
                            <Col style={{padding: "0 30px"}}>
                                <div style={{height: "120px"}}>
                                <img src={col1} width={"25%"}/>
                                </div>
                                <h5 className={"title-col"}>{t('20')}</h5>
                                <p className={"txt-col"}>{t('21')}</p>
                            </Col>
                            <Col style={{padding: "0 30px"}}>
                                <div style={{height: "120px"}}>
                                <img src={col2} width={"25%"}/>
                                </div>
                                <h5 className={"title-col"}>{t('22')}</h5>
                                <p className={"txt-col"}>{t('23')}</p>
                            </Col>
                            <Col style={{padding: "0 30px"}}>
                                <div style={{height: "120px"}}>
                                <img src={col3} width={"40%"}/>
                                </div>
                                <h5 className={"title-col"}>{t('24')}</h5>
                                <p className={"txt-col"}>{t('25')}</p>
                            </Col>
                            <Col style={{padding: "0 30px"}}>
                                <div style={{height: "120px"}}>
                                <img src={col4} width={"30%"}/>
                                </div>
                                <h5 className={"title-col"}>{t('26')}</h5>
                                <p className={"txt-col"}>{t('27')}</p>
                            </Col>
                        </Row> :
                            // ****************** mobile version ***********************
                            <div style={{marginBottom:"40px"}}>
                            <Row style={{padding: "30px 30px 0", textAlign: "center"}}>
                                <Col>
                                    <img src={col1} width={"20%"} style={{marginBottom: "20px"}}/>
                                    <h5 className={"title-col"}>{t('20')}</h5>
                                    <p className={"txt-col"}>{t('21')}</p>
                                </Col>
                            </Row>
                            <Row style={{padding: "30px 30px 0", textAlign: "center"}}>
                                <Col>
                                    <img src={col2} width={"20%"} style={{marginBottom: "20px"}}/>
                                    <h5 className={"title-col"}>{t('22')}</h5>
                                    <p className={"txt-col"}>{t('23')}</p>
                                </Col>
                            </Row>
                            <Row style={{padding: "30px 30px 0", textAlign: "center"}}>
                                <Col>
                                    <img src={col3} width={"30%"} style={{marginBottom: "20px"}}/>
                                    <h5 className={"title-col"}>{t('24')}</h5>
                                    <p className={"txt-col"}>{t('25')}</p>
                                </Col>
                            </Row>
                            <Row style={{padding: "30px 30px 0",  textAlign: "center"}}>
                                <Col>
                                    <img src={col4} width={"20%"} style={{marginBottom: "20px"}}/>
                                    <h5 className={"title-col"}>{t('26')}</h5>
                                    <p className={"txt-col"}>{t('27')}</p>
                                </Col>
                            </Row>
                            </div>
                        }

                    {/*
                    **** pourquoi nous ? ******
                    */}


                    {width > 1025 ?
                        <div>
                            <Row style={{justifyContent: "center",marginTop: "40px"}}>
                                <h2 className={"title-hom"}>{t('28')}</h2>
                            </Row>
                            <Row>
                                <hr className="solid"/>
                            </Row>
                        <Row style={{padding: "120px"}}>
                            <Col style={{padding: "0 30px"}}>
                                <div style={{height: "110px"}}>
                                <img src={fiabilite} width={"20%"}/>
                                </div>
                                    <h5 className={"title-col"}>{t('29')}</h5>
                                <p className={"txt-col"}>{t('30')}</p>
                            </Col>
                            <Col style={{padding: "0 30px"}}>
                                <div style={{height: "110px"}}>
                                <img src={security} width={"20%"}/>
                                </div>
                                <h5 className={"title-col"}>{t('31')}</h5>
                                <p className={"txt-col"}>{t('32')}
                                </p>
                            </Col>
                            <Col style={{padding: "0 30px"}}>
                                <div style={{height: "110px"}}>
                                <img src={access} width={"20%"}/>
                                </div>
                                <h5 className={"title-col"}>{t('33')}</h5>
                                <p className={"txt-col"}>{t('34')}
                                </p>
                            </Col>
                            <Col style={{padding: "0 30px"}}>
                                <div style={{height: "110px"}}>
                                <img src={solidarite} width={"20%"}/>
                                </div>
                                <h5 className={"title-col"}>{t('35')}</h5>
                                <p className={"txt-col"}>{t('36')}
                                </p>
                            </Col>
                        </Row>
                        </div>:

                        //**************************** mobile version ***********************************
                    <div className={"blue-mobile"}>
                        <Row style={{justifyContent: "center",marginTop: "40px"}}>
                            <h2 className={"title-hom color-white"}>{t('28')}</h2>
                        </Row>
                        <Row>
                            <hr className="solid" style={{borderBottom: "2px solid white"}}/>
                        </Row>
                        <Row style={{padding: "30px 30px 0", textAlign: "justify"}}>
                            <Col>
                                <img src={fiabilite_white} width={"20%"} style={{marginBottom: "20px"}}/>
                                <h5 className={"title-col color-white"}>{t('29')}</h5>
                                <p className={"txt-col color-white"}>{t('30')}
                                </p>
                            </Col>
                        </Row>
                        <Row style={{padding: "30px 30px 0", textAlign: "justify"}}>
                            <Col>
                                <img src={security_white} width={"20%"} style={{marginBottom: "20px"}}/>
                                <h5 className={"title-col color-white"}>{t('31')}</h5>
                                <p className={"txt-col color-white"}>{t('32')}</p>
                            </Col>
                        </Row>
                        <Row style={{padding: "30px 30px 0", textAlign: "justify"}}>
                            <Col>
                                <img src={access_white} width={"20%"} style={{marginBottom: "20px"}}/>
                                <h5 className={"title-col color-white"}>{t('33')}</h5>
                                <p className={"txt-col color-white"}>{t('34')}</p>
                            </Col>
                        </Row>
                        <Row style={{padding: "30px 30px 0", textAlign: "justify"}}>
                            <Col>
                                <img src={solidarite_white} width={"20%"} style={{marginBottom: "20px"}}/>
                                <h5 className={"title-col color-white"}>{t('35')}</h5>
                                <p className={"txt-col color-white"}>{t('36')}</p>
                            </Col>
                        </Row>
                    </div>
                    }
                    {/*
                    ******* Avec notre concept ***********
                    */}
                    {width > 1025 ?
                        <div className="Back2">
                            <Row style={{alignItems: "center",padding: "20px 15vw",backgroundColor: "#F5F9FC"}}>
                                <Col className={"overpass-fam"} style={{color :"#707070"}}>
                                    <Row>
                                        <h3 className={"overpass-fam big-title"}>
                                            {t('37')}
                                        </h3>
                                    </Row>
                                    <Row style={{float: "left",marginTop:"20px"}}>
                                        <hr className="solid"/>
                                    </Row>
                                    <Row style={{marginTop: "55px"}}>
                                        <Col style={{paddingLeft:"0"}}>
                                            <p className={"txt-col"}>
                                                <img src={pointImg} sizes={"60%"} className="img-point" />
                                                {t('38')}
                                            </p>
                                            <p className={"txt-col"}>
                                                <img src={pointImg} className="img-point" sizes={"60%"}/>{t('39')}
                                            </p>
                                            <p className={"txt-col"}>
                                            <img src={pointImg} className="img-point" sizes={"60%"} />
                                            {t('40')}
                                            </p>
                                        </Col>
                                        <Col>
                                            <p className={"txt-col"}>
                                                <img src={pointImg} className="img-point" sizes={"60%"} />
                                                {t('41')}
                                            </p>
                                            <Link to={'/concept'}>
                                            <button
                                                type="submit"
                                                className="btn-valider"
                                                style={{boxShadow: "0px 15px 15px #00000029", marginTop : "20px"}}>
                                                {t('42')}</button>
                                            </Link>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col md={2}>

                                </Col>
                                <Col>
                                    <img src={phonePhoto} width={"80%"} style={{margin: "-115px 0"}}/>
                                </Col>
                            </Row>
                        </div> :
                        //**************************** mobile version ***********************************

                        <div/>}

                    {/* ********************* Temoignage ********************
                    */}

                    <Row className="temoignage">
                        <h2 style={{color:"#324865", textAlign : "center"}} className={"title-hom"}>{t('43')}</h2>
                    </Row>
                    <Row>
                        <hr className="solid"/>
                    </Row>
                    <div className="slider-rate">
                        <Slider {...settings}>
                            <div>
                                <Card className="avis" bordered={false}>
                                    <Row>
                                        <Rate disabled allowHalf defaultValue={2.5} />
                                    </Row>
                                    <Row><span className="title-avis">Efficace et simple</span></Row>
                                    <Row>Aaut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                                        voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
                                        sit amet</Row>
                                    <Row className="montant-avis">Montant du gain : 1 007 €</Row>
                                    <Row>Frank</Row>
                                </Card>
                            </div>
                            <div>
                                <Card className="avis" bordered={false}>
                                    <Row>
                                        <Rate disabled allowHalf defaultValue={2.5} />
                                    </Row>
                                    <Row><span className="title-avis">Efficace et simple</span></Row>
                                    <Row>Aaut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                                        voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
                                        sit amet</Row>
                                    <Row className="montant-avis">Montant du gain : 1 007 €</Row>
                                    <Row>Frank</Row>
                                </Card>
                            </div>
                            <div>
                                <Card className="avis" bordered={false}>
                                    <Row>
                                        <Rate disabled allowHalf defaultValue={2.5} />
                                    </Row>
                                    <Row><span className="title-avis">Efficace et simple</span></Row>
                                    <Row>Aaut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                                        voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
                                        sit amet</Row>
                                    <Row className="montant-avis">Montant du gain : 1 007 €</Row>
                                    <Row>Frank</Row>
                                </Card>
                            </div>
                            <div>
                                <Card className="avis">
                                    <Row>
                                        <Rate disabled allowHalf defaultValue={2.5} />
                                    </Row>
                                    <Row><span className="title-avis">Efficace et simple</span></Row>
                                    <Row>Aaut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                                        voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
                                        sit amet</Row>
                                    <Row className="montant-avis">Montant du gain : 1 007 €</Row>
                                    <Row>Frank</Row>
                                </Card>
                            </div>
                            <div>
                                <Card className="avis">
                                    <Row>
                                        <Rate disabled allowHalf defaultValue={2.5} />
                                    </Row>
                                    <Row><span className="title-avis">Efficace et simple</span></Row>
                                    <Row>Aaut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                                        voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet</Row>
                                    <Row className="montant-avis">Montant du gain : 1 007 €</Row>
                                    <Row>Frank</Row>
                                </Card>
                            </div>

                        </Slider>
                    </div>
                </Container>

        );
    }



export default Home1;
