import React, { Component } from 'react'
import Container from "react-bootstrap/Container";
import {Col, Row, Spinner} from "react-bootstrap";
import '../components/profile.css'
import './Nous_Contacter.css'
import {IsLoggedIn} from "../Helpers";
import {Input, notification, Select,Button} from "antd";
import VerticalNavbar_assistance from "../components/VerticalNavbar_assistance";
import axios from "axios";
import HeaderNavbarDashboard from "../components/HeaderNavbarDashboard";
import {NavLink} from "react-router-dom";
import arrow_bottom from "../Images/arrow-bottom.png";
import arrow from "../Images/arrow-right.png";
import {withTranslation} from "react-i18next";



const {Option} = Select;
const { TextArea } = Input;
const key = `open${Date.now()}`;

const btn = (
    <Button type="primary" size="small" onClick={() => notification.close(key)}>
        Ok
    </Button>
);

class Nous_Contacter extends Component {
    constructor() {
        super()
        this.state = {
            user:[],
            nom :"",
            prenom :"",
            email:"",
            num_tele:"",
            message:"",
            sujet:"",
            sendEmail : false,
            EmailSended : false

        }
        this.onChange = this.onChange.bind(this);
        this.handleSujetChange = this.handleSujetChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }


    openNotificationWithIcon = (statut) => {
        if(statut === 200){
            notification['success']({
                message: this.props.t('275'),
                btn,
                key,
            });
        }
        else
        {
            notification['error']({
                message: this.props.t('203'),
            });
        }

    };

    componentDidMount() {
        window.scrollTo(0, 0)
        fetch('/api/users/profile')
            .then(res => {
                console.log(res);
                return res.json()
            })
            .then(user => {
                console.log(user);
                this.setState({ user,
                    nom : user.first_name , prenom : user.last_name, email:user.email, num_tele :user.Num_telephone
                })
            });

    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleSujetChange(value) {
        let val = value;
        console.log(val)
        this.setState({
            sujet: val
        })
    }

    onSubmit(e){
        e.preventDefault();
        this.setState({sendEmail : true})
        axios.post('api/nouscontacter',{
            nom     : this.state.nom,
            prenom  : this.state.prenom,
            email   :this.state.email,
            num_tele:this.state.num_tele,
            message :this.state.message,
            sujet   :this.state.sujet
        })
            .then(res => {
                if (res.status === 200){
                    this.setState({sendEmail : false,EmailSended : true})
                    this.openNotificationWithIcon(res.status)
                    this.setState({message:"", sujet:""})
                }
            })
            .catch(error => {
                this.setState({sendEmail : false})
                this.openNotificationWithIcon(404)
            })
    }

    render() {
        let width = window.innerWidth

        const {t} = this.props
        //-------------------------------------------- DESKTOP VERS -------------------------------------
        if(width > 768){
            return (
                <Container fluid>
                    <Row style={{padding:"0 100px"}}>
                        {IsLoggedIn() ?
                            (
                                <Col>
                                    <HeaderNavbarDashboard/>
                                </Col>
                            )   :
                            (<div/>)}
                    </Row>
                    <Row  className="nous-contacter">
                        {IsLoggedIn() ?
                            (
                                <Col md={2}>
                                    <VerticalNavbar_assistance/>
                                </Col>
                            )   :
                            (<div/>)}
                        {this.state.EmailSended ?
                            <Col style={{marginLeft:"120px",marginTop:'50px',
                            alignSelf : 'center',placeSelf: 'center',marginRight:'80px'}}>
                                <h4 className={"contact-confirm"}>{t('306')}</h4>
                            </Col>:
                            <Col style={{marginTop:"40px",marginLeft:"120px"}}>
                                <h4 className={"contact-title"}>{t('55')}</h4>
                                <form style={{width : "60%"}} >
                                    <input
                                        type={"text"}
                                        name="nom"
                                        value={this.state.nom}
                                        className="input-profile input-contact"
                                        placeholder={t('276')}
                                        required={true}
                                        onChange={this.onChange}
                                    />
                                    <input
                                        type={"text"}
                                        name="prenom"
                                        // value={this.state.user.last_name}
                                        value={this.state.prenom}
                                        placeholder={t('277')}
                                        className="input-profile input-contact"
                                        required={true}
                                        onChange={this.onChange}

                                    />
                                    <input
                                        type={"text"} name="email" value={this.state.email}
                                        className="input-profile input-contact"
                                        placeholder={"E-mail*"} required={true}
                                        onChange={this.onChange}
                                    />
                                    <input
                                        type="tel" name="num_tele"  placeholder={t('105')}
                                        value={this.state.num_tele}  className="input-profile input-contact"
                                        onChange={this.onChange}
                                    />
                                    <Select defaultValue={t('284')} size={"large"} style={{width:"100%"}}
                                            required={true} onChange={this.handleSujetChange}>
                                        <Option value="Mon compte">{t('141')}</Option>
                                        <Option value="Mes gains">{t('278')}</Option>
                                        <Option value="Support technique">{t('279')}</Option>
                                        <Option value="Mes données personnelles">{t('280')}</Option>
                                        <Option value="Autre">{t('93')}</Option>
                                    </Select>
                                    <TextArea name={"message"} placeholder={t('281')}
                                              allowClear style={{marginTop:"25px"}}
                                              onChange={this.onChange}/>
                                </form>
                            </Col>
                        }
                    </Row>
                    {this.state.EmailSended ?
                        <></> :
                        <Row style={{marginTop: "20px", float: "right", marginRight: "30%"}}>
                            <Col>
                                <button
                                    type="submit"
                                    className="btn-valider"
                                    style={{padding: "7px 50px"}}
                                    disabled={this.state.sendEmail}
                                    onClick={this.onSubmit}
                                >{this.state.sendEmail ? <>{t('283')}</> : <span>{t('282')}</span>}
                                </button>
                            </Col>
                        </Row>
                    }
                </Container>
            );
        }

        else{
            return (
                <Container className ="pad-mob">

                    <Row style={{margin : "0 10px"}}>
                        {/* // ***************** MENU MON COMPTE NAVBAR *************** */}

                        <NavLink className="column col" style={{borderRadius: "30px 30px 0 0"}}
                                 to={"/moncompte"} activeClassName="selected">
                            <h5 style={{color : "#FFF"}}>{t('141')}</h5>
                        </NavLink>
                    </Row>

                    <Row style={{margin : "0 10px"}}>
                        <NavLink className="column col"
                                 to={"/Tirage"} activeClassName="selected">
                            <h5 style={{color : "#FFF"}}>{t('64')}</h5>
                        </NavLink>
                    </Row>

                    <Row style={{margin : "0 10px"}}>
                        <NavLink className="column col" to={"/assistance"} activeClassName="selected">
                            <h5 style={{color : "#FFF"}}>{t('65')}</h5>
                        </NavLink>
                    </Row>


                    {/* ********************** UNDER MENU ********************************** */}

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/assistance/accessibilite">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('151')}</h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/assistance/faqS">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('152')}</h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/assistance">
                            <img src={arrow} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('55')}</h5>
                        </NavLink>
                    </Row>



                    {/*------------------------------ Begin og page ----------------------------------------- */}
                    {this.state.EmailSended ?
                        <Row style={{margin : "25px"}}>
                        <h4 className={"contact-confirm"}>{t('306')}</h4>
                        </Row>:
                        <>
                    <Row style={{margin : "20px 0"}}>
                        <Col>
                                <input
                                    type={"text"}
                                    name="nom"
                                    value={this.state.nom}
                                    className="input-profile"
                                    placeholder={t('276')}
                                    required={true}
                                    onChange={this.onChange}
                                />
                                <input
                                    type={"text"}
                                    name="prenom"
                                    // value={this.state.user.last_name}
                                    value={this.state.prenom}
                                    placeholder={t('277')}
                                    className="input-profile"
                                    required={true}
                                    onChange={this.onChange}

                                />
                                <input
                                    type={"text"} name="email" value={this.state.email} className="input-profile"
                                    placeholder={"E-mail*"} required={true}
                                    onChange={this.onChange}
                                />
                                <input
                                    type="tel" name="num_tele"  placeholder={t('105')}
                                    value={this.state.num_tele}  className="input-profile"
                                    onChange={this.onChange}
                                />
                                <Select defaultValue={t('284')} size={"large"} style={{width:"100%"}}
                                        required={true} onChange={this.handleSujetChange}>
                                    <Option value="Mon compte">{t('141')}</Option>
                                    <Option value="Mes gains">{t('278')}</Option>
                                    <Option value="Support technique">{t('279')}</Option>
                                    <Option value="Mes données personnelles">{t('280')}</Option>
                                    <Option value="Autre">{t('93')}</Option>
                                </Select>
                                <TextArea name={"message"} placeholder={t('281')}
                                          rows={5}
                                          allowClear style={{marginTop:"35px"}}
                                          onChange={this.onChange}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"30px",textAlign:"center", textAlignLast : "center"}}>
                        <Col>
                            <button
                                type="submit"
                                className="btn-valider"
                                style={{padding:"7px 50px"}}
                                onClick={this.onSubmit}
                            >{this.state.sendEmail ?<>{t('283')}</> : <span>{t('282')}</span>}
                            </button>
                        </Col>
                    </Row>
                    </>
                        }
                </Container>
            );
        }

    }
}

export default withTranslation()(Nous_Contacter)
