import React, {Component, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import "./Nous_connaitre.css"
import "./concept.css"
import r1 from "../Images/1.png"
import r2 from "../Images/2.png"
import r2_b from "../Images/2_b.png"
import r3 from "../Images/3.png"
import r4 from "../Images/4.png"
import r4_b from "../Images/4_b.png"
import r5 from "../Images/5.png"
import r6 from "../Images/6.png"
import r6_b from "../Images/6_b.png"
import r7 from "../Images/7.png"
import r8 from "../Images/8.png"
import r8_b from "../Images/8_b.png"
import r9 from "../Images/9.png"
import r10 from "../Images/10.png"
import r10_b from "../Images/10_b.png"
import r11 from "../Images/11.png"
import r12 from "../Images/12.png"
import r12_b from "../Images/12_b.png"
import axios from "axios";
import ReactHtmlParser from "react-html-parser";

const Nos_avantages = () =>{

    let width = window.innerWidth;

    let [contenu_1, SetContenu_1]   = useState("");
    let [contenu_2, SetContenu_2]   = useState("");
    let [contenu_3, SetContenu_3]   = useState("");
    let [contenu_4, SetContenu_4]   = useState("");
    let [contenu_5, SetContenu_5]   = useState("");
    let [contenu_6, SetContenu_6]   = useState("");
    let [contenu_7, SetContenu_7]   = useState("");
    let [contenu_8, SetContenu_8]   = useState("");
    let [contenu_9, SetContenu_9]   = useState("");
    let [contenu_10, SetContenu_10] = useState("");
    let [contenu_11, SetContenu_11] = useState("");
    let [contenu_12, SetContenu_12] = useState("");
    let [loading, SetLoading] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/20')
            .then((res) => {
                SetLoading(false)
                SetContenu_1(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/21')
            .then((res) => {
                SetLoading(false)
                SetContenu_2(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/22')
            .then((res) => {
                SetLoading(false)
                SetContenu_3(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/23')
            .then((res) => {
                SetLoading(false)
                SetContenu_4(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/24')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_5(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/25')
            .then((res) => {
                SetLoading(false)
                SetContenu_6(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/26')
            .then((res) => {
                SetLoading(false)
                SetContenu_7(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/27')
            .then((res) => {
                SetLoading(false)
                SetContenu_8(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/28')
            .then((res) => {
                SetLoading(false)
                SetContenu_9(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/29')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_10(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/30')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_11(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/31')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_12(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
    }, [])
    //---------------------------------------- DESKTOP VERS ---------------------------------------------

    if (width > 768) {
        return(
            <div className="nous-connaitre">
                <Container fluid className={"qui-sommes-nous"}>
                    <Row>
                        <Col  style={{    textAlignLast : "center"}}>
                            <h4 className="titre-35px">Nos avantages</h4>
                            <h4 className="txt-30px" style={{marginTop : "50px"}}>Nos avantages sont multiples</h4>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "70px"}}>
                        <Col >
                            <div style={{display: "inline-flex"}}>

                                <img src={r1} style={{    marginRight: "80px"}}
                                     alt="nos avantages 1" className="img-avantage"/>
                                        { ReactHtmlParser(contenu_1) }
                            </div>
                        </Col>
                        <Col className="ml-5">
                            <div style={{display: "inline-flex"}}>
                                <img src={r2} alt="nos avantages 2" className="img-avantage"
                                     style={{    marginRight: "67px"}}/>
                                { ReactHtmlParser(contenu_2) }
                            </div>
                        </Col>
                    </Row>
                    <Row style={{padding: "30px 0"}}>
                        <Col>
                            <div style={{display: "inline-flex"}}>
                                <img src={r3} alt="nos avantages 3" className="img-avantage"/>
                                { ReactHtmlParser(contenu_3) }
                            </div>
                        </Col>
                        <Col className="ml-5">
                            <div style={{display: "inline-flex"}}>
                                <img src={r4} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_4) }
                            </div>
                        </Col>
                    </Row>

                    <Row style={{padding: "30px 0"}}>
                        <Col>
                            <div style={{display: "inline-flex"}}>
                                <img src={r5} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_5) }

                            </div>
                        </Col>
                        <Col className="ml-5">
                            <div style={{display: "inline-flex"}}>
                                <img src={r6} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_6) }

                            </div>
                        </Col>
                    </Row>
                    <Row style={{padding: "30px 0"}}>
                        <Col>
                            <div style={{display: "inline-flex"}}>
                                <img src={r7} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_7) }

                            </div>
                        </Col>
                        <Col className="ml-5">
                            <div style={{display: "inline-flex"}}>
                                <img src={r8} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_8) }

                            </div>
                        </Col>
                    </Row>
                    <Row style={{padding: "30px 0"}}>
                        <Col>
                            <div style={{display: "inline-flex"}}>
                                <img src={r9} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_9) }

                            </div>
                        </Col>
                            <Col className="ml-5">
                                <div style={{display: "inline-flex"}}>
                                    <img src={r10} alt="nos avantages 4" className="img-avantage"/>
                                    { ReactHtmlParser(contenu_10) }
                                </div>
                            </Col>
                    </Row>

                    <Row style={{padding: "30px 0"}}>
                        <Col>
                            <div style={{display: "inline-flex"}}>
                                <img src={r11} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_11) }

                            </div>
                        </Col>
                        <Col className="ml-5">
                            <div style={{display: "inline-flex"}}>
                                <img src={r12} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_12) }

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    //-------------------------------------------  MOBILE VERS  -------------------------------------------

    else {
        return(
            <div className="nous-connaitre">
                <Container fluid className={"qui-sommes-nous"}>
                    <Row>
                        <Col  style={{    textAlignLast : "center"}}>
                            <h4 className="titre-35px">Nos avantages</h4>
                            <h4 className="txt-30px"
                                style={{marginBottom : "40px"}}>
                                Nos avantages sont multiples</h4>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "45px"}}>
                        <Col>
                            <div style={{display: "inline-flex"}}>
                                <img src={r1} style={{marginRight:"24px", width:"20px"}}
                                     alt="nos avantages 1" className="img-avantage"/>
                                { ReactHtmlParser(contenu_1) }
                            </div>
                        </Col>
                    </Row>
                    <Row style={{padding: "30px 20px"}} className={"concept-bleu"}>
                        <Col >
                            <div style={{display: "inline-flex"}}>
                                <img src={r2_b} alt="nos avantages 2" style={{width:"30px"}}
                                     className="img-avantage"/>
                                { ReactHtmlParser(contenu_2) }
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "45px"}}>
                        <Col>
                            <div style={{display: "inline-flex"}}>
                                <img src={r3} alt="nos avantages 3" className="img-avantage"/>
                                { ReactHtmlParser(contenu_3) }
                            </div>
                        </Col>
                    </Row>
                    <Row style={{padding: "30px 20px"}} className={"concept-bleu"}>
                    <Col >
                            <div style={{display: "inline-flex"}}>
                                <img src={r4_b} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_4) }
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "45px"}}>
                        <Col>
                            <div style={{display: "inline-flex"}}>
                                <img src={r5} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_5) }
                            </div>
                        </Col>
                    </Row>
                    <Row style={{padding: "30px 20px"}} className={"concept-bleu"}>
                    <Col >
                            <div style={{display: "inline-flex"}}>
                                <img src={r6_b} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_6) }
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "45px"}}>
                        <Col>
                            <div style={{display: "inline-flex"}}>
                                <img src={r7} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_7) }
                            </div>
                        </Col>
                    </Row>
                    <Row style={{padding: "30px 20px"}} className={"concept-bleu"}>
                    <Col >
                            <div style={{display: "inline-flex"}}>
                                <img src={r8_b} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_8) }
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "45px"}}>
                        <Col>
                            <div style={{display: "inline-flex"}}>
                                <img src={r9} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_9) }
                            </div>
                        </Col>
                    </Row>
                    <Row style={{padding: "30px 20px"}} className={"concept-bleu"}>
                    <Col>
                            <div style={{display: "inline-flex"}}>
                                <img src={r10_b} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_10) }
                            </div>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: "45px"}}>
                        <Col>
                            <div style={{display: "inline-flex"}}>
                                <img src={r11} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_11) }
                            </div>
                        </Col>
                    </Row>
                    <Row style={{padding: "30px 20px"}} className={"concept-bleu"}>
                    <Col>
                            <div style={{display: "inline-flex"}}>
                                <img src={r12_b} alt="nos avantages 4" className="img-avantage"/>
                                { ReactHtmlParser(contenu_12) }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Nos_avantages;
