import React, { Component } from 'react'
import Container from "react-bootstrap/Container";
import {Col, Row, Spinner} from "react-bootstrap";
import './Nous_connaitre.css'
import {IsLoggedIn} from "../Helpers";
import {Input, Select} from "antd";

import VerticalNavbar_assistance from "../components/VerticalNavbar_assistance";
import axios from "axios";
import HeaderNavbarDashboard from "../components/HeaderNavbarDashboard";
import {NavLink} from "react-router-dom";
import arrow_bottom from "../Images/arrow-bottom.png";
import arrow from "../Images/arrow-right.png";
import ReactHtmlParser from "react-html-parser";
import {withTranslation} from "react-i18next";
const {Option} = Select;
const { TextArea } = Input;

class Accessiblite extends Component {
    constructor() {
        super()
        this.state = {
            Loading :true,
            contenu : "",
            user:[],
            nom :"",
            prenom :"",
            email:"",
            num_tele:"",
            message:"",
            sujet:""

        }
    }


    componentDidMount() {
        axios.get('/api/pages/13')
            .then((res) => {
                console.log("content : ",res.data.contenu_page)
                this.setState({Loading : false})
                this.setState({contenu : res.data.contenu_page})
            }).catch((res)=>{
            console.log(res.data)
        })
    }




    render() {
        let width = window.innerWidth
        const {t} = this.props;

        //-------------------------------------------- DESKTOP VERS -------------------------------------
        if(width > 768){
            return (
                <Container fluid className={"qui-sommes-nous"}>
                    <Row style={{padding:"0 100px"}}>
                        {IsLoggedIn() ?
                            (
                                <Col>
                                    <HeaderNavbarDashboard/>
                                </Col>
                            )   :
                            (<div/>)}
                    </Row>
                    <Row  className="nous-contacter accessibilite-font">
                                <Col md={2}>
                                    <VerticalNavbar_assistance/>
                                </Col>

                        <Col style={{padding:"5vw",marginLeft:"70px"}}>
                            {this.state.Loading ? <div className="spinner-center"><Spinner animation="border"
                                role="status" variant="primary"/> </div>:
                                <div >
                                    <Row style={{justifyContent: "center"}}>
                                        <h4 className="titre-35px">Accessibilité</h4>
                                    </Row>

                                    <div>{ ReactHtmlParser(this.state.contenu) }</div>
                                </div>
                            }

                        </Col>
                    </Row>
                </Container>
            );
        }

        else{
            return (
                <Container className ="pad-mob qui-sommes-nous">

                    {IsLoggedIn() ?
                        <>
                            <Row style={{margin : "0 10px"}}>
                                {/* // ***************** MENU MON COMPTE NAVBAR *************** */}

                                <NavLink className="column col" style={{borderRadius: "30px 30px 0 0"}}
                                         to={"/moncompte"} activeClassName="selected">
                                    <h5 style={{color : "#FFF"}}>{t('63')}</h5>
                                </NavLink>
                            </Row>

                            <Row style={{margin : "0 10px"}}>
                                <NavLink className="column col"
                                         to={"/Tirage"} activeClassName="selected">
                                    <h5 style={{color : "#FFF"}}>{t('64')}</h5>
                                </NavLink>
                            </Row>

                            <Row style={{margin : "0 10px"}}>
                                <NavLink className="column col" to={"/assistance"} activeClassName="selected">
                                    <h5 style={{color : "#FFF"}}>{t('65')}</h5>
                                </NavLink>
                            </Row>
                        </>
                        :
                        (<div/>)}


                    {/* ********************** UNDER MENU ********************************** */}

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/assistance/accessibilite">
                            <img src={arrow} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>Accessibilité</h5>
                        </NavLink>
                    </Row>




                    {/*------------------------------ Begin og page ----------------------------------------- */}

                    <Row style={{margin : "20px 0"}}>
                        <Col style={{marginTop:"20px"}}>
                            {this.state.Loading ? <div className="spinner-center"><Spinner animation="border"
                                role="status" variant="primary"/> </div>:
                                <div >
                                    <Row style={{justifyContent: "center"}}>
                                        <h4 className="titre-35px">Accessibilité</h4>
                                    </Row>

                                    <div>{ ReactHtmlParser(this.state.contenu) }</div>
                                </div>
                            }

                        </Col>                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/assistance/faqS">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>FAQ’s</h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/assistance">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>Nous contacter</h5>
                        </NavLink>
                    </Row>

                </Container>
            );
        }

    }
}

export default withTranslation()(Accessiblite)
