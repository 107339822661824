import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { notify } from 'react-notify-toast'
import Spinner from './Spinner'
import { API_URL } from './config'
import {confirm} from "../../Services/EmailVerification";
import  { Redirect } from 'react-router-dom'
import {Button, Result} from "antd";   // for the redirection

import ReCAPTCHA from "react-google-recaptcha";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import axios from "axios";


export default class Confirm extends Component {

    // A bit of state to give the user feedback while their email
    // address is being confirmed on the User model on the server.

    constructor() {
        super();
        this.state = {
            // confirming: true,
            results:"non confirmé",
            id : '',
            confirmed : false,
            confirmedError : false,
            Spin : true
        };

    }



    // When the component mounts the mongo id for the user is pulled  from the
    // params in React Router. This id is then sent to the server to confirm that
    // the user has clicked on the link in the email. The link in the email will
    // look something like this:
    //
    //
    // where 5c40d...a9d42 is the unique id created by Mongo

    componentDidMount = () => {
        const { id } = this.props.match.params
        this.setState({
            id: id
        })
        let UserId  = this.state.id;
        //console.log("user id : "+this.state.id)

        axios.get('/api/users/email/confirm/'+id)
            .then(res => {
            // console.log("result " + res)
            console.log('Email Confirmed');
            this.setState({Spin : false,confirmed : true, confirmedError : false})
            // this.props.history.push(`/login`)
        }).catch(err => {
            console.log(err)
            this.setState({Spin : false, confirm: false, confirmedError : true})
        })

    }

/*
    handleReCaptcha() {
        // if (this.state.isVerified) {
            let UserId  = this.state.id;
            //console.log("user id : "+this.state.id)

            confirm(UserId).then(res => {
                // console.log("result " + res)
                    console.log('Email Confirmed');
                    this.props.history.push(`/login`)
            });
            // console.log('Recaptcha verified');

        /!*} else {
            console.log('Please verify that you are a human!');
        }*!/
    }
*/

    // While the email address is being confirmed on the server a spinner is
    // shown that gives visual feedback. Once the email has been confirmed the
    // spinner is stopped and turned into a button that takes the user back to the
    // <Landing > component so they can confirm another email address.
    render = () =>
            <div style={{margin: "auto"}}>
                {this.state.Spin && <CircularProgress />}
                {this.state.confirmed && <Result
                    status="success"
                    title="Vous avez activé votre compte avec succès"
                    extra={[<Link to={"/login"}>
                        <Button type="primary" key="console">Se connecter</Button>
                    </Link>
                    ]}
                />
                }
                {this.state.confirmedError && <Result
                    status="error"
                    title="Une erreur s'est produite veuillez réessayer."
                    extra={[
                        <Link to={"/login"}>
                            <Button type="primary" key="console">
                                Se connecter
                            </Button>
                        </Link>
                    ]}
                />
                }
            </div>
}

