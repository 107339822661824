import React, { Component } from 'react';
//import logo from './logo.svg';
//import './App.css';
import {Table} from "antd";
import 'antd/dist/antd.css';
import jwt_decode from "jwt-decode";

const columns = [
    {
        title: 'Identifiant',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Nom',
        dataIndex: 'first_name',
        key: 'first_name',
    },
    {
        title: 'Prenom',
        dataIndex: 'last_name',
        key: 'last_name',
    },
    {
        title: 'email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'date de creation',
        dataIndex: 'created',
        key: 'created',
    },
    {
        title: 'le role',
        dataIndex: 'Role',
        key: 'Role',
        render: text => <p>{String(text)}</p>,
    }
];

export default class UsersTable extends Component {
    constructor(){
        super();
        this.state ={users: []};
    }
    componentDidMount() {
        fetch('/api/back/users')
            .then(res => {
                console.log(res);
                return res.json()
            })
            .then(users => {
                console.log(users);
                this.setState({ users })
            });

    }



    render() {
        return (

                <Table dataSource={this.state.users} columns={columns} />

        );
    }


}
