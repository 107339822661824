import React, {Component, useEffect, useState} from 'react';
import {Collapse, message, Select, Tabs} from "antd";
import './MesPaiements.css'
import './mesCredits.css'
import {Col, Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import VerticalNavbar2 from "../components/VerticalNavbar2";
import {FormatDate, getYear_Week, IsLoggedIn} from "../Helpers";
import axios from 'axios'
import wallet from '../Images/wallet.png'
import HeaderNavbarDashboard from "../components/HeaderNavbarDashboard";
import {NavLink} from "react-router-dom";
import arrow_bottom from "../Images/arrow-bottom.png";
import arrow from "../Images/arrow-right.png";
import {FaBell, GoAlert} from "react-icons/all";
import Modal from "antd/lib/modal";
import {montant_participation} from "../Constants/constants";
import { useTranslation } from 'react-i18next';
import Table from "antd/es/table";



const {TabPane} =Tabs;
const {Option} = Select;
const { Panel } = Collapse;


/*
function saveDetails(IBAN, BIC){
    axios.post('api/bank-details', {
        IBAN: IBAN,
        BIC: BIC,
    })
        .then(res => {
            console.log(res)
        })
}
*/

/**********************************************************
 ****** setting tirage number ID year and week ************
 * ********************************************************
 */
let year_week = getYear_Week(new Date());


function MesCredits() {
    const { t } = useTranslation();

    const [Details, setDetails] = useState("");
    const [IBAN, setIBAN] = useState("");
    const [BIC, setBIC] = useState("");
    const [TirageRestants, setTirageRestants] = useState(0);
    const [Nombres_tirages_Acheter, setNombres_tirages_Acheter] = useState(1);
    const [loading, setLoading] = useState(false)
    let [Modal_payment_visible, setModal_payment_visible] = useState(false);
    let [moyen, setMoyen] = useState('cb');
    let [CardDataPayment, setCardDataPayment] = useState([]);
    let [AboDataPayment, setAboDataPayment] = useState([]);
    let [DataPayment, setDataPayment] = useState([]);



    useEffect(() =>{
         const fetchData = () => {
             axios.get('/api/payments')
                 .then(res => {
                     console.log(res);
                     setAboDataPayment(res.data.abo)
                     setCardDataPayment(res.data.card)
                     let children = res.data.card.concat(res.data.abo,res.data.vir);
                     children.sort(function(a,b){
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(b.PaymentDate) - new Date(a.PaymentDate);
                    });
                     setDataPayment(children)
                     console.log(DataPayment)
                 })

             axios.get("/api/payments/abonnements")
                 .then(res => {
                     setTirageRestants(res.data.solde)
                 })
         }
         fetchData();
    },[])

/*
    function update() {
        axios.put(`/api/bank-details/${Details.IdUser}`,{
            IBAN: IBAN,
            BIC: BIC
        })
            .then(res => {
                console.log(res.data)
                message.success('les détails sont mis à jour avec succés', 2.5)
            })
    }
*/

    function RedirectToPayment() {
        setLoading(true)
        axios
            .post('/api/payments/abonnements', {
                nombre_des_tirages : Nombres_tirages_Acheter
            })
            .then(response => {
                window.location.href = response.data.url;
            })
            .catch(err => {
                setLoading(false)
                return ["failed",err.response.data];
            })

    }
   /* function onSubmit()
    {
        // message.loading('Sauvegarde en cours ...', 1)
        console.log("clicked")
        axios.post('/api/bank-details', {
            IBAN: IBAN,
            BIC: BIC,
        })
            .then(res => {
                console.log(res.data)
                if (res.data === 'saved'){
                    message.success('Sauvegarde avec succés', 2.5)
                }
            })
    }*/

    function ChangeMode(value){
        setMoyen(value)
    }

    let width = window.innerWidth

    const columns = [
        {
            title: t('190'),
            dataIndex: 'NumeroTirage',
            key: 'NumeroTirage',
            render: NumeroTirage => (
                <>
                    {NumeroTirage ? <span>{NumeroTirage}</span> : "-------"}
                </>
            ),
        },
        {
            title: t('191'),
            dataIndex: 'PaymentDate',
            key: 'PaymentDate',
            render: Date => (
                <>
                    <span>{FormatDate(Date)}</span>
                </>
            ),

        },
        {
            title: t('192'),
            dataIndex: 'montant',
            key: 'montant',
            render: montant => (
                <>
                    <span>{montant} €</span>
                </>
            ),
        },
        {
            title: t('179'),
            key: 'PaymentType',
            dataIndex: 'PaymentType',
            render: PaymentType => (
                <>
                    {PaymentType === 'Card' || PaymentType ==='ABO' ? <span>{t('180')}</span> :
                        <>
                            {PaymentType === 'ABO-VB'? <span>{t('294')}</span>:
                                <span>{t('295')}</span>}
                        </>}
                </>
            ),
        }

    ];

    //********************************** DESKTOP VERS ****************************
    if(width > 768) {
        return (
            <Container fluid >
                <Row style={{padding:"0 100px"}}>
                    {IsLoggedIn() ?
                        (
                            <Col>
                                <HeaderNavbarDashboard/>
                            </Col>
                        )   :
                        (<div/>)}
                </Row>
                <Row className="credit">
                    {IsLoggedIn() ?
                        (
                            <Col md={2}>
                                <VerticalNavbar2/>
                            </Col>
                        )   :
                        (<div/>)}
                    <Col style={{marginTop:"20px"}}>
                        <div style={{minHeight:"150px"}}>
                            <Tabs type="card">
                                {/* ***** Moyen de paiements ****** */}
                                {/*<TabPane tab="Mes crédits" key="1">
                                    <Container style={{marginTop: "50px"}}>
                                        <Row style={{width:"45%"}}>
                                            <Col>
                                                <div className="inline-input">
                                                    <img src={wallet} width={"6%"} height={"6%"} />
                                                    <label style={{width: "100%",marginLeft:"20px"
                                                        ,color:"#324865"}} >
                                                        Crédit(s) restant(s) : {TirageRestants}
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <label style={{width: "100%",marginLeft:"60px",color:"#324865"}}>
                                                Porte-feuille : {TirageRestants * montant_participation} €
                                            </label>
                                        </Row>
                                        <Row style={{marginTop:"20px",width:"45%"}}>
                                            <form style={{marginTop :"30px"}}>
                                                <label  style={{color:"#1E90FF"}}>Coordonnées bancaires</label>
                                                <div className="inline-input">
                                                    <div>
                                                        <label>IBAN</label>
                                                        <input type="text"
                                                               placeholder="MA4523852374100236"
                                                               value={IBAN}
                                                               onChange={e => setIBAN(e.target.value)}
                                                               style={{marginRight:"50px"}}/>
                                                    </div>
                                                    <div style={{marginLeft:"30px"}}>
                                                        <label>BIC</label>
                                                        <input type="text" placeholder="TYXXX"
                                                               value={BIC}
                                                               onChange={e => setBIC(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </Row>
                                        <Row style={{marginTop:"40px",width:"45%"}}>
                                            <Col>
                                                <button
                                                    className="btn-valider-profile"
                                                    style={{float:"right",padding:"7px 50px",
                                                        marginRight:"10px"}}
                                                    onClick={update}>
                                                    Modifier
                                                </button>
                                            </Col>
                                            <Col>
                                                <button
                                                    onClick={onSubmit}
                                                    className="btn-valider"
                                                    style={{padding:"7px 50px"}}>
                                                    Valider
                                                </button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </TabPane>*/}

                                {/* ***** Recharger mon compte ****** */}
                                <TabPane tab={t('173')} key="1">
                                    <Row>
                                        <Col>
                                            <div className="inline-input">
                                                <img src={wallet} width={"3%"} height={"3%"} />
                                                <label style={{width: "100%",marginLeft:"20px"}} >
                                                    {t('174')} : {TirageRestants}
                                                </label>
                                            </div>
                                            <label style={{color: "#CCCCCC", marginLeft: "50px"}}>
                                                1 {t('175')} = 3 €</label>
                                        </Col>
                                    </Row>
                                    <Row style={{width:"45%"}}>
                                        <Col>
                                            <label><b>{t('173')}</b></label>
                                            <div  className="inline-input">
                                                <label>{t('176')}</label>
                                                <Select defaultValue="1"
                                                        onChange={(e) => setNombres_tirages_Acheter(e)}
                                                className={"credit-list"}>
                                                    <Option value="1">1</Option>
                                                    <Option value="2">2</Option>
                                                    <Option value="3">3</Option>
                                                    <Option value="4">4</Option>
                                                    <Option value="5">5</Option>
                                                    <Option value="6">6</Option>
                                                    <Option value="7">7</Option>
                                                    <Option value="8">8</Option>
                                                    <Option value="9">9</Option>
                                                    <Option value="10">10</Option>
                                                    <Option value="11">11</Option>
                                                    <Option value="12">12</Option>
                                                    <Option value="13">13</Option>
                                                    <Option value="14">14</Option>
                                                    <Option value="15">15</Option>
                                                    <Option value="16">16</Option>
                                                    <Option value="17">17</Option>
                                                    <Option value="18">18</Option>
                                                    <Option value="19">19</Option>
                                                    <Option value="20">20</Option>
                                                </Select>
                                            </div>
                                            <button className="btn-valider"
                                                    style={{padding:"7px 50px", marginTop : "20px"}}
                                                    onClick={()=>{setModal_payment_visible(true)}}
                                                    disabled={loading}
                                            >
                                                {t('177')}
                                            </button>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab={t('201')} key="2">
                                    <Table columns={columns} dataSource={DataPayment}
                                           className="table-pay"/>
                                </TabPane>

                            </Tabs>
                            <Modal
                                visible={Modal_payment_visible}
                                onOk={() => setModal_payment_visible(false)}
                                onCancel={() => setModal_payment_visible(false)}
                                footer={null}
                                width={900}
                            >
                                <Container style={{
                                    backgroundColor:"white",paddingLeft: "0",
                                    paddingRight: "0",
                                    borderRadius: "14px"
                                }}>
                                    <Row noGutters>
                                        <Col className="back-logi" md={2}/>
                                        <Col style={{padding: "50px"}}>
                                            <h5 style={{float : "right"}}> {t('174')} : <b>{TirageRestants}</b>
                                            </h5>
                                            <div  style={{width:"450px"}}>
                                                <h2 className="mb-3 font-weight-bold"
                                                    style={{color:"#4C84FF"}}>
                                                    {t('185')}</h2>
                                                <h4 className={"mode"}>{t('178')}</h4>
                                                <div  className="inline-input">
                                                    <h4 className={"moyen"}>{t('179')}</h4>
                                                    <Select defaultValue={moyen}
                                                            onChange={ChangeMode}
                                                            style={{marginLeft : "15px"}}>
                                                        <Option value="cb">{t('180')}</Option>
                                                        <Option value="virement">{t('181')}</Option>
                                                        <Option value="mandat">{t('182')}</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            {moyen === 'cb' ?
                                                <div style={{float: "right", marginTop: "30px"}}>
                                                    <h5 className={"credit-txt"}>
                                                        {t('183')} {montant_participation * Nombres_tirages_Acheter} €
                                                    </h5>
                                                    <h5 className={"credit-txt"}>
                                                        {t('184')} {Nombres_tirages_Acheter}
                                                    </h5>
                                                    <button className="btn-valider"
                                                            style={{padding: "8px 50px", marginTop: "10px", width: "100%"}}
                                                            onClick={() => RedirectToPayment()}>
                                                        {loading ? <span style={{fontSize: "17px"}}>{t('189')}</span> :
                                                            <span style={{fontSize: "17px"}}>{t('114')}</span>}
                                                    </button>
                                                </div>
                                                :
                                                <>
                                                    <div style={{float : "right" , marginTop : "150px"}}>
                                                        <h5 className={"credit-txt"}>
                                                            {t('183')} {montant_participation * Nombres_tirages_Acheter} €
                                                        </h5>
                                                        <h5 className={"credit-txt"}>
                                                            {t('184')} {Nombres_tirages_Acheter}
                                                        </h5>
                                                    </div>
                                                </>
                                            }

                                            {(moyen === 'virement' || moyen === 'mandat' ) &&
                                            <div style={{width: "450px", marginTop: "20px"}}>
                                            <span style={{color: "#74869E"}}>
                                                {t('186')} BAD COMPANY SARL S MALTA
                                            </span>
                                                <Row style={{marginTop: "20px"}}>
                                                    <Col>
                                                        <p style={{color: "#74869E", marginBottom: "0"}}>IBAN</p>
                                                        <p style={{color: "#565656", fontWeight: "500"}}>
                                                            MA4523852374100236
                                                        </p>
                                                    </Col>
                                                    <Col>
                                                        <p style={{color: "#74869E", marginBottom: "0"}}>BIC</p>
                                                        <p style={{color: "#565656", fontWeight: "500"}}>
                                                            TYXXX
                                                        </p>

                                                    </Col>
                                                </Row>
                                                <div>
                                                <span style={{color: "#74869E"}}>
                                                {t('187')} {window.Pseudonyme} {Nombres_tirages_Acheter}
                                            </span>
                                                    <p style={{color: "#565656", fontWeight: "400", marginTop: "10px"}}>
                                                        {t('188')}
                                                    </p>
                                                </div>
                                            </div>
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal>

                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    //******************************** MOBILE VERS **********************************
    else {
        return (
            <Container className ="pad-mob" >
                <Row style={{margin : "0 10px"}}>
                    {/* // ***************** MENU MON COMPTE NAVBAR *************** */}

                    <NavLink className="column col" style={{borderLeft:"5px solid white",
                        borderRadius: "30px 30px 0 0"}}
                             to={"/moncompte"} activeClassName="selected">
                        <h5 style={{color : "#FFF"}}>{t('63')}</h5>
                    </NavLink>

                </Row>
                {/* ********************** UNDER MENU ********************************** */}
                <Row style={{margin : "30px 15px 0px", borderBottom: "2px solid #DFE7F5", paddingBottom: "5px"}}>
                    <NavLink className="und-menu"
                             to="/moncompte" exact>
                        <img src={arrow_bottom} width={30} height={30}/>
                        <h5 style={{marginLeft : "10px",marginTop : "2px",
                                            marginBottom: "0", color : "#0C4EFC", width:"100%"}}>
                                            <label>{t('144')}</label>
                                            { window.pieceValidation ? <GoAlert style={{float:"right",
                                                color : "#FFC200", height : "30px", width: "30px"}}/> : <></>
                                            }
                                        </h5>
                    </NavLink>
                </Row>
                <Row className="row-und-menu">
                    <NavLink className="und-menu" to="/moncompte/notifications">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",width:"100%",
                                marginBottom: "0", color : "#0C4EFC"}}><label>{t('145')} </label>

                            {window.NotifNumber >0 ?<><FaBell style={{float:"right",
                                color : "#FF4141", height : "30px", width: "30px"}}/>
                                <label className={"bell-number"}>{window.NotifNumber}</label></> : <></>}
                            </h5>
                        </NavLink>
                </Row>

                <Row className="row-und-menu">
                    <NavLink className="und-menu" to="/moncompte/mescredits">
                        <img src={arrow} width={30} height={30}/>
                        <h5 style={{marginLeft : "10px",marginTop : "2px",
                            marginBottom: "0", color : "#0C4EFC"}}>{t('146')}</h5>
                    </NavLink>
                </Row>


                <Row className={"padding-mob"}>
                    <Collapse defaultActiveKey={['1']} accordion className="collapse-credit">
{/*
                        <Panel header="Mes crédits" key="1" className="header-collapse">
                            <Container fluid>

                                <Row>
                                    <Col xs={3}>
                                        <img src={wallet} width={"50px"} height={"50px"} />
                                    </Col>
                                    <Col style={{marginLeft : "10px"}}>
                                        <label style={{fontSize : "15pt", fontWeight : "400" ,color:"#324865"}} >
                                            Crédit(s) restant(s) : <b>{TirageRestants}</b>
                                        </label>
                                        <label style={{color:"#324865",fontSize : "15pt", fontWeight : "400"}}>
                                            Porte-feuille : <b>{TirageRestants * montant_participation} € </b>
                                        </label>
                                    </Col>
                                </Row>

                                ****************************************************

                                <Row style={{marginTop : "30px"}}>
                                        <label  style={{color:"#1E90FF", fontSize: "16pt", fontWeight : "500"}}>
                                            Coordonnées bancaires</label>
                                </Row>
                                <Row style={{marginTop : "15px", marginBottom : "30px", width : "65%"}} className="inp">

                                        <label style={{color:"#74869E", fontSize: "16pt"}}>IBAN</label>
                                        <input type="text"
                                               placeholder="MA4523852374100236"
                                               value={IBAN}
                                               onChange={e => setIBAN(e.target.value)}
                                               />

                                </Row>
                                <Row style={{marginBottom : "50px", width : "65%"}} className="inp">
                                         <label style={{color:"#74869E", fontSize: "16pt"}} >BIC</label>
                                         <input type="text" placeholder="TYXXX"
                                                value={BIC}
                                                onChange={e => setBIC(e.target.value)}
                                         />
                                </Row>

                                 ***************************************************

                                <Row>
                                    <Col xs={6}>
                                        <button
                                            className="btn-valider-profile"
                                            style={{padding:"7px 50px"}}
                                            onClick={update}>
                                            Modifier
                                        </button>
                                    </Col>
                                    <Col xs={6}>
                                        <button
                                            onClick={onSubmit}
                                            className="btn-valider"
                                            style={{padding:"7px 50px"}}>
                                            Valider
                                        </button>
                                    </Col>
                                </Row>
                            </Container>
                        </Panel>
*/}

                        {/* ---------------------------------------------------------- */}
                        <Panel header="Recharger mon compte" key="1">
                            <Container fluid style={{marginTop : "30px"}}>
                                <Row>
                                    <Col xs={3} style={{alignSelf:"center"}}>
                                        <img src={wallet} width={"45px"} height={"45px"} />
                                    </Col>
                                    <Col>
                                        <label style={{fontSize : "15px", fontWeight : "400" ,color:"#A2A2A2"}} >
                                            {t('174')} : <b>{TirageRestants}</b>
                                        </label>
                                        <label style={{color:"#CCCCCC",fontSize : "15px", fontWeight : "400"}}>
                                            1 {t('175')} = <b>3 €</b>
                                        </label>
                                    </Col>
                                </Row>

                            {/*    ------------------------------------------------------------------------  */}
                            <Row style={{marginTop : "30px"}}>
                                <Col >
                                    <label style={{color: "#324865", fontSize: "16px",
                                        fontFamily: 'Roboto, sans-serif', fontWeight: "bold"}}>
                                        {t('173')}
                                    </label>
                                    <div  className="inline-input">
                                        <label style={{color: "rgb(162, 162, 162)", marginRight : "8px"}}>
                                            {t('176')}</label>
                                        <Select defaultValue="1" className={"recharger-select"}
                                                onChange={(e) => setNombres_tirages_Acheter(e)}>
                                            <Option value="1">1</Option>
                                            <Option value="2">2</Option>
                                            <Option value="3">3</Option>
                                            <Option value="4">4</Option>
                                            <Option value="5">5</Option>
                                            <Option value="6">6</Option>
                                            <Option value="7">7</Option>
                                            <Option value="8">8</Option>
                                            <Option value="9">9</Option>
                                            <Option value="10">10</Option>
                                            <Option value="11">11</Option>
                                            <Option value="12">12</Option>
                                            <Option value="13">13</Option>
                                            <Option value="14">14</Option>
                                            <Option value="15">15</Option>
                                            <Option value="16">16</Option>
                                            <Option value="17">17</Option>
                                            <Option value="18">18</Option>
                                            <Option value="19">19</Option>
                                            <Option value="20">20</Option>
                                        </Select>
                                    </div>
                                    <button className="btn-valider"
                                            style={{padding:"7px 50px", marginTop : "20px"}}
                                            onClick={()=>{setModal_payment_visible(true)}}
                                            disabled={loading}>
                                        {t('177')}
                                    </button>
                                </Col>
                            </Row>
                                <Modal
                                    visible={Modal_payment_visible}
                                    onOk={() => setModal_payment_visible(false)}
                                    onCancel={() => setModal_payment_visible(false)}
                                    footer={null}
                                >
                                    <Container style={{
                                        backgroundColor:"white",paddingLeft: "0",
                                        paddingRight: "0",
                                        borderRadius: "14px"
                                    }}>
                                        <Row noGutters>
                                            <Col style={{padding: "50px 40px"}}>
                                                <h4 className="mb-3 font-weight-bold"
                                                    style={{color:"#4C84FF"}}>
                                                    {t('185')}</h4>
                                                <label> {t('174')} : <b>{TirageRestants}</b>
                                                </label>
                                                <label >{t('178')}</label>
                                                <div  className="inline-input">
                                                    <label >{t('179')}</label>
                                                    <Select defaultValue={moyen}
                                                            onChange={ChangeMode}
                                                            style={{marginLeft : "15px"}}>
                                                        <Option value="cb">{t('180')}</Option>
                                                        <Option value="virement">{t('181')}</Option>
                                                        <Option value="mandat">{t('182')}</Option>
                                                    </Select>
                                                </div>
                                                <div>
                                                    <h5 className={"credit-txt"}>
                                                        {t('183')} {montant_participation * Nombres_tirages_Acheter} €
                                                    </h5>
                                                    <h5 className={"credit-txt"}>
                                                        {t('184')} {Nombres_tirages_Acheter}
                                                    </h5>
                                                </div>
                                                {moyen === 'cb' ?
                                                        <button className="btn-valider"
                                                                style={{padding: "8px 50px", marginTop: "10px", width: "100%"}}
                                                                onClick={() => RedirectToPayment()}>
                                                            {loading ? <span style={{fontSize: "17px"}}>{t('189')}</span> :
                                                                <span style={{fontSize: "17px"}}>{t('114')}</span>}
                                                        </button>
                                                    :
                                                    <>
                                                    </>
                                                }

                                                {(moyen === 'virement' || moyen === 'mandat' ) &&
                                                <div style={{ marginTop: "20px"}}>
                                            <span style={{color: "#74869E"}}>
                                                {t('186')} BAD COMPANY SARL S MALTA
                                            </span>
                                                    <Row style={{marginTop: "20px"}}>
                                                        <Col>
                                                            <p style={{color: "#74869E", marginBottom: "0"}}>IBAN</p>
                                                            <p style={{color: "#565656", fontWeight: "500"}}>
                                                                MA4523852374100236
                                                            </p>
                                                        </Col>
                                                        <Col>
                                                            <p style={{color: "#74869E", marginBottom: "0"}}>BIC</p>
                                                            <p style={{color: "#565656", fontWeight: "500"}}>
                                                                TYXXX
                                                            </p>

                                                        </Col>
                                                    </Row>
                                                    <div>
                                                <span style={{color: "#74869E"}}>
                                                {t('187')} {window.Pseudonyme} {Nombres_tirages_Acheter}
                                            </span>
                                                        <p style={{color: "#565656", fontWeight: "400", marginTop: "10px"}}>
                                                            {t('188')}
                                                        </p>
                                                    </div>
                                                </div>
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal>

                            </Container>
                        </Panel>
                        <Panel header={t('201')} key="2" className="header-collapse">
                            <Table columns={columns} dataSource={DataPayment}
                                   className="table-pay"/>
                        </Panel>
                    </Collapse>
                </Row>


                <Row className="row-und-menu">
                    <NavLink className="und-menu" to="/moncompte/parametres">
                        <img src={arrow_bottom} width={30} height={30}/>
                        <h5 style={{marginLeft : "10px",marginTop : "2px",
                            marginBottom: "0", color : "#0C4EFC"}}>{t('148')}</h5>
                    </NavLink>
                </Row>

                <Row className="row-und-menu">
                    <NavLink className="und-menu" to="/moncompte/parametres_jeu">
                        <img src={arrow} width={30} height={30}/>
                        <h5 style={{marginLeft : "10px",marginTop : "2px",
                            marginBottom: "0", color : "#0C4EFC"}}>{t('149')}</h5>
                    </NavLink>
                </Row>

                <Row className="row-und-menu">
                    <NavLink className="und-menu" to="/moncompte/parrainage">
                        <img src={arrow_bottom} width={30} height={30}/>
                        <h5 style={{marginLeft : "10px",marginTop : "2px",
                            marginBottom: "0", color : "#0C4EFC"}}>{t('150')}</h5>
                    </NavLink>
                </Row>


                {/* ************************* MAIN MENU SUITE ******************************   */}
                <Row style={{margin : "30px 10px 0"}}>
                    <NavLink className="column col"
                             to={"/Tirage"} activeClassName="selected">
                        <h5 style={{color : "#FFF"}}>Tirages</h5>
                    </NavLink>

                </Row>

                <Row style={{margin : "0 10px"}}>
                    <NavLink className="column col" style={{borderRadius: "0 0 30px 30px"}}
                             to={"/assistance"} activeClassName="selected">
                        <h5 style={{color : "#FFF"}}>Assistance</h5>
                    </NavLink>

                </Row>

                {/*<Row>
                    <Col style={{marginTop:"20px"}}>
                        <div style={{minHeight:"150px"}}>
                            <Tabs type="card">
                                 ***** Moyen de paiements ******
                                <TabPane tab="Mes crédits" key="1">
                                    <Container style={{marginTop: "50px"}}>
                                        <Row style={{width:"45%"}}>
                                            <Col>
                                                <div className="inline-input">
                                                    <img src={wallet} width={"6%"} height={"6%"} />
                                                    <label style={{width: "100%",marginLeft:"20px"
                                                        ,color:"#324865"}} >
                                                        Crédit(s) restant(s) : {TirageRestants}
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <label style={{width: "100%",marginLeft:"60px",color:"#324865"}}>
                                                Porte-feuille : {TirageRestants * montant_participation} €
                                            </label>
                                        </Row>
                                        <Row style={{marginTop:"20px",width:"45%"}}>
                                            <form style={{marginTop :"30px"}}>
                                                <label  style={{color:"#1E90FF"}}>Coordonnées bancaires</label>
                                                <div className="inline-input">
                                                    <div>
                                                        <label>IBAN</label>
                                                        <input type="text"
                                                               placeholder="MA4523852374100236"
                                                               value={IBAN}
                                                               onChange={e => setIBAN(e.target.value)}
                                                               style={{marginRight:"50px"}}/>
                                                    </div>
                                                    <div style={{marginLeft:"30px"}}>
                                                        <label>BIC</label>
                                                        <input type="text" placeholder="TYXXX"
                                                               value={BIC}
                                                               onChange={e => setBIC(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </Row>
                                        <Row style={{marginTop:"40px",width:"45%"}}>
                                            <Col>
                                                <button
                                                    className="btn-valider-profile"
                                                    style={{float:"right",padding:"7px 50px",
                                                        marginRight:"10px"}}
                                                    onClick={update}>
                                                    Modifier
                                                </button>
                                            </Col>
                                            <Col>
                                                <button
                                                    onClick={onSubmit}
                                                    className="btn-valider"
                                                    style={{padding:"7px 50px"}}>
                                                    Valider
                                                </button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </TabPane>


                                 ***** Recharger mon compte ******

                                <TabPane tab="Recharger mon compte" key="2">
                                    <Row style={{width:"45%"}}>
                                        <Col>
                                            <div className="inline-input">
                                                <img src={wallet} width={"6%"} height={"6%"} />
                                                <label style={{width: "100%",marginLeft:"20px"}} >
                                                    Tirage(s) restant(s) : {TirageRestants}
                                                </label>
                                            </div>
                                            <label style={{color: "#CCCCCC", marginLeft: "50px"}}>
                                                1 tirage = 3 €</label>
                                        </Col>
                                    </Row>
                                    <Row style={{width:"45%"}}>
                                        <Col>
                                            <label><b>Recharger mon compte</b></label>
                                            <div  className="inline-input">
                                                <label>Choisissez le nombre de tirages souhaité :</label>
                                                <Select defaultValue="1" onChange={(e) => setNombres_tirages_Acheter(e)}>
                                                    <Option value="1">1</Option>
                                                    <Option value="5">5</Option>
                                                    <Option value="15">15</Option>
                                                    <Option value="20">20</Option>
                                                    <Option value="30">30</Option>
                                                    <Option value="50">50</Option>
                                                    <Option value="100">100</Option>
                                                </Select>
                                            </div>
                                            <button className="btn-valider"
                                                    style={{padding:"7px 50px", marginTop : "20px"}}
                                                    onClick={()=>RedirectToPayment()}
                                            >
                                                Valider mon paiement
                                            </button>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </div>
                        <div className="payment-image">
                            <img src={background} width={"500px"} height={"500px"} />
                        </div>
                    </Col>
                </Row>*/}
            </Container>
        );
    }


}

export default MesCredits;
