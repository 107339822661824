import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import './MesParametres.css'
import {notification, Radio, Select, Switch} from "antd";
import VerticalNavbar2 from "../components/VerticalNavbar2";
import {IsLoggedIn} from "../Helpers";
import HeaderNavbarDashboard from "../components/HeaderNavbarDashboard";
import {NavLink} from "react-router-dom";
import arrow_bottom from "../Images/arrow-bottom.png";
import arrow from "../Images/arrow-right.png";
import question from "../Images/Question.png"
import axios from "axios";
import {FaBell, GoAlert} from "react-icons/all";
import {withTranslation} from "react-i18next";

const { Option } = Select;


class MesParametres_jeu extends Component {
    constructor(){
        super();

        this.state = {
            isHovering_tirage: false,
            isHovering_authen: false,
            isHovering_choix: false,
            alertes_value:"",
            deconnexion_value : "10",
            vote_value : "non",
            newsletters : false,
            profil : false,
            tirages :false,
            double : false,
            deconnexion : false,
            alertes:false,
            connexion : false,
            isStatut_loading : true,

        };

        this.handleMouseHover_tirage = this.handleMouseHover_tirage.bind(this);
        this.handleMouseHover_authentification = this.handleMouseHover_authentification.bind(this);
        this.handleMouseHover_choix = this.handleMouseHover_choix.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        axios.get('/api/notifParam')
            .then((res) => {
                res.data.map((async item => {
                    console.log(item.param_libelle)
                    await this.setState({[item.param_libelle]: item.statut,
                        [item.param_libelle+'_value']: item.parametres})
                }))
                this.setState({isStatut_loading : false})
            })
    }

    openNotificationWithIcon = (statut) => {
        if(statut === 200){
            notification['success']({
                message: this.props.t('202'),
            });
        }
        else
        {
            notification['error']({
                message: this.props.t('203'),
            });
        }

    };

    // *************** changer les parametres de notifications et envoyer les changements au serveur *****************
    onChange_Params(checked, libelle) {
        console.log(`switch to ${checked}`);

        this.setState({[libelle]: checked})
        switch (libelle) {
            case 'alertes' :
                console.log(checked)
                axios.put('/api/notifParam',{
                    param_libelle: libelle,
                    statut : checked,
                    parametres : this.state.alertes_value
                })
                    .then(res => {
                        if (res.status === 200){
                            this.openNotificationWithIcon(res.status)
                        }
                        else
                            this.openNotificationWithIcon(res.status)
                    })
                    .catch(error => {
                        this.openNotificationWithIcon(404)
                    })
                break;

            case 'deconnexion' :
                axios.put('/api/notifParam',{
                    param_libelle: libelle,
                    statut : checked,
                    parametres : this.state.deconnexion_value
                })
                    .then(res => {
                        if (res.status === 200){
                            this.openNotificationWithIcon(res.status)
                        }
                        else
                            this.openNotificationWithIcon(res.status)
                    })
                    .catch(error => {
                        this.openNotificationWithIcon(404)
                    })
                break;

            default :
                axios.put('/api/notifParam',{
                    param_libelle: libelle,
                    statut : checked,
                })
                    .then(res => {
                        if (res.status === 200){
                            this.openNotificationWithIcon(res.status)
                        }
                        else
                            this.openNotificationWithIcon(res.status)
                    })
                    .catch(error => {
                        this.openNotificationWithIcon(404)
                    })
        }


    }



    // **************************************  Gérer on hover des ?  ************************************
    handleMouseHover_tirage() {
        this.setState(this.toggleHoverState);
    }

    toggleHoverState(state) {
        return {
            isHovering_tirage: !state.isHovering_tirage,
        };
    }

    handleMouseHover_choix() {
        this.setState(this.toggleHoverState_choix);
    }

    toggleHoverState_choix(state) {
        return {
            isHovering_choix: !state.isHovering_choix,
        };
    }

    // ************************************************ END  **************************************************

    // ****************************** handle the change of the value and statut of params *********************
    handleChange(value,name) {
        this.setState({[name] : value});
        console.log(`selected ${value}`);

    }


    handleChange_select(value,libelle) {
        this.setState({[libelle + '_value']: value});
        axios.put('/api/notifParam',{
            param_libelle: libelle,
            parametres : value
        })
            .then(res => {
                this.openNotificationWithIcon(res.status)
            })
            .catch(error => {
                this.openNotificationWithIcon(404)
            })
    }
    // **************************************  Gérer on hover des ?  ************************************

    handleMouseHover_authentification() {
        this.setState(this.toggleHoverState_authen);
    }

    toggleHoverState_authen(state) {
        return {
            isHovering_authen: !state.isHovering_authen,
        };
    }
    // ************************************************ END  **************************************************

    render() {
        let width = window.innerWidth

        const {t}= this.props
        if(width > 768) {
            return (
                <Container  fluid>
                    <Row style={{padding:"0 100px"}}>
                        {IsLoggedIn() ?
                            (
                                <Col>
                                    <HeaderNavbarDashboard/>
                                </Col>
                            )   :
                            (<div/>)}
                    </Row>
                    <Row className="param">
                        {IsLoggedIn() ?
                            (
                                <Col md={2}>
                                    <VerticalNavbar2/>
                                </Col>
                            )   :
                            (<div/>)}
                        <Col style={{marginTop:"20px",marginLeft:"100px"}}>
                            <Row style={{marginBottom : "30px"}}>
                                <Col>
                                    <h4>{t('149')}</h4>
                                </Col>
                            </Row>






                            <Row className="rows">
                                <Col md={7} className="ColParam">
                                    <label>{t('218')}
                                        <Select value={this.state.alertes_value}
                                                style={{ width: 70}} bordered={false} className="option_param"
                                                onChange={ (value) => this.handleChange_select(value,'alertes')}>
                                            <Option value="1" className="option_param">1h</Option>
                                            <Option value="2" className="option_param">2h</Option>
                                            <Option value="3" className="option_param">3h</Option>
                                        </Select> {t('215')}</label>
                                </Col>
                                <Col className="ColParam" md={1}>
                                    <Switch loading={this.state.isStatut_loading}
                                            checked={this.state.alertes}
                                            onChange={(e) => this.onChange_Params(e,'alertes')}/>
                                </Col>
                            </Row>


                            <Row className="rows" style={{marginTop : "30px"}}>
                                <Col md={8} className="ColParam">
                                    <label>{t('219')} :
                                        <Select value={this.state.vote_value}
                                                style={{ width: "180px"}} bordered={false} className="option_param"
                                                onChange={ (value) => this.handleChange_select(value,'vote')}>
                                            <Option value="non" className="option_param">{t('220')}</Option>
                                            <Option value="minimale" className="option_param">{t('221')}</Option>
                                            <Option value="mediane" className="option_param">{t('222')}</Option>
                                            <Option value="maximale" className="option_param">{t('223')}</Option>
                                            <Option value="Ne_se_prononce_pas" className="option_param">{t('224')}</Option>
                                        </Select>
                                    </label>

                                    <img src={question} alt={"question"}
                                         onMouseEnter={this.handleMouseHover_choix}
                                         onMouseLeave={this.handleMouseHover_choix}
                                    />
                                </Col>

                                <Col style={{marginTop : "-100px", paddingRight: "0"}}>
                                    {
                                        this.state.isHovering_choix &&
                                        <div className={"shadow p-3 bg-white rounded"}><label>
                                            {t('225')}
                                        </label>
                                        </div>
                                    }
                                </Col>

                            </Row>


                        </Col>
                    </Row>

                </Container>
            );
        }


        //---------------------------------- MOBILE VERS ---------------------------------------------
        else {
            return (
                <Container className ="pad-mob">
                    <Row style={{margin : "0 10px"}}>
                        {/* // ***************** MENU MON COMPTE NAVBAR *************** */}

                        <NavLink className="column col" style={{borderLeft:"5px solid white",
                            borderRadius: "30px 30px 0 0"}}
                                 to={"/moncompte"} activeClassName="selected">
                            <h5 style={{color : "#FFF"}}>{t('63')}</h5>
                        </NavLink>
                    </Row>

                    {/* ************************* UNDER MENU ********************************** */}
                    <Row style={{margin : "30px 15px 0px", borderBottom: "2px solid #DFE7F5",
                        paddingBottom: "5px"}}>
                        <NavLink className="und-menu"
                                 to="/moncompte" exact>
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                            marginBottom: "0", color : "#0C4EFC"}}> {t('144')}
                                            { window.pieceValidation ? <GoAlert style={{marginLeft : "90px",
                                                color : "#FFC200", height : "30px", width: "30px"}}/> : <></>
                                            }
                                        </h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/notifications">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('145')}
                            </h5>
                            {window.NotifNumber >0 ?<><FaBell style={{marginLeft : "40px",
                                color : "#FF4141", height : "30px", width: "30px"}}/>
                                <label className={"bell-number"}>{window.NotifNumber}</label></> : <></>}
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/mescredits">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('146')}</h5>
                        </NavLink>
                    </Row>



                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parametres">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('148')}</h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parametres_jeu">
                            <img src={arrow} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('149')}</h5>
                        </NavLink>
                    </Row>


                    {/*----------------------------- Begin of page ----------------------------------------- */}

                    <Row style={{margin:"20px 0 0"}}>
                        <Col >

                            <Row className="rows">
                                <Col md={"auto"} className="ColParam" xs={9}>
                                    <label style={{color: "#979797" , fontSize: "15pt"}}>
                                        {t('218')} :</label>
                                </Col>
                                <Col className="ColParam" xs={3}>
                                    <Switch loading={this.state.isStatut_loading}
                                            checked={this.state.alertes}
                                            onChange={(e) => this.onChange_Params(e,'alertes')}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <Radio.Group name="radiogroup" value={this.state.alertes_value}
                                             onChange={ (e) => this.handleChange_select(e.target.value,'alertes')}>
                                    <Radio value="1">1h {t('215')}</Radio>
                                    <Radio value="2">2h {t('215')}</Radio>
                                    <Radio value="3">3h {t('215')}</Radio>
                                </Radio.Group>
                                </Col>
                            </Row>

                            <Row className="rows">
                                <Col md={"auto"} className="ColParam" xs={8}>
                                    <label style={{color: "#979797" , fontSize: "15pt"}}>
                                        {t('219')} :</label>

                                </Col>
                                <Col md={"auto"} className="ColParam" xs={2} style={{padding : "0"}}>
                                    <img src={question} alt={"question"}
                                         onClick={() => this.handleMouseHover_choix()}
                                    />
                                </Col>
                            </Row>
                            <Row className="rows">
                                <Col>
                                    <Radio.Group name="radiogroup" value={this.state.vote_value}
                                                 onChange={ (e) => this.handleChange_select(e.target.value,'vote')}>
                                        <Radio value="non">{t('220')}</Radio>
                                        <Radio value="minimale">{t('221')}</Radio>
                                        <Radio value="mediane">{t('222')}</Radio>
                                        <Radio value="maximale">{t('223')}</Radio>
                                        <Radio value="Ne_se_prononce_pas">{t('224')}</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>

                            <Row className="rows">
                                <Col >
                                    {
                                        this.state.isHovering_choix &&
                                        <div className={"shadow p-3 bg-white rounded"}><label>{t('225')}</label></div>
                                    }

                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parrainage">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('150')}</h5>
                        </NavLink>
                    </Row>

                    {/* ************************* MAIN MENU SUITE ******************************   */}
                    <Row style={{margin : "30px 10px 0"}}>
                        <NavLink className="column col"
                                 to={"/Tirage"} activeClassName="selected">
                            <h5 style={{color : "#FFF"}}>{t('64')}</h5>
                        </NavLink>
                    </Row>

                    <Row style={{margin : "0 10px"}}>
                        <NavLink className="column col" style={{borderRadius: "0 0 30px 30px"}}
                                 to={"/assistance"} activeClassName="selected">
                            <h5 style={{color : "#FFF"}}>{t('65')}</h5>
                        </NavLink>

                    </Row>

                </Container>
            );
        }

    }
}

export default withTranslation()(MesParametres_jeu);
