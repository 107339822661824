import React, {Component, useEffect, useState} from 'react';
import {Container, Row, Spinner} from "react-bootstrap";
import "./Nous_connaitre.css"
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
import Col from "react-bootstrap/Col";




const Regles = () =>{

    let [contenu, SetContenu] = useState("")
    let [loading, SetLoading] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/2')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
    }, [])

    //---------------------------------------- DESKTOP VERS ---------------------------------------------
        return(
            <div className="nous-connaitre">
                {loading ? <div className="spinner-center"><Spinner animation="border" role="status" variant="primary"/> </div>:
                    <Container fluid className={"qui-sommes-nous"}>
                        <Row>
                            <Col style={{textAlign : "center"}}>
                            <h4 className="titre-35px">Règles</h4>
                            </Col>
                        </Row>
                        <Row >
                            <Col className={"regles-title"}>
                            { ReactHtmlParser(contenu) }
                            </Col>
                        </Row>

                    </Container>
                }

            </div>
        )





}

export default Regles;
