import React, { Component } from 'react'
import {Visibility} from "@material-ui/icons";
import './login.css';
import './Register.css'
import {Link} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import {Checkbox, message} from "antd";
import {CheckCountry, getIP} from "../Helpers";
import axios from "axios";
import { withTranslation } from 'react-i18next';
import { Input, Space } from 'antd';

let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            PasswordVisibility: true,
            loadingLogin : false,
            SeSouvenir : false,
            errors: {}
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.toggleShow = this.toggleShow.bind(this)
        this.onChange_Souvenir = this.onChange_Souvenir.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        if (localStorage.checkbox === "true") {
            this.setState({SeSouvenir : true})
            this.setState({email : localStorage.username})
        } else {
            this.setState({SeSouvenir : false})
            this.setState({email : ""})
        }
    }


    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChange_Souvenir (e){
        this.setState({SeSouvenir : e.target.checked})
    }

    /**
     * For showing and hidden password
     */
    toggleShow(){
        this.setState({ PasswordVisibility: !this.state.PasswordVisibility });
    }

    /**
     * ****************  Se souvenir de moi  *******************
     */

    lsRememberMe() {
        if (this.state.SeSouvenir === true) {
            localStorage.username = this.state.email;
            localStorage.checkbox = true;
        } else {
            localStorage.username = "";
            localStorage.checkbox = false;
        }
    }


    async onSubmit(e) {
        e.preventDefault();
        if(!this.state.email || !this.state.password){
            message.error(this.props.t('139'),5.5)
        }else{
            this.setState({loadingLogin : true})
            await getIP().then(data => {
                this.setState({UserIp : data.ip})
            })

        message.loading(this.props.t('136'), 4.5)
        this.lsRememberMe()
        axios
            .post('api/users/login', {
                email: this.state.email,
                password: this.state.password,
                ip : this.state.UserIp,
                SeSouvenir : this.state.SeSouvenir
            })
            .then(async response => {
                if(response.data.redirect){
                    window.location.href="/Code-de-verification/" + response.data.verificationToken
                }
                else
                {
                    localStorage.setItem('usertoken', response.data.token)

                    let CountryCode;
                    await getIP().then(async r =>{let ip = r.ip;
                        await axios.post('/api/get-country', {Userip : ip})
                            .then(r => {CountryCode =r.data.country})
                        console.log(CountryCode)
                        CheckCountry(countries.getName(CountryCode, "en", {select: "official"}))
                    })


                    message.success(this.props.t('68'), 2.5)
                    window.location.href="/moncompte"
                }

            })
            .catch(err => {
                this.setState({loadingLogin : false})
                message.error(this.props.t(err.response.data),5.5)
            })
        }
        

    }


    render() {

        const {t} = this.props;

        let width = window.innerWidth;
        if (width > 768){
            return (
                <div style={{
                    padding: "150px",
                    marginRight: "auto",
                    marginLeft: "auto"
                }}>
                    <Container style={{
                        backgroundColor:"white",paddingLeft: "0",
                        paddingRight: "0",
                        borderRadius: "14px" ,boxShadow: "0px 6px 10px #0000001C"
                    }}>
                        <Row noGutters style={{height: "380px"}}>
                            <Col className="back-logi"/>
                            <Col style={{padding: "50px"}}>
                                <form noValidate onSubmit={this.onSubmit} style={{width:"400px"}}>
                                    <h2 className="mb-1 connect" >{t('70')}</h2>
                                    <h4 className={"txt-after-connect"}>
                                        {t('71')} <Link to={"/register"}>{t('81')}</Link>
                                    </h4>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder={t('76')}
                                            value={this.state.email}
                                            onChange={this.onChange}
                                            required={true}
                                        />
                                    </div>
                                    <div className="form-group">

                                        <div className="pass-wrapper">
                                            <Input.Password name="password"
                                                            placeholder="********"
                                                            value={this.state.password}
                                                            onChange={this.onChange}
                                                            className={'password-visibility'}
                                            />

                                        </div>
                                    </div>
                                    <div>
                                        <Checkbox onChange={this.onChange_Souvenir} checked={this.state.SeSouvenir}>
                                            {t('77')}</Checkbox>
                                    </div>
                                    <div style={{marginTop:"50px"}}>
                            <span>
                                <Link style={{color:"#0C4EFC"}} to="/forgotPassword">{t('72')}</Link>
                            </span>
                                   <button
                                       type="submit"
                                       className="btn-valider"
                                       style={{float:"right",padding:"7px 50px"}}
                                       disabled={this.state.loadingLogin}>
                                       OK
                                   </button>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </Container>

                </div>
            )
        }
        else {
            return (
                <div style={{
                    marginRight: "auto", marginTop: "30px",
                    marginLeft: "auto",
                    textAlignLast: "center", padding:"0 40px"
                }}>
                    <Container style={{padding : "0"}}>
                        <Row noGutters>
                            <Col>
                                    <h1 className="mb-3 connect" >
                                        {t('70')}
                                    </h1>
                                    <h4 className={"txt-after-connect"}>
                                        {t('71')} <Link to={"/register"}>{t('81')}</Link>
                                    </h4>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder={t('76')}
                                            value={this.state.email}
                                            onChange={this.onChange}
                                            className="input-login"
                                        />
                                    </div>
                                    <div className="form-group">

                                        <div className="pass-wrapper">
                                            <Input.Password name="password"
                                                            placeholder="********"
                                                            value={this.state.password}
                                                            onChange={this.onChange}
                                                            className={'password-visibility'}
                                            />
                                        </div>
                                    </div>
                            </Col>
                        </Row>
                        <Row style={{justifyContent: "center" ,marginTop: "30px"}}>
                           <Link style={{color:"#0C4EFC"}}
                                 to="/forgotPassword">{t('72')}
                           </Link>
                        </Row>
                        <Row style={{justifyContent: "center", marginTop: "30px"}}>
                            <button
                                type="submit"
                                className="btn-valider"
                                style={{float:"right",padding:"10px 70px"}}
                                disabled={this.state.loadingLogin}
                                onClick={this.onSubmit}>
                                {t('133')}
                            </button>
                        </Row>

                    </Container>

                </div>
            )
        }

    }
}


export default withTranslation()(Login)
