import React, {Component, useEffect, useState} from 'react';
import {Col, Container, Row, Spinner} from "react-bootstrap";
import "./Nous_connaitre.css"
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
import image_transparence from "../Images/transparence.png"
import {useTranslation} from "react-i18next";



const Transparence_et_tarif = () =>{
    const { t } = useTranslation();

    let [contenu, SetContenu] = useState("")
    let [contenu_2, SetContenu_2] = useState("")
    let [loading, SetLoading] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/6')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/52')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_2(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
    }, [])

    //---------------------------------------- DESKTOP VERS ---------------------------------------------


    return(
        <div className="nous-connaitre">
            {loading ?
                <div className="spinner-center"><Spinner animation="border" role="status" variant="primary"/></div> :
                <Container fluid className={"qui-sommes-nous"}>
                    <Row style={{justifyContent: "center"}}>
                        <h4 className="titre-35px">{t('50')}</h4>
                    </Row>

                    <div>{ReactHtmlParser(contenu)}</div>

                    <div className={"transparence-img"}>
                        <h4 className="transparence-txt" style={{marginTop: "60px"}}>
                            Comment nous répartissons le prix du jeu :
                        </h4>
                        <img src={image_transparence} className={"transparence-img-w"}
                             alt={"Com'on Pot Transparence et tarif"}/>
                    </div>

                    <div style={{marginTop : "50px"}}>{ReactHtmlParser(contenu_2)}</div>

                </Container>
            }

        </div>
    )



    //-------------------------------------------  MOBILE VERS  -------------------------------------------


}

export default Transparence_et_tarif;
