import React, {Component, useState} from 'react';
import {Collapse, message, notification, Select, Tabs} from "antd";
import './MesPaiements.css'
import {Col, Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {Link, NavLink} from "react-router-dom";
import VerticalNavbar2 from "../components/VerticalNavbar2";
import {FormatDate, IsLoggedIn} from "../Helpers";
import HeaderNavbarDashboard from "../components/HeaderNavbarDashboard";
import arrow_bottom from "../Images/arrow-bottom.png";
import arrow from "../Images/arrow-right.png";
import {FaBell, GoAlert, GoPlus} from "react-icons/all";
import axios from "axios";
import Table from "antd/es/table";
import i_plus from "../Images/Icon-plus.png"
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import {Modal} from "antd";
import {withTranslation} from "react-i18next";


const {TabPane} =Tabs;
const {Option} = Select;
const { Panel } = Collapse;
const { confirm } = Modal;




class MesPaiements extends Component {
    constructor() {
        super()
        this.state = {
            moyen : "visa",
            loading:false,
            EmailOrNum:"",
            FullName:"",
            CardNumber:"",
            ExpirationDate:"",
            disableAdd: false,
            disableAddPaypal: false,
            disableAddVirement: false,
            CardDataPayment :[],
            AboDataPayment :[],
            IBAN:"",
            BIC : "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.delete = this.delete.bind(this);
        this.deleteVirement = this.deleteVirement.bind(this);
    }

    componentDidMount() {
            axios.get('/api/moyen-paiement')
                .then(res => {
                    console.log(res)
                    if ((res.data.MoyenType === 'visa')){
                        this.setState({FullName : res.data.FullName,
                            CardNumber : res.data.CardNumber, ExpirationDate : res.data.ExpirationDate,
                            disableAdd: true,})
                    }
                    else if((res.data.MoyenType === 'paypal')){
                        this.setState({EmailOrNum : res.data.EmailOrNum,
                            disableAddPaypal : true})
                    }
                });

        axios.get('/api/bank-details')
            .then(res => {
                this.setState({IBAN : res.data.IBAN, BIC : res.data.BIC, disableAddVirement : true})
            });

        axios.get('/api/payments')
                .then(res => {
                    console.log(res);
                    this.setState({CardDataPayment : res.data.card,
                        AboDataPayment : res.data.abo
                    })

                    let children = this.state.CardDataPayment.concat(this.state.AboDataPayment,res.data.vir);
                    this.setState({DataPayment : children})
                })
    }

    openNotification = () => {
        notification['success']({
            message: this.props.t('172'),
            description:
                this.props.t('154'),
        });
    };
    openNotificationError = () => {
        notification['error']({
            message: this.props.t('199'),
            description:
                this.props.t('74'),
        });
    };

    onSubmit()
    {
        this.setState({disableAdd: true, disableAddPaypal: true})
        axios.post('/api/moyen-paiement', {
            MoyenType: this.state.moyen,
            FullName: this.state.FullName,
            CardNumber: this.state.CardNumber,
            ExpirationDate: this.state.ExpirationDate,
            EmailOrNum: this.state.EmailOrNum,
        })
            .then(res => {
                console.log(res.data)
                this.openNotification()
                if (this.state.moyen === "visa"){
                    this.setState({disableAdd: true, loading : false})
                }else{
                    this.setState({disableAddPaypal: true, loading : false})
                }
            })
            .catch( ()=> {
                this.openNotificationError()
                this.setState({disableAdd: false, disableAddPaypal: false, loading : false})
            })
    }

    onSubmitVirement()
    {
        this.setState({disableAdd: true, disableAddPaypal: true, disableAddVirement: true})
        axios.post('/api/bank-details', {
            IBAN: this.state.IBAN,
            BIC: this.state.BIC,
        })
            .then(res => {
                console.log(res.data)
                this.openNotification()
                if (this.state.moyen === "visa"){
                    this.setState({disableAdd: true, loading : false})
                }else if(this.state.moyen === "paypal"){
                    this.setState({disableAddPaypal: true, loading : false})
                }else{
                    this.setState({disableAddVirement: true, loading : false})
                }
            })
            .catch( ()=> {
                this.openNotificationError()
                this.setState({disableAdd: false, disableAddPaypal: false,
                    disableAddVirement: false,
                    loading : false})
            })
    }


     update() {
         this.setState({loading : true})
         axios.put('/api/moyen-paiement', {
            MoyenType: this.state.moyen,
            FullName: this.state.FullName,
            CardNumber: this.state.CardNumber,
            ExpirationDate: this.state.ExpirationDate,
            EmailOrNum: this.state.EmailOrNum,
        })
            .then(res => {
                this.setState({loading : false})
                this.openNotification()
            })
            .catch((e) => {
                this.setState({loading : false})
                this.openNotificationError()
            })

    }

    updateVirement() {
         this.setState({loading : true})
        axios.put('/api/bank-details/', {
            IBAN: this.state.IBAN,
            BIC: this.state.BIC,
        })
            .then(res => {
                this.setState({loading : false})
                this.openNotification()
            })
            .catch((e) => {
                this.setState({loading : false})
                this.openNotificationError()
            })

    }

    delete() {
         this.setState({loading : true})
         axios.delete('/api/moyen-paiement')
            .then(() => {
                if (this.state.moyen === "visa"){
                    this.setState({loading : false, FullName : "", CardNumber : "",
                        ExpirationDate : "", disableAdd: false,})
                }else{
                    this.setState({loading : false, FullName : "", CardNumber : "",
                        ExpirationDate : "", EmailOrNum:"", disableAddPaypal: false})
                }
                this.openNotification()
            })
            .catch((e) => {
                this.setState({loading : false})
                this.openNotificationError()
            })
    }

    deleteVirement() {
         this.setState({loading : true})
         axios.delete('/api/bank-details')
            .then(() => {
                if (this.state.moyen === "visa"){
                    this.setState({loading : false, FullName : "", CardNumber : "",
                        ExpirationDate : "", disableAdd: false,})
                }else if(this.state.moyen === "paypal"){
                    this.setState({loading : false, FullName : "", CardNumber : "",
                        ExpirationDate : "", EmailOrNum:"", disableAddPaypal: false})
                }else{
                    this.setState({loading : false, IBAN : "", BIC : "",
                       disableAddVirement: false})
                }

                this.openNotification()
            })
            .catch((e) => {
                this.setState({loading : false})
                this.openNotificationError()
            })
    }


    handleChange(value) {
        this.setState({moyen : value})
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    showDeleteConfirm() {
        confirm({
            title: this.props.t('196'),
            icon: <ExclamationCircleOutlined />,
            okText: this.props.t('195'),
            okType: 'danger',
            cancelText: this.props.t('162'),
            onOk: ()=>this.delete(),
            onCancel() {
            },
        });
    }



    render() {
        let width = window.innerWidth

        const {t} = this.props
        const columns = [
            {
                title: t('190'),
                dataIndex: 'NumeroTirage',
                key: 'NumeroTirage',
                render: NumeroTirage => (
                    <>
                        {NumeroTirage ? <span>{NumeroTirage}</span> : "-------"}
                    </>
                ),
            },
            {
                title: t('191'),
                dataIndex: 'PaymentDate',
                key: 'PaymentDate',
                render: Date => (
                    <>
                        <span>{FormatDate(Date)}</span>
                    </>
                ),

            },
            {
                title: t('192'),
                dataIndex: 'montant',
                key: 'montant',
                render: montant => (
                    <>
                        <span>{montant} €</span>
                    </>
                ),
            },
            {
                title: t('179'),
                key: 'PaymentType',
                dataIndex: 'PaymentType',
                render: PaymentType => (
                    <>
                        {PaymentType === 'Card' || PaymentType ==='ABO' ? <span>{t('180')}</span> :
                            <>
                                {PaymentType === 'ABO-VB'? <span>{t('294')}</span>:
                                    <span>{t('295')}</span>}
                            </>}
                    </>
                ),
            }

        ];


        if(width > 768) {
            return (

                <Container fluid className={"mespaiements"}>
                    <Row style={{padding:"0 100px"}}>
                        {IsLoggedIn() ?
                            (
                                <Col>
                                    <HeaderNavbarDashboard/>
                                </Col>
                            )   :
                            (<div/>)}
                    </Row>
                    <Row style={{padding:"0 120px", marginTop : "20px"}}>
                        {IsLoggedIn() ?
                            (
                                <Col md={2}>
                                    <VerticalNavbar2/>
                                </Col>
                            )   :
                            (<div/>)}
                        <Col style={{marginTop:"20px"}}>
                            <div style={{minHeight:"150px"}}>
                                <Tabs type="card">
                                    {/* ***** Moyen de paiements ****** */}

{/*
                                    <TabPane tab={t('179')} key="1">
                                        <Container style={{marginTop: "50px"}}>
                                            <Row style={{width:"45%"}}>
                                                <label className={"title-moy"}>
                                                    {t('193')} :
                                                </label>
                                            </Row>
                                            <Row style={{fontSize:'20px',marginTop:"20px",}}>
                                                <label style={{fontSize: "17px", color: "#74869E"}}>
                                                    {t('179')}
                                                    <Select defaultValue={this.state.moyen}
                                                            style={{ width: 170 ,marginLeft:"10px"}}
                                                            onChange={this.handleChange} bordered={false}>
                                                        <Option value="visa">{t('180')}</Option>
                                                        <Option value="paypal">{t('197')}</Option>
                                                        <Option value="virement">{t('181')}</Option>
                                                    </Select>
                                                </label>
                                            </Row>
                                            {this.state.moyen ==='visa' &&
                                                <>
                                            <Row style={{marginTop:"7px",width:"45%"}}>
                                                    <input type="text" placeholder={t('200')}
                                                           name="FullName"
                                                           value={this.state.FullName}
                                                           onChange={this.onChange} className={"pay-inpu"}/>
                                                    <div className="inline-input">
                                                        <input type="text" placeholder="xxxx-xxxx-xxxx-xxxx-7825"
                                                               style={{marginRight:"50px"}}
                                                               name="CardNumber"
                                                               value={this.state.CardNumber}
                                                               onChange={this.onChange}
                                                               className={"pay-inpu"}/>
                                                        <input type="text" placeholder="XX/25"
                                                               name="ExpirationDate"
                                                               value={this.state.ExpirationDate}
                                                               onChange={this.onChange}
                                                               className={"pay-inpu"}/>
                                                    </div>
                                            </Row>
                                            <Row style={{marginTop:"40px",width:"50%"}}
                                                 className="payement">
                                                <Col md={5}>
                                                    <div className="d-inline-flex payement">
                                                        <button className="btn btn-link"
                                                                disabled={this.state.loading || !this.state.disableAdd}
                                                                onClick={()=>this.update()} >{t('79')}</button>
                                                        <span style={{marginTop:"3px"}}> / </span>
                                                        <button className="btn btn-link"
                                                                disabled={this.state.loading || !this.state.disableAdd}
                                                                onClick={()=>this.showDeleteConfirm()}>
                                                            {t('195')}
                                                        </button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <button className="btn btn-link"
                                                                disabled={this.state.disableAdd }
                                                                onClick={()=>this.onSubmit()}>
                                                            <img src={i_plus} width={12}
                                                                 className={"payment-icon"}/>
                                                            {t('194')}</button>
                                                    </div>
                                                </Col>
                                                    </Row>
                                                </>
                                            }
                                            {this.state.moyen ==='paypal' &&
                                                <>
                                            <Row style={{marginTop:"7px",width:"25%"}}>
                                                <input type="text" placeholder={t('198')}
                                                       name="EmailOrNum"
                                                       value={this.state.EmailOrNum}
                                                       onChange={this.onChange}
                                                       className={"pay-inpu"}/>
                                            </Row>
                                            <Row style={{marginTop:"40px",width:"50%"}}>
                                                <Col>
                                                    <div className="d-inline-flex">
                                                        <button className="btn btn-link"
                                                                disabled={this.state.loading ||
                                                                !this.state.disableAddPaypal}
                                                                onClick={()=>this.update()} >{t('79')}</button>
                                                        <span style={{margin:"10px"}}> / </span>
                                                        <button className="btn btn-link"
                                                                disabled={this.state.loading ||
                                                                !this.state.disableAddPaypal}
                                                                onClick={()=>this.delete()}>
                                                            {t('195')}
                                                        </button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <button className="btn btn-link"
                                                                disabled={this.state.disableAddPaypal }
                                                                onClick={()=>this.onSubmit()}>
                                                            <img src={i_plus} width={12}
                                                                 className={"payment-icon"}/>
                                                            {t('194')}</button>
                                                    </div>
                                                </Col>
                                                    </Row>
                                                </>
                                            }

                                            {this.state.moyen ==='virement' &&
                                                <>
                                            <Row style={{marginTop:"7px"}}>
                                                <div>
                                                    <label>IBAN</label>
                                                    <input type="text"
                                                           placeholder="MA4523852374100236"
                                                           name="IBAN"
                                                           value={this.state.IBAN}
                                                           onChange={this.onChange}
                                                           style={{marginRight:"50px"}}
                                                           className={"pay-inpu"}/>
                                                </div>
                                                <div style={{marginLeft:"30px"}}>
                                                    <label>BIC</label>
                                                    <input type="text" placeholder="TYXXX"
                                                           name="BIC"
                                                           value={this.state.BIC}
                                                           onChange={this.onChange}
                                                           className={"pay-inpu"}
                                                    />
                                                </div>
                                            </Row>
                                            <Row style={{marginTop:"40px",width:"50%"}}>
                                                <Col>
                                                    <div className="d-inline-flex">
                                                        <button className="btn btn-link"
                                                                disabled={this.state.loading ||
                                                                !this.state.disableAddVirement}
                                                                onClick={()=>this.updateVirement()} >{t('79')}</button>
                                                        <span style={{margin:"10px"}}> / </span>
                                                        <button className="btn btn-link"
                                                                disabled={this.state.loading ||
                                                                !this.state.disableAddVirement}
                                                                onClick={()=>this.deleteVirement()}>
                                                            {t('195')}
                                                        </button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <button className="btn btn-link"
                                                                disabled={this.state.disableAddVirement }
                                                                onClick={()=>this.onSubmitVirement()}>
                                                            <img src={i_plus} width={12}
                                                                 className={"payment-icon"}/>
                                                            {t('194')}</button>
                                                    </div>
                                                </Col>
                                                    </Row>
                                                </>
                                            }

                                        </Container>
                                    </TabPane>
*/}
                                    <TabPane tab={t('201')} key="3">
                                        <Table columns={columns} dataSource={this.state.DataPayment}
                                               className="table-pay"/>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </Container>

            );
        }

    // ************************************************* VERS MOBILE ************************************

    else {
            return (

                <Container className ="pad-mob" >
                    <Row style={{margin : "0 10px"}}>
                        {/* // ***************** MENU MON COMPTE NAVBAR *************** */}

                        <NavLink className="column col" style={{borderLeft:"5px solid white",
                            borderRadius: "30px 30px 0 0"}}
                                 to={"/moncompte"} activeClassName="selected">
                            <h5 style={{color : "#FFF"}}>{t('141')}</h5>
                        </NavLink>
                    </Row>


                    {/* ********************** UNDER MENU ********************************** */}
                    <Row style={{margin : "30px 15px 0px", borderBottom: "2px solid #DFE7F5",
                        paddingBottom: "5px"}}>
                        <NavLink className="und-menu"
                                 to="/moncompte" exact>
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                            marginBottom: "0", color : "#0C4EFC", width:"100%"}}>
                                            <label>{t('144')}</label>
                                            { window.pieceValidation ? <GoAlert style={{float:"right",
                                                color : "#FFC200", height : "30px", width: "30px"}}/> : <></>
                                            }
                                        </h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/notifications">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",width:"100%",
                                marginBottom: "0", color : "#0C4EFC"}}><label>{t('145')} </label>

                            {window.NotifNumber >0 ?<><FaBell style={{float:"right",
                                color : "#FF4141", height : "30px", width: "30px"}}/>
                                <label className={"bell-number"}>{window.NotifNumber}</label></> : <></>}
                            </h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/mescredits">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('146')}</h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/mespaiements">
                            <img src={arrow} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('147')}</h5>
                        </NavLink>
                    </Row>

                    {/*------------------------ Begin og page ----------------------------------------- */}
                    <Row className={"padding-mob"}>
                        <Collapse defaultActiveKey={['1']} accordion className="collapse-credit">
                            <Panel header={t('179')} key="1" className="header-collapse">
                                <Container style={{padding:"0 25px"}}>
                                    <Row >
                                        <label style={{fontSize: "15px", fontWeight:"500",
                                            color: "#324865", borderBottom: "1pt solid #DFE7F5",
                                            paddingBottom: "5px"}}>
                                            {t('193')} :
                                        </label>
                                    </Row>
                                    <Row style={{marginTop:"20px",}}>
                                        <label style={{fontSize: "13pt", color: "#74869E"}}>{t('179')}
                                            <Select defaultValue={this.state.moyen}
                                                    style={{ width: 135,marginLeft:"17px"}}
                                                    onChange={this.handleChange} bordered={false}>
                                                <Option value="visa">{t('180')}</Option>
                                                <Option value="paypal">{t('197')}</Option>
                                                <Option value="virement">{t('181')}</Option>
                                            </Select>
                                        </label>
                                    </Row>
                                    {this.state.moyen ==='visa' &&
                                    <>
                                        <Row style={{marginTop:"20px"}}>
                                            <input type="text" placeholder={t('200')}
                                                   name="FullName"
                                                   value={this.state.FullName}
                                                   onChange={this.onChange}/>
                                            <div className="inline-input">
                                                <input type="text" placeholder="xxxx-xxxx-xxxx-xxxx-7825"
                                                       style={{marginRight:"50px"}}
                                                       name="CardNumber"
                                                       value={this.state.CardNumber}
                                                       onChange={this.onChange}/>
                                                <input type="text" placeholder="XX/25"
                                                       name="ExpirationDate"
                                                       value={this.state.ExpirationDate}
                                                       onChange={this.onChange}/>
                                            </div>
                                        </Row>
                                        <Row style={{marginTop:"40px"}}>
                                            <Col>
                                                <div className="d-inline-flex">
                                                    <button className="btn btn-link"
                                                            disabled={this.state.loading || !this.state.disableAdd}
                                                            onClick={()=>this.update()} >{t('79')}</button>
                                                    <span style={{margin:"10px"}}> / </span>
                                                    <button className="btn btn-link"
                                                            disabled={this.state.loading || !this.state.disableAdd}
                                                            onClick={()=>this.delete()}>
                                                        {t('195')}
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <button className="btn btn-link"
                                                            disabled={this.state.disableAdd }
                                                            onClick={()=>this.onSubmit()}>
                                                        <img src={i_plus} width={12}
                                                             className={"payment-icon"}/>
                                                        {t('194')}</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                    }

                                    {this.state.moyen ==='paypal' &&
                                    <>
                                        <Row style={{marginTop:"20px",width:"80%"}}>
                                            <input type="text" placeholder={t('198')}
                                                   name="EmailOrNum"
                                                   value={this.state.EmailOrNum}
                                                   onChange={this.onChange}/>
                                        </Row>
                                        <Row style={{marginTop:"40px", marginLeft: "-40px"}}>
                                            <Col>
                                                <div className="d-inline-flex">
                                                    <button className="btn btn-link"
                                                            disabled={this.state.loading ||
                                                            !this.state.disableAddPaypal}
                                                            onClick={()=>this.update()} >{t('79')}</button>
                                                    <span style={{margin:"10px"}}> / </span>
                                                    <button className="btn btn-link"
                                                            disabled={this.state.loading ||
                                                            !this.state.disableAddPaypal}
                                                            onClick={()=>this.delete()}>
                                                        {t('195')}
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <button className="btn btn-link"
                                                            disabled={this.state.disableAddPaypal }
                                                            onClick={()=>this.onSubmit()}>
                                                        <img src={i_plus} width={12}
                                                             className={"payment-icon"}/>
                                                        {t('194')}</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                    }

                                    {this.state.moyen ==='virement' &&
                                    <>
                                        <Row style={{marginTop:"20px",width:"80%"}}>
                                                <label>IBAN</label>
                                                <input type="text"
                                                       placeholder="MA4523852374100236"
                                                       name="IBAN"
                                                       value={this.state.IBAN}
                                                       onChange={this.onChange}
                                                       style={{marginRight:"50px"}}/>
                                        </Row>
                                        <Row style={{marginTop:"20px",width:"80%"}}>
                                                <label>BIC</label>
                                                <input type="text" placeholder="TYXXX"
                                                       name="BIC"
                                                       value={this.state.BIC}
                                                       onChange={this.onChange}
                                                />
                                        </Row>
                                        <Row style={{marginTop:"40px", marginLeft: "-40px"}}>
                                            <Col>
                                                <div className="d-inline-flex">
                                                    <button className="btn btn-link"
                                                            disabled={this.state.loading ||
                                                            !this.state.disableAddVirement}
                                                            onClick={()=>this.updateVirement()} >{t('79')}</button>
                                                    <span style={{margin:"10px"}}> / </span>
                                                    <button className="btn btn-link"
                                                            disabled={this.state.loading ||
                                                            !this.state.disableAddVirement}
                                                            onClick={()=>this.deleteVirement()}>
                                                        {t('195')}
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <button className="btn btn-link"
                                                            disabled={this.state.disableAddVirement }
                                                            onClick={()=>this.onSubmitVirement()}>
                                                        <img src={i_plus} width={12}
                                                             className={"payment-icon"}/>
                                                        {t('194')}</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                    }
                                </Container>
                            </Panel>
                            <Panel header={t('201')} key="2" className="header-collapse">
                                <Table columns={columns} dataSource={this.state.DataPayment}
                                       className="table-pay"/>
                            </Panel>
                        </Collapse>
                    </Row>


                    {/* -------------------------------------------------------------------------------------- */}
                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parametres">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('148')}</h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parametres_jeu">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('149')}</h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parrainage">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('150')}</h5>
                        </NavLink>
                    </Row>


                    {/* ************************* MAIN MENU SUITE ******************************   */}
                    <Row style={{margin : "30px 10px 0"}}>
                        <NavLink className="column col"
                                 to={"/Tirage"} activeClassName="selected">
                            <h5 style={{color : "#FFF"}}>{t('64')}</h5>
                        </NavLink>
                    </Row>

                    <Row style={{margin : "0 10px"}}>
                        <NavLink className="column col" style={{borderRadius: "0 0 30px 30px"}}
                                 to={"/assistance"} activeClassName="selected">
                            <h5 style={{color : "#FFF"}}>{t('65')}</h5>
                        </NavLink>

                    </Row>

                </Container>

            );
        }

    }
}

export default withTranslation()(MesPaiements);
