import React, {Component, useEffect, useState} from 'react'
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {IsLoggedIn} from "../Helpers";
import VerticalNavbar_assistance from "../components/VerticalNavbar_assistance";
import {Input,Breadcrumb, Collapse} from "antd";
import "./condition_jeu.css"
import {Link, NavLink} from "react-router-dom";
import HeaderNavbarDashboard from "../components/HeaderNavbarDashboard";
import arrow_bottom from "../Images/arrow-bottom.png";
import arrow from "../Images/arrow-right.png";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import {useTranslation} from "react-i18next";

const { Search } = Input;
const { Panel } = Collapse;

function Les_gains() {
    let width = window.innerWidth
    let [titre_1, Settitre_1]   = useState("");
    let [titre_2, Settitre_2]   = useState("");
    let [titre_3, Settitre_3]   = useState("");
    let [contenu_1, SetContenu_1]   = useState("");
    let [contenu_2, SetContenu_2]   = useState("");
    let [contenu_3, SetContenu_3]   = useState("");
    let [loading, SetLoading] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/111')
            .then((res) => {
                SetLoading(false)
                Settitre_1(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/112')
            .then((res) => {
                SetLoading(false)
                Settitre_2(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/113')
            .then((res) => {
                SetLoading(false)
                Settitre_3(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })


        axios.get('/api/pages/114')
            .then((res) => {
                SetLoading(false)
                SetContenu_1(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/115')
            .then((res) => {
                SetLoading(false)
                SetContenu_2(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/116')
            .then((res) => {
                SetLoading(false)
                SetContenu_3(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

    }, [])
    const history = useHistory()
    const {t} = useTranslation()

    const onSearch = value => {
        let queryString = "q=" + value;
        history.push(`/assistance/faqS/search?${queryString}`)
    };

    if(width > 768){
        return(
            <>{
                loading ? <div className="spinner-center" style={{left: "50vw"}}>
                        <Spinner animation="border" role="status" variant="primary"/>
                    </div> :
                    <Container fluid>
                        <Row style={{padding: "0 100px"}}>
                            {IsLoggedIn() ?
                                (
                                    <Col>
                                        <HeaderNavbarDashboard/>
                                    </Col>
                                ) :
                                (<div/>)}
                        </Row>
                        <Row className="condition">
                                    <Col md={2}>
                                        <VerticalNavbar_assistance/>
                                    </Col>
                            <Col className={"padding-faq"}>
                                <Search placeholder="Rechercher..." enterButton onSearch={onSearch}/>
                                <Breadcrumb separator=">" style={{marginTop: "30px"}}>
                                    <Breadcrumb.Item>
                                        <Link to={"/assistance/faqS"}>
                                            <label className="breadcrump"
                                                   style={{
                                                       textDecoration: "underline",
                                                       cursor: "pointer"
                                                   }}>FAQ's</label>
                                        </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item className="breadcrump">Les gains</Breadcrumb.Item>
                                </Breadcrumb>
                                <div style={{marginTop: "50px", marginLeft: "70px"}}>
                                    <Collapse style={{backgroundColor: "#FFF", border: "0"}}>
                                        <Panel header={ReactHtmlParser(titre_1)} key="1">
                                            <p className="condition-text">{ReactHtmlParser(contenu_1)}</p>
                                        </Panel>
                                        <Panel header={ReactHtmlParser(titre_2)} key="2">
                                            <p className="condition-text">{ReactHtmlParser(contenu_2)}</p>
                                        </Panel>
                                        <Panel header={ReactHtmlParser(titre_3)} key="3">
                                            <p className="condition-text">{ReactHtmlParser(contenu_3)}</p>
                                        </Panel>
                                    </Collapse>
                                </div>
                            </Col>
                        </Row>
                    </Container>
            }
            </>
        )
    }

    else {
        return (
            <>{
                loading ? <div className="spinner-center" style={{left: "50vw"}}><Spinner animation="border" role="status" variant="primary"/>
                    </div> :
                    <Container className="pad-mob">
                        {IsLoggedIn() ?
                            <>
                                <Row style={{margin : "0 10px"}}>
                                    {/* // ***************** MENU MON COMPTE NAVBAR *************** */}

                                    <NavLink className="column col" style={{borderRadius: "30px 30px 0 0"}}
                                             to={"/moncompte"} activeClassName="selected">
                                        <h5 style={{color : "#FFF"}}>{t('63')}</h5>
                                    </NavLink>
                                </Row>

                                <Row style={{margin : "0 10px"}}>
                                    <NavLink className="column col"
                                             to={"/Tirage"} activeClassName="selected">
                                        <h5 style={{color : "#FFF"}}>{t('64')}</h5>
                                    </NavLink>
                                </Row>

                                <Row style={{margin : "0 10px"}}>
                                    <NavLink className="column col" to={"/assistance"} activeClassName="selected">
                                        <h5 style={{color : "#FFF"}}>{t('65')}</h5>
                                    </NavLink>
                                </Row>
                            </>
                            :
                            (<div/>)}
                        {/* ********************** UNDER MENU ********************************** */}

                        <Row className="row-und-menu">
                            <NavLink className="und-menu" to="/assistance/accessibilite">
                                <img src={arrow_bottom} width={30} height={30}/>
                                <h5 style={{
                                    marginLeft: "10px", marginTop: "2px",
                                    marginBottom: "0", color: "#0C4EFC"
                                }}>{t('151')}</h5>
                            </NavLink>
                        </Row>

                        <Row className="row-und-menu">
                            <NavLink className="und-menu" to="/assistance/faqS">
                                <img src={arrow_bottom} width={30} height={30}/>
                                <h5 style={{
                                    marginLeft: "10px", marginTop: "2px",
                                    marginBottom: "0", color: "#0C4EFC"
                                }}>{t('152')}</h5>
                            </NavLink>
                        </Row>
                        <Row>
                            <Search placeholder={t('292')}
                                    enterButton onSearch={onSearch}/>
                        </Row>
                        <Row className={"faq-mobile"}>
                            <Breadcrumb separator=">" style={{marginTop: "20px"}}>
                                <Breadcrumb.Item>
                                    <Link to={"/assistance/faqS"}>
                                        <label className="breadcrump"
                                               style={{textDecoration: "underline", cursor: "pointer"}}>{t('152')}
                                        </label>
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item className="breadcrump">{t('288')}</Breadcrumb.Item>
                            </Breadcrumb>
                            <div style={{marginTop: "30px"}}>
                                <Collapse style={{backgroundColor: "#FFF", border: "0"}}>
                                    <Panel header={ReactHtmlParser(titre_1)} key="1">
                                        <p className="condition-text">{ReactHtmlParser(contenu_1)}</p>
                                    </Panel>
                                    <Panel header={ReactHtmlParser(titre_2)} key="2">
                                        <p className="condition-text">{ReactHtmlParser(contenu_2)}</p>
                                    </Panel>
                                    <Panel header={ReactHtmlParser(titre_3)} key="3">
                                        <p className="condition-text">{ReactHtmlParser(contenu_3)}</p>
                                    </Panel>
                                </Collapse>
                            </div>
                        </Row>


                        <Row className="row-und-menu">
                            <NavLink className="und-menu" to="/assistance">
                                <img src={arrow} width={30} height={30}/>
                                <h5 style={{
                                    marginLeft: "10px", marginTop: "2px",
                                    marginBottom: "0", color: "#0C4EFC"
                                }}>{t('55')}</h5>
                            </NavLink>
                        </Row>
                    </Container>
            }
            </>
        )
    }

}

export default Les_gains;
