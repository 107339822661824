import React, {Component} from 'react'
import axios from "axios";
import {getIP} from "../Helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Result} from "antd";
import Button from "antd/es/button";
import {Link} from "react-router-dom";
import { withTranslation } from 'react-i18next';



class ConfirmIpChange extends Component {

    // A bit of state to give the user feedback while their email
    // address is being confirmed on the User model on the server.
    constructor() {
        super();
        this.state = {
            confirmed : false,
            confirmedError : false,
            Spin : true
        };
    }

    componentDidMount = () => {
        const { token } = this.props.match.params
        this.setState({
            token: token
        })
        this.sendConfirmation()
    }

    async sendConfirmation() {
        let UserIp = 0;
        await getIP().then(data => {
            console.log("ip : " + data.ip)
            UserIp = data.ip
            })
        axios.post('/api/users/ip-change/confirm', {
                token: this.state.token,
                UserIp: UserIp,
            })
            .then(res => {
                console.log(res)
                this.setState({Spin : false,confirmed : true, confirmedError : false})
                // this.props.history.push(`/login`)
            })
            .catch(err => {
                console.log(err)
                this.setState({Spin : false, confirm: false, confirmedError : true})
            })
    }

    render() {
        const {t} = this.props;

        return(
            <div style={{margin: "auto"}}>
                {this.state.Spin && <CircularProgress />}
                {this.state.confirmed && <Result
                    status="success"
                    title={t('80')}
                    extra={[<Link to={"/login"}>
                        <Button type="primary" key="console">{t('44')}</Button>
                    </Link>
                    ]}
                />
                }
                {this.state.confirmedError && <Result
                    status="error"
                    title={t('74')}
                    extra={[
                        <Link to={"/login"}>
                        <Button type="primary" key="console">
                            {t('44')}
                        </Button>
                        </Link>
                    ]}
                />
                }
            </div>
        )}

}

export default withTranslation()(ConfirmIpChange)
