import React, {Component, useEffect, useState} from 'react';
import {Col, Container, Row, Spinner} from "react-bootstrap";
import "./Nous_connaitre.css"
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
import fleche_blanche from "../Images/fleches.png";
import logoGaming2 from "../Images/curacao-logo-big.png"



const Licence_jeu = () =>{
    let width = window.innerWidth

    let [contenu, SetContenu] = useState("")
    let [contenu_2, SetContenu_2] = useState("")
    let [contenu_3, SetContenu_3] = useState("")
    let [loading, SetLoading] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/5')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/50')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_2(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/51')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_3(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
    }, [])

    //---------------------------------------- DESKTOP VERS ---------------------------------------------

        return(
            <div className="nous-connaitre">
                {loading ? <div className="spinner-center"><Spinner animation="border" role="status" variant="primary"/> </div>:
                    <Container fluid className={"licence"}>
                        <Row style={{justifyContent: "center",}}>
                            <h4 className="titre-35px">Licence de jeu</h4>
                        </Row>

                        <div className={"h4-underline"}>{ ReactHtmlParser(contenu) }</div>


                        {width > 768 ?
                            <>
                                <div className={"licence-titre"}>
                                    <h4>
                                        Mais pourquoi avoir choisi la <strong>Curacao e-Gaming Licensing
                                        Authority</strong> plutôt qu’une autre ?
                                    </h4>
                                </div>
                                <div style={{textAlign:"center", marginBottom:"30px"}}>
                                    <a target="_blank" rel="noopener noreferrer" href={"https://www.curacao-egaming.com/"}>
                                    <img src={logoGaming2} style={{width:"35%"}}/>
                                    </a>
                                </div>
                                <div className={"licence_2"}>{ReactHtmlParser(contenu_2)}</div>
                                <div className={"licence_2"}>{ReactHtmlParser(contenu_3)}</div>
                            </>

                            :

                            <div style={{marginTop : "75px"}} className={"licence-blue concept-bleu"}>
                                <div className={"licence-titre"}>
                                    <h4>
                                        Mais pourquoi avoir choisi la <strong>Curacao e-Gaming Licensing
                                        Authority</strong>
                                        plutôt qu’une autre ?
                                    </h4>
                                </div>
                                <div style={{textAlign:"center", marginBottom:"30px"}}>
                                    <a target="_blank" rel="noopener noreferrer" href={"https://www.curacao-egaming.com/"}>
                                    <img src={logoGaming2} style={{width:"90%"}}/>
                                    </a>
                                </div>
                                <Row  className={"concept-bleu-d licence-span"}>
                                    <Col md={"1"} xs={2}>
                                        <img src={fleche_blanche} />
                                    </Col>
                                    <Col style={{paddingLeft:"0"}}>
                                        {ReactHtmlParser(contenu_2)}
                                    </Col>
                                </Row>
                                <Row  className={"concept-bleu-d licence-span"}>
                                    <Col md={"1"} xs={2}>
                                        <img src={fleche_blanche} />
                                    </Col>
                                    <Col style={{paddingLeft:"0"}}>
                                        {ReactHtmlParser(contenu_3)}
                                    </Col>
                                </Row>
                            </div>
                        }


                    </Container>
                }

            </div>
        )





}

export default Licence_jeu;
