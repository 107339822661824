import React, {Component} from 'react';
import axios from "axios";
import {Col, Container, Row} from "react-bootstrap";
import '../components/login.css';
import i18n from '../i18n/i18n'

class ForgotPassword extends Component {
    constructor(){
        super()
        this.state={
            email: '',
            showError: false,
            showNull:false,
            messageFromServer:'',
            loadingLogin : false,

        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    SendEmail = e =>{
        e.preventDefault();
        if (this.state.email===''){
            this.setState({
                showNull : true
            })
        } else {
            this.setState({loadingLogin : true})
            axios
                .post('api/forgotPassword',{
                    email : this.state.email
                    })
                .then(res => {
                    console.log(res.data)
                    this.setState({loadingLogin : false})

                    if (res.data === 'email not in db'){
                        this.setState({
                            showError : true,
                            messageFromServer:''
                        })
                    }
                    else if(res.data === 'recovery email sent'){
                        this.setState({
                            showError : false,
                            messageFromServer : res.data
                        })

                    }
                })
                .catch(err=>{
                    console.log(err.data);

                })
        }

    }

    render() {
        const {email,messageFromServer,showNull,showError} = this.state;
        let width = window.innerWidth;
        if (width > 768){
            return (
                <div style={{
                    padding: "150px",
                    marginRight: "auto",
                    marginLeft: "auto"
                }}>
                    <Container style={{
                        backgroundColor:"white",paddingLeft: "0",
                        paddingRight: "0",
                        borderRadius: "14px" ,boxShadow: "0px 6px 10px #0000001C"
                    }}>
                        <Row noGutters style={{height: "350px"}}>
                            <Col className="back-logi"/>
                            <Col style={{padding: "30px 50px 20px 50px", margin : "auto"}}>
                                <form noValidate onSubmit={this.SendEmail} style={{width:"400px"}}>
                                    <h4 className="mb-3 font-weight-normal" style={{color:"#0C4EFC"}}>{i18n.t('72')}</h4>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            id="email"
                                            value={email}
                                            name="email"
                                            placeholder={i18n.t('129')}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    {
                                        showNull && (
                                            <div className="error">
                                                <span >{i18n.t('130')}</span>
                                            </div>
                                        )
                                    }

                                    {
                                        showError && (
                                            <div>
                                                <p className="error">
                                                    {i18n.t('131')}
                                                </p>
                                            </div>

                                        )
                                    }

                                    {messageFromServer && (
                                        <div>
                                            <span className="text-success">{i18n.t('132')}</span>
                                        </div>
                                    )
                                    }
                                    <div style={{marginTop:"50px"}}>
                                        <button
                                            type="submit"
                                            className="btn-valider"
                                            style={{float:"right",padding:"7px 50px"}}
                                            disabled={this.state.loadingLogin}>
                                            {this.state.loadingLogin ? <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : i18n.t('133')}
                                        </button>
                                    </div>

                                </form>

                            </Col>
                        </Row>
                    </Container>

                </div>
            )
        }
        else {
            return (
                <div style={{
                    marginRight: "auto",
                    marginLeft: "auto",
                    textAlignLast: "center"
                }}>
                    <Container style={{padding : "10%"}}>
                        <Row noGutters>
                            <Col>
                                <h4 className="mb-3 font-weight-bold" style={{color:"#0C4EFC"}}>
                                    {i18n.t('72')}
                                </h4>

                                <div className="form-group">
                                    <input
                                        type="email"
                                        id="email"
                                        value={email}
                                        name="email"
                                        placeholder={i18n.t('129')}
                                        onChange={this.onChange}
                                    />
                                </div>

                            </Col>
                        </Row>
                        {
                            showNull && (
                                <div className="error">
                                    <span >{i18n.t('130')}</span>
                                </div>
                            )
                        }

                        {
                            showError && (
                                <div>
                                    <p className="error">
                                        {i18n.t('131')}
                                    </p>
                                </div>

                            )
                        }

                        {messageFromServer && (
                            <div>
                                <span className="text-success">{i18n.t('132')}</span>
                            </div>
                        )
                        }
                        <Row style={{justifyContent: "center", marginTop: "30px"}}>
                            <button
                                type="submit"
                                className="btn-valider"
                                style={{float:"right",padding:"10px 70px"}}
                                onClick={this.SendEmail}
                                disabled={this.state.loadingLogin}>
                                {this.state.loadingLogin ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : i18n.t('133')}
                            </button>
                        </Row>



                    </Container>

                </div>
            )
        }

    }
}

export default ForgotPassword;
