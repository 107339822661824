import axios from 'axios'
import React from 'react'
import {isCountryNoAuthorized, IsLoggedIn} from '../Helpers/index'
import { Redirect, Route } from 'react-router-dom'


export const register = (newUser) => {
    return axios.post('api/users/register', {
            first_name: newUser.first_name,
            last_name: newUser.last_name,
            email: newUser.email,
            password: newUser.password,
            imageFile: newUser.data
        })
        .then(response => {
            console.log('Registered')
        })
}


export const login = user => {
    return axios
        .post('api/users/login', {
            email: user.email,
            password: user.password,
            ip : user.ip
        })
        .then(response => {
            console.log(response.data.redirect)
            console.log(response.data.verificationToken)
            if(response.data.redirect){
                console.log("redirection")
                return ["redirect",response.data.verificationToken]
            }
            else
            {
                localStorage.setItem('usertoken', response.data.token)
                return ["success"]
            }

        })
        .catch(err => {
            console.log("auth Service"+err.response.data)
            return ["failed",err.response.data];
        })
}


// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.


export const PrivateRoute = ({ component: Component, path, ...rest}) => {

    // Add your own authentication on the below line.
    const isLoggedIn = IsLoggedIn()

    return (
        <Route
            path={path}
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/login', state: { prevLocation: path } }} />
                )
            }
        />
    )
}
export const AsssistanceRoute = ({ component: Component, path, ...rest}) => {

    // Add your own authentication on the below line.
    const isLoggedIn = IsLoggedIn()

    return (
        <Route
            path={path}
            {...rest}
            render={props =>
                !isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/assistance', state: { prevLocation: path } }} />
                )
            }
        />
    )
}

export const Auth_AsssistanceRoute = ({ component: Component, path, ...rest}) => {

    // Add your own authentication on the below line.
    const isLoggedIn = IsLoggedIn()

    return (
        <Route
            path={path}
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/assistance_no_auth', state: { prevLocation: path } }} />
                )
            }
        />
    )
}


export const LoginRoute = ({ component: Component, path, ...rest}) => {

    // Add your own authentication on the below line.
    const isLoggedIn = IsLoggedIn()

    return (
        <Route
            path={path}
            {...rest}
            render={props =>
                !isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/' }} />
                )
            }
        />
    )
}


export const AuthCountryRoute = ({component: Component, path, isAuth, ...rest}) => {

    // Add your own authentication on the below line.

    isCountryNoAuthorized().then(r=>{
        if (r){
            return <Route path={path} {...rest} render={props =><Component {...props} />} />
        }
        else{
            return <Route path={path} {...rest} render={props =><Redirect to={{pathname: '/login', state: {prevLocation: path}}}/>} />
        }
        })


    /*return (
        <Route
            path={path}
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{pathname: '/login', state: {prevLocation: path}}}/>
                )
            }
        />
    )*/
}

