import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import './VerticalNavbar2.css'
import {NavLink} from "react-router-dom";
import ellipse from '../Images/Ellipse 264.png'
import axios from "axios";
import {FaBell,GoAlert} from "react-icons/all";
import { withTranslation } from 'react-i18next';

class VerticalNavbar2 extends Component {
    constructor(){
        super()
        this.state = {
            notifCount : 0,
            DisplayNotif : false
        }
    }
    componentDidMount() {
        axios.get('/api/notifications/count')
            .then((res) => {
                console.log(res.data)
                this.setState({notifCount : res.data})

            }).catch((res)=>{
            console.log(res.data)
        })
    }

    render() {
        const {t} = this.props;

        return (
            <Container className="vericalNav" fluid >
                <Row>
                       <NavLink className="columnBack" style={{borderRadius: "30px 0 0 0",
                           borderBottom:"2px solid white", width:"100%"}}
                                to="/moncompte" exact activeClassName="selected selected1">
                           <h5> {t('144')} { window.pieceValidation ? <GoAlert style={{marginLeft : "75px",
                               color : "#FFC200", height : "30px", width: "30px"}}/> : <></>
                           } </h5>
                           <img className="ellipse ellipse1" src={ellipse} width={30} height={30}/>
                       </NavLink>
                </Row>
                <Row>
                       <NavLink className="columnBack" style={{borderBottom:"2px solid white", width:"100%"}}
                                to="/moncompte/notifications" activeClassName="selected selected2">
                           <h5>{t('145')}
                               {window.NotifNumber >0 ?<><FaBell style={{marginLeft : "20px",
                                   color : "#FF4141", height : "30px", width: "30px"}}/>
                                   <label className={"bell-number"}>{this.state.notifCount}</label></> : <></>}</h5>
                           <img className="ellipse ellipse2" src={ellipse} width={30} height={30}/>
                       </NavLink>
                </Row>
                <Row>
                       <NavLink className="columnBack" style={{borderBottom:"2px solid white", width:"100%"}}
                                to="/moncompte/mescredits" activeClassName="selected selected2">
                           <h5>{t('146')}</h5>
                           <img className="ellipse ellipse2" src={ellipse} width={30} height={30}/>
                       </NavLink>
                </Row>
                {/*<Row>
                       <NavLink className="columnBack" style={{borderBottom:"2px solid white", width:"100%"}}
                                to="/moncompte/mespaiements" activeClassName="selected selected3">
                           <h5>{t('147')}</h5>
                           <img className="ellipse ellipse3" src={ellipse} width={30} height={30}/>
                       </NavLink>
                </Row>*/}
                <Row>
                       <NavLink className="columnBack" style={{borderBottom:"2px solid white", width:"100%"}}
                                to="/moncompte/parametres" activeClassName="selected selected4">
                           <h5>{t('148')}</h5>
                           <img className="ellipse ellipse4" src={ellipse} width={30} height={30}/>
                       </NavLink>
                </Row>
                <Row>
                       <NavLink className="columnBack" style={{borderBottom:"2px solid white", width:"100%"}}
                                to="/moncompte/parametres_jeu" activeClassName="selected selected4">
                           <h5>{t('149')}</h5>
                           <img className="ellipse ellipse4" src={ellipse} width={30} height={30}/>
                       </NavLink>
                </Row>
                <Row>
                       <NavLink className="columnBack" style={{borderRadius: "0 0 0 30px", width:"100%"}}
                                to="/moncompte/parrainage" activeClassName="selected selected5">
                           <h5>{t('150')}</h5>
                           <img className="ellipse ellipse4" src={ellipse} width={30} height={30}/>
                       </NavLink>
                </Row>

            </Container>
        );
    }
}

export default withTranslation()(VerticalNavbar2);
