import React, { Component } from 'react'
import '../components/login.css'
import {Col, Container, Row} from "react-bootstrap";
import ReactCodeInput from 'react-verification-code-input';
import axios from 'axios'
import {message, Spin} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


class VerificationCode extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            loading : false,
        };

        this.onComplete = this.onComplete.bind(this);
    }


    onComplete(value){
        console.log(this.props.match.params.token)
        this.setState({loading : true})
        axios.post('/api/users/login/CheckCode',{code: value, VerificationToken: this.props.match.params.token})
            .then(response => {
                localStorage.setItem('usertoken', response.data.token)
                window.location.href="/moncompte"
            })
            .catch(err => {
                message.error(err.response.data,4)
                this.setState({loading : false})

            })
    }


    render() {
        let width = window.innerWidth;
        if (width > 768){
            return (
                <div style={{
                    padding: "150px",
                    marginRight: "auto",
                    marginLeft: "auto"
                }}>
                    <Container style={{
                        backgroundColor:"white",paddingLeft: "0",
                        paddingRight: "0",
                        borderRadius: "14px" ,boxShadow: "0px 6px 10px #0000001C"
                    }}>
                        <Row noGutters style={{height: "370px"}}>
                            <Col className="back-login"/>
                            <Col style={{padding: "0 80px",placeSelf: "center"}}>
                                <Spin indicator={antIcon} spinning={this.state.loading}>
                                <div style={{width:"400px"}}>
                                    <h2 className="mb-3 font-weight-normal" style={{color:"#0C4EFC"}}>
                                        Vérifiez votre email !</h2>
                                    <h4 style={{color:"#565656"}}>
                                        Veuillez entrer le code de vérification à 6 chiffres que nous avons envoyé
                                        par e-mail :</h4>
                                    <ReactCodeInput className="verif_code" onComplete={this.onComplete}
                                                    disabled={this.state.loading}/>
                                </div>
                                </Spin>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        }
        else {
            return (
                <div style={{
                    marginRight: "auto",
                    marginLeft: "auto",
                    textAlignLast: "center"
                }}>
                    <Container style={{padding : "50px 25px 25px"}}>
                        <Row noGutters>
                            <Col>
                                <h1 className="mb-3 font-weight-bold" style={{color:"#0C4EFC",fontSize: "25px"}}>
                                    Vérifiez votre email !
                                </h1>
                                <h4 style={{color:"#565656", fontSize: "17px", marginBottom: "40px",
                                    textAlign: "justify", padding: "0px 10px"}}>
                                    Veuillez entrer le code de vérification à 6 chiffres que nous avons envoyé
                                    par e-mail :
                                </h4>
                                <ReactCodeInput className="verif_code" onComplete={this.onComplete}
                                                style={{width: "auto !important"}} disabled={this.state.loading}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        }

    }
}

export default VerificationCode
