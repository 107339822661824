import React, {Component, useEffect, useState} from 'react';
import axios from "axios";
import {
    checkClotureParticipation,
    foo,
    FormatDate,
    getTimeRemaining,
    getYear_Week,
    IsLoggedIn,
    nextSaturday
} from "../Helpers";
import {Card, notification, Progress, Radio, Select} from 'antd';
import {Link, NavLink} from "react-router-dom";
import './tirage.css'
import "./tirage.css"
import {Row} from "reactstrap";
import { Collapse } from 'reactstrap';
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import {AiFillEye} from "react-icons/all";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {get_choix_tirage, GetVote} from "../Services/Votes";
import {montant_participation} from "../Constants/constants";
import Modal from "antd/lib/modal";
import {Col, Container} from "react-bootstrap";
import HeaderNavbarDashboard from "./HeaderNavbarDashboard";
import MapChart from "./MapChart";
import { useTranslation } from 'react-i18next';

const {Option} = Select;

const openNotification = (msg) => {
    notification['success']({
        message: 'Succès',
        description: msg,
    });
};
const openNotificationError = () => {
    notification['error']({
        message: 'Erreur',
        description:
            'Une erreur s\'est produite veuillez réessayer.',
    });
};

/**********************************************************
 ****** setting tirage number ID year and week ************
 * ********************************************************
 */
let year_week = getYear_Week(new Date());
const NumeroTirage = year_week[0]+"-"+year_week[1];
let PrecedentNumeroTirage = year_week[0]+"-"+(year_week[1]-1);
if(year_week[1] == 1){
    PrecedentNumeroTirage = (year_week[0]-1)+"-"+"52";
}

const deadline2 = nextSaturday()
deadline2.setHours(21,0,0,)

let PlayersNumber = 0


let choix_votes = get_choix_tirage(PlayersNumber * montant_participation)

console.log(choix_votes)


// const state = store.getState();

let VoteStat = false;
let ClotureParticipation = false;
const Tirage = () =>{

    const { t } = useTranslation();

// *********** NOTIFICATION POUR PAYMENT PAR ABONNEMENT *************
    const ABO_PAY_Notification = () => {
        const args = {
            message: t('245'),
            description:
                t('244'),
            duration: 3,
        };
        notification.success(args);
    };

    const ABO_PAY_Notification_FAILED = () => {
        const args = {
            message: t('246'),
            description:
                t('247'),
            duration: 3,
        };
        notification.error(args);
    };

// ------ REDUX ---------------

//const dispatch = useDispatch();

    let [PlayerHasNotVoted,setPlayerHasNotVoted] = useState(true);
    let [Statut,SetStaut] = useState(t('274'));
    let [DisableStep2, SetDisableStep2] = useState(true);
    let [DisableStep3, SetDisableStep3] = useState(true);
    let [isValidated, SetisValidated] = useState('true');

    let [PaymentStatus , SetPaymentStatus] = useState(false);

    let[Participations, SetParticipations] = useState([]);
    let[VotesStats, SetVotesStats] = useState([]);
    let[daysSpan, SetdaysSpan] = useState("");
    let[hoursSpan, SethoursSpan] = useState("");
    let[minutesSpan, SetminutesSpan] = useState("");
    let[secondsSpan, SetsecondsSpan] = useState("");
    let[Payment_method, setPayment_method] = useState("CB");
    let [VoteValue, SetVoteValue ] = useState(choix_votes[0])
    let [VoteTotal, SetVoteTotal] = useState(choix_votes[0])
    let [Modal_payment_visible, setModal_payment_visible] = useState(false);
    let [Solde, setSolde] = useState(0)
    const [loading, setLoading] = useState(false)
    const [errorCredit, seterrorCredit] = useState(false)
    const [validerNumero, setvaliderNumero] = useState(false)
    const [AftervaliderNumero, setAftervaliderNumero] = useState(false)


    // **** number for draw *****
    let [Number,SetNumber] = useState("");


    useEffect(  () =>
    {
        const fetchData = async () =>{
            axios
                .post('/api/payments/checkPaymentStatus')
                .then(res=>{
                    if (res.data.status){
                        SetPaymentStatus(true)
                        SetDisableStep2(true);
                        SetStaut(t('248'));
                        SetNumber(res.data.NumeroTicket)
                    }
                })
                .catch(err =>{
                })
            axios
                .get('/api/users/profile/validation-compte')
                .then(res=>{
                    console.log("validation : ",res.data.validation_piece)
                    SetisValidated(res.data.validation_piece)
                    console.log(isValidated)
                })
                .catch(err =>{
                })

            axios
                .post('/api/votes/CheckVote')
                .then(res=>{
                    if (res.data.status){
                        setPlayerHasNotVoted(false)
                        if(res.data.vote !== "NCPP") SetVoteValue(parseInt(res.data.vote))
                        else SetVoteValue(res.data.vote)
                    }
                    else
                        setPlayerHasNotVoted(true)
                })
                .catch(err =>{
                })

            axios
                .post('/api/payments/MyParticipations')
                .then(res=>{
                    if (res.data.Participations){
                        res.data.Participations.sort(function(a,b){
                            // Turn your strings into dates, and then subtract them
                            // to get a value that is either negative, positive, or zero.
                            return new Date(b.created) - new Date(a.created);
                        });
                        SetParticipations(Object.values(res.data.Participations))
                    }
                })
                .catch(err =>{
                })



            axios.post('api/tirageNumber/countPlayers' , {WeekID : NumeroTirage}).then((response) => {
                PlayersNumber = response.data.nombres_joueurs;
            })
                .catch(err => {
                });


            //********* GET SOLDE OF THE PLAYER **************
            axios.get("/api/payments/abonnements")
                .then(res => {
                    setSolde(res.data.solde)
                })
            VoteStat = await foo()
            ClotureParticipation = await checkClotureParticipation()
            console.log("cloture stat : ",ClotureParticipation)

            axios.get('/api/votes/stats')
                .then(res=>{
                    SetVotesStats(Object.values(res.data.vote))
                    SetVoteTotal(res.data.total)

                })
                .catch(err =>{
                })
        }

        fetchData()
    },[])



    const generateTicket = async () =>{
        if(ClotureParticipation === false){
            setLoading(true)
            await axios.post('/api/generate', {numero : NumeroTirage})
                .then(res =>{
                    setLoading(false)
                    if (res.status === 200){
                        // ******  Add ticket number to the screen ************
                        SetNumber(res.data.number);
                        // ******  Disable Step 2 *************
                        /*SetDisableStep2(false);
                        SetDisableStep3(false);*/
                        setvaliderNumero(true)
                    }

                })
        }


    }

    const validateTicket = async () =>{
        if(ClotureParticipation === false) {

            SetDisableStep2(false);
            SetDisableStep3(false);
            setAftervaliderNumero(true);
        }
    }


    //**********************************************
    const validatePayment = () => {
        if(ClotureParticipation === false) {

            if (Payment_method === "CB") {
                RedirectToPayment()
            }
            //*************** PAYEMENT PAR ABONNEMENT **************
            else {

                if (Solde === 0) {
                    seterrorCredit(true)
                } else {
                    seterrorCredit(false)
                    axios
                        .post('/api/payments/substractAbo')
                        .then(res => {
                            if (res.data.result === "SUCCESS") {
                                setModal_payment_visible(false)
                                setSolde(res.data.solde)
                                SetPaymentStatus(true)
                                SetStaut(t('248'));
                                ABO_PAY_Notification()
                                axios.post('api/tirageNumber/countPlayers' , {WeekID : NumeroTirage}).then((response) => {
                                    PlayersNumber = response.data.nombres_joueurs;
                                })
                                    .catch(err => {
                                    });
                                axios
                .post('/api/votes/CheckVote')
                .then(res=>{
                    if (res.data.status){
                        setPlayerHasNotVoted(false)
                        if(res.data.vote !== "NCPP") SetVoteValue(parseInt(res.data.vote))
                        else SetVoteValue(res.data.vote)
                    }
                    else
                        setPlayerHasNotVoted(true)
                })
                .catch(err =>{
                })
                            } else {
                                seterrorCredit(true)
                                ABO_PAY_Notification_FAILED()
                            }
                        })
                        .catch(err => {
                            seterrorCredit(true)
                        })
                }

            }
        }
    }

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);


    /*********************************
     * *** Redirect to Payline Page
     * ******************************/
    function RedirectToPayment() {
        axios
            .post('api/payments', {
                NumeroTirage : NumeroTirage, Ticket : Number
            })
            .then(response => {
                window.location.href = response.data.url;
            })
            .catch(err => {
                return ["failed",err.response.data];
            })

    }
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    async function Save_Vote() {
        if(ClotureParticipation === false) {
            await axios
                .post('api/votes', {
                    vote: VoteValue
                })
                .then(response => {
                    setPlayerHasNotVoted(false)
                    openNotification(t('249'))
                })
                .catch(err => {
                })
        }
    }

    function initializeClock(endtime) {
        // const daysSpan = clock.querySelector('.days');
        // const hoursSpan = clock.querySelector('.hours');
        // const minutesSpan = clock.querySelector('.minutes');
        // const secondsSpan = clock.querySelector('.seconds');

        function updateClock() {
            const t = getTimeRemaining(endtime);

            SetdaysSpan(t.days);
            SethoursSpan(('0' + t.hours).slice(-2));
            SetminutesSpan(('0' + t.minutes).slice(-2));
            SetsecondsSpan(('0' + t.seconds).slice(-2))


            if (t.total <= 0) {
                clearInterval(timeinterval);
            }
        }

        //updateClock();
        const timeinterval = setInterval(updateClock, 1000);
    }



    //console.log (nextSaturday())

    initializeClock(deadline2);



    let width = window.innerWidth


    //------------------------------------------- Desktop VERS ------------------------------------------------------
    if(width > 768) {
        return(
            <Container fluid >
                <Row style={{padding:"0 100px"}}>
                    {IsLoggedIn() ?
                        (
                            <Col>
                                <HeaderNavbarDashboard/>
                            </Col>
                        )   :
                        (<div/>)}
                </Row>
                <Row style={{marginTop:"30px"}}>
                    <div className="main-div">
                        <div>
                            <h4 style={{float : "left", marginBottom : "30px", color :"#324865"}}>
                                {t('250')} {NumeroTirage}</h4>
                            <div className="montant">
                                <div>
                                    <label style={{color :"#324865", fontWeight : "300"}}>{t('183')} </label>
                                    <label className="numbers">{PlayersNumber * montant_participation} €</label>
                                </div>
                                <div>
                                    <label style={{color :"#324865", fontWeight : "300"}}>{t('271')} : </label>
                                    <label className="numbers">{PlayersNumber}</label>
                                </div>
                            </div>
                        </div>
                        <div style={{clear : "both"}}>

                            <Modal visible={isModalVisible} footer={null} onCancel={handleCancel}
                                   className={'map-winners'}>
                                <div>
                                    <h5 className='cart-title'>{t('319')} {PrecedentNumeroTirage}</h5>
                                    <MapChart />
                                </div>
                            </Modal>
                            <div style={isValidated==='false' ?
                                {pointerEvents: "none", opacity: "0.5"} : {}}>
                            
                            <div style={(PaymentStatus || ClotureParticipation) ?
                                {pointerEvents: "none", opacity: "0.5"} : {}}>
                                {/* ********** Step 1 : Generate Ticket  ************/}
                                <Card title={t('290')} >
                                    <Row style={{justifyContent: "center"}}>
                                        <button className="btn-tirage" disabled={AftervaliderNumero}
                                                onClick={generateTicket}>
                                            {loading ? <span>{t('189')}</span> :
                                                <span>{t('272')}</span>}
                                        </button>
                                    </Row>
                                    {!AftervaliderNumero && validerNumero &&
                                    <Row style={{justifyContent: "center"}}>
                                    <h5 style={{color: "#0C4EFC"}} className={"msg-indic"}>{t('318')}</h5>
                                    </Row>
                                    }
                                    
                                    <Row style={{justifyContent: "center", marginTop : "20px",
                                        alignItems: "baseline"}}>
                                        <h5 style={{color: "#0C4EFC"}}>{t('273')} : {Number}</h5>
                                        {validerNumero &&
                                        <button className="btn-valider btn-v-tirage" disabled={AftervaliderNumero}
                                                onClick={validateTicket}>
                                            <span>{t('114')}</span>
                                        </button>
                                        }
                                    </Row>
                                </Card>


                                <div style={(DisableStep2)? {pointerEvents: "none", opacity: "0.5"} :
                                    {}}>
                                {/* ****** Step 2 : Payment  **********/}
                                <Card title={t('250') +" "+ NumeroTirage} style={{marginTop:"30px"}}  >
                                    <Row style={{justifyContent: "center"}}>
                                        <button className="btn-tirage"
                                                onClick={()=>{!DisableStep2 &&
                                                    setModal_payment_visible(true)}
                                                }>
                                            {t('251')}
                                        </button>
                                    </Row>
                                    <Row style={{justifyContent: "center"}}>
                                        <label style={{marginTop : "10px"}}>{t('252')} {Statut}</label>
                                    </Row>
                                </Card>
                                </div>

                            </div>
                            {/* *************************** Step 3 : Votes  ********************/}
                            <div style={(
                                PlayerHasNotVoted && 
                                PaymentStatus) &&
                            ClotureParticipation === false ? {} : {pointerEvents: "none",
                                opacity: "0.5"}} >
                                <Card title="VOTES"  style={{marginTop:"30px"}}>
                                    <Row style={{justifyContent: "center"}}>
                                        <label className={"votre-choix"}>{t('253')}</label>
                                    </Row>
                                    {PlayerHasNotVoted ?
                                    <>
                                            <Row style={{justifyContent: "center"}}>
                                                <label className={"txt-vote"}>
                                                    {t('254')} {PlayersNumber} {t('255')}
                                                    {PlayersNumber*3} € ;
                                                    {t('256')}</label>
                                            </Row>
                                            <Radio.Group onChange={ e => SetVoteValue(e.target.value)}
                                                         value={VoteValue} className="radio-votes">
                                            <Row>
                                                <Col md={3}>
                                                    <div style={{display : "grid", width:"fit-content"}}>
                                                        <label className={"valeur"}>{t('221')}</label>
                                                        <div style={{display : "grid"}}>
                                                        <Radio value={choix_votes[0]} className={"radio-vote"}>
                                                                <label className={"radio-choix"}>
                                                                    {choix_votes[0]} {t('257')}</label>
                                                        </Radio>
                                                            <label className={"montant-petit"}>{(PlayersNumber
                                                                * montant_participation) / choix_votes[0]} €</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div style={{display : "grid", width:"fit-content"}}>
                                                        <label className={"valeur"}>{t('222')}</label>
                                                        <div style={{display : "grid"}}>
                                                        <Radio value={choix_votes[1]} className={"radio-vote"}>
                                                                <label className={"radio-choix"}>
                                                                    {choix_votes[1]} {t('257')}</label>
                                                        </Radio>
                                                            <label className={"montant-petit"}>{(PlayersNumber
                                                                * montant_participation) / choix_votes[1]} €</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div style={{display : "grid", width:"fit-content"}}>
                                                        <label className={"valeur"}>{t('223')}</label>
                                                        <div style={{display : "grid"}}>
                                                        <Radio value={choix_votes[2]} className={"radio-vote"}>
                                                                <label className={"radio-choix"}>
                                                                    {choix_votes[2]} {t('257')}</label>
                                                        </Radio>
                                                            <label className={"montant-petit"}>{(PlayersNumber
                                                                * montant_participation) / choix_votes[2]} €</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div style={{display : "grid", width:"fit-content"}}>
                                                        <div style={{display : "grid"}} >
                                                        <Radio value={"NCPP"} className={"radio-vote"}
                                                               style={{marginTop : "20px"}}>
                                                                <label className={"radio-choix"}>
                                                                    {t('224')}</label>
                                                        </Radio>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            </Radio.Group>
                                        <Row style={{justifyContent: "center",marginTop : "20px"}}>
                                                <button className="btn-valider btn-v-tirage" onClick={() =>{
                                                    (
                                                        // VoteStat && 
                                                        PlayerHasNotVoted && PaymentStatus) &&
                                                    Save_Vote()}
                                                }>
                                                    {t('114')}
                                                </button>
                                            </Row>
                                        </> :
                                    <>
                                    <Row style={{justifyContent: "center"}}>
                                       {VoteValue === "NCPP" ?<label className={"mon-vote"}>
                                               {t('224')}</label>
                                           :
                                           <label className={"mon-vote"}>{VoteValue} {t('257')} - {(PlayersNumber
                                               * montant_participation) / VoteValue} €</label>
                                       }
                                            </Row>
                                    {/* <Row style={{justifyContent: "center",marginTop : "20px"}}>
                                                <button className="btn-valider btn-v-tirage"
                                                        onClick={() =>{(
                                                            // VoteStat && 
                                                            // PlayerHasNotVoted && 
                                                            PaymentStatus) &&
                                                            setPlayerHasNotVoted(true)}}>
                                                    {t('79')}
                                                </button>
                                            </Row> */}
                                    </>
                                    }

                                </Card>
                            </div>
                            </div>
                            {/* ********************************** Stats  *****************************/}
                            {VotesStats.length>0 ?
                            <Card style={{marginTop:"30px", paddingLeft:"30px", paddingRight:"30px"}}>
                                <Row>
                                    <Col>
                                        <label className={"txt-stats"}>
                                            {t('270')}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{padding:"20px 80px"}}>
                                        {VotesStats.map((row) => (
                                            <div>
                                                {row.Choix_vote === "NCPP" ?
                                                    <label style={{float: "left", color:"#979797"}}
                                                           className={"txt-stats"}>
                                                        {t('224')}</label>
                                                    :
                                                    <label style={{float: "left", color:"#979797"}}
                                                           className={"txt-stats"}>
                                                        {t('269')} : {row.Choix_vote} {t('257')}</label>
                                                }
                                                <Progress percent={((row.Count / VoteTotal) *100).toFixed(2)}
                                                          style={{marginBottom:"30px", marginLeft:"-40px"}}
                                                          className={"stats"}/>
                                            </div>

                                        ))}
                                    </Col>
                                </Row>
                            </Card>
                            :<></>}

                            {/* ************************************************************
                ****************** Step 4 : Tirage au sort  ********************
                ****************************************************************/}
                            <Card title={t('291')}  style={{marginTop:"30px"}}>
                                <Row style={{justifyContent: "center",borderBottom:"1px solid #0000002e"}}>
                                    <label style={{color:"#74869E", fontSize:"15px"}}>{t('258')} </label>
                                    <label style={{marginRight : "4px",marginLeft:"6px",color:"#74869E",fontSize:"15px" }}>
                                        {daysSpan} {t('296')} :
                                    </label>
                                    <label style={{marginRight : "4px",color:"#74869E",fontSize:"15px"}}>
                                        {hoursSpan} {t('297')} :
                                    </label>
                                    <label style={{marginRight : "4px",color:"#74869E",fontSize:"15px"}}>
                                        {minutesSpan} {t('298')} :
                                    </label>
                                    <label style={{marginRight : "4px",color:"#74869E",fontSize:"15px"}}>
                                        {secondsSpan}{t('299')}
                                    </label>
                                </Row>
                                <Row style={{justifyContent: "center"}}>
                                    <button className="btn btn-link"
                                        onClick={()=>setIsModalVisible(!isModalVisible)}>
                                        <label style={{marginTop : "10px",color:"#74869E",fontSize:"15px",
                                        cursor: "pointer"}}>
                                            {t('259')}</label>
                                    </button>

                                </Row>
                            </Card>

                        </div>

                        <div style={{marginTop: "100px"}}>
                            <div onClick={toggle} className="history">
                                <h4 style={{ marginBottom: '0', color :"#979797", fontWeight:"400"}}>
                                    {t('201')}</h4>
                                <DownOutlined color={"#979797"}/>
                            </div>
                            <Collapse isOpen={isOpen} style={{padding: "40px 150px"}}>
                                <TableContainer>
                                    <Table aria-label="simple table" className="table-striped-rows">
                                        <TableHead style={{backgroundColor: "#0C4EFC",
                                            borderRadius: "10px 10px 0px 0px"}}>
                                            <TableRow>
                                                <TableCell align="center">{t('190')}</TableCell>
                                                <TableCell align="center">{t('260')}</TableCell>
                                                <TableCell align="center">{t('261')}</TableCell>
                                                <TableCell align="center">{t('262')}</TableCell>
                                                <TableCell align="center">{t('263')}</TableCell>
                                                <TableCell align="center">{t('264')}</TableCell>
                                                <TableCell align="center">{t('265')}</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {Participations.map((row) => (
                                                <TableRow key={row[0]}>
                                                    <TableCell align="center">{row.NumeroWeek}</TableCell>
                                                    <TableCell align="center">{FormatDate(row.created)}</TableCell>
                                                    <TableCell align="center">
                                                        <label className={"old-ticket"}>{row.Number}</label>
                                                    </TableCell>
                                                    <TableCell align="center">{row.count}</TableCell>
                                                    <TableCell align="center">{row.vote}</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center">
                                                        {row.count * montant_participation} €
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Collapse>
                        </div>

                        <Modal
                            visible={Modal_payment_visible}
                            onOk={() => setModal_payment_visible(false)}
                            onCancel={() => setModal_payment_visible(false)}
                            footer={null}
                            width={850}
                        >
                            <Container style={{
                                backgroundColor:"white",paddingLeft: "0",
                                paddingRight: "0",
                                borderRadius: "14px"
                            }}>
                                <Row noGutters>
                                    <Col className="back-login" md={2}/>
                                    <Col style={{padding: "70px 50px 40px"}}>
                                        <label style={{float : "right", marginTop:"-40px"}}>
                                            {t('184')} <b>{Solde}</b></label>
                                        <div  style={{width:"450px"}}>
                                            <h2 className="mb-3 title-pai-tirage">
                                                {t('266')}</h2>
                                            <label style={{color:"#565656",fontSize: "17px"}}>
                                                {t('178')}</label>
                                            <div  className="inline-input">
                                                <h4 className={"moyen"}>{t('179')} : </h4>
                                                <Select defaultValue="CB" style={{marginLeft : "15px"}}
                                                        onChange = { (value) => {setPayment_method(value)}}>
                                                    <Option value="CB">{t('180')}</Option>
                                                    <Option value="Credit">{t('267')}</Option>
                                                </Select>
                                            </div>
                                            {errorCredit ? <h5 className="text-danger">
                                                {t('268')}
                                            </h5> :<></>}

                                        </div>
                                        <div style={{float : "right" , marginTop : "30px"}}>
                                            <h5 className={"credit-txt"}>{t('183')} {montant_participation} €</h5>
                                            <button
                                                className="btn-valider"
                                                style={{padding:"10px 55px", marginTop : "5px"}}
                                                onClick={validatePayment}>
                                                {t('114')}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal>
                    </div>
                </Row>
            </Container>
        )
    }


    //---------------------------------------------------- MOBILE VERS -----------------------------------------------
    else {
        return(
            <Container className ="pad-mob tir">
                <Row style={{margin : "0 10px"}}>
                    {/* // ***************** MENU MON COMPTE NAVBAR *************** */}

                    <NavLink className="column col" style={{borderRadius: "30px 30px 0 0"}}
                             to={"/moncompte"} activeClassName="selected">
                        <h5 style={{color : "#FFF"}}>{t('63')}</h5>
                    </NavLink>
                </Row>

                <Row style={{margin : "0 10px"}}>
                    <NavLink className="column col"
                             to={"/Tirage"} activeClassName="selected">
                        <h5 style={{color : "#FFF"}}>{t('64')}</h5>
                    </NavLink>
                </Row>

                {/*----------------------------- Begin og page ----------------------------------------- */}

                <Row style={{margin:"30px 15px"}} className="main-div-mobile">
                    <Col>
                        <Row>
                            <h4 style={{fontSize : "15pt" ,float : "left", marginBottom : "30px", color :"#324865"}}>
                                TIRAGE EN COURS {NumeroTirage}</h4>
                        </Row>

                        <Row style={{width : "50%"}}>
                            <Col style={{padding : "0"}}>
                                <label style={{color :"#324865", fontWeight : "500",
                                    fontSize:"15px"}}>
                                    {t('183')} </label>
                            </Col>
                            <Col style={{padding : "0"}}>
                                <label className="numbers">{PlayersNumber * montant_participation} €</label>
                            </Col>
                        </Row>

                        <Row style={{marginTop :"-5px", marginBottom : "20px"}}>
                            <Col style={{padding : "0"}} xs={7}>
                                <label style={{color :"#324865", fontWeight : "500", fontSize:"15px"}}>
                                    {t('271')} : </label>
                            </Col>
                            <Col style={{padding : "0"}}>
                                <label className="numbers">{PlayersNumber}</label>
                            </Col>
                        </Row>
                        <Modal visible={isModalVisible} footer={null} onCancel={handleCancel}>
                            <div>
                            <h5 className='cart-title'>{t('319')} {PrecedentNumeroTirage}</h5>
                                <MapChart />
                            </div>
                        </Modal>
                        <div style={isValidated==='false' ?
                                {pointerEvents: "none", opacity: "0.5"} : {}}>
                        <Row style={PaymentStatus ? {pointerEvents: "none", opacity: "0.5"} : {}}>
                                    <Col style={{padding :"0"}}>
                                    {/* ****** Step 1 : Generate Ticket  **********/}
                                    <Card title={t('290')}   >

                                        <Row style={{justifyContent: "center"}}>
                                            <button className="btn-tirage" onClick={generateTicket}
                                                    disabled={AftervaliderNumero}>
                                                {t('272')}
                                            </button>
                                        </Row>
                                        {!AftervaliderNumero && validerNumero &&
                                    <Row style={{justifyContent: "center"}}>
                                    <h5 style={{color: "#0C4EFC"}} className={"msg-indic"}>{t('318')}</h5>
                                    </Row>
                                    }
                                        <Row style={{justifyContent: "center", marginTop : "20px",
                                            alignItems: "baseline"}}>
                                            <h5 style={{color: "#0C4EFC"}}>{t('273')} : {Number}</h5>
                                            {validerNumero &&
                                            <button className="btn-valider btn-v-tirage"
                                                    disabled={AftervaliderNumero}
                                                    onClick={validateTicket}>
                                                <span>{t('114')}</span>
                                            </button>
                                            }
                                        </Row>
                                    </Card>

                                    </Col>
                                </Row>
                        <Row style={(DisableStep2)? {pointerEvents: "none", opacity: "0.5"} :
                            {}}>
                            <Col style={{padding :"0"}}>

                                    {/* ****** Step 2 : Payment  **********/}
                                    <Card title={t('250') +" "+ NumeroTirage} style={{marginTop:"30px"}}  >
                                        <Row style={{justifyContent: "center"}}>
                                            <button className="btn-tirage" onClick={()=>{
                                                !DisableStep2 && setModal_payment_visible(true)}}>
                                                {t('251')}
                                            </button>
                                        </Row>
                                        <Row style={{justifyContent: "center"}}>
                                            <label style={{marginTop : "10px"}}>{t('252')} {Statut}</label>
                                        </Row>
                                    </Card>

                            </Col>
                        </Row>

                                {/* *************************************
                ****** Step 3 : Votes  *********
                *******************************************/}
                <Row  style={(VoteStat && PlayerHasNotVoted && PaymentStatus) ? {} :
                    {pointerEvents: "none", opacity: "0.5"}}>
                    <Col style={{padding :"0"}}>
                    <Card title="VOTES"  style={{marginTop:"30px"}}>
                        <Row style={{justifyContent: "center"}}>
                            <label className={"votre-choix"} style={{marginTop : "10px"}}>{t('253')}</label>
                        </Row>
                        {PlayerHasNotVoted ?
                            <>
                                <Row style={{justifyContent: "justify", padding : "0 25px"}}>
                                    <label className={"txt-vote"}>
                                        {t('254')} {PlayersNumber} {t('255')}
                                        {PlayersNumber*3} € ;
                                        {t('256')}</label>
                                </Row>
                                <Row style={{justifyContent: "justify", padding : "20px 20px"}}>
                                    <Radio.Group onChange={ e => SetVoteValue(e.target.value)} value={VoteValue}>
                                        <Row>
                                        <Col xs={5}>
                                        <div style={{display : "grid"}}>
                                        <label className={"valeur"}>{t('221')}</label>
                                        <Radio value={choix_votes[0]} className={"radio-vote"}>
                                            <div style={{marginTop:"-5px", display : "grid"}}>
                                                <label className={"radio-choix"}>{choix_votes[0]} {t('257')}</label>
                                                <label className={"montant-petit"}>{(PlayersNumber
                                                    * montant_participation) / choix_votes[0]} €</label>
                                            </div>
                                        </Radio>
                                        </div>
                                        </Col>
                                        <Col xs={5}>
                                                <div style={{display : "grid"}}>
                                                    <label className={"valeur"}>{t('222')}</label>
                                                    <Radio value={choix_votes[1]} className={"radio-vote"}>
                                                        <div style={{marginTop:"-5px", display : "grid"}}>
                                                            <label className={"radio-choix"}>{choix_votes[1]} {t('257')}</label>
                                                            <label className={"montant-petit"}>{(PlayersNumber
                                                                * montant_participation) / choix_votes[1]} €</label>
                                                        </div>
                                                    </Radio>
                                                </div>
                                            </Col>
                                        <Col xs={5} style={{marginTop : "15px"}}>
                                                <div style={{display : "grid"}}>
                                                    <label className={"valeur"}>{t('223')}</label>
                                                    <Radio value={choix_votes[2]} className={"radio-vote"}>
                                                        <div style={{marginTop:"-5px", display : "grid"}}>
                                                            <label className={"radio-choix"}>{choix_votes[2]} {t('257')}</label>
                                                            <label className={"montant-petit"}>{(PlayersNumber
                                                                * montant_participation) / choix_votes[2]} €</label>
                                                        </div>
                                                    </Radio>
                                                </div>
                                            </Col>
                                        <Col xs={5} style={{marginTop : "15px"}}>
                                                <div style={{display : "grid"}}>
                                                    <Radio value={"NCPP"} style={{marginTop : "23px"}}
                                                           className={"radio-vote"}>
                                                        <div style={{marginTop:"-10px"}}>
                                                            <label className={"radio-choix"}>{t('224')}
                                                            </label>
                                                        </div>
                                                    </Radio>
                                                </div>
                                        </Col>
                                        </Row>
                                    </Radio.Group>
                                </Row>
                                <Row style={{justifyContent: "center",margin : "20px"}}>
                                    <button className="btn-valider btn-tirage" onClick={() =>{
                                        (VoteStat && PlayerHasNotVoted && PaymentStatus) && Save_Vote()}}>
                                        {t('114')}
                                    </button>
                                </Row>
                            </> :
                            <>
                                <Row style={{justifyContent: "center"}}>
                                    {VoteValue === "NCPP" ?<label className={"mon-vote"}>
                                            {t('224')}</label>
                                        :
                                        <label className={"mon-vote"}>{VoteValue} {t('257')} - {(PlayersNumber
                                            * montant_participation) / VoteValue} €</label>
                                    }

                                </Row>
                                {/* <Row style={{justifyContent: "center",margin : "20px"}}>
                                    <button className="btn-valider btn-v-tirage"
                                            onClick={() =>{(VoteStat && PlayerHasNotVoted && PaymentStatus) &&
                                                setPlayerHasNotVoted(true)}}>
                                        {t('79')}
                                    </button>
                                </Row> */}

                            </>
                        }
                    </Card>
                    </Col>
                </Row>
                </div>

                        {/* ******************* Stats ********************/}
                        <Row  >
                            <Col style={{padding :"0"}}>
                        {VotesStats.length>0 ?
                            <Card style={{marginTop:"30px", paddingLeft:"10px", paddingRight:"10px"}}>
                                <Row>
                                    <Col>
                                        <label className={"txt-stats"}>
                                            {t('270')}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{padding:"20px 15px"}}>
                                        {VotesStats.map((row) => (
                                            <div>
                                                {row.Choix_vote === "NCPP" ?
                                                    <label style={{float: "left", color:"#979797"}}
                                                           className={"txt-stats"}>
                                                        {t('224')}</label>
                                                    :
                                                    <label style={{float: "left", color:"#979797"}}
                                                           className={"txt-stats"}>
                                                        {t('269')} : {row.Choix_vote} {t('257')}</label>
                                                }
                                                <Progress percent={(row.Count / VoteTotal) *100}
                                                          style={{marginBottom:"30px"}}
                                                          className={"stats"}/>
                                            </div>

                                        ))}
                                    </Col>
                                </Row>
                            </Card>
                            :<></>}
                            </Col>
                        </Row>
                                {/* ************************************************************
                ****************** Step 4 : Tirage au sort  ********************
                ****************************************************************/}
                <Row>
                    <Col style={{padding :"0"}}>
                                <Card title={t('291')}  style={{marginTop:"30px"}}>
                                    <Row style={{justifyContent: "center",borderBottom:"1px solid #0000002e"}}>
                                        <label>{t('258')} </label>
                                        <label style={{marginRight : "4px",marginLeft:"6px",color:"#74869E" }}>
                                            {daysSpan} :
                                        </label>
                                        <label style={{marginRight : "4px",color:"#74869E"}}>
                                            {hoursSpan} :
                                        </label>
                                        <label style={{marginRight : "4px",color:"#74869E"}}>
                                            {minutesSpan} :
                                        </label>
                                        <label style={{marginRight : "4px",color:"#74869E"}}>
                                            {secondsSpan}
                                        </label>
                                    </Row>
                                    <Row style={{justifyContent: "center"}}>
                                        <button className="btn btn-link"
                                                onClick={()=>setIsModalVisible(!isModalVisible)}>
                                        <label style={{marginTop : "10px",color:"#74869E",cursor: "pointer"}}>
                                            {t('259')}</label>
                                        </button>
                                    </Row>
                                </Card>
                    </Col>
                </Row>


                            <div style={{marginTop: "50px"}}>
                                <Row onClick={toggle} className="history">
                                    <div style={{display: "flex"}}>
                                        <h4 style={{ marginBottom: '1rem', color :"#0C4EFC" }}>{t('201')}</h4>
                                        <AiFillEye color={"#0C4EFC"} size={30} className={"eye-coll"}/>
                                    </div>
                                </Row>
                                <Row>
                                    <Col style={{padding :"0"}}>
                                <Collapse isOpen={isOpen} style={{padding: "20px 0"}}>
                                    <TableContainer>
                                        <Table aria-label="simple table" className="table-striped-rows">
                                            <TableHead style={{backgroundColor: "#0C4EFC",
                                                borderRadius: "10px 10px 0px 0px"}}>
                                                <TableRow className={"table-mob"}>
                                                    <TableCell align="center">{t('190')}</TableCell>
                                                    <TableCell align="center">{t('260')}</TableCell>
                                                    <TableCell align="center">{t('261')}</TableCell>
                                                    <TableCell align="center">{t('262')}</TableCell>
                                                    <TableCell align="center">{t('263')}</TableCell>
                                                    <TableCell align="center">{t('264')}</TableCell>
                                                    <TableCell align="center">{t('265')}</TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {Participations.map((row) => (
                                                    <TableRow key={row[0]} className={"table-mob"}>
                                                        <TableCell align="center">{row.NumeroWeek}</TableCell>
                                                        <TableCell align="center">{FormatDate(row.created)}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <label className={"old-ticket"}>{row.Number}
                                                            </label>
                                                        </TableCell>
                                                        <TableCell align="center">{row.count}</TableCell>
                                                        <TableCell align="center">{row.vote}</TableCell>
                                                        <TableCell align="center"></TableCell>
                                                        <TableCell align="center">
                                                            {row.count * montant_participation} €
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Collapse>
                                    </Col>
                                </Row>
                            </div>

                            <Modal
                                visible={Modal_payment_visible}
                                onOk={() => setModal_payment_visible(false)}
                                onCancel={() => setModal_payment_visible(false)}
                                footer={null}
                                width={1000}
                            >
                                <Container style={{backgroundColor:"white", borderRadius: "14px",height: "400px", padding : "50px 30px"}}>
                                    <Row>
                                        <Col>
                                        <h2 className="mb-3 font-weight-bold" style={{fontSize : "22pt", color:"#0C4EFC"}}>
                                            {t('266')}</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h4 style={{color : "#324865", fontSize : "13pt"}}> {t('184')} {Solde}</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{padding: "20px 15px "}}>
                                                <h4 style={{color:"#565656", fontSize : "13pt"}}>{t('178')}</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={7}>
                                            <h4 style={{color:"#0C4EFC", fontSize : "13pt"}}>{t('179')} : </h4>
                                        </Col>
                                        <Col xs={5} style={{marginLeft: "-15px"}}>
                                            <Select defaultValue="CB" style={{marginLeft : "15px"}}
                                                    onChange = { (value) => {setPayment_method(value)}}>
                                                <Option value="CB">{t('180')}</Option>
                                                <Option value="Credit">{t('267')}</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop : "50px"}}>
                                        <Col>
                                            <button
                                                className="btn-valider"
                                                style={{padding:"10px 55px"}}
                                                onClick={validatePayment}>
                                                {t('114')}
                                            </button>
                                        </Col>
                                        <Col className="cent">
                                            <h5 style={{color: "#CCCCCC", fontSize: "13pt"}}>{t('183')} {montant_participation} €</h5>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal>

                    </Col>
                </Row>



                {/* ************************* MAIN MENU SUITE ******************************   */}


                <Row style={{margin : "0 10px"}}>
                    <NavLink className="column col" style={{borderRadius: "0 0 30px 30px"}}
                             to={"/assistance"} activeClassName="selected">
                        <h5 style={{color : "#FFF"}}>{t('65')}</h5>
                    </NavLink>

                </Row>
            </Container>
        )
    }



}
export default Tirage
