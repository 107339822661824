import React, {Component, useEffect, useState} from 'react';
import {Container} from "react-bootstrap";
import {Result, Modal} from "antd";
import Button from "antd/es/button";
import {Link, Redirect} from "react-router-dom";
import axios from "axios";


const Success = <Result
    status="success"
    title="Paiement réussi"
    subTitle = "Vous allez être redirigé vers votre compte."

/>
const Error = <Result
    status="error"
    title="Paiement refusé"
    subTitle = "Vous allez être redirigé vers votre compte."

/>



const SuccessPaymentAbo = (props) =>{
    const query = new URLSearchParams(props.location.search);
    const PaylineToken = query.get('paylinetoken')
    const [PaymentStat , setPaymentStat] = useState("")
    const [loading, setLoading] = useState(true)


    setTimeout(function(){
            props.history.push('/moncompte/mescredits');
        }, 8000);
    useEffect( () => {
        axios
            .post('api/payments/checkPaylineTokenAbo', {
                PaylineToken : PaylineToken
            })
            .then(response => {
                setLoading(false)
                console.log("result checking payline token :" + response.data.result);
                //window.location.href = response.data.url;
                if (response.data.result === "ACCEPTED")
                    setPaymentStat( Success)
                else
                    setPaymentStat( Error)
            })
            .catch(err => {
                console.log("auth Service"+err.response.data)
            })

    }, []);

    return(
        <Container>
            {loading && <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>}

            {PaymentStat}

        </Container>
    )
}

export default SuccessPaymentAbo;
