import React, {Component} from 'react';
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import axios from "axios";
import {Col, Container, Row} from "react-bootstrap";
import {notification} from "antd";
import { withTranslation } from 'react-i18next';


const cent={
    margin: "0 auto",
    width: "300px",
    alignSelf: "center"
}



class ResetPassword extends Component {
    constructor(){
        super()
        this.state = {
            email : '',
            password: '',
            confirmPassword: '',
            error: false ,
            update: false,
            loadingLogin : false,
            OldPassword: false
        }
        this.handleChange=this.handleChange.bind(this)
        this.updatePassword=this.updatePassword.bind(this)
    }

    openNotification = () => {
        notification['success']({
            message: 'Succès',
            description:this.props.t('73'),
        });
    };
    openNotificationError = () => {
        notification['error']({
            message: 'Erreur',
            description: this.props.t('74'),
        });
    };

    async componentDidMount(){
        console.log(this.props.match.params.token);
        await axios
            .get('/api/resetPassword',{
                params: {
                    resetPasswordToken: this.props.match.params.token
                }
            })
            .then(res=>{

                console.log(res);
                if (res.data.message === 'token ok'){
                    this.setState({
                        email: res.data.email,
                        update: false,
                        error: false
                    })
                }else{
                    this.setState({
                        update: false,
                        error: true
                    })
                }
            })
            .catch(err =>{
                console.log(err.data)
            })
    }

    handleChange = e =>{
        this.setState({ [e.target.name]: e.target.value })
    }

    updatePassword = e =>{
        e.preventDefault();
        this.setState({loadingLogin : true})

        axios.put('/api/updatePasswordViaEmail',{
            email : this.state.email,
            password: this.state.password
        })
            .then(res => {
                if (res.data.message === 'password updated'){
                    this.setState({
                        update :true,
                        error : false
                    })
                    this.setState({loadingLogin : false})
                    this.openNotification()
                    setTimeout(() => {
                        this.props.history.push('/login');
                    }, 2000)
                }else if(res.data.message === 'old password') {
                    console.log("old")
                    this.setState({
                        update :false,
                        OldPassword : true
                    })
                    this.setState({loadingLogin : false})
                    this.openNotificationError()
                }
            })
            .catch(err =>{
                this.setState({loadingLogin : false})

            })
    }

    render() {
        const {updated,error} = this.state;
        const {t} = this.props;

        if (error){
            return (
                <div style={cent}>
                    <h4> {t('134')}</h4>
                    <Link to="/forgotPassword">
                        <Button color="secondary">
                            {t('135')}
                        </Button>
                    </Link>
                </div>
            )
        }

        let width = window.innerWidth;
        if (width > 768){
            return (
                <div style={{
                    padding: "150px",
                    marginRight: "auto",
                    marginLeft: "auto"
                }}>
                    <Container style={{
                        backgroundColor:"white",paddingLeft: "0",
                        paddingRight: "0",
                        borderRadius: "14px" ,boxShadow: "0px 6px 10px #0000001C"
                    }}>
                        <Row noGutters style={{height: "350px"}}>
                            <Col className="back-logi"/>
                            <Col style={{padding: "30px 50px 20px 50px", margin : "auto"}}>
                                <form noValidate onSubmit={this.updatePassword} style={{width:"400px"}}>
                                    <h4 className="mb-3 font-weight-normal" style={{color:"#0C4EFC"}}>
                                        {t('75')}</h4>
                                    <div className="form-group">
                                        <input
                                            id="password"
                                            value={this.state.password}
                                            name="password"
                                            type="password"
                                            placeholder={t('78')}
                                            onChange={this.handleChange}
                                        />
                                        {this.state.OldPassword &&
                                        <span className={"text-danger"} style={{fontSize: "12px"}}>
                                            {t('300')}</span>
                                        }

                                    </div>
                                    <div style={{marginTop:"50px"}}>
                                        <button
                                            type="submit"
                                            className="btn-valider"
                                            style={{float:"right",padding:"7px 50px"}}
                                            disabled={this.state.loadingLogin}>
                                            {this.state.loadingLogin ? <div className="spinner-border" role="status">
                                                <span className="sr-only">En cours ...</span>
                                            </div> : t('79')}
                                        </button>
                                    </div>

                                </form>

                            </Col>
                        </Row>
                    </Container>

                </div>
            )
        }
        else {
            return (
                <div style={{
                    marginRight: "auto",
                    marginLeft: "auto",
                    textAlignLast: "center"
                }}>
                    <Container style={{padding : "10%"}}>
                        <Row noGutters>
                            <Col>
                                <h4 className="mb-3 font-weight-bold" style={{color:"#0C4EFC"}}>
                                    {t('75')}
                                </h4>

                                <div className="form-group">
                                    <input
                                        id="password"
                                        value={this.state.password}
                                        name="password"
                                        type="password"
                                        placeholder={t('78')}
                                        onChange={this.handleChange}
                                    />
                                    {this.state.OldPassword &&
                                    <span className={"text-danger"} style={{fontSize: "12px"}}>
                                        {t('300')}</span>
                                    }
                                </div>

                            </Col>
                        </Row>

                        <Row style={{justifyContent: "center", marginTop: "30px"}}>
                            <button
                                type="submit"
                                className="btn-valider"
                                style={{float:"right",padding:"10px 70px"}}
                                onClick={this.updatePassword}
                                disabled={this.state.loadingLogin}>
                                {this.state.loadingLogin ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : t('79')}
                            </button>
                        </Row>

                    </Container>

                </div>
            )
        }

    }
}

export default withTranslation()(ResetPassword);
