import React, { Component } from 'react'
import Container from "react-bootstrap/Container";
import {Col, Row, Spinner} from "react-bootstrap";
import './profile.css'
import VerticalNavbar2 from "./VerticalNavbar2";
import {IsLoggedIn} from "../Helpers";
import {Modal, Dropdown, Menu, notification, Tag, Select, Avatar} from "antd";
import frenchFlag from "../Images/french-flag.png";
import {FaCheck} from "react-icons/all"
import SpainFlag from "../Images/Spain_flag.png";
import BritainFlag from "../Images/Flag_Britain.png";
import Button from "antd/es/button";
import axios from "axios";
import HeaderNavbarDashboard from "./HeaderNavbarDashboard";
import {NavLink} from "react-router-dom";
import arrow from "../Images/arrow-right.png";
import arrow_bottom from "../Images/arrow-bottom.png";
import {FaBell, GoAlert} from "react-icons/all";
import Popover from "antd/es/popover";
import Avatar1 from "../Images/Avatars/Avatar1.png";
import Avatar2 from "../Images/Avatars/Avatar2.png";
import Avatar3 from "../Images/Avatars/Avatar3.png";
import Avatar4 from "../Images/Avatars/Avatar4.png";
import Avatar5 from "../Images/Avatars/Avatar5.png";
import Avatar6 from "../Images/Avatars/Avatar6.png";
import Avatar7 from "../Images/Avatars/Avatar7.png";
import Avatar8 from "../Images/Avatars/Avatar8.png";
import Avatar9 from "../Images/Avatars/Avatar9.png";
import Avatar10 from "../Images/Avatars/Avatar10.png";
import Avatar11 from "../Images/Avatars/Avatar11.png"
import Avatar12 from "../Images/Avatars/Avatar12.png"
import Avatar13 from "../Images/Avatars/Avatar13.png"
import Avatar14 from "../Images/Avatars/Avatar14.png"
import Avatar15 from "../Images/Avatars/Avatar15.png"
import Avatar16 from "../Images/Avatars/Avatar16.png"
import Avatar17 from "../Images/Avatars/Avatar17.png"
import Avatar18 from "../Images/Avatars/Avatar18.png"
import Avatar19 from "../Images/Avatars/Avatar19.png"
import Avatar20 from "../Images/Avatars/Avatar20.png"
import Avatar21 from "../Images/Avatars/Avatar21.png"
import Avatar22 from "../Images/Avatars/Avatar22.png"
import Avatar23 from "../Images/Avatars/Avatar23.png"
import Avatar24 from "../Images/Avatars/Avatar24.png"
import Avatar25 from "../Images/Avatars/Avatar25.png"
import Avatar26 from "../Images/Avatars/Avatar26.png"
import Avatar27 from "../Images/Avatars/Avatar27.png"
import Avatar28 from "../Images/Avatars/Avatar28.png"
import Avatar29 from "../Images/Avatars/Avatar29.png"
import Avatar30 from "../Images/Avatars/Avatar30.png"
import pencil from "../Images/pencil.png";
import ItalyFlag from "../Images/italy.png";
import warn from "../Images/Icon-warning.png";
import { withTranslation } from 'react-i18next';
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import {City} from "country-state-city";
import countryList from "react-select-country-list";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import {checkFileSize, checkPDFType, maxSelectFile} from "../Controls/FilesErrors";
import {Checkbox, message} from "antd";

const { Option } = Select;

let imageURL='';


class Profile extends Component {
    constructor() {
        super()
        this.state = {
            user:[],
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            nom_marital:'',
            gender: '',
            motif: "1",
            Date_Naissance:'',
            Ville_Naissance:'',
            Ville_Residence:'',
            Pays_Residence:'',
            Pseudonyme:'',
            Langue_prefere :'',
            Num_telephone :'',
            confirm :false,
            pieceRecto : "",
            pieceVerso : "",
            image: "",
            modal: false,
            pieceSubmit : false,
            loading: false,
            readOnly: true,
            readOnlyPassword: true,
            avatarChoice:false,
            OldPassword: false,
            country : countryList().getData(),
            ville_residenceErr : false,
            Pays_ResidenceErr : false,
            cities : [],
            DonwloadedRecto : '',
            DonwloadedRectoType : '',
            DonwloadedVersoType : '',
            DonwloadedVerso : '',
            LoadingData:true,
            RectoError : '',
            VersoError : '',
            solde:0,
        }
        this.onPieceChange = this.onPieceChange.bind(this)
        this.onchange = this.onchange.bind(this)
        this.PictureOnChange = this.PictureOnChange.bind(this)
        this.getAvatarSrc = this.getAvatarSrc.bind(this);
        this.change_motif_delete = this.change_motif_delete.bind(this);

    }

    componentDidMount() {
        window.scrollTo(0, 0)
        fetch('/api/users/profile')
            .then(res => {
                return res.json()
            })
            .then(userData => {
                console.log(userData)
                this.setState({
                    user :userData,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    email: userData.email,
                    nom_marital:userData.nom_marital,
                    gender: userData.gender,
                    Date_Naissance:userData.Date_Naissance,
                    Ville_Naissance: userData.Ville_Naissance,
                    Ville_Residence: userData.Ville_Residence,
                    Pseudonyme: userData.Pseudonyme,
                    Langue_prefere : userData.Langue_prefere,
                    pieceSubmit : userData.pieceSubmit,
                    Num_telephoneErr : false,
                    DonwloadedRecto : userData.pieceRecto,
                    DonwloadedRectoType : userData.FileTypeRecto,
                    DonwloadedVerso : userData.pieceVerso,
                    DonwloadedVersoType : userData.FileTypeVerso,
                    LoadingData: false,

                })
                this.onChangeCountry(userData.Pays_Residence)
                if(userData.Num_telephone === 'undefined'){
                    console.log('11111')
                    this.setState({
                        Num_telephone :'',
                    })
                }else {
                    console.log('2222222')
                    this.setState({
                        Num_telephone :userData.Num_telephone,
                    })
                }
                this.displayImage('data:image/jpeg;base64,' + new Buffer(this.state.user.image, 'binary').toString('base64'))
            });

    }

     DownloadPdfFile(FileName, byte, FileType) {
        console.log(FileType)
        let blob = new Blob([new Uint8Array(byte)], {type: FileType});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = FileName;
        link.click();
    }
    hide = () => {
        this.setState({
            avatarChoice: false,
        });
    };

    handleVisibleChange = avatarChoice => {
        this.setState({ avatarChoice });
    };

    setValuePhone(value){
        console.log('tel : ',value)
        this.setState({Num_telephone: value})
    }

    FlagsMenu = (
        <Menu>
            <Menu.Item>
                <img src={SpainFlag} width={30} height={25} title={"es"} />
            </Menu.Item>
            <Menu.Item>
                <img src={BritainFlag} title={"en"} width={30} height={25} />
            </Menu.Item>

        </Menu>
    );

    avatars=(
        <div>
            <div>
                <img src={Avatar1} width={"60px"} className="avatar" id={"avatar1"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar2} width={"60px"} className="avatar" id={"avatar2"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar3} width={"60px"} className="avatar" id={"avatar3"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar4} width={"60px"} className="avatar" id={"avatar4"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar5} width={"60px"} className="avatar" id={"avatar5"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar21} width={"60px"} className="avatar" id={"avatar21"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            </div>
            <div style={{marginTop:"15px"}}>
                <img src={Avatar6} width={"60px"} className="avatar" id={"avatar6"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar7} width={"60px"} className="avatar" id={"avatar7"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar8} width={"60px"} className="avatar" id={"avatar8"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar9} width={"60px"} className="avatar" id={"avatar9"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar10} width={"60px"} className="avatar" id={"avatar10"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar22} width={"60px"} className="avatar" id={"avatar22"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                     </div>
                     <div style={{marginTop:"15px"}}>
                <img src={Avatar11} width={"60px"} className="avatar" id={"avatar11"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar12} width={"60px"} className="avatar" id={"avatar12"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar13} width={"60px"} className="avatar" id={"avatar13"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar14} width={"60px"} className="avatar" id={"avatar14"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar15} width={"60px"} className="avatar" id={"avatar15"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar23} width={"60px"} className="avatar" id={"avatar23"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                     </div>
                     <div style={{marginTop:"15px"}}>
                <img src={Avatar16} width={"60px"} className="avatar" id={"avatar16"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar17} width={"60px"} className="avatar" id={"avatar17"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar18} width={"60px"} className="avatar" id={"avatar18"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar19} width={"60px"} className="avatar" id={"avatar19"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar20} width={"60px"} className="avatar" id={"avatar20"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar24} width={"60px"} className="avatar" id={"avatar24"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            </div>
            <div style={{marginTop:"15px"}}>
                <img src={Avatar25} width={"60px"} className="avatar" id={"avatar25"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar26} width={"60px"} className="avatar" id={"avatar26"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar27} width={"60px"} className="avatar" id={"avatar27"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar28} width={"60px"} className="avatar" id={"avatar28"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar29} width={"60px"} className="avatar" id={"avatar29"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar30} width={"60px"} className="avatar" id={"avatar"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                </div>

        </div>
    )
    avatars_mobile=(
        <div>
            <div>
                <img src={Avatar1} width={"60px"} className="avatar" id={"avatar1"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar2} width={"60px"} className="avatar" id={"avatar2"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar3} width={"60px"} className="avatar" id={"avatar3"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar4} width={"60px"} className="avatar" id={"avatar4"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                
            </div>
            <div style={{marginTop:"15px"}}>
                <img src={Avatar6} width={"60px"} className="avatar" id={"avatar6"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar7} width={"60px"} className="avatar" id={"avatar7"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar8} width={"60px"} className="avatar" id={"avatar8"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar9} width={"60px"} className="avatar" id={"avatar9"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                
                     </div>
                     <div style={{marginTop:"15px"}}>
                <img src={Avatar11} width={"60px"} className="avatar" id={"avatar11"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar12} width={"60px"} className="avatar" id={"avatar12"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar13} width={"60px"} className="avatar" id={"avatar13"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar14} width={"60px"} className="avatar" id={"avatar14"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                </div>
                     <div style={{marginTop:"15px"}}>
                <img src={Avatar16} width={"60px"} className="avatar" id={"avatar16"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar17} width={"60px"} className="avatar" id={"avatar17"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar18} width={"60px"} className="avatar" id={"avatar18"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar19} width={"60px"} className="avatar" id={"avatar19"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                </div>
            <div style={{marginTop:"15px"}}>
            <img src={Avatar10} width={"60px"} className="avatar" id={"avatar10"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar5} width={"60px"} className="avatar" id={"avatar5"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar15} width={"60px"} className="avatar" id={"avatar15"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar20} width={"60px"} className="avatar" id={"avatar20"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                     
            </div>

            <div style={{marginTop:"15px"}}>
            <img src={Avatar21} width={"60px"} className="avatar" id={"avatar21"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar22} width={"60px"} className="avatar" id={"avatar22"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar23} width={"60px"} className="avatar" id={"avatar23"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar24} width={"60px"} className="avatar" id={"avatar24"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            </div>
            <div style={{marginTop:"15px"}}>
            <img src={Avatar25} width={"60px"} className="avatar" id={"avatar25"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar26} width={"60px"} className="avatar" id={"avatar26"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar27} width={"60px"} className="avatar" id={"avatar27"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar28} width={"60px"} className="avatar" id={"avatar28"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
        </div>
        <div style={{marginTop:"15px"}}>
            <img src={Avatar29} width={"60px"} className="avatar" id={"avatar29"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar30} width={"60px"} className="avatar" id={"avatar30"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
           </div>
           </div>
    )

    //****************** Modal *******************
    showModal = async () => {
        let solde = await  axios.get("/api/payments/abonnements")
                 console.log(solde.data)
                 if(solde.data.solde){
                     this.setState({solde : solde.data.solde})
                 }
        this.setState({
            modal: true,
        });
    };

    handleCancel = e => {
        this.setState({
            modal: false,
        });
    };
    //****************** Modal *******************


    onchange(e){
        e.preventDefault()
        const { name, value } = e.target;
        this.setState({[name]: value})

    }

    onPieceChange(e){
        if(checkFileSize(e) ){
            if (maxSelectFile(e)){
                if (checkPDFType(e) ){
                    switch (e.target.name) {
                        case "pieceRecto" :
                            this.setState({pieceRecto : e.target.files[0],
                                RectoError : ''})
                            break;
                        case "pieceVerso" :
                            this.setState({pieceVerso : e.target.files[0],
                                VersoError : ''})
                            break
                    }
                }
                else{
                    switch (e.target.name) {
                        case "pieceRecto" :
                            this.setState({pieceRecto : '',
                                RectoError : this.props.t('314')})
                            break;
                        case "pieceVerso" :
                            this.setState({pieceVerso : '',
                                VersoError : this.props.t('314')})
                            break
                    }
                }
            }
            else{
                switch (e.target.name) {
                    case "pieceRecto" :
                        this.setState({pieceRecto : '',
                            RectoError : this.props.t('315')})
                        break;
                    case "pieceVerso" :
                        this.setState({pieceVerso : '',
                            VersoError : this.props.t('315')})
                        break
                }
            }
        }
        else{
            switch (e.target.name) {
                case "pieceRecto" :
                    this.setState({pieceRecto : '',
                        RectoError : this.props.t('316')})
                    break;
                case "pieceVerso" :
                    this.setState({pieceVerso : '',
                        VersoError : this.props.t('316')})
                    break
            }
        }

    }

    openNotification = () => {
        notification['success']({
            message: this.props.t('172'),
            description:
                this.props.t('154'),
        });
    };

    openNotificationError = () => {
        notification['error']({
            message: 'Erreur',
            description:
                this.props.t('74'),
        });
    };
    onChangeCountry(value) {
        console.log(value)
        console.log()
        let liste = City.getCitiesOfCountry(countryList().getValue(value))
        let finalListe =[]
        liste.map(e=>{
            finalListe.push({
                value : e.name,
                label : e.name
            });
        })
        this.setState({
            cities : finalListe
        })

        // this.setState({cities : City.getCitiesOfCountry(countryList().getValue(value))})
        this.setState({ Pays_Residence : value})
    }
    onChangeVilleResidence(value) {
        console.log(value)
        this.setState({ Ville_Residence : value})
    }
    validateForm(){
        let error = false
        if(this.state.Num_telephone){
            if(isValidPhoneNumber(this.state.Num_telephone)){
                this.setState({Num_telephoneErr: false})
                error = false
            }else {
                error = true
                this.setState({Num_telephoneErr: true})
                document.getElementById('Num_telephone').scrollIntoView()
                return error
            }
        }else {
            this.setState({Num_telephoneErr: false})
            error = false
        }

        if(!this.state.Ville_Residence){
            this.setState({ville_residenceErr: true})
            error = true
            document.getElementById('ville_residence').scrollIntoView()
            return error
        }
        else{
            this.setState({ville_residenceErr: false})
            error = false
        }

        if(!this.state.Pays_Residence){
            this.setState({Pays_ResidenceErr: true})
            error = true
            document.getElementById('Pays_Residence').scrollIntoView()
            return error
        }
        else{
            this.setState({Pays_ResidenceErr: false})
            error = false
        }


        return error
    }
    updateProfile = async (e) =>{
        let valid = await this.validateForm()
        if(valid ===false){
            this.setState({
                loading : true
            })
            let data = new FormData();
            data.append('first_name', this.state.first_name);
            data.append('last_name', this.state.last_name);
            data.append('email', this.state.email);
            data.append('password', this.state.password);
            data.append('gender', this.state.gender);
            data.append('nom_marital', this.state.nom_marital);
            data.append('Date_Naissance', this.state.Date_Naissance);
            data.append('Ville_Naissance', this.state.Ville_Naissance);
            data.append('Ville_Residence', this.state.Ville_Residence);
            data.append('Pays_Residence', this.state.Pays_Residence);
            data.append('Pseudonyme', this.state.Pseudonyme);
            data.append('Langue_prefere', this.state.Langue_prefere);
            data.append('Num_telephone', this.state.Num_telephone);
            data.append('pieceRecto', this.state.pieceRecto);
            data.append('pieceVerso', this.state.pieceVerso);
            data.append('image', this.state.image);

            axios.post("/api/updateuser",data,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((res) =>{
                    this.setState({
                        loading : false
                    })
                    console.log(res.data)
                    if (res.data === "ok"){
                        this.openNotification()
                        window.location.reload();
                    }
                    if(res.data.message === "old password"){
                        console.log("old")
                        this.setState({OldPassword : true})
                    }
                }).catch(()=>{
                this.setState({
                    loading : false
                })
                this.openNotificationError()
            })
        }

    }

    readOnly = () =>{
        this.setState({readOnly : !this.state.readOnly})
    }

    readOnlyPassword = () =>{
        this.setState({readOnlyPassword : !this.state.readOnlyPassword})
    }

    deleteUser = () => {
        axios.delete(`api/updateuser/${this.state.user.id}`).then(res => {
            message.success(this.props.t('323'), 2.5)
            console.log(res)
            localStorage.removeItem('usertoken');
            localStorage.removeItem('username');
            localStorage.removeItem('checkbox');
            window.location.reload();
        })
    }

    displayImage(src){
        imageURL = src;
        document.getElementById("profile-img").src=imageURL;
    }

    PictureOnChange(event){
        let file = event.target.files[0];

        // ******* have a preview on the image before loading  *******
        let reader = new FileReader();
        let url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            imageURL = [reader.result];
            document.getElementById("profile-img").src=imageURL;
        }
        this.setState({image : event.target.files[0]})
        //********************************************
        // let errors = this.state.errors;
    }
    getAvatarSrc(e){
        console.log(this.imgToBase64(document.getElementById(e.target.id)))
        let result = this.imgToBase64(document.getElementById(e.target.id));
        let file = this.dataURLtoFile(result, 'avatar.png');
        console.log("file : " , file)
        document.getElementById("profile-img").src=e.target.src;
        this.setState({
            image: file,
            loaded : 1
        })
        this.hide()
    }

    change_motif_delete(value){
        let val = value;
        console.log(val)
        this.setState({
            motif: val
        })}

    imgToBase64(img) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 500;
        canvas.height = 500;

        // I think this won't work inside the function from the console
        //img.crossOrigin = 'anonymous';

        ctx.drawImage(img, 0, 0);

        return canvas.toDataURL();
    }

    getLangue(value){
        console.log(value)
        this.setState({Langue_prefere: value})
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }


    render() {
        let width = window.innerWidth;

        const {t} = this.props;
        //********************* DESKTOP VER  **************************
        if (width > 768){
            return (
                <Container fluid >
                    <Row style={{padding:"0 100px"}}>
                        {IsLoggedIn() ?
                            (
                                <Col>
                                    <HeaderNavbarDashboard/>
                                </Col>
                            )   :
                            (<div/>)}
                    </Row>
                    <Row className="profile">

                        {IsLoggedIn() ?
                            (
                                <Col md={2}>
                                    <VerticalNavbar2/>
                                </Col>
                            )   :
                            (<div/>)}

                        {/*  ************ modal *********************  */}
                        <div>
                            <Modal
                                visible={this.state.modal}
                                onCancel={this.handleCancel}
                                footer={null}
                                width={"850px"}>
                                <Container>
                                    <Row>
                                        <Col md={3} style={{
                                            background: "transparent linear-gradient(180deg," +
                                                " #0C4EFC 0%, #4F3DFF 100%) 0% 0% no-repeat padding-box",
                                            borderRadius: "14px 0px 0px 14px"}}/>
                                        <Col style={{alignSelf: "center", textAlignLast: "center",
                                            padding:"30px 20px"}}>
                                            <label className="txt">
                                                {t('155')}
                                            </label>
                                            <div>
                                                <span className="txt">{t('156')}</span>
                                            </div>
                                            <div style={{marginBottom:"30px", marginTop:"15px",
                                                textAlign:"center"}}>
                                                <Select Value={this.state.motif} style={{width : "400px"}}
                                                        defaultValue={"1"}
                                                onChange={this.change_motif_delete}>
                                                    <Option value="1">{t('157')}</Option>
                                                    <Option value="2">{t('158')}
                                                    </Option>
                                                    <Option value="3">{t('159')}</Option>
                                                    <Option value="4">{t('160')}</Option>
                                                    <Option value="5">{t('161')}</Option>
                                                </Select>
                                                {this.state.motif ==="5" && <input
                                                    type={"text"}
                                                    name="autre_choix"
                                                    className="input-profile"
                                                    onChange={this.onchange}
                                                    style={{width:"50%", marginTop:"25px"}}
                                                />}
                                            </div>
                                            <div>
                                                <button
                                                    onClick={this.handleCancel}
                                                    className="btn-valider"
                                                    style={{padding:"7px 50px", marginRight : "60px"}}>
                                                    {t('162')}
                                                </button>
                                                <button
                                                    onClick={()=>this.deleteUser()}
                                                    className="btn-valider-profile"
                                                    style={{padding:"7px 50px",marginRight:"10px"}}>
                                                    {t('163')}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                </Container>
                                {this.state.solde > 0 &&
                                    <div className={"alert-credit"}>
                                    <label>{t('321')}{this.state.solde} {t('322')}</label> 
                                    <img className='img-alert-credit' src={require('../Images/danger_icon_orange.png')} />
                                </div>
                                }
                                
                            </Modal>
                        </div>
                        {/*  ************ modal *********************  */}
                        {
                            this.state.LoadingData ? <div className="spinner-center" style={{left:"35vw"}}>
                                <Spinner animation="border" role="status" variant="primary" className={'spinn'}/>
                            </div> :
                                <>
                        <Col style={{marginTop:"60px",marginLeft:"100px"}} md={5}>
                            <h4 style={{marginBottom:"30px",color: "#1890ff",
                                fontSize: "1.2rem", fontWeight: "600"}}>{t('144')}</h4>
                            <div style={{width : "145%"}}>
                                <label className={'label-profile'}>{t('307')}</label>
                                <input
                                    type={"text"}
                                    name="first_name"
                                    value={this.state.first_name}
                                    className="input-profile-disabled"
                                    disabled={true}
                                />
                                <label className={'label-profile'}>{t('89')}</label>
                                <input
                                    type={"text"}
                                    name="nom_marital"
                                    value={this.state.nom_marital}
                                    placeholder={t('89')}
                                    className={this.state.readOnly ? 'input-profile-disabled':"input-profile"}
                                    onChange={this.onchange}
                                    readOnly={this.state.readOnly}
                                />
                                <label className={'label-profile'}>{t('308')}</label>
                                <input
                                    type={"text"}
                                    name="last_name"
                                    value={this.state.last_name}
                                    className="input-profile-disabled"
                                    disabled={true}
                                />
                                <label className={'label-profile'}></label>
                                <div style={{display : "flex",flexDirection: "row",margin:"10px"}}>
                                    <input style={{width : "auto"}}
                                           type="radio" value="MALE" name="gender"
                                           checked={this.state.gender === "MALE"}
                                           disabled={true}
                                    />
                                    <label style={{marginTop:"-3px",marginRight:"30px",color:"#1E90FF"}}>
                                        {t('91')}</label>

                                    <input style={{width : "auto"}}
                                           type="radio" value="FEMALE" name="gender"
                                           checked={this.state.gender === "FEMALE"}
                                           disabled={true}
                                    />
                                    <label style={{marginTop:"-3px",marginRight:"30px",color:"#1E90FF"}}>
                                        {t('92')}</label>

                                    <input style={{width : "auto"}}
                                           type="radio" value="Autre" name="gender"
                                           checked={this.state.gender === "Autre"}
                                           disabled={true}
                                    />
                                    <label style={{marginTop:"-3px",color:"#1E90FF"}}>
                                        {t('93')}</label>
                                </div>
                                <label className={'label-profile'}>{t('309')}</label>
                                <input type="text" id="start"
                                       name="Date_Naissance"
                                       className="input-profile-disabled"
                                       required={true}
                                       value={this.state.Date_Naissance}
                                       disabled={true}
                                />
                                {/* *** ville de naissance OBL*** */}
                                <label className={'label-profile'}>{t('310')}</label>
                                <input
                                        type="text"
                                        name="Ville_Naissance"
                                        className="input-profile-disabled"
                                        required={true}
                                        value={this.state.Ville_Naissance}
                                        disabled={true}
                                    />

                                <label className={'label-profile'}>{t('311')}</label>
                                <Select showSearch name="pays_residence"
                                        className={this.state.readOnly ?
                                            'border-input input-profile-disabled':" border-input input-profile"}
                                        onChange={v => this.onChangeCountry(v)}
                                        placeholder={t('98')}
                                        value={this.state.Pays_Residence}
                                        optionFilterProp="children"
                                        filterOption = {(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        disabled={this.state.readOnly}
                                >
                                    {this.state.country.map(r =>(
                                        <Option value={r.label}>{r.label}</Option>
                                    ))}
                                </Select>
                                {this.state.Pays_ResidenceErr && <span className='error'>
                                        {t('99')}</span>}
                                {/* *** ville de residence OBL*** */}
                                <label className={'label-profile'}>{t('312')}</label>
                                <Select showSearch
                                        className={this.state.readOnly ?
                                            'border-input input-profile-disabled':" border-input input-profile"}
                                        name="ville_residence"
                                        onSelect={v => this.onChangeVilleResidence(v)}
                                        placeholder={t('97')}
                                        optionFilterProp="children"
                                        value={this.state.Ville_Residence}
                                        /*filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }*/
                                        disabled={this.state.readOnly}
                                        options={this.state.cities}
                                >
                                </Select>
                                {this.state.ville_residenceErr && <span className='error'>
                                        {t('302')}</span>}

                                <label className={'label-profile'}>{t('313')}</label>
                                <input
                                    type={"text"}
                                    name="email"
                                    value={this.state.email}
                                    className={this.state.readOnly ? 'input-profile-disabled':"input-profile"}
                                    onChange={this.onchange}
                                    readOnly={this.state.readOnly}
                                />
                                {/* *** langue pref *** */}
                                <div  style={{marginBottom: "16px", marginTop:"7px", paddingLeft:"10px"}}>
                                    <label style={{display:"inline-block", marginRight:"15px",color: "#74869E",
                                        fontFamily: 'ROBOTO'}}>
                                        {t('102')}</label>
                                    <Select value={this.state.Langue_prefere}
                                            bordered={false}
                                            onChange={v => this.getLangue(v)} disabled={this.state.readOnly}>
                                        <Option value="fr"><img src={frenchFlag} width={30} height={25}/></Option>
                                        <Option value="es"><img src={SpainFlag} width={30} height={25}/></Option>
                                        <Option value="en"><img src={BritainFlag} width={30} height={25}/></Option>
                                        <Option value="it"><img src={ItalyFlag} width={30} height={25}/></Option>
                                    </Select>
                                </div>
                                {/* *** Num phone *** */}
                                <label className={'label-profile'}>{t('105')}</label>
                                <div className="form-group" id={'Num_telephone'}>
                                    <PhoneInput
                                        className={this.state.readOnly ? 'input-profile-disabled':"input-profile"}
                                        international
                                        countryCallingCodeEditable={false}
                                        placeholder={t('105')}
                                        defaultCountry="FR"
                                        value={this.state.Num_telephone}
                                        onChange={(v) => this.setValuePhone(v)}
                                        readOnly={this.state.readOnly}
                                    disabled={this.state.readOnly}/>

                                </div>
                                {this.state.Num_telephoneErr && <span className='error'>
                                        {t('107')}</span>}

                                {/* *** Password OBL*** */}
                                <div>
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="******"
                                    onChange={this.onchange}
                                    className={this.state.readOnlyPassword ? 'input-profile-disabled':"input-profile"}
                                    style={{marginBottom:"0"}}
                                    required={true}
                                    readOnly={this.state.readOnlyPassword}

                                />
                                    {this.state.OldPassword &&
                                    <span className={"text-danger"} style={{fontSize: "12px"}}>
                                            {t('300')}</span>
                                    }
                                <button className={"btn btn-link pass-change"}
                                        onClick={this.readOnlyPassword.bind(this)}>
                                    {t('165')}</button>
                                </div>
                                {/* ****** joindre piece recto ******** */}
                                {/* si !this.state.pieceSubmit === true alors la piece est non transmis encore*/}


                                {this.state.DonwloadedRecto ?
                                    <Button color="primary" className={'download-btn'} icon={<DownloadOutlined />}
                                            onClick={() => {
                                                this.DownloadPdfFile("Recto",
                                                    this.state.DonwloadedRecto.data,
                                                    this.state.DonwloadedRectoType)
                                            }}>
                                        {t('304')}
                                    </Button>
                                    : <></>}
                                {this.state.DonwloadedVerso ?
                                    <Button color="primary" className={'download-btn'} icon={<DownloadOutlined />}
                                            onClick={() => {
                                                this.DownloadPdfFile("Verso",
                                                    this.state.DonwloadedVerso.data,
                                                    this.state.DonwloadedVersoType)
                                            }}>
                                        {t('305')}
                                    </Button>
                                    : <></>}
                                {!this.state.pieceSubmit ? 
                                <>
                                        <div>
                                            <label htmlFor="recto" className="custom-file-upload">
                                                <input id="recto" className="input-file"
                                                       type="file" name="pieceRecto"
                                                       onChange={this.onPieceChange} />
                                                {t('166')}</label>
                                            { window.pieceValidation ?
                                                <img src={warn} style={{marginLeft : "20px", width: "20px",
                                                    marginBottom:"10px"}}/>
                                                : <></>
                                            }
                                        </div>
                                        <span>{this.state.pieceRecto.name}</span>
                                        <span className='error'>{this.state.RectoError}</span>
                                        <div style={{marginBottom : "7px", lineHeight:"16px"}}>
                                            <span style={{fontSize : "13px"}} className='register-txt'>{t('320')}</span>
                                        </div>
                                        {/* ****** joindre piece verso ******** */}
                                        <div>
                                            <label htmlFor="verso" className="custom-file-upload">
                                                <input id="verso" className="input-file"
                                                       type="file" name="pieceVerso"
                                                       onChange={this.onPieceChange}  />
                                                {t('167')} {t('317')}</label>
                                            { window.pieceValidation ?
                                                <img src={warn} style={{marginLeft : "20px", width: "20px",
                                                    marginBottom:"10px"}}/>
                                                : <></>
                                            }
                                        </div>
                                        <span>{this.state.pieceVerso.name}</span>
                                        <span className='error'>{this.state.VersoError}</span>
                                    </>
                                    :<>
                                        {window.pieceValidation ? <Tag color="#108ee9" style={{padding: "10px 20px",marginTop:"15px"}}>
                                            {t('168')}</Tag> :
                                            <></>
                                        }
                                    </>}


                                <div style={{textAlign : "center", marginTop:"30px",
                                display: "flex"}}>
                                    <button className="btn-valider"
                                            style={{padding:"6px 20px",marginRight:"22%",
                                                cursor: "not-allowed"}}>
                                        {/*{loading ? <span style={{fontSize: "17px"}}>En cours ...</span> :
                                            <span style={{fontSize: "17px"}}>Valider</span>}*/}
                                            <span>{this.state.Pseudonyme}</span>
                                        <FaCheck style={{marginLeft:" 20px",
                                            marginBottom: "5px"}}/>
                                    </button>
                                    <button
                                        className="btn-valider-profile"
                                        style={{padding:"6px 45px",marginRight:"45px"}}
                                        onClick={this.readOnly.bind(this)}>
                                        {t('79')}
                                    </button>
                                    <button className="btn-valider"
                                            style={{padding:"6px 45px",marginRight:"10px", zIndex:"10"}}
                                            onClick={this.updateProfile.bind(this)}

                                    >
                                        {this.state.loading ? <div className="spinner-border" role="status">
                                                <span className="sr-only">En cours ...</span>
                                            </div> :
                                            <span style={{fontSize: "17px"}}>{t('114')}</span>}
                                    </button>
                                </div>
                            </div>

                        </Col>
                        <Col>
                            <div style={{float: "right",display: "grid",placeItems:"center"}}>
                                <img id="profile-img" style={{borderRadius: "50%", width :"150px", height:"150px"}}/>

                                <Button type="link" style={{marginTop : "20px"}}>
                                    <label htmlFor="file" >{t('170')}</label></Button>
                                <input id="file" className="input-file"
                                       type="file" name="image"
                                       onChange={this.PictureOnChange} />
                                <div style={{display:"inline-block"}}>
                                    <Popover placement="right"
                                             style={{cursor:"pointer"}}
                                             content={this.avatars} trigger="hover">
                                        <Button type="link" >
                                            <label >{t('171')} </label></Button>
                                    </Popover>
                                </div>
                                <Button type="link" onClick={this.showModal}>{t('169')}</Button>
                                <Button type="link" onClick={e=>{e.preventDefault();
                                    localStorage.removeItem('usertoken');
                                    console.log("deconnexion ...")
                                    window.location.reload();                }}
                                >{t('66')}</Button>
                            </div>
                        </Col>
                        </>
                            }
                    </Row>

                </Container>
            );
        }

        //********************** MOBILE VER ******************************
        else {
            return (
                <Container className ="pad-mob">
                    <Row style={{margin : "0 10px"}}>
                        {IsLoggedIn() ?
                            (
                                // ***************** MENU MON COMPTE NAVBAR ***************
                                <Col>
                                    <Row>
                                        <NavLink className="column col" style={{borderLeft:"5px solid white",
                                            borderRadius: "30px 30px 0 0"}}
                                                 to={"/moncompte"} activeClassName="selected">
                                            <h5 style={{color : "#FFF"}}>{t('63')}</h5>
                                        </NavLink>
                                    </Row>
                                </Col>
                            )   :
                            (<div/>)}
                    </Row>
                        {IsLoggedIn() ?
                            (<Row style={{margin : "30px 15px 0px", borderBottom: "2px solid #DFE7F5",
                                    paddingBottom: "5px"}}>
                                    <NavLink className="und-menu"
                                             to="/moncompte" exact>
                                        <img src={arrow} width={30} height={30}/>
                                        <h5 style={{marginLeft : "10px",marginTop : "2px",
                                            marginBottom: "0", color : "#0C4EFC", width:"100%"}}>
                                            <label>{t('144')}</label>
                                            { window.pieceValidation ? <GoAlert style={{float:"right",
                                                color : "#FFC200", height : "30px", width: "30px"}}/> : <></>
                                            }
                                        </h5>
                                    </NavLink>
                                </Row>
                            )   :
                            (<div/>)}
                    {
                        this.state.LoadingData ? <></> :
                            <Row style={{marginTop: "30px"}}>
                                <Col sm xs={6}>
                                    <Button type="link" style={{marginTop: "10px"}}>
                                        <label htmlFor="file">{t('170')}</label>
                                    </Button>
                                    <input id="file" className="input-file"
                                           type="file" name="image"
                                           onChange={this.PictureOnChange}/>
                                    <div style={{display: "inline-block"}}>

                                    </div>
                                    <Button type="link" onClick={e => {
                                        e.preventDefault();
                                        localStorage.removeItem('usertoken');
                                        console.log("deconnexion ...")
                                        window.location.reload();
                                    }}
                                    ><span style={{fontSize: "18px", fontWeight: "600"}}>{t('66')}</span></Button>
                                </Col>
                                <Col sm xs={6}>
                                    <img id="profile-img" style={{
                                        borderRadius: "50%", float: "right",
                                        width: "100px", height: "100px"
                                    }}/>
                                    <Popover placement="right"
                                             style={{cursor: "pointer"}}

                                             content={this.avatars_mobile} trigger="click"
                                             visible={this.state.avatarChoice}
                                             onVisibleChange={this.handleVisibleChange}>
                                        <Button type="link" style={{marginTop: "10px"}}>
                                            <img src={pencil} alt={"crayon"} className={"pencil-edit"}/>
                                        </Button>
                                    </Popover>

                                </Col>
                                {/*</div>*/}
                            </Row>
                    }
                    <Row>
                        {/*  ************ modal *********************  */}
                        <div>
                            <Modal
                                visible={this.state.modal}
                                onCancel={this.handleCancel}
                                footer={null}
                                width={"850px"}>
                                <Container>
                                    <Row style={{padding: "50px 10px"}}>
                                        <Col style={{alignSelf: "center",
                                            textAlignLast: "center", textAlign: "center"}}>
                                            <label className="txt">
                                                {t('155')}
                                            </label>

                                            <div>
                                                <span className="txt">{t('156')}</span>
                                            </div>
                                            <div style={{marginBottom:"30px", marginTop:"15px",
                                                textAlign:"center"}}>
                                                <Select Value={this.state.motif} style={{width : "250px"}}
                                                        defaultValue={"1"}
                                                        onChange={this.change_motif_delete}>
                                                    <Option value="1">{t('157')}</Option>
                                                    <Option value="2">{t('158')}</Option>
                                                    <Option value="3">{t('159')}</Option>
                                                    <Option value="4">{t('160')}</Option>
                                                    <Option value="5">{t('161')}</Option>
                                                </Select>
                                                {this.state.motif ==="5" && <input
                                                    type={"text"}
                                                    name="autre_choix"
                                                    className="input-profile"
                                                    onChange={this.onchange}
                                                    style={{width:"60%", marginTop:"25px"}}
                                                />}
                                            </div>
                                            <button
                                                    onClick={this.handleCancel}
                                                    className="btn-valider"
                                                    style={{padding:"7px 50px", marginRight : "10px", marginBottom : "20px"}}>
                                                    {t('162')}
                                                </button>
                                                <button
                                                    onClick={()=>this.deleteUser()}
                                                    className="btn-valider-profile"
                                                    style={{padding:"7px 50px"}}>
                                                    {t('163')}
                                                </button>
                                            <div>
                                                
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                {this.state.solde > 0 &&
                                    <div className={"alert-credit"}>
                                    <label>{t('321')}{this.state.solde} {t('322')}</label> 
                                    <img className='img-alert-credit' src={require('../Images/danger_icon_orange.png')} />
                                </div>
                                }
                            </Modal>
                        </div>
                        {/*  ************ modal *********************  */}
                        {
                            this.state.LoadingData ? <div className="spinner-center" style={{left: "45vw"}}>
                                    <Spinner animation="border" role="status" variant="primary" className={'spinn'}/>
                                </div> :
                                <Col style={{padding: "20px 30px"}}>
                                    <div style={{textAlign: "-webkit-center"}}>
                                        <label className={'label-profile'}>{t('307')}</label>
                                        <input
                                            type={"text"}
                                            name="first_name"
                                            value={this.state.first_name}
                                            className="input-profile-disabled"
                                            disabled={true}
                                        />
                                        <label className={'label-profile'}>{t('89')}</label>
                                        <input
                                            type={"text"}
                                            name="nom_marital"
                                            // value={this.state.user.last_name}
                                            value={this.state.nom_marital}
                                            placeholder={t('89')}
                                            className={this.state.readOnly ? 'input-profile-disabled':"input-profile"}
                                            onChange={this.onchange}
                                            readOnly={this.state.readOnly}

                                        />
                                        <label className={'label-profile'}>{t('308')}</label>
                                        <input
                                            type={"text"}
                                            name="last_name"
                                            value={this.state.last_name}
                                            className="input-profile-disabled"
                                            readOnly={this.state.readOnly}
                                            disabled={true}
                                        />
                                        <div style={{
                                            display: "flex", flexDirection: "row",
                                            margin: "15px 0"
                                        }}>
                                            <input style={{marginRight: "20px", width: "auto"}}
                                                   type="radio" value="MALE" name="gender"
                                                   checked={this.state.gender === "MALE"}
                                                   disabled={true}
                                            />
                                            <label style={{marginTop: "-5px", marginRight: "15px", color: "#1E90FF"}}>
                                                {t('91')}</label>

                                            <input style={{marginRight: "20px", width: "auto"}}
                                                   type="radio" value="FEMALE" name="gender"
                                                   checked={this.state.gender === "FEMALE"}
                                                   disabled={true}
                                            />
                                            <label style={{marginTop: "-5px", marginRight: "15px", color: "#1E90FF"}}>
                                                {t('92')}</label>

                                            <input style={{marginRight: "20px", width: "auto"}}
                                                   type="radio" value="Autre" name="gender"
                                                   checked={this.state.gender === "Autre"}
                                                   disabled={true}
                                            />
                                            <label style={{marginTop: "-5px", marginRight: "15px", color: "#1E90FF"}}>
                                                {t('93')}</label>
                                        </div>
                                        <label className={'label-profile'}>{t('309')}</label>
                                        <input type="text" id="start"
                                               name="Date_Naissance"
                                               className="input-profile-disabled"
                                               required={true}
                                               value={this.state.Date_Naissance}
                                               disabled={true}
                                        />
                                        {/* *** ville de naissance OBL*** */}
                                        <label className={'label-profile'}>{t('310')}</label>
                                        <input
                                            type="text"
                                            name="Ville_Naissance"
                                            className="input-profile-disabled"
                                            required={true}
                                            value={this.state.Ville_Naissance}
                                            disabled={true}
                                        />

                                        <label className={'label-profile'}>{t('311')}</label>
                                        <Select showSearch
                                                className={this.state.readOnly ?
                                                    'border-input input-profile-disabled':" border-input input-profile"}
                                                name="pays_residence"
                                                onChange={v => this.onChangeCountry(v)}
                                                placeholder={t('98')}
                                                value={this.state.Pays_Residence}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                disabled={this.state.readOnly}
                                        >
                                            {this.state.country.map(r => (
                                                <Option value={r.label}>{r.label}</Option>
                                            ))}
                                        </Select>
                                        {this.state.Pays_ResidenceErr && <span className='error'>
                                        {t('99')}</span>}

                                        <label className={'label-profile'}>{t('312')}</label>
                                        <Select showSearch
                                                className={this.state.readOnly ?
                                                    'border-input input-profile-disabled':" border-input input-profile"}
                                                name="ville_residence"
                                                onSelect={v => this.onChangeVilleResidence(v)}
                                                placeholder={t('97')}
                                                optionFilterProp="children"
                                                value={this.state.Ville_Residence}
                                                disabled={this.state.readOnly}
                                                options={this.state.cities}
                                        >
                                        </Select>
                                        {this.state.ville_residenceErr && <span className='error'>
                                        {t('302')}</span>}

                                        <label className={'label-profile'}>{t('313')}</label>
                                        <input
                                            type={"text"}
                                            name="email"
                                            value={this.state.email}
                                            className={this.state.readOnly ? 'input-profile-disabled':"input-profile"}
                                            onChange={this.onchange}
                                            readOnly={this.state.readOnly}

                                        />
                                        {/* *** langue pref *** */}
                                        <div style={{margin: "10px 0", textAlign: "left"}}>
                                            <label style={{
                                                display: "inline-block", marginRight: "15px", color: "#74869E",
                                                fontFamily: 'ROBOTO'
                                            }}>{t('102')}</label>
                                            <Select value={this.state.Langue_prefere} bordered={false}
                                                    onChange={v => this.getLangue(v)} disabled={this.state.readOnly}>
                                                <Option value="fr"><img src={frenchFlag} width={30}
                                                                        height={25}/></Option>
                                                <Option value="es"><img src={SpainFlag} width={30}
                                                                        height={25}/></Option>
                                                <Option value="en"><img src={BritainFlag} width={30}
                                                                        height={25}/></Option>
                                                <Option value="it"><img src={ItalyFlag} width={30}
                                                                        height={25}/></Option>
                                            </Select>
                                        </div>

                                        <label className={'label-profile'}>{t('105')}</label>
                                        <PhoneInput
                                            international
                                            id={'Num_telephone'}
                                            countryCallingCodeEditable={false}
                                            placeholder={t('105')}
                                            defaultCountry="FR"
                                            value={this.state.Num_telephone}
                                            onChange={(v) => this.setValuePhone(v)}
                                            disabled={this.state.readOnly}
                                            className={this.state.readOnly ? 'input-profile-disabled':"input-profile"}
                                        />
                                        {this.state.Num_telephoneErr && <span className='error'>
                                        {t('107')}</span>}

                                        {/*<input
                                    type="tel"
                                    name="Num_telephone"
                                    placeholder={t('105')}
                                    value={this.state.Num_telephone}
                                    className="input-profile"
                                    onChange={this.onchange}
                                    readOnly={this.state.readOnly}
                                />*/}

                                        {/* *** Password OBL*** */}
                                        <div>
                                            <input
                                                type="password"
                                                name="password"
                                                placeholder="******"
                                                onChange={this.onchange}
                                                // onChange={this.onChange}
                                                className={this.state.readOnlyPassword ?
                                                    'input-profile-disabled':"input-profile"}
                                                required={true}
                                                readOnly={this.state.readOnlyPassword}
                                            />
                                            {this.state.OldPassword &&
                                            <span className={"text-danger"}
                                                  style={{fontSize: "12px", textAlign: "left", float: "left"}}>
                                            {t('300')}</span>
                                            }
                                            <button className={"btn btn-link pass-change"}
                                                    onClick={this.readOnlyPassword.bind(this)}>
                                                {t('165')}</button>
                                        </div>

                                        {/* ****** joindre piece recto ******** */}
                                        {/* si !this.state.pieceSubmit === true alors la piece est non transmis encore*/}
                                        {!this.state.pieceSubmit ? <>
                                                <div style={{textAlign: "left"}}>
                                                    <label htmlFor="recto" className="custom-file-upload">
                                                        <input id="recto" className="input-file"
                                                               type="file" name="pieceRecto"
                                                               onChange={this.onPieceChange}/>
                                                        {t('166')}</label>
                                                    {window.pieceValidation ?
                                                        <img src={warn} style={{
                                                            marginLeft: "20px", width: "25px",
                                                            marginBottom: "10px"
                                                        }}/>
                                                        : <></>
                                                    }
                                                </div>
                                                <span>{this.state.pieceRecto.name}</span>
                                                <span className='error'>{this.state.RectoError}</span>
                                                <div style={{marginBottom : "7px", lineHeight:"16px",textAlign:'left'}}>
                                                    <span style={{fontSize : "13px"}} className='register-txt'>{t('320')}</span>
                                                </div>
                                                {/* ****** joindre piece verso ******** */}
                                                <div style={{textAlign: "left"}}>
                                                    <label htmlFor="verso" className="custom-file-upload">
                                                        <input id="verso" className="input-file"
                                                               type="file" name="pieceVerso"
                                                               onChange={this.onPieceChange}/>
                                                        {t('167')}</label>
                                                    {window.pieceValidation ?
                                                        <img src={warn} style={{
                                                            marginLeft: "20px", width: "25px",
                                                            marginBottom: "10px"
                                                        }}/>
                                                        : <></>
                                                    }
                                                </div>
                                                <span>{this.state.pieceVerso.name}</span>
                                                <span className='error'>{this.state.VersoError}</span>
                                            </>
                                            : <>
                                                {window.pieceValidation ? <Tag color="#108ee9"
                                                                               style={{padding: "10px 20px"}}>
                                                        {t('168')}</Tag> :
                                                    <></>
                                                }
                                            </>}
                                        <div style={{textAlign: "left", marginTop: "35px"}}>
                                            <Button type="link" onClick={this.showModal}
                                                    style={{marginBottom: "20px", padding: "0"}}>
                                                {t('169')}
                                            </Button>
                                        </div>
                                        <div style={{textAlign: "left"}}>
                                            <button className="btn-valider"
                                                    style={{
                                                        padding: "6px 15px",
                                                        cursor: "not-allowed"
                                                    }}>
                                                {/*{loading ? <span style={{fontSize: "17px"}}>En cours ...</span> :
                                            <span style={{fontSize: "17px"}}>Valider</span>}*/}
                                                <span>{this.state.Pseudonyme}</span>
                                                <FaCheck style={{
                                                    marginLeft: " 20px",
                                                    marginBottom: "5px"
                                                }}/>
                                            </button>
                                        </div>
                                        <div style={{
                                            marginTop: "35px",
                                            display: "flex"
                                        }}>

                                            <button
                                                onClick={this.readOnly.bind(this)}
                                                className="btn-valider-profile"
                                                style={{padding: "6px 35px", marginRight: "35px"}}>
                                                {t('79')}
                                            </button>

                                            <button className="btn-valider"
                                                    style={{padding: "6px 35px", zIndex: "10"}}
                                                    onClick={this.updateProfile.bind(this)}>
                                                {this.state.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">En cours ...</span>
                                                    </div> :
                                                    <span style={{fontSize: "17px"}}>{t('114')}</span>}
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                        }
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/notifications">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",width:"100%",
                                marginBottom: "0", color : "#0C4EFC"}}><label>{t('145')}</label>
                                {window.NotifNumber >0 ?<><FaBell style={{float:"right",
                                    color : "#FF4141", height : "30px", width: "30px"}}/>
                                    <label className={"bell-number"}>{window.NotifNumber}</label></> : <></>}
                            </h5>
                        </NavLink>
                    </Row>
                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/mescredits">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('146')}</h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parametres">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('148')}</h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parametres_jeu">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('149')}</h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parrainage">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('150')}</h5>
                        </NavLink>
                    </Row>

                {/* ************ MAIN MENU SUITE **************   */}
                    <Row style={{margin : "30px 10px 0"}}>
                                        <NavLink className="column col"
                                                 to={"/Tirage"} activeClassName="selected">
                                            <h5 style={{color : "#FFF"}}>{t('142')}</h5>
                                        </NavLink>

                    </Row>

                    <Row style={{margin : "0 10px"}}>
                                 <NavLink className="column col" style={{borderRadius: "0 0 30px 30px"}}
                                          to={"/assistance"} activeClassName="selected">
                                     <h5 style={{color : "#FFF"}}>{t('143')}</h5>
                                 </NavLink>
                    </Row>

                </Container>
            );
        }



    }
}

export default withTranslation()(Profile)
