import jwt_decode from "jwt-decode";
import jwt from 'jsonwebtoken'
import axios from "axios";
import React from "react";

let d = new Date();
let day = d.getDate();
let month = d.getMonth()+1;
let year = d.getFullYear();

let date = ('0' + day).slice(-2) + '/'
    + ('0' + (month)).slice(-2) + '/' + year;

let hours = d.getHours();
let mins = d.getMinutes();
let time = ('0' + (hours)).slice(-2) + ":" + ('0' + (mins)).slice(-2);
/**
 * @return {boolean}
 */
export function IsLoggedIn(){
    try {

        const token = localStorage.usertoken;
        let decodedToken=jwt.decode(token, process.env.jwtPrivateKey);

        if(jwt_decode(token).exp < Date.now() / 1000 && !jwt.verify(token, process.env.jwtPrivateKey)){
            //console.log("not logged")
            return false
        }else{
            //console.log("logged")
            return true
        }
    } catch (err) {
        return false;
    }
}


export async function isCountryNoAuthorized(){


}


/**
 * get uear and week number
 * @param d get year and week number
 * @returns {[number, number]}
 */
export function getYear_Week(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    let weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
}

export const calculateTimeLeft = () => {
    let difference = +new Date(nextSaturday()) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    }
    console.log(" difference : " + difference);
    console.log("time left : " + timeLeft)
    return difference;
}
/**
 * for getting next saturday
 * @param dayIndex 0 for monday 1 2 ... 0 for sunday
 * @returns {Date}
 */
export const nextSaturday = (dayIndex = 6) => {
    let today = new Date();
    today.setDate(today.getDate() + (dayIndex - 1 - today.getDay() + 7) % 7 + 1);
    return today;
};

export function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
        total,
        days,
        hours,
        minutes,
        seconds
    };
}

export async function foo() {
    let day = new Date().getDay();
    let hours = new Date().getHours();

    //***************************** REDUX FOR VOTE STATE **********************
    // const state = store.getState();
    //console.log(state.VoteState)
    //store.dispatch(enableVote());


    //0 correspondant à dimanche

    if ((day === 5 && hours >= 19) || (day === 6 && hours <= 19) ){
        // store.dispatch(enableVote());
        console.log("vote enabled");
        return true
    }
    else {
        // store.dispatch(disableVote());
        console.log("vote disabled");
        return false
    }

}

export async function checkClotureParticipation() {
    let day = new Date().getDay();
    let hours = new Date().getHours();


    //0 correspondant à dimanche

    if ((day === 6 && hours >= 19)){
        // store.dispatch(enableVote());
        console.log("participation disabled");
        return true
    }
    else {
        // store.dispatch(disableVote());
        console.log("participation enabled");
        return false
    }

}

export async function getIP(){
    const response = await fetch('https://api.ipify.org/?format=json');
    return await response.json();
}

/**
 * @return {string}
 */
export function FormatDate(_date) {
    let date = new Date(_date);
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();
    let hours = date.getHours();
    let mins = date.getMinutes();
    let MyDateString = ('0' + day).slice(-2) + '/'
        + ('0' + (month)).slice(-2) + '/' + year + " " +
        ('0' + (hours)).slice(-2) + ":" + ('0' + (mins)).slice(-2);
    return( MyDateString);
}

/**
 * @return {string}
 */
export function FormatDateNoTime(_date) {
    let date = new Date(_date);
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();

    return( day + '/' + month + '/' +  year);
}

/**
 * @return {string}
 */
export function FormatDateNoTimeMonthName(_date) {
    const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre",
        "Octobre", "Novembre", "Décembre"];
    let date = new Date(_date);
    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    return( ('0' + day).slice(-2) + " " + month + ' ' +  year);
}



export function getSundayFromWeekNum( weeks, year ) {
    var d = new Date(year, 0, 1);
    var dayNum = d.getDay();
    var requiredDate = --weeks * 7;
    // If 1 Jan is Friday to Sunday, go to next week
    if (((dayNum!=0) || dayNum > 4)) {
        requiredDate += 7;
    }
    // Add required number of days
    d.setDate(1 - d.getDay() + ++requiredDate );
    return d;
}


let changeCountry = (countryName) => ({
    subject: 'Connexion d\'un autre pays',
    html: "Une connexion récente a été détectée sur votre compte, hors de votre pays de résidence le "
        +date+ " à " + time+". Lieu : "+countryName+"."
})
export function CheckCountry( countryName ) {
        const token = localStorage.usertoken;
        let decodedToken=jwt.decode(token, process.env.jwtPrivateKey);
        let pays_residence = decodedToken.Pays_Residence
    console.log(pays_residence)
        if (countryName.trim() !== pays_residence.trim()){
            axios.post('/api/notifications/country',{IdUser : decodedToken.id,
                message : "Nous avons détecté une connexion d'un autre pays que votre lieu de résidence."})
            axios.post('/api/notifications/sendEmailCountry',{email : decodedToken.email,
                templateName : changeCountry(countryName)})
        }

}
