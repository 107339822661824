import React, {useEffect, useState} from 'react'
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {IsLoggedIn} from "../Helpers";
import VerticalNavbar_assistance from "../components/VerticalNavbar_assistance";
import {Input,Breadcrumb, Collapse} from "antd";
import "./condition_jeu.css"
import {Link, NavLink} from "react-router-dom";
import HeaderNavbarDashboard from "../components/HeaderNavbarDashboard";
import arrow_bottom from "../Images/arrow-bottom.png";
import arrow from "../Images/arrow-right.png";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from 'react-router-dom'



const { Search } = Input;
const { Panel } = Collapse;

function SearchResult() {
    let width = window.innerWidth
    let [result, SetResult]   = useState([]);
    let [loading, SetLoading] = useState(true)
    let [found, SetFound] = useState(true)
    const search = useLocation().search;
    const name = new URLSearchParams(search).get('q')

    const onSearch = value => {
        let queryString = "/assistance/faqS/search?q=" + value;
        window.location.href=queryString
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.post('/api/pages/search',{word:name})
            .then((res) => {
                SetLoading(false)
                SetResult(res.data)
                if(res.data.length<=0)
                    SetFound(false)
                else
                    SetFound(true)

                console.log(res.data)
            }).catch((res)=>{
            SetLoading(false)
            SetFound(false)
            console.log(res.data)
        })


    }, [])


    if(width > 768){
        return(
            <>{
                loading ? <div className="spinner-center" style={{left: "50vw"}}>
                        <Spinner animation="border" role="status" variant="primary"/>
                    </div> :
                    <Container fluid>
                        <Row style={{padding: "0 100px"}}>
                            {IsLoggedIn() ?
                                (
                                    <Col>
                                        <HeaderNavbarDashboard/>
                                    </Col>
                                ) :
                                (<div/>)}
                        </Row>
                        <Row className="condition">
                            {IsLoggedIn() ? (
                                    <Col md={2}>
                                        <VerticalNavbar_assistance/>
                                    </Col>) :
                                (<div/>)}
                            <Col className={"padding-faq"}>
                                <Search placeholder="Rechercher..."
                                        enterButton onSearch={onSearch} defaultValue={name}/>
                                <Breadcrumb separator=">" style={{marginTop: "30px"}}>
                                    <Breadcrumb.Item>
                                        <Link to={"/assistance/faqS"}>
                                            <label className="breadcrump"
                                                   style={{
                                                       textDecoration: "underline",
                                                       cursor: "pointer"
                                                   }}>FAQ's</label>
                                        </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item className="breadcrump">Résultat</Breadcrumb.Item>
                                </Breadcrumb>
                                <div style={{marginTop: "50px", marginLeft: "70px"}}>
                                    {found === true ?
                                        <Collapse style={{backgroundColor: "#FFF", border: "0"}}>
                                            {result.map((row) => (
                                                <Panel header={ReactHtmlParser(row.title)} key={row[0]}>
                                                    <p className="condition-text">{ReactHtmlParser(row.contenu_page)}</p>
                                                </Panel>
                                            ))}

                                        </Collapse> :
                                        <Row>
                                           <h4>Aucun résultat trouvé</h4>
                                           <p>
                                           Désolé, nous n'avons trouvé aucun résultat correspondant à votre
                                           recherche. Effectuez une autre recherche ou parcourez notre FAQ.
                                           </p>
                                        </Row>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
            }
            </>
        )
    }

    else {
        return (
            <>{
                loading ? <div className="spinner-center" style={{left: "50vw"}}><Spinner animation="border" role="status" variant="primary"/>
                    </div> :
                    <Container className="pad-mob">
                        <Row style={{margin: "0 10px"}}>
                            {/* // ***************** MENU MON COMPTE NAVBAR *************** */}

                            <NavLink className="column col" style={{borderRadius: "30px 30px 0 0"}}
                                     to={"/moncompte"} activeClassName="selected">
                                <h5 style={{color: "#FFF"}}>Mon compte</h5>
                            </NavLink>
                        </Row>

                        <Row style={{margin: "0 10px"}}>
                            <NavLink className="column col"
                                     to={"/Tirage"} activeClassName="selected">
                                <h5 style={{color: "#FFF"}}>Tirages</h5>
                            </NavLink>
                        </Row>

                        <Row style={{margin: "0 10px"}}>
                            <NavLink className="column col" to={"/assistance"} activeClassName="selected">
                                <h5 style={{color: "#FFF"}}>Assistance</h5>
                            </NavLink>
                        </Row>


                        {/* ********************** UNDER MENU ********************************** */}

                        <Row className="row-und-menu">
                            <NavLink className="und-menu" to="/assistance/accessibilite">
                                <img src={arrow_bottom} width={30} height={30}/>
                                <h5 style={{
                                    marginLeft: "10px", marginTop: "2px",
                                    marginBottom: "0", color: "#0C4EFC"
                                }}>Accessibilité</h5>
                            </NavLink>
                        </Row>

                        <Row className="row-und-menu">
                            <NavLink className="und-menu" to="/assistance/faqS">
                                <img src={arrow_bottom} width={30} height={30}/>
                                <h5 style={{
                                    marginLeft: "10px", marginTop: "2px",
                                    marginBottom: "0", color: "#0C4EFC"
                                }}>FAQ’s</h5>
                            </NavLink>
                        </Row>
                        <Row>
                            <Search placeholder="Rechercher..."
                                    enterButton onSearch={onSearch} defaultValue={name}/>
                        </Row>
                        <Row className={"faq-mobile"}>
                            <Breadcrumb separator=">" style={{marginTop: "20px"}}>
                                <Breadcrumb.Item>
                                    <Link to={"/assistance/faqS"}>
                                        <label className="breadcrump"
                                               style={{textDecoration: "underline", cursor: "pointer"}}>FAQ's</label>
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item className="breadcrump">Résultat</Breadcrumb.Item>
                            </Breadcrumb>
                            <div style={{marginTop: "30px"}}>
                                {found === true ?
                                    <Collapse style={{backgroundColor: "#FFF", border: "0"}}>
                                        {result.map((row) => (
                                            <Panel header={ReactHtmlParser(row.title)} key={row[0]}>
                                                <p className="condition-text">{ReactHtmlParser(row.contenu_page)}</p>
                                            </Panel>
                                        ))}

                                    </Collapse> :
                                    <Row>
                                        <Col>
                                        <h4>Aucun résultat trouvé</h4>
                                        <p>
                                            Désolé, nous n'avons trouvé aucun résultat correspondant à votre
                                            recherche. Effectuez une autre recherche ou parcourez notre FAQ.
                                        </p>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </Row>


                        <Row className="row-und-menu">
                            <NavLink className="und-menu" to="/assistance">
                                <img src={arrow} width={30} height={30}/>
                                <h5 style={{
                                    marginLeft: "10px", marginTop: "2px",
                                    marginBottom: "0", color: "#0C4EFC"
                                }}>Nous contacter</h5>
                            </NavLink>
                        </Row>
                    </Container>
            }
            </>
        )
    }

}

export default SearchResult;
