import React, {Component, useEffect, useState} from 'react'
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {IsLoggedIn} from "../Helpers";
import VerticalNavbar_assistance from "../components/VerticalNavbar_assistance";
import {Input,Breadcrumb, Collapse} from "antd";
import "./condition_jeu.css"
import {Link, NavLink} from "react-router-dom";
import HeaderNavbarDashboard from "../components/HeaderNavbarDashboard";
import arrow_bottom from "../Images/arrow-bottom.png";
import arrow from "../Images/arrow-right.png";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next";


const { Search } = Input;
const { Panel } = Collapse;


function Le_jeu() {
    let width = window.innerWidth;
    let [titre_1, Settitre_1]   = useState("");
    let [titre_2, Settitre_2]   = useState("");
    let [titre_3, Settitre_3]   = useState("");
    let [titre_4, Settitre_4]   = useState("");
    let [titre_5, Settitre_5]   = useState("");
    let [titre_6, Settitre_6]   = useState("");
    let [titre_7, Settitre_7]   = useState("");
    let [titre_8, Settitre_8]   = useState("");
    let [titre_9, Settitre_9]   = useState("");
    let [titre_10, Settitre_10]   = useState("");
    let [titre_11, Settitre_11]   = useState("");
    let [titre_12, Settitre_12]   = useState("");
    let [titre_13, Settitre_13]   = useState("");
    let [titre_14, Settitre_14]   = useState("");
    let [contenu_1, SetContenu_1]   = useState("");
    let [contenu_2, SetContenu_2]   = useState("");
    let [contenu_3, SetContenu_3]   = useState("");
    let [contenu_4, SetContenu_4] = useState("");
    let [contenu_5, SetContenu_5] = useState("");
    let [contenu_6, SetContenu_6] = useState("");
    let [contenu_7, SetContenu_7] = useState("");
    let [contenu_8, SetContenu_8] = useState("");
    let [contenu_9, SetContenu_9] = useState("");
    let [contenu_10, SetContenu_10] = useState("");
    let [contenu_11, SetContenu_11] = useState("");
    let [contenu_12, SetContenu_12] = useState("");
    let [contenu_13, SetContenu_13] = useState("");
    let [contenu_14, SetContenu_14] = useState("");
    let [loading, SetLoading] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/66')
            .then((res) => {
                SetLoading(false)
                Settitre_1(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/77')
            .then((res) => {
                SetLoading(false)
                Settitre_2(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/78')
            .then((res) => {
                SetLoading(false)
                Settitre_3(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/79')
            .then((res) => {
                SetLoading(false)
                Settitre_4(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/80')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                Settitre_5(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/81')
            .then((res) => {
                SetLoading(false)
                Settitre_6(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/82')
            .then((res) => {
                SetLoading(false)
                Settitre_7(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
axios.get('/api/pages/83')
            .then((res) => {
                SetLoading(false)
                Settitre_8(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
axios.get('/api/pages/84')
            .then((res) => {
                SetLoading(false)
                Settitre_9(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
axios.get('/api/pages/86')
            .then((res) => {
                SetLoading(false)
                Settitre_10(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/87')
            .then((res) => {
                SetLoading(false)
                Settitre_11(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/90')
            .then((res) => {
                SetLoading(false)
                Settitre_12(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/91')
            .then((res) => {
                SetLoading(false)
                Settitre_13(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/146')
            .then((res) => {
                SetLoading(false)
                Settitre_14(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })


        axios.get('/api/pages/92')
            .then((res) => {
                SetLoading(false)
                SetContenu_1(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/93')
            .then((res) => {
                SetLoading(false)
                SetContenu_2(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/94')
            .then((res) => {
                SetLoading(false)
                SetContenu_3(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/95')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_4(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/96')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_5(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/97')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_6(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/98')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_7(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/99')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_8(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/100')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_9(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/101')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_10(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/102')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_11(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/103')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_12(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/104')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_13(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/147')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_14(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
    }, [])

    const history = useHistory()
    const {t} = useTranslation()

    const onSearch = value => {
        let queryString = "q=" + value;
        history.push(`/assistance/faqS/search?${queryString}`)
    };

    if(width > 768){
        return(
            <>{
                loading ? <div className="spinner-center" style={{left: "50vw"}}>
                        <Spinner animation="border" role="status" variant="primary"/>
                    </div> :
                    <Container fluid>
                        <Row style={{padding: "0 100px"}}>
                            {IsLoggedIn() ?
                                (
                                    <Col>
                                        <HeaderNavbarDashboard/>
                                    </Col>
                                ) :
                                (<div/>)}
                        </Row>
                        <Row className="condition">

                                    <Col md={2}>
                                        <VerticalNavbar_assistance/>
                                    </Col>
                            <Col className="padding-faq">
                                <Search placeholder="Rechercher..."
                                        enterButton onSearch={onSearch}/>
                                <Breadcrumb separator=">" style={{marginTop: "30px"}}>
                                    <Breadcrumb.Item>
                                        <Link to={"/assistance/faqS"}>
                                            <label className="breadcrump"
                                                   style={{
                                                       textDecoration: "underline",
                                                       cursor: "pointer"
                                                   }}>FAQ's</label>
                                        </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item className="breadcrump">Le jeu</Breadcrumb.Item>
                                </Breadcrumb>
                                <div style={{marginTop: "50px", marginLeft: "70px"}}>
                                    {/*{loading ? <div className="spinner-center"><Spinner animation="border" role="status" variant="primary"/> </div>:
                                <Container fluid>
                                    <div>{ ReactHtmlParser(contenu) }</div>
                                </Container>
                            }*/}

                                    <Collapse style={{backgroundColor: "#FFF", border: "0"}}>

                                        <Panel header={ReactHtmlParser(titre_1)} key="1">
                                            <p className="condition-text">{ReactHtmlParser(contenu_1)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_2)} key="2">
                                            <p className="condition-text">{ReactHtmlParser(contenu_2)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_3)} key="3">
                                            <p className="condition-text">{ReactHtmlParser(contenu_3)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_4)} key="4">
                                            <p className="condition-text">{ReactHtmlParser(contenu_4)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_5)} key="5">
                                            <p className="condition-text">{ReactHtmlParser(contenu_5)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_6)} key="6">
                                            <p className="condition-text">{ReactHtmlParser(contenu_6)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_7)} key="7">
                                            <p className="condition-text">{ReactHtmlParser(contenu_7)}</p></Panel>

                                        <Panel header={ReactHtmlParser(titre_8)} key="8">
                                            <p className="condition-text">{ReactHtmlParser(contenu_8)}</p></Panel>

                                        <Panel header={ReactHtmlParser(titre_9)} key="9">
                                            <p className="condition-text">{ReactHtmlParser(contenu_9)}</p></Panel>

                                        <Panel header={ReactHtmlParser(titre_10)} key="10">
                                            <p className="condition-text">{ReactHtmlParser(contenu_10)}</p></Panel>

                                        <Panel header={ReactHtmlParser(titre_11)} key="11">
                                            <p className="condition-text">{ReactHtmlParser(contenu_11)}</p></Panel>

                                        <Panel header={ReactHtmlParser(titre_12)} key="12">
                                            <p className="condition-text">{ReactHtmlParser(contenu_12)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_13)} key="13">
                                            <p className="condition-text">{ReactHtmlParser(contenu_13)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_14)} key="14">
                                            <p className="condition-text">{ReactHtmlParser(contenu_14)}</p>
                                        </Panel>

                                    </Collapse>


                                </div>
                            </Col>
                        </Row>
                    </Container>
            }
            </>
        )
    }

    else{
            return (
                <>{
                    loading ?
                        <div className="spinner-center" style={{left: "50vw"}}>
                            <Spinner animation="border" role="status" variant="primary"/>
                        </div> :
                        <Container className="pad-mob">
                            {IsLoggedIn() ?
                                <>
                                    <Row style={{margin : "0 10px"}}>
                                        {/* // ***************** MENU MON COMPTE NAVBAR *************** */}

                                        <NavLink className="column col" style={{borderRadius: "30px 30px 0 0"}}
                                                 to={"/moncompte"} activeClassName="selected">
                                            <h5 style={{color : "#FFF"}}>{t('63')}</h5>
                                        </NavLink>
                                    </Row>

                                    <Row style={{margin : "0 10px"}}>
                                        <NavLink className="column col"
                                                 to={"/Tirage"} activeClassName="selected">
                                            <h5 style={{color : "#FFF"}}>{t('64')}</h5>
                                        </NavLink>
                                    </Row>

                                    <Row style={{margin : "0 10px"}}>
                                        <NavLink className="column col" to={"/assistance"} activeClassName="selected">
                                            <h5 style={{color : "#FFF"}}>{t('65')}</h5>
                                        </NavLink>
                                    </Row>
                                </>
                                :
                                (<div/>)}
                            {/* ********************** UNDER MENU ********************************** */}

                            <Row className="row-und-menu">
                                <NavLink className="und-menu" to="/assistance/accessibilite">
                                    <img src={arrow_bottom} width={30} height={30}/>
                                    <h5 style={{
                                        marginLeft: "10px", marginTop: "2px",
                                        marginBottom: "0", color: "#0C4EFC"
                                    }}>{t('151')}</h5>
                                </NavLink>
                            </Row>

                            <Row className="row-und-menu">
                                <NavLink className="und-menu" to="/assistance/faqS">
                                    <img src={arrow_bottom} width={30} height={30}/>
                                    <h5 style={{
                                        marginLeft: "10px", marginTop: "2px",
                                        marginBottom: "0", color: "#0C4EFC"
                                    }}>{t('152')}</h5>
                                </NavLink>
                            </Row>

                            <Row>
                                <Search placeholder={t('292')}
                                        enterButton onSearch={onSearch}/>
                            </Row>
                            <Row className={"faq-mobile"}>
                                <Breadcrumb separator=">" style={{marginTop: "20px"}}>
                                    <Breadcrumb.Item>
                                        <Link to={"/assistance/faqS"}>
                                            <label className="breadcrump"
                                                   style={{
                                                       textDecoration: "underline",
                                                       cursor: "pointer"
                                                   }}>{t('152')}</label>
                                        </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item className="breadcrump">{t('286')}</Breadcrumb.Item>
                                </Breadcrumb>
                                <div style={{marginTop: "30px"}}>
                                    <Collapse style={{backgroundColor: "#FFF", border: "0"}}>

                                        <Panel header={ReactHtmlParser(titre_1)} key="1">
                                            <p className="condition-text">{ReactHtmlParser(contenu_1)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_2)} key="2">
                                            <p className="condition-text">{ReactHtmlParser(contenu_2)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_3)} key="3">
                                            <p className="condition-text">{ReactHtmlParser(contenu_3)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_4)} key="4">
                                            <p className="condition-text">{ReactHtmlParser(contenu_4)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_5)} key="5">
                                            <p className="condition-text">{ReactHtmlParser(contenu_5)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_7)} key="7">
                                            <p className="condition-text">{ReactHtmlParser(contenu_7)}</p></Panel>

                                        <Panel header={ReactHtmlParser(titre_8)} key="8">
                                            <p className="condition-text">{ReactHtmlParser(contenu_8)}</p></Panel>

                                        <Panel header={ReactHtmlParser(titre_9)} key="9">
                                            <p className="condition-text">{ReactHtmlParser(contenu_9)}</p></Panel>

                                        <Panel header={ReactHtmlParser(titre_10)} key="10">
                                            <p className="condition-text">{ReactHtmlParser(contenu_10)}</p></Panel>

                                        <Panel header={ReactHtmlParser(titre_11)} key="11">
                                            <p className="condition-text">{ReactHtmlParser(contenu_11)}</p></Panel>

                                        <Panel header={ReactHtmlParser(titre_12)} key="12">
                                            <p className="condition-text">{ReactHtmlParser(contenu_12)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_13)} key="13">
                                            <p className="condition-text">{ReactHtmlParser(contenu_13)}</p>
                                        </Panel>

                                    </Collapse>
                                </div>
                            </Row>


                            <Row className="row-und-menu">
                                <NavLink className="und-menu" to="/assistance">
                                    <img src={arrow} width={30} height={30}/>
                                    <h5 style={{
                                        marginLeft: "10px", marginTop: "2px",
                                        marginBottom: "0", color: "#0C4EFC"
                                    }}>{t('55')}</h5>
                                </NavLink>
                            </Row>
                        </Container>
                }</>
            )
        }


}

export default Le_jeu;
