import React, {Component, useEffect, useState} from 'react';
import {Container, Row, Spinner} from "react-bootstrap";
import "./Nous_connaitre.css"
import axios from "axios";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const A_propos = () =>{
    let width = window.innerWidth

    let [contenu, SetContenu] = useState("")
    let [loading, SetLoading] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/32')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
    }, [])

    //---------------------------------------- DESKTOP VERS ---------------------------------------------

        return(
            <div className="nous-connaitre">
                {loading ? <div className="spinner-center"><Spinner animation="border" role="status" variant="primary"/> </div>:
                    <Container fluid className={"qui-sommes-nous"}>
                        <Row style={{justifyContent: "center"}}>
                            <h4 className="titre-35px">À propos</h4>
                        </Row>

                        <div>{ ReactHtmlParser(contenu) }</div>



                    </Container>
                }

            </div>
        )



    //-------------------------------------------  MOBILE VERS  -------------------------------------------

/*
    else {
        return(
            <>
            {loading ? <div className="spinner-center"><Spinner animation="border" role="status" variant="primary"/> </div>:
                    <Container fluid>
                        <Row style={{justifyContent: "center"}}>
                            <h4 className="titre-35px">À propos</h4>
                        </Row>

                        <div>{ ReactHtmlParser(contenu) }</div>



                    </Container>
            }
            </>

        )
    }
*/

}

export default A_propos;
