import React, {Component, useEffect} from 'react'
import {Col, Container, Row} from "react-bootstrap";
import {IsLoggedIn} from "../Helpers";
import VerticalNavbar_assistance from "../components/VerticalNavbar_assistance";
import "./faq_s.css"
import condition from "../Images/conditions_jeu.png"
import jeu from "../Images/Le_jeu.png"
import tirage from "../Images/homeTirage.png"
import argent from "../Images/argent.png"
import cadenas from "../Images/cadenas.png"
import { Card, Input } from 'antd';
import {Link, NavLink} from "react-router-dom";
import HeaderNavbarDashboard from "../components/HeaderNavbarDashboard";
import arrow_bottom from "../Images/arrow-bottom.png";
import arrow from "../Images/arrow-right.png";
import { useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next";
import VerticalNavbar2 from "../components/VerticalNavbar2";

const { Search } = Input;
const gridStyle = {
    width: '150px',
    height : '155px',
    textAlign: 'center',
    margin: "20px",
    display: "inline-grid",
    justifyItems: "center",
    cursor: "pointer",
    marginRight: "55px",
};

function Faq_s() {

    let width = window.innerWidth
    const history = useHistory()
    const {t} = useTranslation()
    const onSearch = value => {
        let queryString = "q=" + value;
        history.push(`/assistance/faqS/search?${queryString}`)
    };
    useEffect(() => {
            window.scrollTo(0, 0)
        }
        ,[]
    )

    //-------------------------------------------- DESKTOP VERS -------------------------------------
    if(width > 768) {
        return (
            <Container fluid>
                <Row style={{padding: "0 100px"}}>
                    {IsLoggedIn() ?
                        (
                            <Col>
                                <HeaderNavbarDashboard/>
                            </Col>
                        ) :
                        (<div/>)}
                </Row>
                <Row className={"faq"}>
                            <Col md={2}>
                                <VerticalNavbar_assistance/>
                            </Col>
                    <Col className="faq">
                        <Search placeholder={t('292')}
                                enterButton onSearch={onSearch}/>
                        <Card bordered={false} >

                            <Link to={"/assistance/faqS/conditionjeu"}>
                                <Card.Grid style={gridStyle}>
                                    <img style={{width: "55px", height: "70px"}} src={condition}/>
                                    <label className="title-faq">{t('285')}</label>
                                </Card.Grid>
                            </Link>

                            <Link to={"/assistance/faqS/lejeu"}>
                                <Card.Grid style={gridStyle}>
                                    <img style={{width: "55px", height: "70px"}} src={jeu}/>
                                    <label className="title-faq">{t('286')}</label>
                                </Card.Grid>
                            </Link>

                            <Link to={"/assistance/faqS/letirage"}>
                                <Card.Grid style={gridStyle}>
                                    <img style={{width: "115px"}} src={tirage}/>
                                    <label className="title-faq">{t('287')}</label>
                                </Card.Grid>
                            </Link>

                            <Link to={"/assistance/faqS/lesgains"}>
                                <Card.Grid style={gridStyle}>
                                    <img style={{width: "70px", height: "55px"}} src={argent}/>
                                    <label className="title-faq">{t('288')}</label>
                                </Card.Grid>
                            </Link>

                            <Link to={"/assistance/faqS/securite"}>
                                <Card.Grid style={gridStyle}>
                                    <img style={{width: "55px", height: "70px"}} src={cadenas}/>
                                    <label className="title-faq">{t('289')}</label>
                                </Card.Grid>
                            </Link>
                        </Card>,
                    </Col>

                </Row>
            </Container>
        )
    }

    //-------------------------------------------- MOBILE VERS ---------------------------------------
    else {
        return(
            <Container className ="pad-mob">
                {IsLoggedIn() ?
                        <>
                            <Row style={{margin : "0 10px"}}>
                                {/* // ***************** MENU MON COMPTE NAVBAR *************** */}

                                <NavLink className="column col" style={{borderRadius: "30px 30px 0 0"}}
                                         to={"/moncompte"} activeClassName="selected">
                                    <h5 style={{color : "#FFF"}}>{t('63')}</h5>
                                </NavLink>
                            </Row>

                            <Row style={{margin : "0 10px"}}>
                                <NavLink className="column col"
                                         to={"/Tirage"} activeClassName="selected">
                                    <h5 style={{color : "#FFF"}}>{t('64')}</h5>
                                </NavLink>
                            </Row>

                            <Row style={{margin : "0 10px"}}>
                                <NavLink className="column col" to={"/assistance"} activeClassName="selected">
                                    <h5 style={{color : "#FFF"}}>{t('65')}</h5>
                                </NavLink>
                            </Row>
                        </>
                     :
                    (<div/>)}


                {/* ********************** UNDER MENU ********************************** */}

                <Row className="row-und-menu">
                    <NavLink className="und-menu" to="/assistance/accessibilite">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('151')}</h5>
                        </NavLink>
                </Row>

                <Row className="row-und-menu">
                    <NavLink className="und-menu" to="/assistance/faqS">
                        <img src={arrow} width={30} height={30}/>
                        <h5 style={{marginLeft : "10px",marginTop : "2px",
                            marginBottom: "0", color : "#0C4EFC"}}>{t('152')}</h5>
                    </NavLink>
                </Row>


                <Row>
                    <Search placeholder={t('292')}
                            enterButton onSearch={onSearch}/>
                </Row>

                <Row style={{margin :"0", marginTop : "20px"}}>

                    <Col xs = {6} style={{padding : "0"}}>
                            <Link to={"/assistance/faqS/conditionjeu"}>
                                <Card.Grid style={gridStyle}>
                                    <img className="assist-img" src={condition}/>
                                    <label className="title-faq">{t('285')}</label>
                                </Card.Grid>
                            </Link>
                    </Col>

                    <Col xs = {6} style={{padding : "0"}}>

                        <Link to={"/assistance/faqS/lejeu"}>
                            <Card.Grid style={gridStyle}>
                                <img className="assist-img" src={jeu}/>
                                <label className="title-faq">{t('286')}</label>
                            </Card.Grid>
                        </Link>
                    </Col>
                </Row>
                <Row style={{margin :"0"}}>

                    <Col xs = {6} style={{padding : "0"}}>
                            <Link to={"/assistance/faqS/letirage"}>
                                <Card.Grid style={gridStyle}>
                                    <img className="assist-img" src={tirage}/>
                                    <label className="title-faq">{t('287')}</label>
                                </Card.Grid>
                            </Link>
                    </Col>

                    <Col xs = {6} style={{padding : "0"}}>

                            <Link to={"/assistance/faqS/lesgains"}>
                                <Card.Grid style={gridStyle}>
                                    <img className="assist-img" src={argent}/>
                                    <label className="title-faq">{t('288')}</label>
                                </Card.Grid>
                            </Link>
                    </Col>

                </Row>
                <Row style={{margin :"0"}}>
                    <Col xs = {6} style={{padding : "0"}}>
                            <Link to={"/assistance/faqS/securite"}>
                                <Card.Grid style={gridStyle}>
                                    <img className="assist-img" src={cadenas}/>
                                    <label className="title-faq">{t('289')}</label>
                                </Card.Grid>
                            </Link>
                    </Col>

                </Row>



                <Row className="row-und-menu">
                    <NavLink className="und-menu" to="/assistance">
                        <img src={arrow_bottom} width={30} height={30}/>
                        <h5 style={{marginLeft : "10px",marginTop : "2px",
                            marginBottom: "0", color : "#0C4EFC"}}>{t('55')}</h5>
                    </NavLink>
                </Row>
            </Container>
        )

    }
}

export default Faq_s;
