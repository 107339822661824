import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import './MesNotifications.css'
import {Select, Switch} from "antd";
import VerticalNavbar2 from "../components/VerticalNavbar2";
import {FormatDate, IsLoggedIn} from "../Helpers";
import HeaderNavbarDashboard from "../components/HeaderNavbarDashboard";
import {NavLink} from "react-router-dom";
import arrow_bottom from "../Images/arrow-bottom.png";
import arrow from "../Images/arrow-right.png";
import axios from "axios";
import {FaBell, GoAlert} from "react-icons/all";
import {withTranslation} from "react-i18next";
import ReactHtmlParser from "react-html-parser";



const { Option } = Select;

class MesNotifications extends Component {
    constructor() {
        super();
        this.state = {
            notifications:[]
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        if (window.NotifNumber>0){
            axios.post('/api/notifications')
                .then(res=>{
                    window.NotifNumber = 0
                    axios.get('/api/notifications')
                        .then(res=>{
                            console.log("Tous les notifs : " , res.data);
                            this.setState({
                                notifications: res.data,
                            });
                        })
                        .catch(err =>{
                            console.log(err.data)
                        });
                })
                .catch(err =>{
                    console.log(err.data)
                });
        }
        else {
            axios.get('/api/notifications')
                .then(res=>{
                    console.log("Tous les notifs : " , res.data);
                    this.setState({
                        notifications: res.data,
                    });
                })
                .catch(err =>{
                    console.log(err.data)
                });
        }

    }

    render() {
        let width = window.innerWidth

        const {t} = this.props
        if(width > 768) {
            return (
                <Container fluid>
                    <Row style={{padding: "0 100px"}}>
                        {IsLoggedIn() ?
                            (
                                <Col>
                                    <HeaderNavbarDashboard/>
                                </Col>
                            ) :
                            (<div/>)}
                    </Row>
                    <Row className="notif">
                        {IsLoggedIn() ?
                            (
                                <Col md={2}>
                                    <VerticalNavbar2/>
                                </Col>
                            ) :
                            (<div/>)}
                        <Col style={{marginTop: "20px", marginLeft: "100px"}}>
                            <Row style={{marginBottom: "30px"}}>
                                <Col>
                                    <h4>{t('145')}</h4>
                                </Col>
                            </Row>
                            {this.state.notifications.length>0 ? this.state.notifications.map((row) => (
                                <Row className="rows">
                                    <Col className="vertic">
                                        <label className="date">{FormatDate(row.date)}</label>
                                        <label className="msg">{ReactHtmlParser(row.message)}</label>
                                    </Col>
                                </Row>
                            ))
                                :
                                <div></div>}

                        </Col>
                    </Row>

                </Container>

            );
        }

        //************************* MOBILE VERS **************************
        else {
            return (
                <Container className ="pad-mob">
                    <Row style={{margin : "0 10px"}}>
                        {/* // ***************** MENU MON COMPTE NAVBAR *************** */}

                                <NavLink className="column col" style={{borderLeft:"5px solid white",
                                    borderRadius: "30px 30px 0 0"}}
                                         to={"/moncompte"} activeClassName="selected">
                                    <h5 style={{color : "#FFF"}}>{t('63')}</h5>
                                </NavLink>

                    </Row>


                    {/* ********************** UNDER MENU ********************************** */}
                    <Row className="row-und-menu" style={{margin : "30px 15px 0px", borderBottom: "2px solid #DFE7F5",
                        paddingBottom: "5px"}}>
                        <NavLink className="und-menu"
                                 to="/moncompte" exact>
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                            marginBottom: "0", color : "#0C4EFC", width:"100%"}}>
                                            <label>{t('144')}</label>
                                            { window.pieceValidation ? <GoAlert style={{float:"right",
                                                color : "#FFC200", height : "30px", width: "30px"}}/> : <></>
                                            }
                                        </h5>
                        </NavLink>
                    </Row>
                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/notifications">
                            <img src={arrow} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",width:"100%",
                                marginBottom: "0", color : "#0C4EFC"}}><label>{t('145')}</label>

                            {window.NotifNumber >0 ?<><FaBell style={{float:"right",
                                color : "#FF4141", height : "30px", width: "30px"}}/>
                                <label className={"bell-number"}>{window.NotifNumber}</label></> : <></>}
                            </h5>
                        </NavLink>
                    </Row>


                    <Row style={{margin : "0", marginTop : "30px"}}>
                        <Col>
                            <Row style={{marginBottom: "30px"}}>
                                <Col>
                                    <h4>{t('145')}</h4>
                                </Col>
                            </Row>
                            {this.state.notifications.length>0 ? this.state.notifications.map((row) => (
                                <Row className="rows">
                                    <Col className="vertic">
                                        <label className="date">{FormatDate(row.date)}</label>
                                        <label className="msg">{row.message}</label>
                                    </Col>
                                </Row>
                            )) : <></>}
                        </Col>
                    </Row>
                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/mescredits">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('146')}</h5>
                        </NavLink>
                    </Row>


                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parametres">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('148')}</h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parametres_jeu">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('149')}</h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parrainage">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('150')}</h5>
                        </NavLink>
                    </Row>

                    {/* ************************* MAIN MENU SUITE ******************************   */}
                    <Row style={{margin : "30px 10px 0"}}>
                        <NavLink className="column col"
                                 to={"/Tirage"} activeClassName="selected">
                            <h5 style={{color : "#FFF"}}>{t('64')}</h5>
                        </NavLink>

                    </Row>

                    <Row style={{margin : "0 10px"}}>
                        <NavLink className="column col" style={{borderRadius: "0 0 30px 30px"}}
                                 to={"/assistance"} activeClassName="selected">
                            <h5 style={{color : "#FFF"}}>{t('65')}</h5>
                        </NavLink>

                    </Row>

                </Container>

            );
        }
    }
}

export default withTranslation()(MesNotifications);
