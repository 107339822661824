import React, {Component, useEffect, useState} from 'react';
import {Col, Container, Row, Spinner} from "react-bootstrap";
import "./Nous_connaitre.css"
import axios from "axios";
import fleche_blanche from "../Images/fleches.png"
import fleche_bleu from "../Images/fleches_bleu.png"
import ReactHtmlParser from 'react-html-parser';



const Concept = () =>{

    let [contenuBleu, SetContenuBleu] = useState("")
    let [contenuBleu_gauche, SetContenuBleu_gauche] = useState("")
    let [contenuBleu_d_1, SetContenuBleu_d_1] = useState("")
    let [contenuBleu_d_2, SetContenuBleu_d_2] = useState("")
    let [contenuBleu_d_3, SetContenuBleu_d_3] = useState("")
    let [contenuBleu_d_4, SetContenuBleu_d_4] = useState("")
    let [contenuBleu_d_5, SetContenuBleu_d_5] = useState("")
    let [contenuBas_u_t, SetContenuBas_u_t] = useState("")
    let [contenuBas_1, SetContenuBas_1] = useState("")
    let [contenuBas_2, SetContenuBas_2] = useState("")
    let [contenuBas_3, SetContenuBas_3] = useState("")
    let [contenuBas_4, SetContenuBas_4] = useState("")
    let [contenuBas_5, SetContenuBas_5] = useState("")
    let [contenuHaut, SetContenuHaut] = useState("")
    let [contenuBas, SetContenuBas] = useState("")
    let [loading, SetLoading] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/18')
            .then((res) => {
                console.log(res.data.contenu_page)
                // SetLoading(false)
                SetContenuBleu(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })

        axios.get('/api/pages/17')
            .then((res) => {
                console.log(res.data.contenu_page)
                // SetLoading(false)
                SetContenuHaut(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/19')
            .then((res) => {
                console.log(res.data.contenu_page)
                // SetLoading(false)
                SetContenuBas(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/35')
            .then((res) => {
                console.log(res.data.contenu_page)
                // SetLoading(false)
                SetContenuBleu_gauche(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/36')
            .then((res) => {
                console.log(res.data.contenu_page)
                // SetLoading(false)
                SetContenuBleu_d_1(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/37')
            .then((res) => {
                console.log(res.data.contenu_page)
                // SetLoading(false)
                SetContenuBleu_d_2(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/38')
            .then((res) => {
                console.log(res.data.contenu_page)
                // SetLoading(false)
                SetContenuBleu_d_3(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/39')
            .then((res) => {
                console.log(res.data.contenu_page)
                // SetLoading(false)
                SetContenuBleu_d_4(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/40')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenuBleu_d_5(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/41')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenuBas_u_t(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/42')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenuBas_1(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/43')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenuBas_2(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/44')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenuBas_3(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/45')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenuBas_4(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/46')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenuBas_5(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
    }, [])

    //---------------------------------------- DESKTOP VERS ---------------------------------------------

    return(
        <div className="nous-connaitre">
            {loading ? <div className="spinner-center"><Spinner animation="border" role="status" variant="primary"/> </div>:
                <Container fluid style={{padding :"0"}}>
                    <div style={{textAlign: "center", marginBottom: "50px"}} >
                        <h4 className="titre-35px">Concept</h4>
                    </div>
                    <Row className="concept" style={{marginBottom : "50px"}}>
                        <Col className="concept-haut">
                        { ReactHtmlParser(contenuHaut) }
                        </Col>
                    </Row>
                    <Row className="concept concept-bleu">
                        <Col md={"5"} className={"concept-bleu-g"}>
                            { ReactHtmlParser(contenuBleu_gauche) }
                        </Col>
                        <div style={{width : "4%"}}>
                        </div>
                        <Col>
                            {/* ***** 1 *****/}
                            <Row  className={"concept-bleu-d"}>
                                <Col md={"1"} xs={2}>
                                    <img src={fleche_blanche} />
                                </Col>
                                <Col style={{paddingLeft:"0"}}>
                                    { ReactHtmlParser(contenuBleu_d_1) }
                                </Col>
                            </Row>
                            {/* ***** 2 *****/}
                            <Row  className={"concept-bleu-d"}>
                                <Col md={"1"} xs={2}>
                                    <img src={fleche_blanche} />
                                </Col>
                                <Col style={{paddingLeft:"0"}}>
                                    { ReactHtmlParser(contenuBleu_d_2) }
                                </Col>
                            </Row>
                            {/* ****** 3 *****/}
                            <Row  className={"concept-bleu-d"}>
                                <Col md={"1"} xs={2}>
                                    <img src={fleche_blanche} />
                                </Col>
                                <Col style={{paddingLeft:"0"}}>
                                    { ReactHtmlParser(contenuBleu_d_3) }
                                </Col>
                            </Row>
                            {/* ****** 4 *****/}
                            <Row  className={"concept-bleu-d"}>
                                <Col md={"1"} xs={2}>
                                    <img src={fleche_blanche} />
                                </Col>
                                <Col style={{paddingLeft:"0"}}>
                                    { ReactHtmlParser(contenuBleu_d_4) }
                                </Col>
                            </Row>
                            {/* ****** 5 *****/}
                            <Row  className={"concept-bleu-d"}>
                                <Col md={"1"} xs={2}>
                                    <img src={fleche_blanche} />
                                </Col>
                                <Col style={{paddingLeft:"0"}}>
                                    { ReactHtmlParser(contenuBleu_d_5) }
                                </Col>
                            </Row>
                            {/* ****** bas *****/}
                            <Row  className={"concept-bleu-d"}>
                                <Col md={"1"}>
                                </Col>
                                <Col className={"concept-beu-d-b"} style={{paddingLeft:"0"}}>
                                    { ReactHtmlParser(contenuBleu) }
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <Row className="concept concept-b-title">
                        <Col style={{textAlign: "center"}}>
                        <h4 className={"txt-30px title-conc"} style={{textDecoration:"underline"}}>
                        Mais comment fonctionne-t-on alors ? C’est quoi concrètement ce concept ?
                        </h4>
                        {/* <span className={"concept concept-b-u-t"}>
                            Notre loterie, qui n’est pas une loterie, est très simple à comprendre.
                        </span> */}
                        </Col>
                    </Row>
                    <Row className={"concept concept-b-txt-u-t concept-haut"}>
                        <Col>
                        { ReactHtmlParser(contenuBas_u_t) }
                        </Col>
                    </Row>

                    {/* *****************  partie bas  *************** */}
                    <Row style={{marginTop : "60px"}} className="concept">
                        <Col>
                            {/* ***** 1 *****/}
                            <Row  className={"concept-beu-b"}>
                                <div style={{marginRight:"20px"}}>
                                    <img src={fleche_bleu} />
                                </div>
                                <Col style={{paddingLeft:"0"}}>
                                    { ReactHtmlParser(contenuBas_1) }
                                </Col>
                            </Row>

                            {/* ***** 2 *****/}
                            <Row  className={"concept-beu-b"}>
                                <div style={{marginRight:"20px"}}>
                                    <img src={fleche_bleu} />
                                </div>
                                <Col style={{paddingLeft:"0"}}>
                                    { ReactHtmlParser(contenuBas_2) }
                                </Col>
                            </Row>

                            {/* ***** 3 *****/}
                            <Row  className={"concept-beu-b"}>
                                <div style={{marginRight:"20px"}}>
                                    <img src={fleche_bleu} />
                                </div>
                                <Col style={{paddingLeft:"0"}}>
                                    { ReactHtmlParser(contenuBas_3) }
                                </Col>
                            </Row>

                            {/* ***** 4 *****/}
                            <Row  className={"concept-beu-b"}>
                                <div style={{marginRight:"20px"}}>
                                    <img src={fleche_bleu} />
                                </div>
                                <Col style={{paddingLeft:"0"}}>
                                    { ReactHtmlParser(contenuBas_4) }
                                </Col>
                            </Row>

                            {/* ***** 5 *****/}
                            <Row  className={"concept-beu-b"}>
                                <div style={{marginRight:"20px"}}>
                                    <img src={fleche_bleu} />
                                </div>
                                <Col style={{paddingLeft:"0"}}>
                                    { ReactHtmlParser(contenuBas_5) }
                                </Col>
                            </Row>

                        </Col>

                    </Row>

                    <Row style={{marginTop : "30px"}} className="concept concept-haut">
                        <Col>
                        { ReactHtmlParser(contenuBas) }
                        </Col>
                    </Row>
                </Container>
            }

        </div>
    )



}

export default Concept;
