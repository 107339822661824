import React, {Component, useMemo} from 'react'
import {checkFileSize, checkMimeType, checkPDFType, maxSelectFile} from "../Controls/FilesErrors";
import axios from "axios";
import './Register.css'
import {Container} from "react-bootstrap";
import {Avatar, Tooltip, Dropdown, Menu, notification, message, DatePicker} from "antd";
import Popover from "antd/es/popover";
import {FaAngleRight, AiOutlinePlus,IoIosCloseCircleOutline} from "react-icons/all";
import Avatar1 from "../Images/Avatars/Avatar1.png"
import Avatar2 from "../Images/Avatars/Avatar2.png"
import Avatar3 from "../Images/Avatars/Avatar3.png"
import Avatar4 from "../Images/Avatars/Avatar4.png"
import Avatar5 from "../Images/Avatars/Avatar5.png"
import Avatar6 from "../Images/Avatars/Avatar6.png"
import Avatar7 from "../Images/Avatars/Avatar7.png"
import Avatar8 from "../Images/Avatars/Avatar8.png"
import Avatar9 from "../Images/Avatars/Avatar9.png"
import Avatar10 from "../Images/Avatars/Avatar10.png"
import Avatar11 from "../Images/Avatars/Avatar11.png"
import Avatar12 from "../Images/Avatars/Avatar12.png"
import Avatar13 from "../Images/Avatars/Avatar13.png"
import Avatar14 from "../Images/Avatars/Avatar14.png"
import Avatar15 from "../Images/Avatars/Avatar15.png"
import Avatar16 from "../Images/Avatars/Avatar16.png"
import Avatar17 from "../Images/Avatars/Avatar17.png"
import Avatar18 from "../Images/Avatars/Avatar18.png"
import Avatar19 from "../Images/Avatars/Avatar19.png"
import Avatar20 from "../Images/Avatars/Avatar20.png"
import Avatar21 from "../Images/Avatars/Avatar21.png"
import Avatar22 from "../Images/Avatars/Avatar22.png"
import Avatar23 from "../Images/Avatars/Avatar23.png"
import Avatar24 from "../Images/Avatars/Avatar24.png"
import Avatar25 from "../Images/Avatars/Avatar25.png"
import Avatar26 from "../Images/Avatars/Avatar26.png"
import Avatar27 from "../Images/Avatars/Avatar27.png"
import Avatar28 from "../Images/Avatars/Avatar28.png"
import Avatar29 from "../Images/Avatars/Avatar29.png"
import Avatar30 from "../Images/Avatars/Avatar30.png"

import defaultAvatar from "../Images/Avatars/DeafultAvatar.png"
import frenchFlag from '../Images/french-flag.png';
import SpainFlag from '../Images/Spain_flag.png';
import BritainFlag from '../Images/Flag_Britain.png';
import ItalyFlag from '../Images/italy.png';
import info from "../Images/info.png"
import {GoAlert} from "react-icons/all"
import countryList from 'react-select-country-list'
import { Select } from 'antd';
import {withTranslation} from "react-i18next";
import PhoneInput,{isValidPhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import {City }  from 'country-state-city';


const { Option } = Select;





class Register extends Component {
    constructor(props) {
        super(props)
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            nom_marital:'',
            gender: '',
            date_naissance:'',
            ville_naissance:'',
            ville_residence:'',
            pays_residence:'',
            pseudonyme:'',
            langue_pref :'fr',
            num_tele :'',
            code_parrainage : '',
            errorPays : false,
            errorVilleResidence : false,
            errorPhone : false,
            uniquePseudo : false,
            ErrorAge : false,
            errors: {
                fullName: '',
                email: '',
                password: '',
                confirm_password:'',
                image: '',
                confirm_email:'',
                piece: ''
            },
            ImageFile : null,
            PieceFile : null,
            PieceFileName : '',
            loaded : 0,
            //errors: {}
            imgSrc : '',
            CapchaIsVerified: false,
            country : countryList().getData(),
            pieceRecto : "",
            pieceVerso : "",
            visiblepop: false,
            newsletters: false,
            notifJeu: false,
            avatarChoice:false,
            ville_residenceErr : false,
            ville_naissanceErr : false,
            cities : [],
            AllCities : []

        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.PictureOnChange = this.PictureOnChange.bind(this);
        this.PieceOnChange = this.PieceOnChange.bind(this);
        this.getAvatarSrc = this.getAvatarSrc.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeDateChange = this.onChangeDateChange.bind(this);
        this.onChangeNewsLetters = this.onChangeNewsLetters.bind(this);


    }

    componentDidMount() {
        /*let liste = City.getAllCities()
        this.setState({
            AllCities : liste
        })*/
    }



    avatars=(
        <div>
            <div>
                <img src={Avatar1} width={"60px"} className="avatar" id={"avatar1"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar2} width={"60px"} className="avatar" id={"avatar2"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar3} width={"60px"} className="avatar" id={"avatar3"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar4} width={"60px"} className="avatar" id={"avatar4"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar5} width={"60px"} className="avatar" id={"avatar5"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar21} width={"60px"} className="avatar" id={"avatar21"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            </div>
            <div style={{marginTop:"15px"}}>
                <img src={Avatar6} width={"60px"} className="avatar" id={"avatar6"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar7} width={"60px"} className="avatar" id={"avatar7"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar8} width={"60px"} className="avatar" id={"avatar8"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar9} width={"60px"} className="avatar" id={"avatar9"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar10} width={"60px"} className="avatar" id={"avatar10"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar22} width={"60px"} className="avatar" id={"avatar22"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                     </div>
                     <div style={{marginTop:"15px"}}>
                <img src={Avatar11} width={"60px"} className="avatar" id={"avatar11"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar12} width={"60px"} className="avatar" id={"avatar12"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar13} width={"60px"} className="avatar" id={"avatar13"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar14} width={"60px"} className="avatar" id={"avatar14"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar15} width={"60px"} className="avatar" id={"avatar15"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar23} width={"60px"} className="avatar" id={"avatar23"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                     </div>
                     <div style={{marginTop:"15px"}}>
                <img src={Avatar16} width={"60px"} className="avatar" id={"avatar16"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar17} width={"60px"} className="avatar" id={"avatar17"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar18} width={"60px"} className="avatar" id={"avatar18"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar19} width={"60px"} className="avatar" id={"avatar19"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar20} width={"60px"} className="avatar" id={"avatar20"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar24} width={"60px"} className="avatar" id={"avatar24"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            </div>
            <div style={{marginTop:"15px"}}>
                <img src={Avatar25} width={"60px"} className="avatar" id={"avatar25"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar26} width={"60px"} className="avatar" id={"avatar26"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar27} width={"60px"} className="avatar" id={"avatar27"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar28} width={"60px"} className="avatar" id={"avatar28"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar29} width={"60px"} className="avatar" id={"avatar29"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar30} width={"60px"} className="avatar" id={"avatar"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                </div>

        </div>
    )
    avatars_mobile=(
        <div>
            <div>
                <img src={Avatar1} width={"60px"} className="avatar" id={"avatar1"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar2} width={"60px"} className="avatar" id={"avatar2"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar3} width={"60px"} className="avatar" id={"avatar3"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar4} width={"60px"} className="avatar" id={"avatar4"} crossOrigin={"anonymous"}
                     alt={"avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                
            </div>
            <div style={{marginTop:"15px"}}>
                <img src={Avatar6} width={"60px"} className="avatar" id={"avatar6"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar7} width={"60px"} className="avatar" id={"avatar7"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar8} width={"60px"} className="avatar" id={"avatar8"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar9} width={"60px"} className="avatar" id={"avatar9"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                
                     </div>
                     <div style={{marginTop:"15px"}}>
                <img src={Avatar11} width={"60px"} className="avatar" id={"avatar11"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar12} width={"60px"} className="avatar" id={"avatar12"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar13} width={"60px"} className="avatar" id={"avatar13"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar14} width={"60px"} className="avatar" id={"avatar14"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                </div>
                     <div style={{marginTop:"15px"}}>
                <img src={Avatar16} width={"60px"} className="avatar" id={"avatar16"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar17} width={"60px"} className="avatar" id={"avatar17"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar18} width={"60px"} className="avatar" id={"avatar18"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                <img src={Avatar19} width={"60px"} className="avatar" id={"avatar19"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                </div>
            <div style={{marginTop:"15px"}}>
            <img src={Avatar10} width={"60px"} className="avatar" id={"avatar10"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar5} width={"60px"} className="avatar" id={"avatar5"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar15} width={"60px"} className="avatar" id={"avatar15"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar20} width={"60px"} className="avatar" id={"avatar20"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
                     
            </div>

            <div style={{marginTop:"15px"}}>
            <img src={Avatar21} width={"60px"} className="avatar" id={"avatar21"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar22} width={"60px"} className="avatar" id={"avatar22"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar23} width={"60px"} className="avatar" id={"avatar23"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar24} width={"60px"} className="avatar" id={"avatar24"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            </div>
            <div style={{marginTop:"15px"}}>
            <img src={Avatar25} width={"60px"} className="avatar" id={"avatar25"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar26} width={"60px"} className="avatar" id={"avatar26"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar27} width={"60px"} className="avatar" id={"avatar27"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar28} width={"60px"} className="avatar" id={"avatar28"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
        </div>
        <div style={{marginTop:"15px"}}>
            <img src={Avatar29} width={"60px"} className="avatar" id={"avatar29"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
            <img src={Avatar30} width={"60px"} className="avatar" id={"avatar30"} crossOrigin={"anonymous"}
                     alt={"Avatar"} onClick={(e)=> this.getAvatarSrc(e)}/>
           </div>
           </div>
    )

    FlagsMenu = (
        <Menu>
            <Menu.Item>
                    <img src={SpainFlag} width={30} height={25} title={"es"} onClick={(e)=> this.getLangue(e)}/>
            </Menu.Item>
            <Menu.Item>
            <img src={BritainFlag} title={"en"} width={30} height={25} onClick={(e)=> this.getLangue(e)}/>
        </Menu.Item>
            <Menu.Item>
            <img src={ItalyFlag} title={"it"} width={30} height={25} onClick={(e)=> this.getLangue(e)}/>
            </Menu.Item>

        </Menu>
    );

    hide = () => {
        this.setState({
            avatarChoice: false,
        });
    };
    /*fetchUserList(name) {
        console.log('fetching user', name);
        if(this.state.cities){
            let liste = this.state.cities.filter(e=>(name === e.name));
            if(liste.length>0)
            {
                return liste.map((city) => ({
                    label: city.name,
                    value: city.name,
                }))
            }else return [{label : '', value : ''}]

        }
        else
            return []
    }
*/
    handleVisibleChangeMobile = avatarChoice => {
        this.setState({ avatarChoice });
    };

    getLangue(value){
        this.setState({langue_pref: value})
    }
    setValuePhone(value){
        this.setState({num_tele: value})
    }
    onChangeCountry(value) {
        console.log(value)
        console.log()
        let liste = City.getCitiesOfCountry(countryList().getValue(value))
        let finalListe =[]
        liste.map(e=>{
            finalListe.push({
                value : e.name,
                label : e.name
            });
        })
        this.setState({
            cities : finalListe
        })

        // this.setState({cities : City.getCitiesOfCountry(countryList().getValue(value))})
        this.setState({ pays_residence : value})
    }

    onChangeVilleResidence(value) {
        console.log(value)
        this.setState({ ville_residence : value})
    }
    onChangeVilleNaissance(value) {
        console.log(value)
        this.setState({ ville_naissance : value})
    }

    deleteFile(v){
        let errors = this.state.errors;
        errors.piece =""
        if(v==="R"){
            this.setState({pieceRecto : ""})
        }
        else {
            this.setState({pieceVerso : ""})
        }
    }
    onChange(e) {
        //this.setState({ [e.target.name]: e.target.value })

        const { name, value } = e.target;
        let errors = this.state.errors;

        switch (name) {
            /*case 'fullName':
                errors.fullName =
                    value.length < 5
                        ? 'Full Name must be 5 characters long!'
                        : '';
                break;*/
            case 'email':
                errors.email =
                    validEmailRegex.test(value)
                        ? ''
                        : this.props.t('126');
                break;
            case 'confirm_email':
                errors.confirm_email =
                    value === this.state.email
                        ? ''
                        : this.props.t('127');
                break;
            /*case 'password':
                errors.password =
                    value.length < 8
                        ? 'Password must be 8 characters long!'
                        : '';
                break;*/
            case 'confirm_password':
                errors.confirm_password =
                    value === this.state.password
                        ? ''
                        : this.props.t('128');
                break;

            default:
                break;
        }

        this.setState({errors, [name]: value})
    }

    onChangeNewsLetters(){
        this.setState({newsletters : !this.state.newsletters})
    }
    onChangeNotifJeu(){
        console.log(!this.state.notifJeu)
        this.setState({notifJeu : !this.state.notifJeu})
    }
    onChangeDateChange(date, dateString) {
        this.setState({date_naissance : dateString})
    }

    handleVisibleChange = visiblepop => {
        this.setState({ visiblepop });
    };
    /**
     * to get avatar src
     * @param img
     */
    imgToBase64(img) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 500;
        canvas.height = 500;

        // I think this won't work inside the function from the console
        //img.crossOrigin = 'anonymous';

        ctx.drawImage(img, 0, 0);

        return canvas.toDataURL();
    }

    getAvatarSrc(e){

        let result = this.imgToBase64(document.getElementById(e.target.id));
        let file = this.dataURLtoFile(result, 'avatar.png');
        this.setState({
            imgSrc: e.target.src,
            ImageFile: file,
            loaded : 1,
            visiblepop : false
        })
        this.hide()
    }

    /**
     * checking and saving image in state
     * @param event
     * @constructor
     */
    PictureOnChange= event =>{
        let file = event.target.files[0];
        console.log("filllle",file)
        
        //********************************************
        let errors = this.state.errors;
        if(checkFileSize(event) ){
            if (maxSelectFile(event)){
                if (checkMimeType(event) ){
                    console.log('okeeey')
                    // ******* have a preview on the image before loading  *******
        let reader = new FileReader();
        let url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            console.log(e.target.result)
            this.setState({
                imgSrc: e.target.result
        })
        }.bind(this);
                    // if return true allow to setState 
                    this.setState({
                        ImageFile: event.target.files[0],
                        loaded: 1,
                    })
                    errors.image=""
                }
                else{
                    errors.image = 'Formats acceptés : PNG et JPG '
                }

            }
            else{
                errors.image = 'Vous devez selectionner une seule image'

            }
        }
        else{
            errors.image = "l'image doit etre inferieure à 2 Mb"
        }
        this.setState({errors})
    }

    /**
     * checking and saving piece in state
     * @param event
     * @constructor
     */
    PieceOnChange= event =>{
        // Adding File to State
        let errors = this.state.errors;
        if(checkFileSize(event) ){
            if (maxSelectFile(event)){
                if (checkPDFType(event) ){
                    switch (event.target.name) {
                        case "pieceRecto" :
                            this.setState({pieceRecto : event.target.files[0]})
                            break;
                        case "pieceVerso" :
                            this.setState({pieceVerso : event.target.files[0]})
                            break
                    }
                    errors.piece=""
                }
                else{
                    errors.piece = "Formats acceptés : PNG, JPG et PDF"

                }

            }
            else{
                errors.piece = "Vous devez selectionner une seule piece d'identité"

            }
        }
        else{
            errors.piece = "le document doit etre inferieure à 2 Mb"
        }
        this.setState({errors})
    }


    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    validate(){
        let error = false
        if(this.state.ville_residence === ''){
            this.setState({ville_residenceErr: true})
            error = true
            document.getElementById('ville_residence').scrollIntoView()
            return error
        }
        else{
            this.setState({ville_residenceErr: false})
            error = false
        }

        if(this.state.ville_naissance === ''){
            this.setState({ville_naissanceErr: true})
            error = true
            document.getElementById('ville_naissance').scrollIntoView()
            return error
        }
        else{
            this.setState({ville_naissanceErr: false})
            error = false
        }


        return error
    }
    async onSubmit(e) {
        e.preventDefault();
        let valid = this.validate()
        if(valid === false){
            if(validateForm(this.state.errors) ) {
                if (this.state.pays_residence.length === 0){
                    this.setState({errorPays : true})
                }
                else{
                    this.setState({errorPays : false})
                    if (this.state.num_tele === undefined ) {
                        this.setState({num_tele : ''})
                    }
                    if (!underAgeValidate(this.state.date_naissance)) {
                        this.setState({ErrorAge : false})
                        // **********************  verifier si le pseudonyme est unique *********************
                        await axios.post('api/users/register/checkUsername', {pseudonyme : this.state.pseudonyme})
                            .then(async r => {
                                if (r.data) this.setState({uniquePseudo : true});
                                else {
                                    this.setState({uniquePseudo : false});

                                    // *************** send data ********************
                                    let file
                                    if (this.state.loaded === 0) {
                                        console.log("loaaaaaded : ",this.state.loaded )
                                        let result = await this.imgToBase64(document.getElementById("avatar"));
                                        file = this.dataURLtoFile(result, 'avatar.png');
                                        this.setState({
                                            ImageFile: file,
                                        })
                                        message
                                            .loading(this.props.t('117'), 3.5)
                                        if(this.state.pieceRecto && this.state.pieceVerso){
                                            const data = new FormData();
                                        data.append('first_name', this.state.first_name);
                                        data.append('last_name', this.state.last_name);
                                        data.append('email', this.state.email);
                                        data.append('password', this.state.password);
                                        data.append('gender', this.state.gender);
                                        data.append('nom_marital', this.state.nom_marital);
                                        data.append('image', file);
                                        data.append('pieceRecto', this.state.pieceRecto);
                                        data.append('pieceVerso', this.state.pieceVerso);
                                        data.append('date_naissance', this.state.date_naissance);
                                        data.append('ville_naissance', this.state.ville_naissance);
                                        data.append('ville_residence', this.state.ville_residence);
                                        data.append('pays_residence', this.state.pays_residence);
                                        data.append('pseudonyme', this.state.pseudonyme);
                                        data.append('langue_pref', this.state.langue_pref);
                                        data.append('num_tele', this.state.num_tele);
                                        data.append('code_parrainage', this.state.code_parrainage);
                                        data.append('newsletters', this.state.newsletters);
                                        data.append('notifJeu', this.state.notifJeu);
                                        axios.post('api/users/register', data,{
                                            headers: {
                                                'Content-Type': 'multipart/form-data'
                                            }})
                                            .then(response => {
                                                console.log(response)
                                                message.success(this.props.t('115'), 3.5)
                                                    .then(() => this.props.history.push(`/login`))
                                            })
                                            .catch((response) =>{
                                                console.log(response)
                                                message.error(this.props.t('116'), 3.5)
                                            })
                                        }
                                        else if(this.state.pieceRecto){
                                            const data = new FormData();
                                        data.append('first_name', this.state.first_name);
                                        data.append('last_name', this.state.last_name);
                                        data.append('email', this.state.email);
                                        data.append('password', this.state.password);
                                        data.append('gender', this.state.gender);
                                        data.append('nom_marital', this.state.nom_marital);
                                        data.append('image', file);
                                        data.append('pieceRecto', this.state.pieceRecto);
                                        data.append('date_naissance', this.state.date_naissance);
                                        data.append('ville_naissance', this.state.ville_naissance);
                                        data.append('ville_residence', this.state.ville_residence);
                                        data.append('pays_residence', this.state.pays_residence);
                                        data.append('pseudonyme', this.state.pseudonyme);
                                        data.append('langue_pref', this.state.langue_pref);
                                        data.append('num_tele', this.state.num_tele);
                                        data.append('code_parrainage', this.state.code_parrainage);
                                        data.append('newsletters', this.state.newsletters);
                                        data.append('notifJeu', this.state.notifJeu);
                                        axios.post('api/users/register', data,{
                                            headers: {
                                                'Content-Type': 'multipart/form-data'
                                            }})
                                            .then(response => {
                                                message.success(this.props.t('115'), 3.5)
                                                    .then(() => this.props.history.push(`/login`))
                                            })
                                            .catch(() =>{
                                                message.error(this.props.t('116'), 3.5)
                                            })
                                        }
                                        else if(this.state.pieceVerso){
                                            const data = new FormData();
                                        data.append('first_name', this.state.first_name);
                                        data.append('last_name', this.state.last_name);
                                        data.append('email', this.state.email);
                                        data.append('password', this.state.password);
                                        data.append('gender', this.state.gender);
                                        data.append('nom_marital', this.state.nom_marital);
                                        data.append('image', file);
                                        data.append('pieceVerso', this.state.pieceVerso);
                                        data.append('date_naissance', this.state.date_naissance);
                                        data.append('ville_naissance', this.state.ville_naissance);
                                        data.append('ville_residence', this.state.ville_residence);
                                        data.append('pays_residence', this.state.pays_residence);
                                        data.append('pseudonyme', this.state.pseudonyme);
                                        data.append('langue_pref', this.state.langue_pref);
                                        data.append('num_tele', this.state.num_tele);
                                        data.append('code_parrainage', this.state.code_parrainage);
                                        data.append('newsletters', this.state.newsletters);
                                        data.append('notifJeu', this.state.notifJeu);
                                        axios.post('api/users/register', data,{
                                            headers: {
                                                'Content-Type': 'multipart/form-data'
                                            }})
                                            .then(response => {
                                                message.success(this.props.t('115'), 3.5)
                                                    .then(() => this.props.history.push(`/login`))
                                            })
                                            .catch(() =>{
                                                message.error(this.props.t('116'), 3.5)
                                            })
                                        }
                                        // if there is no PI
                                        else {
                                            const data = new FormData();
                                        data.append('first_name', this.state.first_name);
                                        data.append('last_name', this.state.last_name);
                                        data.append('email', this.state.email);
                                        data.append('password', this.state.password);
                                        data.append('gender', this.state.gender);
                                        data.append('nom_marital', this.state.nom_marital);
                                        data.append('image', file);
                                        data.append('date_naissance', this.state.date_naissance);
                                        data.append('ville_naissance', this.state.ville_naissance);
                                        data.append('ville_residence', this.state.ville_residence);
                                        data.append('pays_residence', this.state.pays_residence);
                                        data.append('pseudonyme', this.state.pseudonyme);
                                        data.append('langue_pref', this.state.langue_pref);
                                        data.append('num_tele', this.state.num_tele);
                                        data.append('code_parrainage', this.state.code_parrainage);
                                        data.append('newsletters', this.state.newsletters);
                                        data.append('notifJeu', this.state.notifJeu);
                                        axios.post('api/users/register', data,{
                                            headers: {
                                                'Content-Type': 'multipart/form-data'
                                            }})
                                            .then(response => {
                                                message.success(this.props.t('115'), 3.5)
                                                    .then(() => this.props.history.push(`/login`))
                                            })
                                            .catch(() =>{
                                                message.error(this.props.t('116'), 3.5)
                                            })
                                        }
                                    }
                                    else{
                                        console.log("loaaaaaded : ",this.state.loaded )
                                        message
                                            .loading(this.props.t('117'), 3.5)
                                            if(this.state.pieceRecto && this.state.pieceVerso){
                                                const data = new FormData();
                                            data.append('first_name', this.state.first_name);
                                            data.append('last_name', this.state.last_name);
                                            data.append('email', this.state.email);
                                            data.append('password', this.state.password);
                                            data.append('gender', this.state.gender);
                                            data.append('nom_marital', this.state.nom_marital);
                                            data.append('image', this.state.ImageFile);
                                            data.append('pieceRecto', this.state.pieceRecto);
                                            data.append('pieceVerso', this.state.pieceVerso);
                                            data.append('date_naissance', this.state.date_naissance);
                                            data.append('ville_naissance', this.state.ville_naissance);
                                            data.append('ville_residence', this.state.ville_residence);
                                            data.append('pays_residence', this.state.pays_residence);
                                            data.append('pseudonyme', this.state.pseudonyme);
                                            data.append('langue_pref', this.state.langue_pref);
                                            data.append('num_tele', this.state.num_tele);
                                            data.append('code_parrainage', this.state.code_parrainage);
                                            data.append('newsletters', this.state.newsletters);
                                            data.append('notifJeu', this.state.notifJeu);
                                            axios.post('api/users/register', data,{
                                                headers: {
                                                    'Content-Type': 'multipart/form-data'
                                                }})
                                                .then(response => {
                                                    message.success(this.props.t('115'), 3.5)
                                                        .then(() => this.props.history.push(`/login`))
                                                })
                                                .catch(() =>{
                                                    message.error(this.props.t('116'), 3.5)
                                                })
                                            }
                                            else if(this.state.pieceRecto){
                                                const data = new FormData();
                                            data.append('first_name', this.state.first_name);
                                            data.append('last_name', this.state.last_name);
                                            data.append('email', this.state.email);
                                            data.append('password', this.state.password);
                                            data.append('gender', this.state.gender);
                                            data.append('nom_marital', this.state.nom_marital);
                                            data.append('image', this.state.ImageFile);
                                            data.append('pieceRecto', this.state.pieceRecto);
                                            data.append('date_naissance', this.state.date_naissance);
                                            data.append('ville_naissance', this.state.ville_naissance);
                                            data.append('ville_residence', this.state.ville_residence);
                                            data.append('pays_residence', this.state.pays_residence);
                                            data.append('pseudonyme', this.state.pseudonyme);
                                            data.append('langue_pref', this.state.langue_pref);
                                            data.append('num_tele', this.state.num_tele);
                                            data.append('code_parrainage', this.state.code_parrainage);
                                            data.append('newsletters', this.state.newsletters);
                                            axios.post('api/users/register', data,{
                                                headers: {
                                                    'Content-Type': 'multipart/form-data'
                                                }})
                                                .then(response => {
                                                    message.success(this.props.t('115'), 3.5)
                                                        .then(() => this.props.history.push(`/login`))
                                                })
                                                .catch(() =>{
                                                    message.error(this.props.t('116'), 3.5)
                                                })
                                            }
                                            else if(this.state.pieceVerso){
                                                const data = new FormData();
                                            data.append('first_name', this.state.first_name);
                                            data.append('last_name', this.state.last_name);
                                            data.append('email', this.state.email);
                                            data.append('password', this.state.password);
                                            data.append('gender', this.state.gender);
                                            data.append('nom_marital', this.state.nom_marital);
                                            data.append('image', this.state.ImageFile);
                                            data.append('pieceVerso', this.state.pieceVerso);
                                            data.append('date_naissance', this.state.date_naissance);
                                            data.append('ville_naissance', this.state.ville_naissance);
                                            data.append('ville_residence', this.state.ville_residence);
                                            data.append('pays_residence', this.state.pays_residence);
                                            data.append('pseudonyme', this.state.pseudonyme);
                                            data.append('langue_pref', this.state.langue_pref);
                                            data.append('num_tele', this.state.num_tele);
                                            data.append('code_parrainage', this.state.code_parrainage);
                                            data.append('newsletters', this.state.newsletters);
                                            axios.post('api/users/register', data,{
                                                headers: {
                                                    'Content-Type': 'multipart/form-data'
                                                }})
                                                .then(response => {
                                                    message.success(this.props.t('115'), 3.5)
                                                        .then(() => this.props.history.push(`/login`))
                                                })
                                                .catch(() =>{
                                                    message.error(this.props.t('116'), 3.5)
                                                })
                                            }
                                            // if there is no PI
                                            else {
                                                const data = new FormData();
                                            data.append('first_name', this.state.first_name);
                                            data.append('last_name', this.state.last_name);
                                            data.append('email', this.state.email);
                                            data.append('password', this.state.password);
                                            data.append('gender', this.state.gender);
                                            data.append('nom_marital', this.state.nom_marital);
                                            data.append('image', this.state.ImageFile);
                                            data.append('date_naissance', this.state.date_naissance);
                                            data.append('ville_naissance', this.state.ville_naissance);
                                            data.append('ville_residence', this.state.ville_residence);
                                            data.append('pays_residence', this.state.pays_residence);
                                            data.append('pseudonyme', this.state.pseudonyme);
                                            data.append('langue_pref', this.state.langue_pref);
                                            data.append('num_tele', this.state.num_tele);
                                            data.append('code_parrainage', this.state.code_parrainage);
                                            data.append('newsletters', this.state.newsletters);
                                            axios.post('api/users/register', data,{
                                                headers: {
                                                    'Content-Type': 'multipart/form-data'
                                                }})
                                                .then(response => {
                                                    message.success(this.props.t('115'), 3.5)
                                                        .then(() => this.props.history.push(`/login`))
                                                })
                                                .catch(() =>{
                                                    message.error(this.props.t('116'), 3.5)
                                                })
                                            }
                                    }
                                }
                            })
                    }
                    else {
                        this.setState({ErrorAge : true})
                    }
                }
            }
        }
    }

    render() {
        const {errors} = this.state;
        let width = window.innerWidth;
        const {t} = this.props
        const infoText = <span><p>{t('122')}</p>
    <p>{t('123')}</p>
    <p>{t('124')}</p>
</span>;
        // ********************************** DESKTOP VERS ***************************************

        if (width > 768) {
            return (
                <Container style={{maxWidth: "90vw"}}>
                    <form onSubmit={this.onSubmit} encType="multipart/form-data">
                    <div className="row">
                        <div className="col-md-6 mt-5 mx-auto">
                            <img src={defaultAvatar} width={"60px"} id={"avatar"}
                                 crossOrigin={"anonymous"} style={{visibility : "hidden"}}/>
                                <h3 className="regoingez">{t('82')}</h3>
                                <label style={{color: "#565656",fontSize:"17px !important", fontFamily:"Roboto"}}>
                                    {t('83')}
                                </label>
                                <p style={{color: "#979797",fontSize:"13px",lineHeight: "25px"}}>
                                    {t('84')}</p>
                                <div className="form-group" style={{display:"inline-block"}}>
                                    <label htmlFor="file" className="label-file">{t('85')}</label>
                                    <input id="file" className="input-file"
                                           type="file" name="photo"
                                           onChange={this.PictureOnChange} />
                                    {errors.image.length > 0 &&
                                    <span className='error'>{errors.image}</span>}
                                </div>
                                <div style={{display:"inline-block"}}>
                                    <label className="ml-3 mr-3 label-avatar" >{t('86')}</label>
                                    <label className="label-avatar" >{t('87')}</label>
                                    <Popover placement="right" visible={this.state.visiblepop}
                                             style={{cursor:"pointer"}}
                                             onVisibleChange={this.handleVisibleChange}
                                             content={this.avatars} trigger="click" >
                                        <Avatar size={60}
                                                icon={<FaAngleRight style={{color:"#74869E"}} size={"20px"}/>}
                                                style={{boxShadow: "0px 3px 6px #C5D7FF87",
                                                    backgroundColor:"#FFF",marginLeft:"20px"}}/>
                                    </Popover>
                                </div>
                                {Object.keys(this.state.imgSrc).length !== 0 &&
                                <Avatar id={'img-prof'} src={this.state.imgSrc} size={76} style={{margin:"10px 20px"}}/>}
                                <div className="form-group">
                                    {/*<label htmlFor="name">First name</label>*/}
                                    <input
                                        type="text"
                                        name="first_name"
                                        placeholder={t('88')}
                                        value={this.state.first_name}
                                        onChange={this.onChange}
                                        className="input-text"
                                        required={true}
                                    />
                                </div>
                                {/* ****** Nom marital ****** */}
                                <div className="form-group">
                                    {/*<label htmlFor="name">Last name</label>*/}
                                    <input
                                        type="text"
                                        name="nom_marital"
                                        placeholder={t('89')}
                                        className="input-text"
                                        onChange={this.onChange}
                                    />
                                </div>

                                {/* ****** last name ****** */}
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="last_name"
                                        placeholder={t('90')}
                                        value={this.state.last_name}
                                        onChange={this.onChange}
                                        className="input-text"
                                        required={true}
                                    />
                                </div>

                                {/* ****** Gender ****** */}
                                <div className="form-group" style={{marginBottom : "1.5rem", marginTop:"33px"}}>
                                    <div className={"radio-btn"}>
                                        <input type="radio" value="MALE" name="gender"
                                               required={true} onChange={this.onChange}/>
                                               <lable className={"label-radio"}>{t('91')}</lable>
                                    </div>
                                    <div className={"radio-btn"}>
                                        <input type="radio" value="FEMALE"
                                               name="gender" onChange={this.onChange}/>
                                        <lable className={"label-radio"}>{t('92')}</lable>
                                    </div>
                                    <div className={"radio-btn"}>
                                        <input type="radio" value="Autre" name="gender"
                                               onChange={this.onChange}/>
                                               <lable className={"label-radio"}>{t('93')}</lable>
                                    </div>
                                </div>

                                {/* **** Date de naissance **** */}
                                <div className="form-group">
                                    <DatePicker format={"DD/MM/YYYY"}
                                                placeholder={t('94')}
                                                className={"border-input"}
                                                onChange={this.onChangeDateChange}/>

                                </div>
                            {this.state.ErrorAge && <span className='error'>
                                       {t('95')}</span>}
                                {/* *** ville de naissance OBL*** */}
                                <div className="form-group" id={'ville_naissance'}>
                                    {/*<Select showSearch className={"border-input"} name="ville_naissance"
                                            onSelect={v => this.onChangeVilleNaissance(v)}
                                            placeholder={t('96')}
                                    >
                                        {this.state.AllCities.map(r =>(
                                            <Option value={r.name}>{r.name}</Option>
                                        ))}
                                    </Select>
                                    {this.state.ville_naissanceErr && <span className='error'>
                                        {t('303')}</span>}*/}
                                    <input
                                        type="text"
                                        name="ville_naissance"
                                        placeholder={t('96')}
                                        value={this.state.ville_naissance}
                                        onChange={this.onChange}
                                        className="input-text"
                                        required={true}
                                    />
                                </div>


                                {/*<div className="form-group">
                                    <label htmlFor="name">Last name</label>
                                    <input
                                        type="text"
                                        name="ville_residence"
                                        placeholder={t('97')}
                                        // value={this.state.last_name}
                                        // onChange={this.onChange}
                                        className="input-text"
                                        required={true}
                                        onChange={this.onChange}
                                    />
                                </div>*/}

                                {/* *** Pays de residence OBL*** */}
                                <div className="form-group">
                                    {/*<label htmlFor="name">Last name</label>*/}
                                    <Select showSearch className={"border-input"} name="pays_residence"
                                            onChange={v => this.onChangeCountry(v)}
                                            placeholder={t('98')}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                    >
                                        {this.state.country.map(r =>(
                                            <Option value={r.label}>{r.label}</Option>
                                        ))}
                                    </Select>
                                    {this.state.errorPays && <span className='error'>
                                        {t('99')}</span>}
                                </div>
                                {/* *** ville de residence OBL*** */}
                                <div className="form-group" id={'ville_residence'}>
                                    {/*<label htmlFor="name">Last name</label>*/}
                                    <Select showSearch className={"border-input"} name="ville_residence"
                                            optionFilterProp="label"
                                            onSelect={v => this.onChangeVilleResidence(v)}
                                            placeholder={t('97')}
                                            // optionFilterProp="children"
                                            // filterOption={(input, option) =>
                                            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            // }
                                            options={this.state.cities}
                                    >
                                    </Select>
                                    {this.state.ville_residenceErr && <span className='error'>
                                        {t('302')}</span>}
                                </div>


                                {/* *** Pseudonyme OBL *** */}
                                <div className="form-group">
                                    {/*<label htmlFor="name">Last name</label>*/}
                                    <input
                                        type="text"
                                        name="pseudonyme"
                                        placeholder={t('100')}
                                        className="input-text"
                                        required={true}
                                        onChange={this.onChange}
                                    />
                                    {this.state.uniquePseudo && <span className='error'>
                                        {t('101')}</span>}
                                </div>

                                {/* *** langue pref *** */}
                                <div className="form-group">
                                    <label style={{display:"inline-block", marginRight:"15px",color: "#74869E",
                                        fontFamily: 'ROBOTO'}}>
                                        {t('102')}</label>
                                    <Select defaultValue={this.state.langue_pref} bordered={false}
                                            onChange={v => this.getLangue(v)}>
                                        <Option value="fr"><img src={frenchFlag} width={30} height={25}/></Option>
                                        <Option value="es"><img src={SpainFlag} width={30} height={25}/></Option>
                                        <Option value="en"><img src={BritainFlag} width={30} height={25}/></Option>
                                        <Option value="it"><img src={ItalyFlag} width={30} height={25}/></Option>
                                    </Select>

                                </div>

                                {/* *** Email OBL*** */}
                                <div className="form-group">
                                    {/*<label htmlFor="email">Email address</label>*/}
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder={t('103')}
                                        value={this.state.email}
                                        onChange={this.onChange}
                                        className="input-text"
                                        required={true}
                                    />
                                    {errors.email.length > 0 &&
                                    <span className='error'>{errors.email}</span>}
                                </div>

                                {/* *** Confirm Email OBL*** */}
                                <div className="form-group">
                                    {/*<label htmlFor="email">Email address</label>*/}
                                    <input
                                        type="email"
                                        name="confirm_email"
                                        placeholder={t('104')}
                                        //value={this.state.email}
                                        onChange={this.onChange}
                                        className="input-text"
                                        required={true}
                                    />
                                    {errors.confirm_email.length > 0 &&
                                    <span className='error'>{errors.confirm_email}</span>}
                                </div>


                                {/* *** Num phone *** */}
                                <div className="form-group">
                                    <PhoneInput
                                        international
                                        countryCallingCodeEditable={false}
                                        placeholder={t('105')}
                                        defaultCountry="FR"
                                        value={this.state.num_tele}
                                        onChange={(v) => this.setValuePhone(v)}/>

                                </div>
                            {this.state.errorPhone && <span className='error'>
                                        {t('107')}</span>}

                                {/* *** Password OBL*** */}
                                <div className="form-group">
                                    {/*<label htmlFor="password">Password</label>*/}
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder={t('106')}
                                        value={this.state.password}
                                        onChange={this.onChange}
                                        className="input-text"
                                        required={true}
                                    />
                                    {errors.password.length > 0 &&
                                    <span className='error'>{errors.password}</span>}
                                </div>

                                {/* *** Confirm password OBL*** */}
                                <div className="form-group">
                                    {/*<label htmlFor="password">Password</label>*/}
                                    <input
                                        type="password"
                                        name="confirm_password"
                                        placeholder={t('108')}
                                        //value={this.state.password}
                                        onChange={this.onChange}
                                        className="input-text"
                                        required={true}
                                    />
                                    {errors.confirm_password.length > 0 &&
                                    <span className='error'>{errors.confirm_password}</span>}
                                </div>

                                {/* *** Code parrainage si existe *** */}
                                <div className="form-group">
                                    {/*<label htmlFor="password">Password</label>*/}
                                    <input
                                        type="text"
                                        name="code_parrainage"
                                        placeholder={t('110')}
                                        //value={this.state.password}
                                        onChange={this.onChange}
                                        className="input-text"
                                    />
                                </div>

                                {/* *** Pieces d'identite *** */}

                            <div className="upload-btn-wrapper">
                                <button className="btn-piece">{t('111')}</button>
                                <input
                                    type="file"
                                    name="pieceRecto"
                                    onChange={this.PieceOnChange}
                                />
                                {(this.state.pieceRecto !== undefined && this.state.pieceRecto!== "") &&
                                    <div>
                                <span >
                                    {this.state.pieceRecto.name}</span>
                                        <span onClick={()=>{this.deleteFile("R")}}>
                                        <IoIosCloseCircleOutline
                                            style={{color:"#0C4EFC"}} size={"25px"}/>
                                        </span>
                                    </div>
                                }
                                {errors.piece.length >= 0 &&
                                <span className='error'>{errors.piece}</span>}
                            </div>
                            <div style={{marginTop : "15px", lineHeight:"16px"}}>
                                <span style={{fontSize : "13px"}} className='register-txt'>{t('320')}</span>
                            </div>
                            <div className="upload-btn-wrapper" style={{display:"inline-block"}}>
                                    <button className="btn-piece">{t('112')}</button>
                                    <input
                                        type="file"
                                        name="pieceVerso"
                                        onChange={this.PieceOnChange}
                                    />
                                {(this.state.pieceVerso !== undefined && this.state.pieceVerso!== "") &&
                                <div>
                                <span >
                                    {this.state.pieceVerso.name}</span>
                                    <span onClick={()=>{this.deleteFile("V")}}>
                                        <IoIosCloseCircleOutline
                                            style={{color:"#0C4EFC"}} size={"25px"}/>
                                        </span>
                                </div>
                                }

                            </div>

                            <Tooltip placement="right" title={infoText}>
                                    <img src={info} style={{width: "30px", marginBottom: "15px",verticalAlign: "super"}}/>
                            </Tooltip>

                            <div>
                                <label className="consignes">{t('113')}</label>
                            </div>

                        </div>
                        {/* conditions */}
                        <div className="col-md-5">
                            <div className=" middle-center">

                                <div style={{marginRight :"10px", marginBottom : "20px"}}>
                                    <input type="checkbox" required className="terms"/>
                                    <span className={"register-txt"}>
                                            {t('109')}</span>
                                </div>
                                <div style={{marginRight :"10px", marginBottom : "20px"}}>
                                    <input type="checkbox" className="terms" name="newsletters"
                                           onClick={()=>this.onChangeNewsLetters()}/>
                                    <span className={"register-txt"}>
                                        {t('118')}
                                    </span>
                                </div>
                                <div style={{marginRight :"10px", marginBottom : "20px"}}>
                                    <input type="checkbox" className="terms" name="notif_jeu"
                                    onClick={()=>this.onChangeNotifJeu()}/>
                                    <span className={"register-txt"}>
                                        {t('119')}
                                    </span>
                                </div>
                                <div style={{marginRight :"10px"}}>
                                    <input type="checkbox" required className="terms"/>
                                    <span className={"register-txt"}>
                                        {t('120')}<a target="_blank" rel="noopener noreferrer" href={"/Conditions_generales_vente"}>
                                        {t('125')}</a>
                                    </span>
                                </div>
                                <div className="shadow p-4 m-5 bg-white rounded d-inline-flex" >
                                    <div className="mr-3">
                                        <GoAlert style={{color: "#FF1603"}} size="25px"/>
                                    </div>
                                    <div>
                                         <span className={"msg-register"}>
                                             {t('121')}
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                        <div style={{marginTop:"20px", float: "Right", width : "8vw"}}>
                            <button
                                type="submit"
                                className="btn-valider" >
                                {t('114')}
                            </button>
                        </div>
                    </form>
                </Container>
            )
        }

        //************************************** MOBILE VERS ***************************************

        else {
            return (
                <Container className="register_mobile">
                    <div className="row">
                        <div className="back-mobile-reg">
                            <h3 className="regoingez">{t('82')}</h3>
                            <h5 style={{color: "#FFF",fontSize:"15pt", fontFamily:"Roboto"}}>{t('83')}</h5>
                            <p style={{color: "#FFF",fontSize:"15px"}}>{t('84')}</p></div>
                        <div className="col" style={{padding : "0 40px"}}>
                            <form onSubmit={this.onSubmit} encType="multipart/form-data">
                                <div style={{marginTop: "-15px", marginBottom:"30px"}}>
                                <div style={{display:"inline-block"}}>
                                    <label htmlFor="file" className="label-file telecharger">
                                        {t('85')}</label>
                                    <input id="file" className="input-file"
                                           type="file" name="photo"
                                           onChange={this.PictureOnChange} />
                                    {errors.image.length > 0 &&
                                    <span className='error'>{errors.image}</span>}
                                </div>
                                <div style={{display:"inline-block", width:"100%"}}>
                                    <label className=" mr-1 label-avatar telecharger" >{t('86')} </label>
                                    <label className="label-avatar telecharger" >{t('87')}</label>
                                    <Popover placement="right"
                                             style={{cursor:"pointer"}}
                                             content={this.avatars_mobile}
                                             trigger="click"
                                             visible={this.state.avatarChoice}
                                             onVisibleChange={this.handleVisibleChangeMobile}>
                                        <Avatar size={110}
                                                icon={<AiOutlinePlus style={{color:"#0C4EFC"}}
                                                                     size={"50px"}/>}
                                                style={{boxShadow: "0px 3px 6px #C5D7FF87",
                                                    backgroundColor:"#FFF",
                                                    marginTop: "-60px", float:"right"}}/>
                                    </Popover>
                                </div>
                                {Object.keys(this.state.imgSrc).length !== 0 &&
                                <Avatar src={this.state.imgSrc} size={76} style={{marginLeft:"20px"}}/>}
                                </div>
                                <div className="form-group">
                                    {/*<label htmlFor="name">First name</label>*/}
                                    <input
                                        type="text"
                                        name="first_name"
                                        placeholder={t('88')}
                                        value={this.state.first_name}
                                        onChange={this.onChange}
                                        className="input-text"
                                        required={true}
                                    />
                                </div>
                                {/* ****** Nom marital ****** */}
                                <div className="form-group">
                                    {/*<label htmlFor="name">Last name</label>*/}
                                    <input
                                        type="text"
                                        name="nom_marital"
                                        placeholder={t('89')}
                                        className="input-text"
                                        onChange={this.onChange}
                                    />
                                </div>

                                {/* ****** last name ****** */}
                                <div className="form-group">
                                    {/*<label htmlFor="name">Last name</label>*/}
                                    <input
                                        type="text"
                                        name="last_name"
                                        placeholder={t('90')}
                                        value={this.state.last_name}
                                        onChange={this.onChange}
                                        className="input-text"
                                        required={true}
                                    />
                                </div>

                                {/* ****** Gender ****** */}
                                <div className="form-group" style={{marginTop:"30px"}}>
                                    <div style={{marginRight :"30px",  display: "inline-flex"}}>
                                        <input type="radio" value="MALE" name="gender"
                                               required={true}
                                               onChange={this.onChange}/>
                                        <label className={"label-radio"}>{t('91')}</label>
                                    </div>
                                    <div style={{marginRight :"30px",display: "inline-flex"}}>
                                        <input type="radio" value="FEMALE" name="gender"
                                               onChange={this.onChange}/>
                                        <label className={"label-radio"}>{t('92')}</label>
                                    </div>
                                    <div style={{display: "inline-flex"}}>
                                        <input type="radio" value="Autre" name="gender" onChange={this.onChange}/>
                                        <label className={"label-radio"}>{t('93')}</label>
                                    </div>
                                </div>
                                {/* **** Date de naissance **** */}
                                <div className="form-group">
                                    <DatePicker format={"DD/MM/YYYY"}
                                                placeholder={t('94')}
                                                className={"border-input"}
                                                onChange={this.onChangeDateChange}/>
                                    {this.state.ErrorAge && <span className='error'>
                                        {t('95')}</span>}
                                </div>

                                {/* *** ville de naissance OBL*** */}
                                <div className="form-group" id={'ville_naissance'}>
                                    <Select showSearch className={"border-input"} name="ville_naissance"
                                            onSelect={v => this.onChangeVilleNaissance(v)}
                                            placeholder={t('96')}
                                    >
                                        {this.state.AllCities.map(r =>(
                                            <Option value={r.name}>{r.name}</Option>
                                        ))}
                                    </Select>
                                    {this.state.ville_naissanceErr && <span className='error'>
                                        {t('303')}</span>}
                                </div>

                                {/* *** Pays de residence OBL*** */}
                                <div className="form-group">
                                    {/*<label htmlFor="name">Last name</label>*/}
                                    <Select showSearch style={{ width: "100%" }}
                                            className={"border-input input-text"}
                                            onChange={v => this.onChangeCountry(v)}
                                            placeholder={t('98')}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                    >
                                        {this.state.country.map(r =>(
                                            <Option value={r.label}>{r.label}</Option>
                                        ))}
                                    </Select>
                                    {this.state.errorPays && <span className='error'>
                                        {t('99')}</span>}
                                </div>

                                {/* *** ville de residence OBL*** */}
                                <div className="form-group">
                                    <Select showSearch className={"border-input input-text"} name="ville_residence"
                                            optionFilterProp="label"
                                            onSelect={v => this.onChangeVilleResidence(v)}
                                            placeholder={t('97')}
                                        // optionFilterProp="children"
                                        // filterOption={(input, option) =>
                                        //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        // }
                                            options={this.state.cities}
                                    >
                                    </Select>
                                    {this.state.ville_residenceErr && <span className='error'>
                                        {t('302')}</span>}
                                </div>


                                {/* *** Pseudonyme OBL *** */}
                                <div className="form-group">
                                    {/*<label htmlFor="name">Last name</label>*/}
                                    <input
                                        type="text"
                                        name="pseudonyme"
                                        placeholder={t('100')}
                                        className="input-text"
                                        required={true}
                                        onChange={this.onChange}
                                    />
                                    {this.state.uniquePseudo && <span className='error'>
                                        {t('101')}</span>}
                                </div>

                                {/* *** langue pref *** */}
                                <div className="form-group" style={{marginTop:"30px"}}>
                                    <label style={{display:"inline-block", marginRight:"15px",color: "#74869E",
                                        fontFamily: 'ROBOTO'}}>
                                        {t('102')}</label>
                                    <Select defaultValue={this.state.langue_pref}
                                            bordered={false}
                                            onChange={v => this.getLangue(v)}>
                                        <Option value="fr"><img src={frenchFlag} width={30} height={25}/></Option>
                                        <Option value="es"><img src={SpainFlag} width={30} height={25}/></Option>
                                        <Option value="en"><img src={BritainFlag} width={30} height={25}/></Option>
                                        <Option value="it"><img src={ItalyFlag} width={30} height={25}/></Option>
                                    </Select>
                                </div>

                                {/* *** Email OBL*** */}
                                <div className="form-group">
                                    {/*<label htmlFor="email">Email address</label>*/}
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder={t('103')}
                                        value={this.state.email}
                                        onChange={this.onChange}
                                        className="input-text"
                                        required={true}
                                    />
                                    {errors.email.length > 0 &&
                                    <span className='error'>{errors.email}</span>}
                                </div>

                                {/* *** Confirm Email OBL*** */}
                                <div className="form-group">
                                    {/*<label htmlFor="email">Email address</label>*/}
                                    <input
                                        type="email"
                                        name="confirm_email"
                                        placeholder={t('104')}
                                        //value={this.state.email}
                                        onChange={this.onChange}
                                        className="input-text"
                                        required={true}
                                    />
                                    {errors.confirm_email.length > 0 &&
                                    <span className='error'>{errors.confirm_email}</span>}
                                </div>

                                {/* *** Num phone *** */}
                                <div className="form-group">
                                    <PhoneInput
                                        international
                                        placeholder={t('105')}
                                        countryCallingCodeEditable={false}
                                        defaultCountry="FR"
                                        value={this.state.num_tele}
                                        onChange={(v) => this.setValuePhone(v)}/>
                                    {this.state.errorPhone && <span className='error'>
                                        {t('107')}</span>}
                                </div>

                                {/* *** Password OBL*** */}
                                <div className="form-group">
                                    {/*<label htmlFor="password">Password</label>*/}
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder={t('106')}
                                        value={this.state.password}
                                        onChange={this.onChange}
                                        className="input-text"
                                        required={true}
                                    />
                                    {errors.password.length > 0 &&
                                    <span className='error'>{errors.password}</span>}
                                </div>

                                {/* *** Confirm password OBL*** */}
                                <div className="form-group">
                                    {/*<label htmlFor="password">Password</label>*/}
                                    <input
                                        type="password"
                                        name="confirm_password"
                                        placeholder={t('108')}
                                        //value={this.state.password}
                                        onChange={this.onChange}
                                        className="input-text"
                                        required={true}
                                    />
                                    {errors.confirm_password.length > 0 &&
                                    <span className='error'>{errors.confirm_password}</span>}
                                </div>


                                {/* *** conditions générales **** */}
                                <div onChange={this.onChange} style={{marginTop :"50px", marginBottom : "25px"
                                    ,textAlign: "justify"}}>

                                    <div style={{marginRight :"10px", marginBottom : "15px",textAlign: "justify"}}>
                                    <input type="checkbox" required className="terms"/>
                                    <span className={"register-txt"}>
                                        {t('109')}</span>
                                    </div>

                                    <div style={{marginRight :"10px", marginBottom : "15px",textAlign: "justify"}}>
                                        <input type="checkbox" className="terms" />
                                        <span className={"register-txt"}>
                                        {t('118')}
                                    </span>
                                    </div>

                                    <div style={{marginRight :"10px", marginBottom : "15px",textAlign: "justify"}}>
                                        <input type="checkbox" required className="terms"/>
                                        <span className={"register-txt"}>
                                        {t('119')}
                                    </span>
                                    </div>
                                    <div style={{marginRight :"10px"}}>
                                        <input type="checkbox" required className="terms"/>
                                        <span className={"register-txt"}>
                                        {t('120')}<a target="_blank" rel="noopener noreferrer" href={"/Conditions_generales_vente"}>
                                        {t('125')}</a>
                                    </span>
                                    </div>
                                    <div className="shadow p-4 bg-white rounded-lg d-inline-flex mt-5 mb-5" >
                                        <div className="mr-3">
                                            <GoAlert style={{color: "#FF1603"}} size="25px"/>
                                        </div>
                                        <div>
                                         <span className={"register-txt"}>
                                             {t('121')}
                                        </span>
                                        </div>
                                    </div>
                                </div>

                                {/* *** Pieces d'identite *** */}
                                {/*<div className="upload-btn-wrapper">
                                    <button className="btn-piece">Joindre votre pièce d'identité</button>
                                    <input
                                        type="file"
                                        name="piece"
                                        onChange={this.PieceOnChange}
                                    />
                                    {errors.piece.length >= 0 &&
                                    <span className='error'>{errors.piece}</span>}

                                </div>*/}
                                {/* *** Pieces d'identite *** */}

                                <div className="upload-btn-wrapper">
                                    <button className="btn-piece btn-piece-mob">
                                        {t('111')}</button>
                                    <input
                                        type="file"
                                        name="pieceRecto"
                                        onChange={this.PieceOnChange}
                                    />

                                </div>
                                {errors.piece.length >= 0 && <span className='error'>{errors.piece}</span>}
                                {(this.state.pieceRecto !== undefined && this.state.pieceRecto!== "") &&
                                <div>
                                <span >
                                    {this.state.pieceRecto.name}</span>
                                    <span onClick={()=>{this.deleteFile("V")}}>
                                        <IoIosCloseCircleOutline
                                            style={{color:"#0C4EFC"}} size={"25px"}/>
                                        </span>
                                </div>
                                }
                            <div style={{marginTop : "20px", lineHeight:"16px"}}>
                                <span style={{fontSize : "13px"}} className='register-txt'>{t('320')}</span>
                            </div>
                                <div className="upload-btn-wrapper" style={{display:"inline-block"}}>
                                    <button className="btn-piece btn-piece-mob">
                                        {t('112')}</button>
                                    <input
                                        type="file"
                                        name="pieceVerso"
                                        onChange={this.PieceOnChange}
                                    />

                                </div>
                                <Tooltip placement="topLeft" title={infoText}>
                                    <img src={info} style={{width: "30px", marginBottom: "15px",verticalAlign: "super"}}/>
                                </Tooltip>
                                <div>
                                    <label className="consignes">{t('113')}</label>
                                </div>
                                {errors.piece.length >= 0 &&
                                <span className='error'>{errors.piece}</span>}
                                {(this.state.pieceVerso !== undefined && this.state.pieceVerso!== "") &&
                                <div>
                                <span>
                                    {this.state.pieceVerso.name}</span>
                                    <span onClick={()=>{this.deleteFile("V")}}>
                                        <IoIosCloseCircleOutline
                                            style={{color:"#0C4EFC"}} size={"25px"}/>
                                        </span>
                                </div>
                                }
                                <div style={{marginTop:"50px", textAlign:"center"}}>
                                    <button
                                        type="submit"
                                        className="btn-valider" >
                                        {t('114')}
                                    </button>
                                </div>
                            </form>
                        </div>
                        {/* Image */}
                        {/*<div className="col-md-6 mt-5 mx-auto">
                            <img src={Image} width={700} height={700}/>
                            <img src={Image2} width={500} height={500} style={{marginTop: "-100px"}}/>
                        </div>*/}
                    </div>
                </Container>
            )
        }

    }
}

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

const underAgeValidate = (birthday) => {

    // convert from DD/MM/YYYY to yyyy/mm/dd
    var datearray = birthday.split("/");
    var newdate =  datearray[2] + '/' + datearray[1] + '/' + datearray[0] ;

    // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
    var optimizedBirthday = newdate.replace(/-/g, "/");

    //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
    var myBirthday = new Date(optimizedBirthday);

    // set current day on 01:00:00 hours GMT+0100 (CET)
    var currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';

    // calculate age comparing current date and borthday
    var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));

    if(myAge < 18) {
        return true;
    }else{
        return false;
    }

}


export default withTranslation()(Register)
