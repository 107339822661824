import {CountPlayers} from "./Tirage/tirage";
import {montant_participation} from "../Constants/constants";
import axios from "axios";

export function get_choix_tirage(Montant_cagnotte) {

    if (Montant_cagnotte < 10000.99)
        return [2, 3, 4]
    else if (Montant_cagnotte < 50000.99)
        return [2, 4, 5]
    else if (Montant_cagnotte < 100000.99)
        return [3, 4, 6]
    else if (Montant_cagnotte < 500000.99)
        return [3, 5, 7]
    else if (Montant_cagnotte < 1000000.99)
        return [3, 6, 8]
    else if (Montant_cagnotte < 5000000.99)
        return [4, 7, 9]
    else if (Montant_cagnotte < 10000000.99)
        return [4, 8, 10]
    else
        return [5, 9, 13]

}


export const GetVote = (WeekID) => {
    return axios.post('api/votes/GetVote' , {Numero_Week : WeekID})

}
