import React, {Component, useEffect, useState} from 'react'
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {IsLoggedIn} from "../Helpers";
import VerticalNavbar_assistance from "../components/VerticalNavbar_assistance";
import {Input,Breadcrumb, Collapse} from "antd";
import "./condition_jeu.css"
import {Link, NavLink} from "react-router-dom";
import HeaderNavbarDashboard from "../components/HeaderNavbarDashboard";
import arrow_bottom from "../Images/arrow-bottom.png";
import arrow from "../Images/arrow-right.png";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import {useTranslation} from "react-i18next";

const { Search } = Input;
const { Panel } = Collapse;

function Securite() {
    let width = window.innerWidth
    let [titre_1, Settitre_1]   = useState("");
    let [titre_2, Settitre_2]   = useState("");
    let [titre_3, Settitre_3]   = useState("");
    let [titre_4, Settitre_4]   = useState("");
    let [titre_5, Settitre_5]   = useState("");
    let [titre_6, Settitre_6]   = useState("");
    let [titre_7, Settitre_7]   = useState("");
    let [titre_8, Settitre_8]   = useState("");
    let [titre_9, Settitre_9]   = useState("");
    let [contenu_1, SetContenu_1]   = useState("");
    let [contenu_2, SetContenu_2]   = useState("");
    let [contenu_3, SetContenu_3]   = useState("");
    let [contenu_4, SetContenu_4] = useState("");
    let [contenu_5, SetContenu_5] = useState("");
    let [contenu_6, SetContenu_6] = useState("");
    let [contenu_7, SetContenu_7] = useState("");
    let [contenu_8, SetContenu_8] = useState("");
    let [contenu_9, SetContenu_9] = useState("");
    let [loading, SetLoading] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/117')
            .then((res) => {
                // SetLoading(false)
                Settitre_1(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/118')
            .then((res) => {
                // SetLoading(false)
                Settitre_2(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/119')
            .then((res) => {
                Settitre_3(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/120')
            .then((res) => {
                Settitre_4(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/121')
            .then((res) => {
                console.log(res.data.contenu_page)
                Settitre_5(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/122')
            .then((res) => {
                Settitre_6(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })


        axios.get('/api/pages/140')
            .then((res) => {
                Settitre_7(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })


        axios.get('/api/pages/141')
            .then((res) => {
                Settitre_8(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })


        axios.get('/api/pages/142')
            .then((res) => {
                Settitre_9(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/123')
            .then((res) => {
                SetContenu_1(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/124')
            .then((res) => {
                SetContenu_2(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/125')
            .then((res) => {
                SetContenu_3(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/126')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetContenu_4(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/127')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetContenu_5(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/128')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_6(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/143')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_7(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/144')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_8(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/145')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu_9(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
    }, [])
    const history = useHistory()
    const {t} = useTranslation()

    const onSearch = value => {
        let queryString = "q=" + value;
        history.push(`/assistance/faqS/search?${queryString}`)
    };

    if(width > 768){
        return(<>{
                loading ? <div className="spinner-center" style={{left: "50vw"}}><Spinner animation="border" role="status" variant="primary"/>
                    </div> :
                    <Container fluid>
                        <Row style={{padding: "0 100px"}}>
                            {IsLoggedIn() ?
                                (
                                    <Col>
                                        <HeaderNavbarDashboard/>
                                    </Col>
                                ) :
                                (<div/>)}
                        </Row>
                        <Row className="condition">
                                    <Col md={2}>
                                        <VerticalNavbar_assistance/>
                                    </Col>
                            <Col className={"padding-faq"}>
                                <Search placeholder="Rechercher..."
                                        enterButton onSearch={onSearch}/>
                                <Breadcrumb separator=">" style={{marginTop: "30px"}}>
                                    <Breadcrumb.Item>
                                        <Link to={"/assistance/faqS"}>
                                            <label className="breadcrump"
                                                   style={{
                                                       textDecoration: "underline",
                                                       cursor: "pointer"
                                                   }}>FAQ's</label>
                                        </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item className="breadcrump">Sécurité</Breadcrumb.Item>
                                </Breadcrumb>
                                <div style={{marginTop: "50px", marginLeft: "70px"}}>
                                    <Collapse style={{backgroundColor: "#FFF", border: "0"}}>
                                        <Panel header={ReactHtmlParser(titre_1)} key="1">
                                            <p className="condition-text">{ReactHtmlParser(contenu_1)}</p>
                                        </Panel>
                                        <Panel header={ReactHtmlParser(titre_2)} key="2">
                                            <p className="condition-text">{ReactHtmlParser(contenu_2)}</p>
                                        </Panel>
                                        <Panel header={ReactHtmlParser(titre_3)} key="3">
                                            <p className="condition-text">{ReactHtmlParser(contenu_3)}</p>
                                        </Panel>
                                        <Panel header={ReactHtmlParser(titre_4)} key="4">
                                            <p className="condition-text">{ReactHtmlParser(contenu_4)}</p>
                                        </Panel>
                                        <Panel header={ReactHtmlParser(titre_5)} key="5">
                                            <p className="condition-text">{ReactHtmlParser(contenu_5)}</p>
                                        </Panel>

                                        <Panel header={ReactHtmlParser(titre_6)} key="6">
                                            <p className="condition-text">{ReactHtmlParser(contenu_6)}</p>
                                        </Panel>
                                        <Panel header={ReactHtmlParser(titre_7)} key="7">
                                            <p className="condition-text">{ReactHtmlParser(contenu_7)}</p>
                                        </Panel>
                                        <Panel header={ReactHtmlParser(titre_8)} key="8">
                                            <p className="condition-text">{ReactHtmlParser(contenu_8)}</p>
                                        </Panel>
                                        <Panel header={ReactHtmlParser(titre_9)} key="9">
                                            <p className="condition-text">{ReactHtmlParser(contenu_9)}</p>
                                        </Panel>
                                    </Collapse>
                                </div>
                            </Col>
                        </Row>
                    </Container>
            }</>
        )
    }

    else{
        return (
            <>{
                loading ? <div className="spinner-center" style={{left: "50vw"}}><Spinner animation="border" role="status" variant="primary"/>
                    </div> :
                    <Container className="pad-mob">
                        {IsLoggedIn() ?
                            <>
                                <Row style={{margin : "0 10px"}}>
                                    {/* // ***************** MENU MON COMPTE NAVBAR *************** */}

                                    <NavLink className="column col" style={{borderRadius: "30px 30px 0 0"}}
                                             to={"/moncompte"} activeClassName="selected">
                                        <h5 style={{color : "#FFF"}}>{t('63')}</h5>
                                    </NavLink>
                                </Row>

                                <Row style={{margin : "0 10px"}}>
                                    <NavLink className="column col"
                                             to={"/Tirage"} activeClassName="selected">
                                        <h5 style={{color : "#FFF"}}>{t('64')}</h5>
                                    </NavLink>
                                </Row>

                                <Row style={{margin : "0 10px"}}>
                                    <NavLink className="column col" to={"/assistance"} activeClassName="selected">
                                        <h5 style={{color : "#FFF"}}>{t('65')}</h5>
                                    </NavLink>
                                </Row>
                            </>
                            :
                            (<div/>)}
                        {/* ********************** UNDER MENU ********************************** */}

                        <Row className="row-und-menu">
                            <NavLink className="und-menu" to="/assistance/accessibilite">
                                <img src={arrow_bottom} width={30} height={30}/>
                                <h5 style={{
                                    marginLeft: "10px", marginTop: "2px",
                                    marginBottom: "0", color: "#0C4EFC"
                                }}>{t('151')}</h5>
                            </NavLink>
                        </Row>

                        <Row className="row-und-menu">
                            <NavLink className="und-menu" to="/assistance/faqS">
                                <img src={arrow_bottom} width={30} height={30}/>
                                <h5 style={{
                                    marginLeft: "10px", marginTop: "2px",
                                    marginBottom: "0", color: "#0C4EFC"
                                }}>{t('152')}</h5>
                            </NavLink>
                        </Row>
                        <Row>
                            <Search placeholder={t('292')}
                                    enterButton onSearch={onSearch}/>
                        </Row>
                        <Row className={"faq-mobile"}>
                            <Breadcrumb separator=">" style={{marginTop: "20px"}}>
                                <Breadcrumb.Item>
                                    <Link to={"/assistance/faqS"}>
                                        <label className="breadcrump"
                                               style={{textDecoration: "underline", cursor: "pointer"}}>{t('152')}</label>
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item className="breadcrump">{t('289')}</Breadcrumb.Item>
                            </Breadcrumb>
                            <div style={{marginTop: "30px"}}>
                                <Collapse style={{backgroundColor: "#FFF", border: "0"}}>
                                    <Panel header={ReactHtmlParser(titre_1)} key="1">
                                        <p className="condition-text">{ReactHtmlParser(contenu_1)}</p>
                                    </Panel>
                                    <Panel header={ReactHtmlParser(titre_2)} key="2">
                                        <p className="condition-text">{ReactHtmlParser(contenu_2)}</p>
                                    </Panel>
                                    <Panel header={ReactHtmlParser(titre_3)} key="3">
                                        <p className="condition-text">{ReactHtmlParser(contenu_3)}</p>
                                    </Panel>
                                    <Panel header={ReactHtmlParser(titre_4)} key="4">
                                        <p className="condition-text">{ReactHtmlParser(contenu_4)}</p>
                                    </Panel>
                                    <Panel header={ReactHtmlParser(titre_5)} key="5">
                                        <p className="condition-text">{ReactHtmlParser(contenu_5)}</p>
                                    </Panel><Panel header={ReactHtmlParser(titre_6)} key="6">
                                    <p className="condition-text">{ReactHtmlParser(contenu_6)}</p>
                                </Panel>
                                </Collapse>
                            </div>
                        </Row>


                        <Row className="row-und-menu">
                            <NavLink className="und-menu" to="/assistance">
                                <img src={arrow} width={30} height={30}/>
                                <h5 style={{
                                    marginLeft: "10px", marginTop: "2px",
                                    marginBottom: "0", color: "#0C4EFC"
                                }}>{t('55')}</h5>
                            </NavLink>
                        </Row>
                    </Container>
            }
                </>
        )
    }

}

export default Securite;
