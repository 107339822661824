import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../Images/logoFooter.png"
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import {FaTwitter} from "react-icons/all";
import "./Footer.css"
import logoGaming2 from "../Images/CuracaoEgaming-Logo.png"
import {FaYoutube} from "react-icons/all";
import {NavLink} from "react-router-dom";
import {FaTelegramPlane} from "react-icons/all";
import {withTranslation} from "react-i18next";

class Footer extends Component {
    render() {
        const {t} = this.props

        let width = window.innerWidth;

        if (width > 1024){
        return (
            <div className="mainDiv">
            <Container className="max">
                <Row style ={{paddingBottom : "40px"}}>
                    <Col style={{textAlign : "center"}} >
                        <img src={logo} alt={"logo"} width={90} height={90}/>
                        <h5 style={{marginTop: "10px",marginBottom:"0px",color:"white"}}>Com'on Pot</h5>
                        <label className={"under-brand"}>The only social lottery</label>
                    </Col>
                    <Col md={1}>
                        <div><FaFacebookF size="23px" style={{marginBottom:"15px"}} /></div>
                        <div><FaTwitter size="23px" style={{marginBottom:"15px"}} /></div>
                        <div><FaTelegramPlane size="23px" style={{marginBottom:"15px"}} /></div>
                        <div><FaYoutube size="23px" style={{marginBottom:"15px"}}/></div>
                        <div><FaInstagram size="23px" style={{marginBottom:"15px"}}/></div>
                        <div>
                            <a target="_blank" rel="noopener noreferrer" href={"https://www.curacao-egaming.com/"}>
                                <img src={logoGaming2} width="22px"/>
                            </a>
                        </div>

                    </Col>
                    <Col md={2}>
                        <h5 className={"Hwhite"}>{t('46')}</h5>
                        <ul className={"list-unstyled"}>
                            <li><NavLink exact className="footer_link"
                                to="/a_propos">
                                {t('47')}
                            </NavLink></li>
                            <li><NavLink exact className="footer_link"
                                to="/securite">
                                {t('48')}
                            </NavLink></li>
                        </ul>
                    </Col>
                    <Col md={3}>
                        <h5 className={"Hwhite"}>{t('42')}</h5>
                        <ul className={"list-unstyled"}>
                            <li><NavLink exact className="footer_link"
                                         to="/licence-jeu">
                                {t('49')}
                            </NavLink></li>
                            <li><NavLink exact className="footer_link"
                                         to="/transparence-et-tarif">
                                {t('50')}
                            </NavLink></li>
                            <li><NavLink exact className="footer_link"
                                         to="/risques-pour-joueur">
                                {t('52')}
                            </NavLink>
                            </li>
                            <li><NavLink exact className="footer_link"
                                         to="/grievance_book">
                            {t('53')}
                            </NavLink></li>
                        </ul>
                    </Col>
                    <Col >
                        <h5 className={"Hwhite"}>{t('60')}</h5>
                        <ul className={"list-unstyled"}>
                            <li><NavLink exact className="footer_link"
                                         to="/assistance/accessibilite">
                                {t('33')}
                            </NavLink></li>
                            <li><NavLink exact className="footer_link"
                                         to="/assistance/faqS">
                                {t('54')}
                            </NavLink></li>
                            <li><NavLink exact className="footer_link"
                                         to="/assistance">
                                {t('55')}
                            </NavLink></li>
                        </ul>
                    </Col>
                    <Col md={3} style={{marginRight : "-30px"}}>
                        <h5 className={"Hwhite"}>{t('61')}</h5>
                        <ul className={"list-unstyled"}>
                            <li><NavLink exact className="footer_link"
                                         to="/conditions_generales">
                                {t('56')}
                            </NavLink></li>
                            <li><NavLink exact className="footer_link"
                                         to="/Conditions_generales_vente">
                                {t('57')}
                            </NavLink></li>
                            <li><NavLink exact className="footer_link"
                                         to="/politique_confidentialite">
                                {t('58')}
                            </NavLink></li>
                            <li><NavLink exact className="footer_link"
                                         to="/Mentions_legales">
                                {t('59')}
                            </NavLink></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <label className={"border-top"} style={{width:"100%"}}>© Com'on Pot {new Date().getFullYear()}
                    </label>
                </Row>
            </Container>
            </div>
        );
        }
        else
        {
            return (
                <div className="mainDiv">
                    <Container >
                        <Row style ={{marginTop: "-150px"}}>
                            <Col>
                                <img src={logo} alt={"logo"} width={90} height={90}
                                     style={{boxShadow: "0px 15px 15px #00000029"}}/>
                                <h5 className={"logo-title"}>Com'on Pot</h5>
                                <label className={"under-brand"}>The only social lottery</label>
                            </Col>
                        </Row>
                        <Row style={{padding: "40px 0", alignContent:"center", justifyContent: "center"}}>
                            <FaFacebookF size="40px" style={{marginRight:"20px"}} />
                            <FaTwitter size="40px" style={{marginRight:"20px"}} />
                            <FaTelegramPlane size="40px" style={{marginRight:"20px"}} />
                            <FaYoutube size="40px" style={{marginRight:"20px"}} />
                            <a target="_blank" rel="noopener noreferrer" href={"https://www.curacao-egaming.com/"}>
                                <img src={logoGaming2} width="45px" />
                            </a>
                        </Row>
                        <Row style={{paddingBottom: "20px"}}>
                            <Col>
                                <h5 className={"Hwhite"}>{t('46')}</h5>
                                <ul className={"list-unstyled"}>
                                    <li><NavLink exact className="footer_link"
                                                 to="/a_propos">
                                        {t('47')}
                                    </NavLink></li>
                                    <li><NavLink exact className="footer_link"
                                                 to="/securite">
                                        {t('48')}
                                    </NavLink></li>
                                </ul>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: "20px"}}>
                            <Col>
                                <h5 className={"Hwhite"}>{t('42')}</h5>
                                <ul className={"list-unstyled"}>
                                    <li><NavLink exact className="footer_link"
                                                 to="/licence-jeu">
                                        {t('49')}
                                    </NavLink></li>
                                    <li><NavLink exact className="footer_link"
                                                 to="/transparence-et-tarif">
                                        {t('50')}
                                    </NavLink></li>
                                    <li><NavLink exact className="footer_link"
                                                 to="/risques-pour-joueur">
                                        {t('52')}
                                    </NavLink>
                                    </li>
                                    <li><NavLink exact className="footer_link"
                                                 to="/grievance_book">
                                        {t('53')}
                                    </NavLink></li>
                                </ul>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: "20px"}}>
                            <Col>
                                <h5 className={"Hwhite"}>{t('60')}</h5>
                                <ul className={"list-unstyled"}>
                                    <li><NavLink exact className="footer_link"
                                                 to="/grievance_book">
                                        {t('33')}
                                    </NavLink></li>
                                    <li><NavLink exact className="footer_link"
                                                 to="/assistance/faqS">
                                        {t('54')}
                                    </NavLink></li>
                                    <li><NavLink exact className="footer_link"
                                                 to="/assistance">
                                        {t('55')}
                                    </NavLink></li>
                                </ul>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: "20px"}}>
                            <Col>
                                <h5 className={"Hwhite"}>{t('61')}</h5>
                                <ul className={"list-unstyled"}>
                                    <li><NavLink exact className="footer_link"
                                                 to="/conditions_generales">
                                        {t('56')}
                                    </NavLink></li>
                                    <li><NavLink exact className="footer_link"
                                                 to="/Conditions_generales_vente">
                                        {t('57')}
                                    </NavLink></li>
                                    <li><NavLink exact className="footer_link"
                                                 to="/politique_confidentialite">
                                        {t('58')}
                                    </NavLink></li>
                                    <li><NavLink exact className="footer_link"
                                                 to="/Mentions_legales">
                                        {t('59')}
                                    </NavLink></li>
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            <label className={"border-top"} style={{width:"100%", paddingTop: "15px"}}>
                                © Com'on Pot {new Date().getFullYear()}</label>
                        </Row>
                    </Container>
                </div>
            );
        }
    }
}

export default withTranslation()(Footer);
