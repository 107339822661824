import React, {Component, useEffect, useState} from 'react';
import {Container, Row, Spinner} from "react-bootstrap";
import "./Nous_connaitre.css"
import axios from "axios";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import imageError from "../Images/error.png"
import imageError_mobile_1 from "../Images/bebe_gauche.png"
import imageError_mobile_2 from "../Images/bebe_droite.png"
import Col from "reactstrap/es/Col";


const Country_noAuth = () =>{


        return(
            <div className="nous-connaitre no-auth">
                    <Container>
                        <Row className={'error'}>
                            <Col className={'error-404'} lg sm={12}
                                 xs ={12}>
                                <h3>Error 403</h3>
                                <h4 className={"h4-auth"}>Oups, vous n'êtes pas autorisé à accéder à cette page.</h4>
                            </Col>
                            <Col style={{textAlign : 'center'}} lg={4} sm={12} xs ={12}>
                                <img src={imageError} alt={"pays non authorisé"} className={"no-ath-img"}/>
                            </Col>
                        </Row>
                    </Container>

            </div>
        )


}

export default Country_noAuth;
