import React, {Component, useEffect, useState} from 'react';
import {Col, Container, Row, Spinner} from "react-bootstrap";
import "./Nous_connaitre.css"
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
import les_gains_img from "../Images/Les_gains.png"
import les_gains_img1 from "../Images/gains1.png"
import les_gains_img2 from "../Images/gains2.png"
import les_gains_img3 from "../Images/gains3.png"
import les_gains_img_ordi from "../Images/gains_ordi2.png"
import { useTranslation } from 'react-i18next';



const Les_gains = () =>{
    const { t } = useTranslation();

    let [contenu, SetContenu] = useState("")
    let [loading, SetLoading] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/135')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })

    }, [])

    //---------------------------------------- DESKTOP VERS ---------------------------------------------

    let width = window.innerWidth

    return(
        <div className="nous-connaitre">
            {loading ?
                <div className="spinner-center"><Spinner animation="border" role="status" variant="primary"/></div> :
                <Container fluid className={"qui-sommes-nous"}>
                    <Row style={{justifyContent: "center"}}>
                        <h4 className="titre-35px">{t('301')}</h4>
                    </Row>

                    <div>{ReactHtmlParser(contenu)}</div>

                    <div className={"transparence-img"}>
                        {width > 1025 ? <img src={les_gains_img_ordi} className={"img-gains_ordi"}
                                            alt={"Com'on Pot Les gains"}/>
                        :
                            <>
                                <img src={les_gains_img1} className={"transparence-img-gains"}
                                     alt={"Com'on Pot Les gains"}/>
                                     <img src={les_gains_img2} className={"transparence-img-gains"}
                                     alt={"Com'on Pot Les gains"}/>
                                     <img src={les_gains_img3} className={"transparence-img-gains"}
                                     alt={"Com'on Pot Les gains"}/>
                            </>
                            }
                    </div>


                </Container>
            }

        </div>
    )



    //-------------------------------------------  MOBILE VERS  -------------------------------------------


}

export default Les_gains;
