import React, {Component, useEffect, useState} from 'react';
import {Container, Row, Spinner} from "react-bootstrap";
import "./Nous_connaitre.css"
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';



const Conditions_generales = () =>{

    let [contenu, SetContenu] = useState("")
    let [updatedAt, SetupdatedAt] = useState("")
    let [loading, SetLoading] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/9')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
    }, [])

    //---------------------------------------- DESKTOP VERS ---------------------------------------------

        return(
            <div className="nous-connaitre">
                {loading ? <div className="spinner-center"><Spinner animation="border" role="status" variant="primary"/> </div>:
                    <Container fluid className={"qui-sommes-nous"}>
                        <div style={{textAlign: "center"}}>
                            <h4 className="titre-35px">Conditions générales d'utilisation</h4>
                        </div>

                        <div className={"cgu cgu-italique"}>{ ReactHtmlParser(contenu) }</div>

                    </Container>
                }

            </div>
        )




}

export default Conditions_generales;
