import React, {Component, useEffect, useState} from 'react';
import {Col, Container, Row, Spinner} from "react-bootstrap";
import "./Nous_connaitre.css"
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';



const ALGORITHME_VS_MACHINES = () =>{
    let width = window.innerWidth

    let [contenuGauche, SetContenuGauche] = useState("")
    let [contenuDroite, SetContenuDroite] = useState("")
    let [contenuHaut, SetContenuHaut] = useState("")
    let [contenuBas, SetContenuBas] = useState("")
    let [loading, SetLoading] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/8')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenuGauche(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/14')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenuDroite(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/15')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenuHaut(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/16')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenuBas(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
    }, [])

    //---------------------------------------- DESKTOP VERS ---------------------------------------------

    if(width > 768) {
        return(
            <div className="nous-connaitre">
                {loading ? <div className="spinner-center"><Spinner animation="border" role="status" variant="primary"/> </div>:
                    <Container fluid className={"qui-sommes-nous"}>
                        {/* <Row style={{justifyContent: "center"}}>
                            <h4 className="titre-35px">Grievance book</h4>
                        </Row> */}
                        <Row style={{justifyContent: "center"}}>
                            <h4 className="titre-35px">Soon</h4>
                        </Row>
                        {/* <Row style={{margin : "40px 0"}} className={'algo'}>
                            { ReactHtmlParser(contenuHaut) }
                        </Row>
                        <Row className={'algo-black-title algo algo-black'}>
                            <Col>
                                { ReactHtmlParser(contenuGauche) }
                            </Col>
                            <Col md={1}>
                                <div className="wrapper">
                                    <div className="line"/>
                                    <div className="wordwrapper">
                                        <div className="word">VS</div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                { ReactHtmlParser(contenuDroite) }
                            </Col>
                        </Row>
                        <Row style={{margin : "40px 0"}} className={'algo-black'}>
                            { ReactHtmlParser(contenuBas) }
                        </Row> */}


                    </Container>
                }

            </div>
        )
    }



    //-------------------------------------------  MOBILE VERS  -------------------------------------------

    else {
        return(
            <div className="algo-haut nous-connaitre">
                {loading ? <div className="spinner-center"><Spinner animation="border" role="status" variant="primary"/> </div>:

                    <Container fluid className="qui-sommes-nous">
                        <div style={{textAlign: "center"}}>
                            <h4 className="titre-35px">ALGORITHME VS. MACHINES A BOULES</h4>
                        </div>

                        <div style={{marginBottom : "20px"}}>
                            { ReactHtmlParser(contenuHaut) }
                        </div>
                        <div  className={"algo-black-title algo-black"}>
                                { ReactHtmlParser(contenuGauche) }
                        </div>
                        <Row className={"row-line"}>
                            <div className="line-mob"/>
                            <div className="vs">VS</div>
                            <div className="line-mob"/>
                        </Row>
                        <div className={"algo-black-title algo-black"}>
                            { ReactHtmlParser(contenuDroite) }
                        </div>
                        <div style={{margin : "20px 0"}} className={'algo-black'}>
                            { ReactHtmlParser(contenuBas) }
                        </div>

                    </Container>
                }

            </div>

        )
    }

}

export default ALGORITHME_VS_MACHINES;
