import React, {useEffect, useState} from 'react';
import {Container, Row, Spinner} from "react-bootstrap";
import "./Nous_connaitre.css"
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
import imag_svg from "../Images/Deroulement.png"
import imag_svg_mobile from "../Images/DeroulementMobile.png"
import Col from "react-bootstrap/Col";


const Deroulement = () =>{
    let width = window.innerWidth

    let [contenu, SetContenu] = useState("")
    let [updatedAt, SetupdatedAt] = useState("")
    let [loading, SetLoading] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/12')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
    }, [])

    //---------------------------------------- DESKTOP VERS ---------------------------------------------

/*
    if(width > 768) {
*/
        return(
            <div className="nous-connaitre deroul">
                {loading ? <div className="spinner-center"><Spinner animation="border" role="status" variant="primary"/> </div>:
                    <Container fluid className={"qui-sommes-nous"}>
                        <Row style={{textAlign: "center"}}>
                            <Col>
                                {width > 768 ?
                                    <h4 className="titre-35px">Déroulement du tirage - Pré et post tirage</h4>
                                    :
                                    <h4 className="titre-35px">Déroulement du tirage</h4>
                                }
                                    </Col>
                        </Row>

                        <Row>
                            <Col>
                            { ReactHtmlParser(contenu) }
                            </Col>
                        </Row>
                        <Row className={"deroul-detail"}>
                            <Col style={{textAlign: "center"}}>
                            <h4 className="txt-30px" >Déroulement dans le détail</h4>
                            </Col>
                        </Row>
                        <Row >
                            <Col style={{textAlign: "center"}}>
                                {width > 768 ?
                                    <img src={imag_svg} style={{width: "70%"}}/> :
                                    <img src={imag_svg_mobile} width={"90%"}/>
                                }
                            </Col>
                        </Row>


                    </Container>
                }

            </div>
        )
/*
    }
*/



    //-------------------------------------------  MOBILE VERS  -------------------------------------------

/*
    else {
        return(
            <div className="nous-connaitre deroul">
                {loading ? <div className="spinner-center">
                        <Spinner animation="border" role="status" variant="primary"/>
                </div>:
                    <Container fluid className={"mobile-ver qui-sommes-nous"} >
                        <Row style={{justifyContent: "center"}}>
                            <h4 className="titre-35px">Déroulement du tirage</h4>
                        </Row>

                        <Row>{ ReactHtmlParser(contenu) }</Row>
                        <Row style={{justifyContent: "center", margin:"50px 0px 20px"}}>
                            <h4 className="txt-30px" style={{color: "#000"}}>Déroulement dans le détail</h4>
                        </Row>
                        <Row style={{justifyContent: "center"}}>
                            <img src={imag_svg_mobile} style={{width: "320px"}}/>
                        </Row>

                    </Container>
                }
            </div>

        )
    }
*/

}

export default Deroulement;
