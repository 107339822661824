import React, { useState,Component } from 'react';
import Navbar from "react-bootstrap/Navbar";
import { Menu, Dropdown } from 'antd';
import frenchFlag from '../Images/french-flag.png';
import SpainFlag from '../Images/Spain_flag.png';
import BritainFlag from '../Images/Flag_Britain.png';
import {AccountCircle} from "@material-ui/icons";

import AccountCircle_mobile from '../Images/Account-circle.png';
import ItalyFlag from '../Images/italy.png';
import {Link, NavLink, Redirect} from "react-router-dom"; // Tell webpack this JS file uses this image
import {IsLoggedIn} from "../Helpers";
import Logo from "../Images/Logo.png"
import "./Navbar2.css"
import axios from "axios";
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import MenuOutlined from "@ant-design/icons/lib/icons/MenuOutlined";
import {Drawer, Select} from "antd";
import { withTranslation } from 'react-i18next';
import Button from "antd/lib/button";
import i18next from "i18next";

const { Option } = Select;


logout=(e)=>{

}

/**
 * if the user already authenticated
 */



/*const FlagsMenu = (

        <Select defaultValue="lucy" style={{ width: 120 }} >
            <Option value="jack"><img src={SpainFlag} width={30} height={25}/></Option>
            <Option value="lucy"><img src={BritainFlag} width={30} height={25}/></Option>
            <Option value="Yiminghe"><img src={BritainFlag} width={30} height={25}/></Option>
        </Select>
        /!*{/!*<Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="#">
                <img src={SpainFlag} width={30} height={25}/>
            </a>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="#">
                <img src={BritainFlag} width={30} height={25}/>
            </a>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="#">
                <img src={ItalyFlag} width={30} height={25}/>
            </a>
        </Menu.Item>*!/}
   *!/
);*/


function logout(){
    localStorage.clear('usertoken')
    this.setState({navigate : true});
}


class NavBar extends Component {
    constructor() {
        super()
        this.state = {
            user:[],
            DrawerVisible : false,
            selectedLang : localStorage.getItem("i18nextLng") || "fr"
        }
        this.showDrawer = this.showDrawer.bind(this)
        this.onClose = this.onClose.bind(this)

    }

    showDrawer(){
        console.log("clicked")
        this.setState({
            DrawerVisible : true
            })
    };
    onClose(){
        console.log("click")
        this.setState({
            DrawerVisible : false
        })
    };

    state = {
        navigate: false
    };
    componentDidMount() {
        if (IsLoggedIn()){
            axios.get('/api/users/profile/forNavbar')
                .then(res => {
                    console.log("daaaaaaaaaaata")
                    console.log(res.data);
                    this.setState({
                        user :res.data,
                    })
                    window.Pseudonyme = this.state.user.Pseudonyme

                    this.displayImage('data:image/jpeg;base64,' + new Buffer(this.state.user.image, 'binary').toString('base64'))
                });
        }
    }

    displayImage(src){
        document.getElementById("navbar-img").src=src;
    }

    async changeLang(value){
        await i18next.changeLanguage(value);
    }

    async changeLangMobile(value){
        await i18next.changeLanguage(value);
        this.onClose()
    }

    render() {
        const {t} = this.props

        const menu_mobile = (
            <Menu>
                <Menu.Item onClick={this.onClose}>
                    <NavLink
                        exact
                        to="/login"
                        className="menu-a"
                    >
                        <span>{t('44')}</span>
                    </NavLink>

                </Menu.Item>
                <Menu.Item onClick={this.onClose}>
                    <NavLink
                        exact
                        to="/register"
                        className="menu-a"
                    >
                        <span >{t('45')}</span>
                    </NavLink>
                </Menu.Item>

            </Menu>
        );

        const menu = (
            <Menu>
                <Menu.Item className={"dropdown-nav"}>
                    <NavLink
                        exact
                        to="/login"
                    >
                        {t('44')}
                    </NavLink>

                </Menu.Item>
                <Menu.Item className={"dropdown-nav"}>
                    <NavLink
                        exact
                        to="/register"
                    >
                        {t('45')}
                    </NavLink>
                </Menu.Item>

            </Menu>
        );

        const LoggedMenu_mobile = (
            <Menu>
                <Menu.Item>
                        <span className="menu-a" onClick={this.onClose}>
                            {this.state.user.Pseudonyme}</span>
                </Menu.Item>
                {/*<Menu.Item>
                    <NavLink exact to="/Tirage" className="menu-a">
                        <span onClick={this.onClose}>Tirages</span></NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink exact to="/assistance" className="menu-a">
                        <span onClick={this.onClose}>Assistance</span></NavLink>
                </Menu.Item>*/}
                <Menu.Item>
                    <NavLink
                        exact
                        className="menu-a"
                        to="/login"
                        onClick={e=>{e.preventDefault();
                            localStorage.removeItem('usertoken');
                            console.log("deconnexion ...")
                            window.location.reload();                }}>
                        <span onClick={this.onClose}>Se déconnecter</span>
                    </NavLink>
                </Menu.Item>

            </Menu>
        );

        const LoggedMenu = (
            <Menu>
                <Menu.Item>
                    <NavLink exact to="/moncompte">{t('63')}</NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink exact to="/Tirage">{t('64')}</NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink exact to="/assistance">{t('65')}</NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink
                        exact
                        to="/login"
                        onClick={e=>{e.preventDefault();
                            localStorage.removeItem('usertoken');
                            console.log("deconnexion ...")
                            window.location.reload();                }}>
                        {t('66')}
                    </NavLink>
                </Menu.Item>

            </Menu>
        );


        let width = window.innerWidth;
        //console.log("width of the screen : " + width)

        if (width > 768){

            return (
                <div className="mt-2">
                    <Navbar bg="transparent" expand="lg" className="main-nav shadow-sm">
                        <Navbar.Brand href="/" style={{textAlign: "-webkit-center"}}>

                            <img src={Logo} height={"70px"} style={{verticalAlign:"super"}} />
                            <div style={{display:"inline-block",fontFamily : "Roboto", verticalAlign : "bottom",
                                marginLeft :"10px"
                            }}>
                                <label className="logo-brand">
                                    Com'on Pot
                                </label>
                                <p style={{fontSize : "10px",textAlign : "left"}}>
                                    The only social lottery</p>
                            </div>


                        </Navbar.Brand>
                        {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
                        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                            <nav className="navbar" >
                                {/*<NavLink
                                    exact
                                    activeClassName="navbar__link--active"
                                    className="navbar__link"
                                    to="/"
                                >
                                    {t('6')}
                                </NavLink>*/}
                                <NavLink
                                    exact
                                    activeClassName="navbar__link--active"
                                    className="navbar__link"
                                    to="/nous_connaitre"
                                >
                                    {t('7')}
                                </NavLink>
                                <NavLink
                                    exact
                                    activeClassName="navbar__link--active"
                                    className="navbar__link"
                                    to="/concept"
                                >
                                    {t('8')}
                                </NavLink>
                                <NavLink
                                    exact
                                    activeClassName="navbar__link--active"
                                    className="navbar__link"
                                    to="/nos_avantages"
                                >
                                    {t('9')}
                                </NavLink>
                                <NavLink
                                    activeClassName="navbar__link--active"
                                    className="navbar__link"
                                    to="/regles"
                                >
                                    {t('10')}
                                </NavLink>
                                <NavLink
                                    activeClassName="navbar__link--active"
                                    className="navbar__link"
                                    to="/deroulement"
                                >
                                    {t('11')}
                                </NavLink>
                                <NavLink
                                    activeClassName="navbar__link--active"
                                    className="navbar__link"
                                    to="/les-gains"
                                >
                                    {t('301')}
                                </NavLink>
                                <NavLink
                                    activeClassName="navbar__link--active"
                                    className="navbar__link"
                                    to="/assistance_no_auth"
                                >
                                    {t('65')}
                                </NavLink>
                            </nav>
                        </Navbar.Collapse>
                        <Select defaultValue={this.state.selectedLang}
                                onChange={this.changeLang} bordered={false}>
                            <Option value="fr"><img src={frenchFlag} width={30} height={25}/></Option>
                            <Option value="es"><img src={SpainFlag} width={30} height={25}/></Option>
                            <Option value="en"><img src={BritainFlag} width={30} height={25}/></Option>
                            <Option value="it"><img src={ItalyFlag} width={30} height={25}/></Option>
                        </Select>
                        {/*<Dropdown overlay={FlagsMenu} className="nav-link">
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <img src={frenchFlag} width={30} height={25}/><DownOutlined/>
                            </a>
                        </Dropdown>*/}
                        {IsLoggedIn() ?
                            (
                                <label className="name">
                                    {this.state.user.Pseudonyme}</label>
                            )   :
                            (<label/>)}

                        <Dropdown overlay={IsLoggedIn() ? LoggedMenu : menu} className="nav-link">
                            <a className="ant-dropdown-link" style={{marginBottom : "0"}}
                               onClick={e => e.preventDefault()}>
                                {IsLoggedIn() ?
                                    (
                                        <img id="navbar-img" style={{borderRadius: "50%", width :"60px", height:"60px"}}/>
                                    )   :
                                    (<AccountCircle style={{color:"#4D61FF",fontSize:"30px"}} />)}
                            </a>
                        </Dropdown>
                    </Navbar>
                </div>
            );
        }
        /*
        * *****************************************************************************
        * **************************** VERSION MOBILE ********************************
        * *****************************************************************************
        * */
        else {
            return (<div className="mt-2" >
                <Container fluid style={{marginTop : "25px"}}>
                    <Row className="main-nav-mobile">
                        <Col xs={9} sm={7} >
                            <Link to={"/"} >
                            <img src={Logo} width={"65px"} height={"65px"} style={{verticalAlign:"top"}} />

                            <div style={{display:"inline-block",fontFamily : "Roboto", verticalAlign : "bottom",
                                marginLeft :"10px"
                            }}>
                                <label className="logo-brand">
                                    Com'on Pot
                                </label>
                                <p style={{fontSize : "12px",fontFamily : "Roboto Regular",color:"#133A50"}}>
                                    The only social lottery</p>
                            </div>
                            </Link>
                        </Col>
                        <Col style={{marginTop: "-10px"}}>
                            <Button onClick={this.showDrawer} style={{height:"auto"}}>
                                <MenuOutlined style={{fontSize : "30px", color : "rgb(76, 132, 255)"}}/>
                            </Button>
                        </Col>
                    </Row>

                    <Drawer
                        placement="right"
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.DrawerVisible}
                        getContainer={false}
                        style={{ position: 'fixed' }}
                    >
                        <Container fluid style={{padding : "0"}}>
                            <Row style={{backgroundColor: "#1349FC",padding: "30px 10px"}}>
                                <Col>
                                    {IsLoggedIn() ? LoggedMenu_mobile : menu_mobile}
                                </Col>
                                <Col>
                                    <NavLink exact to="/moncompte">
                                        {IsLoggedIn() ?
                                            (
                                                <img id="navbar-img" style={{borderRadius: "50%", width :"85%"}}
                                                     onClick={this.onClose}/>
                                            )   :
                                            (<img src={AccountCircle_mobile} alt="account circle"
                                                  onClick={this.onClose}
                                            />)}
                                    </NavLink>

                                </Col>
                            </Row>

                            <div className={"back-drawer"}>

                            <Row className = "mobile-men">
                            <NavLink
                                        exact
                                        className="navbar__link nav-mobile"
                                        to="/nous_connaitre"
                                    >
                                <span onClick={this.onClose}>{t('7')}</span>
                                    </NavLink>
                            </Row>
                            <Row className = "mobile-men">
                                    <NavLink
                                        exact
                                        className="navbar__link nav-mobile"
                                        to="/concept"
                                    >
                                        <span onClick={this.onClose}>{t('8')}</span>
                                    </NavLink>
                            </Row>
                            <Row className = "mobile-men">
                                    <NavLink
                                        exact
                                        className="navbar__link nav-mobile"
                                        to="/nos_avantages"
                                    >
                                        <span onClick={this.onClose}>{t('9')}</span>
                                    </NavLink>
                            </Row>
                            <Row className = "mobile-men">
                                    <NavLink
                                        className="navbar__link nav-mobile"
                                        to="/regles"
                                    >
                                        <span onClick={this.onClose}>{t('10')}</span>
                                    </NavLink>
                            </Row>
                            <Row className = "mobile-men">
                                    <NavLink
                                        className="navbar__link nav-mobile"
                                        to="/deroulement"
                                    >
                                        <span onClick={this.onClose}>{t('11')}</span>
                                    </NavLink>
                            </Row>
                                <Row className = "mobile-men">
                                    <NavLink
                                        className="navbar__link nav-mobile"
                                        to="/les-gains"
                                    >
                                        <span onClick={this.onClose}>{t('301')}</span>
                                    </NavLink>
                            </Row>
                            <Row className = "mobile-men">
                                    <NavLink
                                        className="navbar__link nav-mobile"
                                        to="/assistance_no_auth"
                                    >
                                        <span onClick={this.onClose}>{t('65')}</span>
                                    </NavLink>
                            </Row>
                            <Row style={{margin: "25px auto"}}>
                                <Col>
                                  <img src={frenchFlag} width={30} height={25}
                                       onClick={()=>{this.changeLangMobile('fr')}}/>
                                </Col>
                                <Col>
                                  <img src={SpainFlag} width={30} height={25}
                                       onClick={()=>{this.changeLangMobile('es')}}/>
                                </Col>
                                <Col>
                                  <img src={BritainFlag} width={30} height={25}
                                       onClick={()=>{this.changeLangMobile('en')}}/>
                                </Col>
                                <Col>
                                  <img src={ItalyFlag} width={30} height={25}
                                       onClick={()=>{this.changeLangMobile('it')}}/>
                                </Col>
                            </Row>
                            </div>
                        </Container>
                    </Drawer>

                </Container>

            </div>)
        }

    }
}

export default withTranslation()(NavBar);




