import React from "react";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker
} from "react-simple-maps";

const geoUrl =
    "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const markers = [
    {markerOffset: -15, name: "QDRPYCHT", coordinates: [-58.3816, -34.6037]},
    { markerOffset: -25, name: "EO5GMZOO", coordinates: [ 2.213749,46.227638] },
    { markerOffset: 25, name: "UV7HFBAJ", coordinates: [ -106.346771 , 56.130366] },
    { markerOffset: 25, name: "NBACXWQZ", coordinates: [-119.417931, 36.778259] },
    { markerOffset: 25, name: "ABSCDCHT", coordinates: [133.775136, -25.274398] },
];

const MapChart = () => {
    return (
        <ComposableMap className="map">
            <Geographies geography={geoUrl}>
                {({ geographies }) =>
                    geographies
                        .map(geo => (
                            <Geography
                                key={geo.rsmKey}
                                geography={geo}
                                fill="#0452fc"
                                stroke="#D6D6DA"
                            />
                        ))
                }
            </Geographies>
        </ComposableMap>
    );
};

export default MapChart;
