import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import './MesNotifications.css'
import {Button, Card, Progress, Select} from "antd";
import VerticalNavbar2 from "../components/VerticalNavbar2";
import {FormatDate, IsLoggedIn} from "../Helpers";
import HeaderNavbarDashboard from "../components/HeaderNavbarDashboard";
import {NavLink} from "react-router-dom";
import arrow_bottom from "../Images/arrow-bottom.png";
import arrow from "../Images/arrow-right.png";
import axios from "axios";
import rookie from "../Images/parrainages/Rookie -min.png"
import bronze from "../Images/parrainages/Bronze-min.png"
import silver from "../Images/parrainages/Silver-min.png"
import platinium from "../Images/parrainages/Platinum-min.png"
import gold from "../Images/parrainages/Gold-min.png"
import diamond from "../Images/parrainages/Diamond -min.png"
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import '../components/tirage.css';
import {FaBell, GoAlert} from "react-icons/all";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {withTranslation} from "react-i18next";


let progress = 0
class Parrainage extends Component {
    constructor(){
        super();
        this.state = {
            codeParrainage:"",
            niveau: "",
            nextNiveau :"",
            imgNiveau : "",
            nextNumber: 1,
            progress : 0,
            number:"",
            credit: 0,
            copySuccess: false,
            users : []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        axios.get('/api/parrainage/code')
            .then((res) => {
                console.log(res.data)
                this.setState({codeParrainage : res.data})
            });
        axios.get('/api/parrainage/niveau')
            .then((res) => {
                console.log(res.data)
                this.setState({niveau : res.data.niveau.Niveau, number : res.data.number,
                    credit : res.data.niveau.Credit,users : res.data.users})
                switch (this.state.niveau) {
                    case "Rookie":
                        this.setState({imgNiveau : rookie, nextNiveau : "Bronze",
                            nextNumber : 10})
                        break;
                    case "Bronze":
                        this.setState({imgNiveau : bronze, nextNiveau : "Silver",nextNumber : 20})
                        break;
                    case "Silver":
                        this.setState({imgNiveau : silver, nextNiveau : "Gold",nextNumber : 40})
                        break;
                    case "Gold":
                        this.setState({imgNiveau : gold, nextNiveau : "Platinium",nextNumber : 70})
                        break;
                    case "Platinium":
                        this.setState({imgNiveau : platinium, nextNiveau : "Diamond",nextNumber : 110})
                        break;
                    case "Diamond":
                        this.setState({imgNiveau : diamond, nextNiveau : "",nextNumber : 170})
                        break;
                }
                progress = (this.state.number / this.state.nextNumber) * 100
                this.setState({progress : progress})
                console.log(progress)
            })
    }

    copyCodeToClipboard = () => {
        const el = this.state.codeParrainage
        el.select()
        document.execCommand("copy")
        this.setState({copySuccess: true})
    }

    render() {
        let width = window.innerWidth


        const {t} = this.props
        if(width > 768) {
            return (
                <Container fluid>
                    <Row style={{padding: "0 100px"}}>
                        {IsLoggedIn() ?
                            (
                                <Col>
                                    <HeaderNavbarDashboard/>
                                </Col>
                            ) :
                            (<div/>)}
                    </Row>
                    <Row className="notif">
                        {IsLoggedIn() ?
                            (
                                <Col md={2}>
                                    <VerticalNavbar2/>
                                </Col>
                            ) :
                            (<div/>)}
                        <Col style={{marginTop: "20px", marginLeft: "100px"}}>
                            <Row style={{marginBottom: "30px"}}>
                                <Col>
                                    <h4>{t('150')}</h4>
                                </Col>
                            </Row>
                            <Row className="rows">
                                <Col className="vertic"  md={"6"}>
                                    <p>
                                        {t('226')}
                                    </p>
                                    <p>{t('227')} :</p>
                                    <p>{t('228')}<br/>
                                        {t('229')}<br/>
                                        {t('230')}<br/>
                                        {t('231')}<br/>
                                        {t('232')}<br/>
                                        {t('233')}</p>
                                    <p>
                                        {t('234')}
                                        {t('235')}
                                    </p>
                                    <p>{t('236')}
                                    </p>
                                    <Card bordered={false} className="shadow mt-4 bg-white rounded parrainage">
                                        <Row>
                                            <Col>
                                                {this.state.codeParrainage}
                                            </Col>
                                            <Col>
                                                <CopyToClipboard text={this.state.codeParrainage}
                                                onCopy={() => this.setState({copySuccess: true})}>
                                                    <button
                                                        type="submit"
                                                        className="btn-valider"
                                                        style={{float:"right",padding:"5px 30px"}}
                                                    >
                                                        {t('237')}
                                                    </button>
                                                </CopyToClipboard>

                                            </Col>
                                            {
                                                this.state.copySuccess ?
                                                    <Col>
                                                        <div style={{"color": "green"}}>
                                                            {t('238')}
                                                        </div>
                                                    </Col>
                                                     : null
                                            }
                                        </Row>
                                    </Card>

                                </Col>

                                <Col style={{textAlign : "right"}}>
                                    <img src={this.state.imgNiveau} style={{width : "80%"}}
                                         alt={this.state.imgNiveau}/>
                                    <div style={{width: "40%", float: "right",marginTop : "70px"}} className={"parr"}>
                                        <div style={{marginBottom : "40px"}}>
                                            <label>{t('239')} : </label> <label>{this.state.niveau}</label>
                                        </div>
                                        <div>
                                            <label className={"left-parr"}>{this.state.niveau} </label>
                                            <label className={"right-parr"}>{this.state.nextNiveau}</label>
                                        </div>
                                            <Progress percent={this.state.progress} showInfo={false}
                                                      strokeColor={"#0612B7"} trailColor="#BFE3FF"/>
                                        <div><label>{this.state.number} / {this.state.nextNumber}</label></div>
                                        <div>
                                            <label>{t('240')} : </label> <label>{this.state.credit}</label>
                                        </div>
                                    </div>
                                         </Col>
                            </Row>

                            <Row style={{marginTop : "50px", marginBottom : "10px"}}>
                                <h6>{t('241')}</h6>
                            </Row>

                            <Row>
                                <Table aria-label="simple table" className="table-striped-rows">
                                    <TableHead style={{background: "#4F3DFF 0% 0% no-repeat padding-box",
                                        borderRadius: "10px 10px 0px 0px", color: "white"}}>
                                        <TableRow>
                                            <TableCell align="center">{t('242')}</TableCell>
                                            <TableCell align="center">E-mail</TableCell>
                                            <TableCell align="center">{t('243')}</TableCell>
                                            {/*<TableCell align="center">Mon vote</TableCell>
                                            <TableCell align="center">Nb de gagnants</TableCell>
                                            <TableCell align="center">Montant du gain</TableCell>*/}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {this.state.users.map((row) => (
                                            <TableRow key={row[0]}>
                                                <TableCell align="center">{row.Pseudonyme}</TableCell>
                                                <TableCell align="center">{row.email}</TableCell>
                                                <TableCell align="center">{FormatDate(row.created)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            );
        }

        //************************* MOBILE VERS **************************
        else {
            return (
                <Container className ="pad-mob">
                    <Row style={{margin : "0 10px"}}>
                        {/* // ***************** MENU MON COMPTE NAVBAR *************** */}

                                <NavLink className="column col" style={{borderLeft:"5px solid white",
                                    borderRadius: "30px 30px 0 0"}}
                                         to={"/moncompte"} activeClassName="selected">
                                    <h5 style={{color : "#FFF"}}>{t('63')}</h5>
                                </NavLink>

                    </Row>


                    {/* ********************** UNDER MENU ********************************** */}
                    <Row style={{margin : "30px 15px 0px", borderBottom: "2px solid #DFE7F5",
                        paddingBottom: "5px"}}>
                        <NavLink className="und-menu"
                                 to="/moncompte" exact>
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                            marginBottom: "0", color : "#0C4EFC", width:"100%"}}>
                                            <label>{t('144')}</label>
                                            { window.pieceValidation ? <GoAlert style={{float:"right",
                                                color : "#FFC200", height : "30px", width: "30px"}}/> : <></>
                                            }
                                        </h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/notifications">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",width:"100%",
                                marginBottom: "0", color : "#0C4EFC"}}><label>{t('145')}</label>

                            {window.NotifNumber >0 ?<><FaBell style={{float:"right",
                                color : "#FF4141", height : "30px", width: "30px"}}/>
                                <label className={"bell-number"}>{window.NotifNumber}</label></> : <></>}
                            </h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/mescredits">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('146')}</h5>
                        </NavLink>
                    </Row>


                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parametres">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('148')}</h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parametres_jeu">
                            <img src={arrow_bottom} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('149')}</h5>
                        </NavLink>
                    </Row>

                    <Row className="row-und-menu">
                        <NavLink className="und-menu" to="/moncompte/parrainage">
                            <img src={arrow} width={30} height={30}/>
                            <h5 style={{marginLeft : "10px",marginTop : "2px",
                                marginBottom: "0", color : "#0C4EFC"}}>{t('150')}</h5>
                        </NavLink>
                    </Row>

                    <Row style={{margin:"30px 15px"}} className="main-div-mobile">
                        <Col>
                            <Row style={{marginBottom: "30px"}}>
                                <Col>
                                    <h4>{t('150')}</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                    <p>
                                        {t('226')}
                                    </p>
                                    <p>{t('227')} :</p>
                                    <p>{t('228')}<br/>
                                        {t('229')}<br/>
                                        {t('230')}<br/>
                                        {t('231')}<br/>
                                        {t('232')}<br/>
                                        {t('233')}</p>
                                    <p>
                                        {t('234')}
                                        {t('235')}
                                    </p>
                                    <p>{t('236')}
                                    </p>
                                    </Row>
                                    <Row>
                                        <Card bordered={false} className="shadow mt-4 bg-white rounded parrainage"
                                        style={{textAlign : "center"}}>
                                        {this.state.codeParrainage}
                                        </Card>
                                    </Row>
                                    <Row style={{justifyContent: "center", marginTop : "20px"}}>
                                        <CopyToClipboard text={this.state.codeParrainage}
                                                         onCopy={() => this.setState({copySuccess: true})}>
                                            <button
                                                type="submit"
                                                className="btn-valider"
                                                style={{float:"right",padding:"5px 30px"}}
                                            >
                                                {t('237')}
                                            </button>
                                        </CopyToClipboard>
                                    </Row>
                                    {
                                        this.state.copySuccess ?
                                            <Row style={{justifyContent: "center", marginTop : "20px"}}>
                                                <div style={{"color": "green", justifyContent: "center"}}>
                                                    {t('238')}
                                                </div>
                                            </Row>
                                            : null
                                    }

                                    <Row style={{justifyContent: "center", marginTop : "50px"}}>
                                        <img src={this.state.imgNiveau} style={{width : "100%"}}
                                             alt={this.state.imgNiveau}/>
                                        <div style={{marginTop : "40px"}} className={"parr"}>
                                            <div style={{marginBottom : "40px"}}>
                                                <label>{t('239')} : </label> <label>{this.state.niveau}</label>
                                            </div>
                                            <div>
                                                <label className={"left-parr"}>{this.state.niveau} </label>
                                                <label className={"right-parr"}>{this.state.nextNiveau}</label>
                                            </div>
                                            <Progress percent={this.state.progress} showInfo={false}
                                                      strokeColor={"#0612B7"} trailColor="#BFE3FF"/>
                                            <div><label>{this.state.number} / {this.state.nextNumber}</label></div>
                                            <div>
                                                <label>{t('240')} : {this.state.credit}</label>
                                            </div>
                                        </div>
                                    </Row>

                                    <Row style={{marginTop : "50px", marginBottom : "10px"}}>
                                        <h6>{t('241')}</h6>
                                    </Row>

                                    <Row>
                                        <TableContainer>
                                        <Table aria-label="simple table" className="table-striped-rows">
                                            <TableHead style={{background: "#4F3DFF 0% 0% no-repeat padding-box",
                                                borderRadius: "10px 10px 0px 0px", color: "white"}}>
                                                <TableRow>
                                                    <TableCell align="center">{t('242')}</TableCell>
                                                    <TableCell align="center">E-mails</TableCell>
                                                    <TableCell align="center">{t('243')}</TableCell>

                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {this.state.users.map((row) => (
                                                    <TableRow key={row[0]}>
                                                        <TableCell align="center">{row.Pseudonyme}</TableCell>
                                                        <TableCell align="center">{row.email}</TableCell>
                                                        <TableCell align="center">{FormatDate(row.created)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        </TableContainer>
                                    </Row>
                                </Col>
                            </Row>


                        </Col>
                    </Row>

                    {/* ************************* MAIN MENU SUITE ******************************   */}
                    <Row style={{margin : "30px 10px 0"}}>
                        <NavLink className="column col"
                                 to={"/Tirage"} activeClassName="selected">
                            <h5 style={{color : "#FFF"}}>{t('64')}</h5>
                        </NavLink>

                    </Row>

                    <Row style={{margin : "0 10px"}}>
                        <NavLink className="column col" style={{borderRadius: "0 0 30px 30px"}}
                                 to={"/assistance"} activeClassName="selected">
                            <h5 style={{color : "#FFF"}}>{t('65')}</h5>
                        </NavLink>
                    </Row>

                </Container>

            );
        }
    }
}

export default withTranslation()(Parrainage);
