import React, {Component, useEffect, useState} from 'react';
import {Container, Row, Spinner} from "react-bootstrap";
import "./Nous_connaitre.css"
import axios from "axios";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Col from "react-bootstrap/Col";
import fleche_bleu from "../Images/fleches_bleu.png";


const Securite_public = () =>{

    let [contenu_1, SetContenu_1] = useState("")
    let [contenu_2, SetContenu_2] = useState("")
    let [contenu_3, SetContenu_3] = useState("")
    let [contenu_4, SetContenu_4] = useState("")
    let [contenu, SetContenu] = useState("")
    let [loading, SetLoading] = useState(true)


    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/33')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
        axios.get('/api/pages/47')
            .then((res) => {
                console.log(res.data.contenu_page)
                // SetLoading(false)
                SetContenu_1(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/48')
            .then((res) => {
                console.log(res.data.contenu_page)
                // SetLoading(false)
                SetContenu_2(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
        axios.get('/api/pages/49')
            .then((res) => {
                console.log(res.data.contenu_page)
                // SetLoading(false)
                SetContenu_3(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })

        axios.get('/api/pages/33')
            .then((res) => {
                console.log(res.data.contenu_page)
                // SetLoading(false)
                SetContenu_4(res.data.contenu_page)
            }).catch((res)=>{
            console.log(res.data)
        })
    }, [])

    //---------------------------------------- DESKTOP VERS ---------------------------------------------

        return(
            <div className="nous-connaitre ">
                {loading ? <div className="spinner-center">
                        <Spinner animation="border" role="status" variant="primary"/> </div>:
                    <Container fluid className={"qui-sommes-nous"}>
                        <Row style={{justifyContent: "center"}}>
                            <h4 className="titre-35px">Sécurité</h4>
                        </Row>

                        <div>{ ReactHtmlParser(contenu_1) }</div>

                        <div>
                            <h4 className="title-secu">Pour les données personnelles :</h4>
                        </div>

                        {/* *****************  partie bas  *************** */}
                        <div>
                                {/* ***** 1 *****/}
                                <Row  className={"concept-beu-b"}>
                                    <div style={{marginRight:"40px"}}>
                                        <img src={fleche_bleu} />
                                    </div>
                                    <Col style={{paddingLeft:"0"}}>
                                        <span>Avec le protocole HTTPS et un certificat SSL</span>
                                    </Col>
                                </Row>
                            <div>{ ReactHtmlParser(contenu_2) }</div>

                            {/* ***** 2 *****/}
                                <Row  className={"concept-beu-b"}>
                                    <div style={{marginRight:"40px"}}>
                                        <img src={fleche_bleu} />
                                    </div>
                                    <Col style={{paddingLeft:"0"}}>
                                        <span>Législation RGPD</span>
                                    </Col>
                                </Row>
                            <div>{ ReactHtmlParser(contenu_3) }</div>

                            <div>
                                <h4 className="title-secu">Pour les données financières :</h4>
                            </div>

                            {/* ***** 4 *****/}
                                <Row  className={"concept-beu-b"}>
                                    <div style={{marginRight:"40px"}}>
                                        <img src={fleche_bleu} />
                                    </div>
                                    <Col style={{paddingLeft:"0"}}>
                                        <span>Sécurité paiement avec garantie 3D Secure et certifié PCI DSS</span>
                                    </Col>
                                </Row>
                            <div>{ ReactHtmlParser(contenu_4) }</div>

                        </div>


                    </Container>
                }

            </div>
        )


}

export default Securite_public;
