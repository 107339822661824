import React, {Component, useEffect, useState} from 'react';
import {Container, Row, Spinner} from "react-bootstrap";
import "./Nous_connaitre.css"
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';



const Politique_confidentialite = () =>{
    let width = window.innerWidth

    let [contenu, SetContenu] = useState("")
    let [updatedAt, SetupdatedAt] = useState("")
    let [loading, SetLoading] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/pages/4')
            .then((res) => {
                console.log(res.data.contenu_page)
                SetLoading(false)
                SetContenu(res.data.contenu_page)
            }).catch((res)=>{
                console.log(res.data)
        })
    }, [])


        return(
            <div className="nous-connaitre">
                {loading ? <div className="spinner-center"><Spinner animation="border" role="status" variant="primary"/> </div>:
                    <Container fluid>
                        <Row style={{justifyContent: "center"}}>
                            <h4 className="titre-35px">Politique de confidentialité</h4>
                        </Row>

                        <div>{ ReactHtmlParser(contenu) }</div>

                    </Container>
                }
            </div>
        )



}

export default Politique_confidentialite;
